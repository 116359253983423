var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex d-flex-c calendar-content-card"},[_c('div',{class:[
      'd-flex d-flex-r mb-1',
      { 'd-flex-between': _vm.content.project_name },
      { 'd-flex-end': !_vm.content.project_name },
    ]},[(_vm.content.project_name)?_c('span',{staticClass:"project-name"},[_vm._v(_vm._s(_vm.content.project_name))]):_vm._e(),_c('span',{staticClass:"d-flex d-flex-end close",on:{"click":_vm.closeContent}},[_vm._v("×")])]),(_vm.assetPreview)?_c('object',{staticClass:"content-img-preview mb-1",on:{"click":() => _vm.selectAsset(_vm.index)}},[_c('img',{attrs:{"src":_vm.assetPreview},on:{"error":_vm.defaultBg}})]):_vm._e(),_c('span',{staticClass:"text-preview mb-1"},[_vm._v(_vm._s(_vm.content.text))]),_vm._m(0),_c('div',{staticClass:"d-flex d-flex-c mb-1 details"},[_c('span',[_vm._v(_vm._s(_vm.content.date))])]),_vm._m(1),_c('div',{staticClass:"d-flex d-flex-c mb-1 details"},[(_vm.content.frequency === 'weekly')?_c('span',[_c('b',[_vm._v("Days: "+_vm._s(_vm.content.repeat_weekdays.toString())+" ")])]):_vm._e(),_c('span',[_c('b',[_vm._v("Repeat: ")]),_vm._v(_vm._s(_vm.content.frequency))]),_c('span',[_c('b',[_vm._v("End date: ")]),_vm._v(_vm._s(_vm.content.end_date ? _vm.content.end_date : "N/A"))])]),_c('div',{staticClass:"d-flex d-flex-r d-flex-end"},[_c('button',{staticClass:"edit-button",on:{"click":_vm.editContent}},[_vm._v("Edit")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"mb-05"},[_c('b',[_vm._v("Scheduled for")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"mb-05"},[_c('b',[_vm._v("Post summary")])])
}]

export { render, staticRenderFns }