import dayjs from "../../utils/timezone";

import {
  INIT_CONTENT_PAYLOAD,
  SET_SCHEDULE_DATE,
  RESET_CONTENT_PAYLOAD,
  ALTER_CONTENT_PAYLOAD,
  CONFIRM_SCHEDULE_DATE,
  CONFIRM_REPEAT_SETTINGS,
  RESET_SCHEDULE_DATE,
} from "../actions/schedule";

import { RESET_MODULE_STATE } from "../actions/global";

const getDefaultPayload = () => ({
  id: null,
  text: "",
  status: null,
  channels: [],
  start_date: null, // current date at 1 AM will be the default value,
  end_date: null,
  links: [],
  hash_tags: [],
  assets: [],
  project_id: null,
  repeat_weekdays: [],
  repeat_type: "never",
});

const getDefaultState = () => ({
  scheduleModalSettings: {
    selectedDate: dayjs().hour(1).minute(0).second(0), // current date at 1 AM will be the default value
  },
  fileUploadSettings: {
    uploadedImages: [],
  },
  contentPayload: getDefaultPayload(),
});

const state = getDefaultState();

const actions = {
  initContentPayload: ({ commit }, payload) => {
    commit(INIT_CONTENT_PAYLOAD, payload);
  },
  resetContentPayload: ({ commit }) => {
    commit(RESET_CONTENT_PAYLOAD);
  },
  alterContentPayload: ({ commit }, item) => {
    commit(ALTER_CONTENT_PAYLOAD, item);
  },
  setScheduleDate: ({ commit }, date) => {
    commit(SET_SCHEDULE_DATE, date);
  },
  confirmPostScheduleTime: ({ commit }, date) => {
    commit(CONFIRM_SCHEDULE_DATE, date);
  },
  confirmRepeatSettings: ({ commit }, payload) => {
    commit(CONFIRM_REPEAT_SETTINGS, payload);
  },
  resetScheduleDate: ({ commit }) => {
    commit(RESET_SCHEDULE_DATE);
  },
};

const mutations = {
  [INIT_CONTENT_PAYLOAD]: (state, payload = {}) => {
    let contentPayload = Object.assign({}, state.contentPayload);
    for (const key in payload) {
      if (key in contentPayload && payload[key]) {
        contentPayload[key] = payload[key];
        if (key === "frequency") {
          contentPayload.repeat_type = payload[key];
        }
      }
    }
    state.contentPayload = contentPayload;
  },
  [RESET_CONTENT_PAYLOAD]: (state) => {
    state.contentPayload = getDefaultPayload();
  },
  [ALTER_CONTENT_PAYLOAD]: (state, item = {}) => {
    const { key, value } = item;
    state.contentPayloadp[key] = value;
  },
  [SET_SCHEDULE_DATE]: (state, date) => {
    state.scheduleModalSettings.selectedDate = dayjs(date);
  },
  [CONFIRM_SCHEDULE_DATE]: (state, date) => {
    state.contentPayload.start_date = date;

    if (state.contentPayload.repeat_type === "never") {
      state.contentPayload.end_date = date.endOf("day");
    }
    if (
      state.contentPayload.repeat_weekdays.length > 0 &&
      state.contentPayload.repeat_type !== "weekly"
    ) {
      state.contentPayload.repeat_weekdays = [];
    }
  },
  [CONFIRM_REPEAT_SETTINGS]: (state, payload = {}) => {
    state.contentPayload = { ...state.contentPayload, ...payload };
  },
  [RESET_SCHEDULE_DATE]: (state) => {
    state.scheduleModalSettings.selectedDate = dayjs()
      .hour(1)
      .minute(0)
      .second(0);
  },
  [RESET_MODULE_STATE]: (state) => {
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    // This mutation is called from the logout action!
    Object.assign(state, getDefaultState());
  },
};

export default {
  state,
  actions,
  mutations,
};
