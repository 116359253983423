var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'd-flex d-flex-c content-card-wrapper',
    { '--is-selected': _vm.isSelected },
  ],on:{"click":function($event){return _vm.$emit('card-click')}}},[_c('div',{staticClass:"d-flex d-flex-r d-flex-between"},[_c('div',{staticClass:"d-flex d-flex-c"},[_c('div',{staticClass:"d-flex d-flex-r mb-1"},[_c('span',{staticClass:"content-card-image",style:(_vm.setBg(_vm.cardInfo.card_avatar))}),_c('div',{staticClass:"d-flex d-flex-r content-card-info"},[_c('span',{staticClass:"text-preview"},[_vm._v(_vm._s(_vm.cardInfo.text)+" ")])])]),_c('schedule-labels',{attrs:{"startTime":_vm.scheduleStartTime,"timeZone":_vm.contentTimeZone,"repeatFrequency":_vm.repeatFrequency,"repeatDays":_vm.repeatDays,"endTime":_vm.scheduleTimeEnd}})],1),_vm._m(0),_c('div',{staticClass:"d-flex d-flex-c d-flex-between"},[_c('div',{staticClass:"d-flex d-flex-c"},[_c('div',{staticClass:"d-flex d-flex-r d-flex-end mb-2"},[(_vm.cardInfo.project_name)?_c('label',{staticClass:"mr-1 project-label"},[_vm._v(_vm._s(_vm.cardInfo.project_name))]):_vm._e(),_c('trash-button',{on:{"button-click":_vm.deleteContent}})],1),_c('div',{staticClass:"d-flex d-flex-r card-socials-wrapper"},_vm._l((_vm.cardInfo.platform_types),function(platform,index){return _c('span',{key:index,class:platform.toLowerCase()})}),0)]),_c('div',{staticClass:"d-flex d-flex-r card-button-wrapper"},[(_vm.showApproveNow)?_c('button',{staticClass:"content-button",class:[
            'content-button',
            'approve',
            'mr-1',
            { 'is-approved': _vm.isApproved },
          ],on:{"click":function($event){return _vm.changeStatus('approved')}}},[_vm._v(" Approve ")]):(_vm.showScheduleNow)?_c('button',{class:[
            'content-button',
            'schedule',
            'mr-1',
            { 'is-approved': _vm.isApproved },
          ],on:{"click":function($event){return _vm.changeStatus('scheduled')}}},[_vm._v(" Schedule now ")]):_vm._e(),_c('button',{staticClass:"content-button edit",on:{"click":_vm.editContent}},[_vm._v("edit")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex d-flex-r"},[_c('div',{staticClass:"d-flex d-flex-c d-flex-end"})])
}]

export { render, staticRenderFns }