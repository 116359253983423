<template>
  <modal title="Platform optimizations" @close="closeModal">
    <div
      slot="modal-content"
      class="d-flex d-flex-c mt-1 modal-content-wrapper"
    >
      <div class="d-flex d-flex-c">
        <div class="d-flex d-flex-r mb-05">
          <platform-button
            v-for="(platform, index) in platforms"
            :key="index"
            :platform="platform"
            :is-selected="selectedPlatform === platform"
            class="mr-1"
            @button-click="selectPlatform"
          />
        </div>
        <div class="d-flex platform-text">
          <pre style="white-space: normal">
            {{ selectedPlatformText }}
            </pre
          >
        </div>
      </div>
      <dashboard-button
        class="d-flex d-align-self-end mt-2 dashboard-button"
        label="Apply optimizations"
        @button-click="confirm"
      />
    </div>
  </modal>
</template>

<script>
import { Modal } from "@/components";
import { DashboardButton } from "@/components/ui/";
import { PlatformButton } from "@/components/dashboard/";

export default {
  name: "OptimizePlatformModal",
  components: { Modal, PlatformButton, DashboardButton },
  props: {
    optimizedTexts: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      selectedPlatform: Object.keys(this.optimizedTexts)[0],
    };
  },
  computed: {
    platforms() {
      return Object.keys(this.optimizedTexts);
    },
    selectedPlatformText() {
      return this.optimizedTexts[this.selectedPlatform];
    },
  },
  methods: {
    selectPlatform(platform) {
      this.selectedPlatform = platform;
    },
    closeModal() {
      this.$emit("closeModal");
    },
    confirm() {
      this.$emit("modal-submit");
      this.closeModal();
    },
  },
};
</script>

<style lang="scss" scoped>
.platform-text {
  max-width: 450px;
}
</style>
