<template>
  <form id="loginForm" class="login-form">
    <validation-observer
      ref="loginForm"
      v-slot="{ errors }"
      @submit.prevent="handleSubmit"
    >
      <label class="d-flex d-flex-center">{{ label }}</label>
      <div v-for="(field, index) in fields" :key="index" class="field mt-2">
        <form-input
          :label="field.label"
          :name="field.name"
          :type="field.type"
          :rules="field.rules"
          :validation-mode="field.validationMode"
          :v-model="field.value"
        />
      </div>
      <span
        v-if="
          requestError &&
          errors.email &&
          errors.email.length == 0 &&
          errors.password &&
          errors.password.length == 0
        "
        class="error"
        >{{ requestError }}</span
      >

      <div class="d-flex d-flex-center password_misc_container">
        <a href="#">Forgot password?</a>
        <!-- <div class="remember-password">
					<label>Remember password</label>
					<RoundedSwitch />
				</div> -->
        <!-- {{ console.log("errororr", errors.email) }} -->
      </div>
      <div class="d-flex d-flex-center">
        <socianis-button
          label="Login"
          :isRequesting="isRequesting"
          @button-click="handleSubmit"
        />
      </div>
    </validation-observer>
  </form>
</template>

<script>
import {
  FormInput,
  SocianisButton,
  // RoundedSwitch
} from "@/components/";
import { mapActions, mapState } from "vuex";
import { ValidationObserver } from "vee-validate";

export default {
  name: "LoginForm",
  components: {
    FormInput,
    SocianisButton,
    // RoundedSwitch,
    ValidationObserver,
  },
  props: {
    label: { type: String, default: "" },
  },
  data: () => ({
    fields: [
      {
        label: "E-mail",
        name: "email",
        type: "email",
        rules: "required|email",
        value: "",
        validationMode: "loginForm",
      },
      {
        label: "Password",
        name: "password",
        type: "password",
        rules: "required",
        value: "",
        validationMode: "loginForm",
      },
    ],
  }),
  computed: {
    ...mapState({
      isRequesting: (state) => state.auth.status.requesting.login,
      requestError: (state) => state.auth.status.errors.login,
    }),
    isInviteRedirect() {
      return !!this.$route.query.token;
    },
  },
  methods: {
    ...mapActions({
      login: "login",
    }),
    handleSubmit: async function () {
      const valid = await this.$refs.loginForm.validate();
      if (valid) {
        let payload = {};
        const loginForm = document.getElementById("loginForm");
        const formData = new FormData(loginForm);
        formData.forEach((value, key) => {
          payload[key] = value;
        });
        this.login(payload).then((res) => {
          if (this.isInviteRedirect) {
            this.$router.push({ path: "invite", query: this.$route.query });
          } else {
            this.$router.push("/");
          }
        });
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.login-form {
  // padding: 0 30px 30px;
  background-color: white;
  max-width: 750px;
  width: 90%;
  padding: 3em;
  border-radius: 6px;

  span.error {
    display: block;
    text-align: right;
    font-size: $--font-size--regular;
    color: $--color-red;
    margin-bottom: 1.5em;
  }

  label {
    font-size: $--font-size--large;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: bold;
    color: $--color-black;
  }

  .password_misc_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2em 0 1em;
    font-size: 0.75rem;

    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-width: 150px;

      > label {
        margin-right: 0.5em;
      }
    }
  }
}
</style>
