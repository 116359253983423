<template>
  <div class="dashboard-content">
    <div class="d-flex d-flex-between top-content">
      <div class="d-flex d-flex-between align-center">
        <span v-if="!activeMenu.hideTitle" class="title">{{
          activeMenu.label
        }}</span>
        <div class="d-flex d-flex-row" style="margin-left: 3.5em">
          <advanced-select
            v-for="(filter, i) in activeMenu.filters"
            :key="`${i}-${filter.name}`"
            :name="filter.label"
            :items="advancedSelectItems[filter.itemsName]"
            :radio="true"
            @trigger="advancedSelectAction"
          />
        </div>
      </div>
      <content-button
        v-if="activeMenu && activeMenu.action"
        style="top: 0"
        :label="activeMenu.action.label"
        @button-click="contentAction"
      />
    </div>
    <div class="d-flex d-flex-c content-wrapper">
      <component v-bind:is="activeMenu.name" v-bind="activeMenu" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { dashboardMenuItems } from "@/utils/constants";

export default {
  name: "DashboardContent",
  components: {
    Schedule: () =>
      import("@/components/dashboard/content/").then((res) => {
        return res.Schedule;
      }),
    Projects: () =>
      import("@/components/dashboard/content/").then((res) => {
        return res.Projects;
      }),
    Channels: () =>
      import("@/components/dashboard/content/").then((res) => {
        return res.Channels;
      }),
    Media: () =>
      import("@/components/dashboard/content/").then((res) => {
        return res.Media;
      }),
    MediaEdit: () =>
      import("@/components/dashboard/content/").then((res) => {
        return res.MediaEdit;
      }),
    Drafts: () =>
      import("@/components/dashboard/content/").then((res) => {
        return res.Drafts;
      }),
    Post: () =>
      import("@/components/dashboard/content/").then((res) => {
        return res.Post;
      }),
    Team: () =>
      import("@/components/dashboard/content/").then((res) => {
        return res.Team;
      }),
    Insights: () =>
      import("@/components/dashboard/content/").then((res) => {
        return res.Insights;
      }),
    Analytics: () =>
      import("@/components/dashboard/content/").then((res) => {
        return res.Analytics;
      }),
    ContentButton: () =>
      import("@/components/ui/").then((res) => {
        return res.ContentButton;
      }),
    AdvancedSelect: () =>
      import("@/components/ui/").then((res) => {
        return res.AdvancedSelect;
      }),
  },
  computed: {
    ...mapState({
      projects: (state) =>
        [{ id: null, key: 0, value: "All" }].concat(
          state.project.projects.map((project) => {
            return {
              id: project.id,
              key: project.name,
              value: project.name,
              picture: project.project_picture,
            };
          }),
        ),
      metricChannels: (state) =>
        [{ id: null, key: 0, value: "None" }].concat(
          state.channel.channels
            .filter((channel) => {
              if (state.analytics.selectedProjectId === null) {
                return true;
              } else {
                const selectedProject =
                  state.project.projects.find(
                    (project) =>
                      project.id === state.analytics.selectedProjectId,
                  ) || {};
                if (
                  selectedProject &&
                  selectedProject.channels.includes(channel.id)
                ) {
                  return true;
                } else {
                  return false;
                }
              }
            })

            .map((channel) => {
              return {
                id: channel.id,
                key: channel.channel_name,
                value: channel.channel_name,
                picture: channel.picture_url,
                type: channel.platform_type,
              };
            }),
        ),
    }),
    menuItems() {
      return [
        dashboardMenuItems.filter((item) => !item.children || item.hideSubMenu), // 1st nav level
      ]
        .flat()
        .concat(
          dashboardMenuItems
            .filter((item) => !!item.children)
            .map((item) => item.children)
            .flat(), // 2nd nav level
        )
        .concat(
          dashboardMenuItems
            .filter((item) => !!item.children)
            .map((item) => {
              return item.children;
            })
            .map((item) =>
              item
                .filter((i) => !!i.children)
                .map((i) => i.children)
                .flat(),
            )
            .map((item) => item.flat())
            .flat(), // 3rd nav level
        );
    },
    advancedSelectItems() {
      return {
        projects: this.projects,
        metricChannels: this.metricChannels,
      };
    },
    activeMenu() {
      return (
        this.menuItems.find((item) => item.path === this.$route.path) || {}
      );
    },
  },
  methods: {
    ...mapActions({
      setActiveModal: "setActiveModal",
      setContentViewProject: "setContentViewProject",
      setDraftViewProject: "setDraftViewProject",
      setContentViewChannels: "setContentViewChannels",
      setInsightsViewProject: "setInsightsViewProject",
      setMetricProject: "setMetricProject",
      setMetricChannel: "setMetricChannel",
    }),
    contentAction() {
      if (this.activeMenu) {
        if (this.activeMenu.action.type === "navigate") {
          this.$router.push(
            `${this.activeMenu.path}/${this.activeMenu.action.params.path}`,
          );
        } else if (this.activeMenu.action.type === "modal") {
          this.setActiveModal({
            name: this.activeMenu.action.params.name,
            modalSubmit: () => ({}),
          });
        }
      }
    },
    advancedSelectAction(name, val) {
      const filter = this.activeMenu.filters.find(
        (filter) => filter.label === name,
      );

      if (filter) {
        this[filter.triggerName](val);
      }
    },
    setContentProject(id) {
      this.setContentViewProject(id);
    },
    setDraftProject(id) {
      this.setDraftViewProject(id);
    },
    setInsightsProject(id) {
      this.setInsightsViewProject(id);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.dashboard-content {
  position: relative;
  display: flex;
  flex-direction: column;
  left: 70px;
  height: 100%;
  min-height: 100vh;
  width: calc(100% - 70px);
  padding: 2em 1.5em;

  > div {
    max-width: 1500px;
  }

  .dashboard-error {
    display: flex;
    justify-content: center;
    position: absolute;

    width: 90%;
    padding: 1em;
    background-color: rgb(255, 59, 59);
    border-radius: 6px;
    color: white;
    font-weight: bold;
    bottom: 1em;
    left: 5%;
  }

  .top-content {
    position: relative;
    width: 100%;
    align-self: center;
    align-items: center;
    .title {
      position: relative;
      text-transform: uppercase;
      font-size: 1.25rem;
      font-weight: bold;
    }
  }

  .content-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    align-self: center;
    z-index: 99;
    .content {
      position: relative;
      display: flex;
      width: 100%;
      height: 100%;
      margin-top: 2em;
      padding: 1em;
      background-color: white;
      border-radius: 6px;
      min-height: 80vh;
      /deep/.content-left {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 1.5;
        height: 80vh;
        min-height: 720px;

        .tabs-wrapper {
          display: flex;
          margin-bottom: 3em;

          .tab {
            position: relative;
            margin-right: 4em;
            padding: 0.5em 1em;
            cursor: pointer;
            &.selected {
              background-color: #d5daef;
              border-radius: 6px;
            }
            &::after {
              content: "";
              display: block;
              position: absolute;
              height: 75%;
              width: 2px;
              border-right: 2px solid #000;
              right: -25px;
              top: 12.5%;
            }

            .tab-label {
              font-size: 1rem;
              cursor: pointer;
            }

            &:last-child {
              margin-right: 0;
              &::after {
                display: none;
              }
            }
          }
        }

        .content-displays-wrapper {
          display: flex;
          justify-content: flex-start;
          flex-direction: row;
          flex-wrap: wrap;
          padding-right: 1.5em;
          overflow-y: scroll;
          .content-display-wrapper:nth-child(3n + 3) {
            .picture {
              margin-right: 0;
            }
            .content-display-label {
              margin-right: 0;
            }
          }
          .content-display-wrapper:nth-child(3n + 1) {
            .picture {
              margin-left: 0;
            }
            .content-display-label {
              margin-left: 0;
            }
          }
        }
      }
      /deep/ .content-right {
        flex: 1.5;
        height: 80vh;
        min-height: 720px;
        .content-items {
          display: flex;
          flex-direction: column;
          height: 100%;

          padding: 1em 0.5em 1em 1em;
          background-color: #f9f9f9;
          border-radius: 6px;
          &.empty {
            position: relative;
            &::after {
              content: "";
              position: absolute;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1475px) {
  .dashboard-content {
    .content-wrapper {
      .content {
        /deep/.content-left {
          .content-displays-wrapper {
            justify-content: space-between;

            .content-display-wrapper:nth-child(3n + 3) {
              .picture {
                margin-right: unset;
              }
              .content-display-label {
                margin-right: unset;
              }
            }
            .content-display-wrapper:nth-child(3n + 1) {
              .picture {
                margin-left: unset;
              }
              .content-display-label {
                margin-left: unset;
              }
            }
          }
        }
      }
    }
  }
}
</style>
