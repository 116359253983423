<template>
  <form id="registerForm" class="register-form">
    <validation-observer
      class="d-flex d-flex-c"
      ref="registerForm"
      v-slot="{ invalid, errors }"
    >
      <label>{{ label }}</label>
      <div v-if="requestSuccess">
        <div v-if="token" class="register-message">
          Your registration is complete. You can now continue to login below.
        </div>
        <span v-else class="register-message"
          >To complete your registration please follow the verification link
          sent to your email.</span
        >
      </div>
      <div v-else class="d-flex d-flex-c">
        <div v-for="(field, index) in fields" :key="index" class="mt-2">
          <form-input
            :label="field.label"
            :name="field.name"
            :type="field.type"
            :rules="field.rules"
          />
        </div>
        <span
          v-if="
            requestError &&
            errors.email &&
            errors.email.length == 0 &&
            errors.full_name &&
            errors.full_name.length == 0 &&
            errors.password &&
            errors.password.length == 0
          "
          class="error"
          >{{ requestError }}</span
        >

        <span class="small mt-2 mb-2"
          >By signing up you agree with our
          <a href="https://socianis.com/privacy">Privacy Policy</a> and
          <a href="https://socianis.com/terms">Terms and Conditions</a></span
        >
        <div class="d-flex d-flex-r d-flex-end mb-5">
          <socianis-button
            label="Create account"
            :isRequesting="isRequesting"
            @button-click="handleSubmit"
          />
        </div>
      </div>
    </validation-observer>
  </form>
</template>

<script>
import { SocianisButton, FormInput } from "@/components";
import { mapActions, mapState } from "vuex";
import { ValidationObserver } from "vee-validate";

export default {
  name: "RegistrationForm",
  components: { SocianisButton, FormInput, ValidationObserver },
  props: {
    label: { type: String, default: "" },
    token: { type: String, default: null },
  },
  data: () => ({
    requestSuccess: false,
    fields: [
      {
        label: "Full name",
        name: "full_name",
        type: "text",
        rules: "required|alpha_spaces",
        value: "",
      },
      {
        label: "E-mail",
        name: "email",
        type: "email",
        rules: "required|email",
        value: "",
      },
      {
        label: "Password",
        name: "password",
        type: "password",
        rules: "required",
        value: "",
      },
    ],
    submitted: false,
  }),
  computed: {
    ...mapState({
      isRequesting: (state) => state.auth.status.requesting.register,
      requestError: (state) => state.auth.status.errors.register,
    }),
  },
  watch: {
    serverErrors(newValue) {
      if (Object.keys(newValue).length !== 0) {
        this.$refs.registerForm.setErrors(newValue);
      }
    },
  },
  methods: {
    ...mapActions({
      register: "register",
    }),
    handleSubmit: function () {
      let data = {};
      const params = { ...(this.token && { token: this.token }) };
      const registerForm = document.getElementById("registerForm");
      const formData = new FormData(registerForm);
      formData.forEach((value, key) => {
        data[key] = value;
      });
      const payload = {
        data,
        params,
      };
      this.register(payload).then((res) => {
        this.requestSuccess = true;
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.register-form {
  position: relative;
  margin-bottom: 1em;

  .register-message {
    text-align: left;
  }

  span.error {
    display: block;
    text-align: right;
    font-size: $--font-size--regular;
    color: $--color-red;
    margin-bottom: 1.5em;
  }

  label {
    display: block;
    font-size: $--font-size--large;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: bold;
    color: $--color-black;
    text-align: left;
  }
  .small {
    font-size: $--font-size--regular;
  }

  button {
    right: 0;
  }
}

.button-wrapper {
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-self: flex-end;
  justify-self: flex-end;
}
</style>
