import { loadScript } from "@paypal/paypal-js";
import { encryptParam } from "./url";
import CONFIG from "../config";

let stripePaymentElement = null;
let paypal = null;
const stripe = window.Stripe(CONFIG.STRIPE_PUBLIC_KEY);
loadScript({
  "client-id": CONFIG.PAYPAL_PUBLIC_KEY,
  "disable-funding":
    "ideal,sofort,card,credit,paylater,bancontact,blik,eps,giropay,mercadopago,mybank,p24,sepa,venmo",
  vault: true,
  intent: "subscription",
}).then((res) => {
  paypal = res;
});

export const PAYMENT_METHOD_UPDATE = "payment_method_update";
export const SUBSCRIPTION_PLAN_SELECTION = "subscription_plan_selection";
export const paymentMethods = [
  {
    name: "Stripe",
    type: "Stripe",
    label: "Debit/Credit card",
    provider: "stripe",
  },
  { name: "PayPal", type: "PayPal", label: "paypal", provider: "paypal" },
];

export const renderStripeDetails = (secret) => {
  return new Promise((resolve) => {
    if (secret) {
      const options = {
        clientSecret: secret,
        theme: "none",
        appearance: {
          variables: {
            colorBackground: "#f1f9ff",
            borderRadius: "6px",
            colorText: "#2699fb",
          },
          rules: {
            ".Input": {
              border: "1px solid #66bbff",
              boxShadow: "none",
              outline: "none",
            },
          },
        },
      };
      // Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained in step 5
      const elements = stripe.elements(options);
      const paymentElement = elements.create("payment");
      paymentElement.mount("#payment-form");
      paymentElement.on("ready", function (event) {
        stripePaymentElement = elements;
        resolve(true);
      });
    }
  });
};

export const billingSettings = (
  tier = "",
  provider = "",
  billingCycle = "",
) => {
  return {
    [SUBSCRIPTION_PLAN_SELECTION]: {
      confirmButtonText: "Pay now",
      returnUrl: `${CONFIG.CLIENT_REDIRECT_URL}/plans/${tier}/confirmed?provider=${provider}&cycle=${billingCycle}`,
    },
    [PAYMENT_METHOD_UPDATE]: {
      confirmButtonText: "Submit",
      returnUrl: `${CONFIG.CLIENT_REDIRECT_URL}/profile/subscription/confirmed?provider=${provider}`,
    },
  };
};

export const confirmSetup = (returnUrl, redirect = "always") => {
  return new Promise(async (resolve, reject) => {
    const { setupIntent, error } = await stripe.confirmSetup({
      elements: stripePaymentElement,
      confirmParams: {
        return_url: returnUrl,
      },
      redirect,
    });
    stripePaymentElement = null;
    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      // console.log("err", error.message);
      reject(error.message);
    } else {
      resolve(setupIntent);
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  });
};

export const renderPayPalButton = (
  confirmPayment,
  redirectUrl,
  redirect = true,
) => {
  return new Promise((resolve) => {
    if (paypal) {
      paypal
        .Buttons({
          createSubscription: async (data, actions) => {
            const { plan_id, start_time } = await confirmPayment();
            return actions.subscription.create({
              plan_id,
              ...(start_time && { start_time }),
            });
          },
          onApprove: async (data, actions) => {
            if (redirect) {
              const redirect_id = encryptParam(
                CONFIG.URL_SALT,
                data.subscriptionID,
              );
              actions.redirect(
                `${redirectUrl}&redirect_id=${redirect_id}&redirect_status=succeeded`,
              );
            } else {
              import("./event-bus").then(({ eventBus }) => {
                eventBus.$emit("paypal-approved", data.subscriptionID);
              });
            }
          },
          style: {
            layout: "vertical",
            color: "blue",
            shape: "rect",
            label: "paypal",
          },
        })
        .render("#paypal-button-container")
        .then((res) => {
          resolve(true);
        });
    }
  });
};
