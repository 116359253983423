var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{staticClass:"register-form",attrs:{"id":"registerForm"}},[_c('validation-observer',{ref:"registerForm",staticClass:"d-flex d-flex-c",scopedSlots:_vm._u([{key:"default",fn:function({ invalid, errors }){return [_c('label',[_vm._v(_vm._s(_vm.label))]),(_vm.requestSuccess)?_c('div',[(_vm.token)?_c('div',{staticClass:"register-message"},[_vm._v(" Your registration is complete. You can now continue to login below. ")]):_c('span',{staticClass:"register-message"},[_vm._v("To complete your registration please follow the verification link sent to your email.")])]):_c('div',{staticClass:"d-flex d-flex-c"},[_vm._l((_vm.fields),function(field,index){return _c('div',{key:index,staticClass:"mt-2"},[_c('form-input',{attrs:{"label":field.label,"name":field.name,"type":field.type,"rules":field.rules}})],1)}),(
          _vm.requestError &&
          errors.email &&
          errors.email.length == 0 &&
          errors.full_name &&
          errors.full_name.length == 0 &&
          errors.password &&
          errors.password.length == 0
        )?_c('span',{staticClass:"error"},[_vm._v(_vm._s(_vm.requestError))]):_vm._e(),_c('span',{staticClass:"small mt-2 mb-2"},[_vm._v("By signing up you agree with our "),_c('a',{attrs:{"href":"https://socianis.com/privacy"}},[_vm._v("Privacy Policy")]),_vm._v(" and "),_c('a',{attrs:{"href":"https://socianis.com/terms"}},[_vm._v("Terms and Conditions")])]),_c('div',{staticClass:"d-flex d-flex-r d-flex-end mb-5"},[_c('socianis-button',{attrs:{"label":"Create account","isRequesting":_vm.isRequesting},on:{"button-click":_vm.handleSubmit}})],1)],2)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }