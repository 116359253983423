<template>
  <!-- <div class="lds-hourglass" /> -->
  <div
    class="d-flex d-flex-r align-center spinner-wrapper"
    :style="backgroundStyle"
  >
    <span class="loader" :style="style"></span>
    <label v-if="label" :style="labelStyle">{{ label }}</label>
  </div>
</template>

<script>
export default {
  name: "Loader",
  props: {
    color: {
      type: String,
      default: "#fff",
    },
    width: { type: Number, default: 48 },
    height: { type: Number, default: 48 },
    label: { type: String, default: "" },
    labelSize: { type: Number, default: 16 },
    wrapperStyle: { type: Object, default: () => {} },
    showBackground: { type: Boolean, default: false },
    backgroundColor: { type: String, default: "#ccc" },
  },
  computed: {
    style() {
      return {
        "border-color": this.color,
        "border-bottom-color": "transparent",
        width: `${this.width}px`,
        height: `${this.height}px`,
      };
    },
    backgroundStyle() {
      return {
        ...this.wrapperStyle,
        ...(this.showBackground && {
          "background-color": this.backgroundColor,
          width: "fit-content",
          padding: "0.25em 0.5em",
          display: "inline-flex",
        }),
      };
    },
    labelStyle() {
      return {
        "font-size": `${this.labelSize}px`,
        "margin-left": "0.5em",
        color: this.color,
      };
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.spinner-wrapper {
  border-radius: 6px;

  .loader {
    border: 2px solid #fff;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
