import httpClient from "./httpClient";

const USER_END_POINT = "/user";

export const register = (data = {}, params = {}) =>
  httpClient.post(`${USER_END_POINT}/register`, data, { params });

export const login = (payload = {}) =>
  httpClient.post(`${USER_END_POINT}/login`, payload);

export const verifyRegistration = (params = {}) =>
  httpClient.post(`${USER_END_POINT}/register/verify`, {}, { params });

export const logout = () => httpClient.post(`${USER_END_POINT}/logout`);
