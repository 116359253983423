<template>
  <div
    :class="[
      'd-flex d-flex-r align-center platform-button-wrapper',
      { 'is-selected': isSelected },
    ]"
    @click="handleClick"
  >
    <span :class="['platform-logo mr-05', platform.toLowerCase()]" />
    <label>{{ platform }}</label>
  </div>
</template>

<script>
export default {
  name: "PlatformButton",
  props: {
    platform: { type: String, default: "" },
    isSelected: { type: Boolean, default: false },
  },
  methods: {
    handleClick() {
      this.$emit("button-click", this.platform);
    },
  },
};
</script>

<style lang="scss" scoped>
.platform-button-wrapper {
  padding: 0.5em;
  border-radius: 6px;
  background-color: white;
  cursor: pointer;
  &.is-selected {
    background-color: #d5daef;
  }
  .platform-logo {
    height: 24px;
    width: 24px;
    background-size: contain;
    background-repeat: no-repeat;

    &.facebook {
      background-image: url("/assets/images/icons/social/facebook_channel.png");
    }
    &.instagram {
      background-image: url("/assets/images/icons/social/instagram_channel.png");
    }
    &.linkedin {
      background-image: url("/assets/images/icons/social/linkedin_channel.png");
    }
    &.twitter {
      background-image: url("/assets/images/icons/social/twitter_channel.png");
    }
  }
  label {
    text-transform: capitalize;
    cursor: pointer;
    font-weight: 200;
  }
}
</style>
