<template>
  <button class="trash" @click="handleClick" />
</template>

<script>
export default {
  name: "TrashButton",
  methods: {
    handleClick() {
      this.$emit("button-click");
    },
  },
};
</script>

<style lang="scss" scoped>
.trash {
  display: block;
  width: 24px;
  height: 24px;
  background-image: url("/assets/images/icons/common/trash_icon.webp");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-color: white;
  border-radius: 4px;
  cursor: pointer;
  border: none;
}
</style>
