<template>
  <div :class="['content', { 'd-flex-c': monthView }]">
    <div class="content-left">
      <month-view
        :contents="monthView ? monthlySortedContents : contents"
        :monthView="monthView"
      />
      <drop-down
        style="position: absolute; right: 15px; top: 0"
        :width="100"
        :items="
          tabs.filter((tab) => tab.name !== activeTab).map((tab) => tab.label)
        "
        @select-event="selectTab"
        :placeholder="tabs[0].label"
        :fitContent="true"
        :inverseDirection="true"
      />
    </div>
    <div v-if="!monthView" class="content-right">
      <div
        :class="[
          'content-items',
          'schedule',
          { empty: sortedContents.length === 0 },
        ]"
      >
        <content-cards-wrapper
          :cards="sortedContents"
          :disable-shadow="true"
          :path-context="pathContext"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { sortContentByTime } from "@/utils/timezone";
import { MonthView } from "@/components/calendar";
import { ContentCardsWrapper } from "@/components/dashboard";

import DropDown from "@/components/dashboard/ui/DropDown.vue";

const SCHEDULE_MENU_TABS = [
  { name: "list_calendar", label: "List" },
  { name: "month_calendar", label: "Month" },
];

export default {
  name: "Schedule",
  components: { MonthView, ContentCardsWrapper, DropDown },
  data() {
    return {
      tabs: SCHEDULE_MENU_TABS,
      activeTab: "list_calendar",
      pathContext: "/schedule/edit",
    };
  },
  computed: {
    ...mapState({
      contents: (state) => state.content.contents,
      sortedContents: (state) =>
        [
          ...state.content.contents
            .filter((content) =>
              state.content.contentSortSettings.selectedContentIds.includes(
                content.id,
              ),
            )
            .filter((content) => {
              if (
                state.content.contentSortSettings.selectedProjectId === null
              ) {
                return true;
              } else {
                if (
                  content.project_id ===
                  state.content.contentSortSettings.selectedProjectId
                ) {
                  return true;
                } else {
                  return false;
                }
              }
            }),
        ].sort(sortContentByTime),
      monthlySortedContents: (state) =>
        [...state.content.contents]
          .filter((content) => {
            if (state.content.contentSortSettings.selectedProjectId === null) {
              return true;
            } else {
              if (
                content.project_id ===
                state.content.contentSortSettings.selectedProjectId
              ) {
                return true;
              } else {
                return false;
              }
            }
          })
          .sort(sortContentByTime),
    }),
    monthView() {
      return this.activeTab === "month_calendar";
    },
  },
  methods: {
    selectTab(tab) {
      this.activeTab = this.tabs.find((t) => t.label === tab).name;
    },
    openPostModal() {
      this.$root.$emit("toggle-create-post");
    },
  },
};
</script>
<style lang="scss">
.content {
  .content-right {
    .content-items {
      &.schedule {
        &.empty {
          position: absolute;
          &::after {
            background-image: url("/assets/images/layout/dashboard/schedule/no_posts.svg");
            width: 432px;
            height: 320px;
            left: 50%;
            top: 15%;
            transform: translateX(-50%);
          }
        }
      }
    }
  }
}
.schedule-dropdown {
  width: 100px;
}
</style>
