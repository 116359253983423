<template>
  <side-modal title="Details" @close="closeModal">
    <div slot="content-left" class="side-content-wrapper">
      <object class="asset">
        <img
          :src="
            selectedAsset.asset_type === 'image'
              ? selectedAsset.media_url
              : selectedAsset.thumbnail_url
          "
          @error="defaultBg"
        />
      </object>
      <div class="d-flex d-flex-end">
        <button class="edit-button edit ml-1" @click="editAsset">
          Edit asset
        </button>
      </div>
      <!-- <div class="d-flex d-flex-end" @click="editAsset">Edit asset</div> -->
    </div>
    <div slot="content-right" class="side-content-wrapper">
      <div class="d-flex d-flex-c details-container mt-3">
        <div class="d-flex d-flex-r d-flex-between detail mb-1">
          <span><b>Uploaded by:</b></span>
          <span>{{ formattedMetaInfo.uploader }}</span>
        </div>
        <div class="d-flex d-flex-r d-flex-between detail mb-1">
          <span><b>Upload date:</b></span>
          <span>{{ formattedMetaInfo.date }}</span>
        </div>
        <div class="d-flex d-flex-r d-flex-between detail mb-1">
          <span><b>File size:</b></span>
          <span>{{ formattedMetaInfo.size }}</span>
        </div>
        <div class="d-flex d-flex-r d-flex-between detail mb-1">
          <span><b>File type:</b></span>
          <span>{{ formattedMetaInfo.fileType }}</span>
        </div>
        <div
          v-if="assetType === 'video'"
          class="d-flex d-flex-r d-flex-between detail mb-1"
        >
          <span><b>Duration:</b></span>
          <span>{{ formattedMetaInfo.duration }}</span>
        </div>
        <div v-else class="d-flex d-flex-r d-flex-between detail mb-1">
          <span><b>Resolution:</b></span>
          <span>{{ formattedMetaInfo.resolution }}</span>
        </div>
      </div>
    </div>
  </side-modal>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { SideModal } from "@/components";
import dayjs from "@/utils/timezone";
import { formatSize, formatDuration } from "@/utils/media";
import { getFileTypeFromUrl } from "@/utils/url";
// import {  } from "@/components/ui";

const pathContext = "/workspace/media/edit";
export default {
  name: "AssetDetailsModal",
  components: { SideModal },
  props: {
    assetId: { type: String, default: "" },
  },
  computed: {
    ...mapState({
      selectedAsset(state) {
        return state.asset.assets.find((asset) => asset.id === this.assetId);
      },
    }),
    assetType() {
      return this.selectedAsset.asset_type;
    },
    formattedMetaInfo() {
      const resolution = `${this.selectedAsset.media_width} X ${this.selectedAsset.media_height}`;
      const duration = formatDuration(this.selectedAsset.media_duration);
      const size = formatSize(this.selectedAsset.media_size);
      const uploader = this.selectedAsset.upload_by;
      const date = dayjs(this.selectedAsset.created_at).format("DD-MM-YYYY");
      const fileType = `${this.assetType}/${getFileTypeFromUrl(
        this.selectedAsset.media_url,
      )}`;
      return {
        resolution,
        duration,
        size,
        uploader,
        date,
        fileType,
      };
    },
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },

    defaultBg(e) {
      e.target.src = "/assets/images/layout/dashboard/common/content_bg.svg";
    },
    editAsset() {
      this.$router.push({
        path: pathContext,
        query: {
          id: this.selectedAsset.id,
        },
      });
      this.closeModal();
    },
  },
};
</script>

<style lang="scss" scoped>
.side-content-wrapper {
  height: 220px;

  .asset {
    img {
      width: 100%;
      height: 100%;
      border-radius: 6px;
      object-fit: cover;
    }
  }

  .details-container {
    .detail {
      width: 300px;
    }
  }
  .edit-button {
    background-color: #e4e8f5;
    padding: 0.5em;
    height: fit-content;
    border: none;
    border-radius: 6px;
    font-size: 0.85rem;
    outline: none;
    color: black;
  }
}
</style>
