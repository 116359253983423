import { render, staticRenderFns } from "./UploadItem.vue?vue&type=template&id=0b2a755a&scoped=true"
import script from "./UploadItem.vue?vue&type=script&lang=js"
export * from "./UploadItem.vue?vue&type=script&lang=js"
import style0 from "./UploadItem.vue?vue&type=style&index=0&id=0b2a755a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b2a755a",
  null
  
)

export default component.exports