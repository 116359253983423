export const GET_SUBSCRIPTION_PRODUCTS_REQUEST =
  "GET_SUBSCRIPTION_PRODUCTS_REQUEST";
export const GET_SUBSCRIPTION_PRODUCTS_SUCCESS =
  "GET_SUBSCRIPTION_PRODUCTS_SUCCESS";
export const GET_SUBSCRIPTION_PRODUCTS_ERROR =
  "GET_SUBSCRIPTION_PRODUCTS_ERROR";

export const GET_USER_BILLING_INFO_REQUEST = "GET_USER_BILLING_INFO_REQUEST";
export const GET_USER_BILLING_INFO_SUCCESS = "GET_USER_BILLING_INFO_SUCCESS";
export const GET_USER_BILLING_INFO_ERROR = "GET_USER_BILLING_INFO_ERROR";

export const INITIALIZE_PAYMENT_REQUEST = "INITIALIZE_PAYMENT_REQUEST";
export const INITIALIZE_PAYMENT_SUCCESS = "INITIALIZE_PAYMENT_SUCCESS";
export const INITIALIZE_PAYMENT_ERROR = "INITIALIZE_PAYMENT_ERROR";

export const CONFIRM_PAYMENT_REQUEST = "CONFIRM_PAYMENT_REQUEST";
export const CONFIRM_PAYMENT_SUCCESS = "CONFIRM_PAYMENT_SUCCESS";
export const CONFIRM_PAYMENT_ERR0R = "CONFIRM_PAYMENT_ERROR";

export const FINALIZE_PAYMENT_REQUEST = "FINALIZE_PAYMENT_REQUEST";
export const FINALIZE_PAYMENT_SUCCESS = "FINALIZE_PAYMENT_SUCCESS";
export const FINALIZE_PAYMENT_ERR0R = "FINALIZE_PAYMENT_ERROR";

export const PREVIEW_SUBSCRIPTION_UPDATE_REQUEST =
  "PREVIEW_SUBSCRIPTION_UPDATE_REQUEST";
export const PREVIEW_SUBSCRIPTION_UPDATE_SUCCESS =
  "PREVIEW_SUBSCRIPTION_UPDATE_SUCCESS";
export const PREVIEW_SUBSCRIPTION_UPDATE_ERROR =
  "PREVIEW_SUBSCRIPTION_UPDATE_ERROR";

export const UPDATE_SUBSCRIPTION_REQUEST = "UPDATE_SUBSCRIPTION_REQUEST";
export const UPDATE_SUBSCRIPTION_SUCCESS = "UPDATE_SUBSCRIPTION_SUCCESS";
export const UPDATE_SUBSCRIPTION_ERROR = "UPDATE_SUBSCRIPTION_ERROR";

export const RENDER_PAYMENT_COMPLETION = "RENDER_PAYMENT_COMPLETION";

export const SET_ACTIVE_SUBSCRIPTION_MODAL = "SET_ACTIVE_SUBSCRIPTION_MODAL";
