var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex d-flex-c"},[_c('label',{staticClass:"mb-05"},[_c('b',[_vm._v(_vm._s(_vm.label))])]),_c('ul',{staticClass:"d-flex d-flex-c select-items"},[_vm._l((_vm.items),function(item,index){return _c('li',{key:index,class:[
        'select-item d-flex d-flex-start',
        {
          'is-selected': item.isSelected,
          'is-checkbox': _vm.checkbox,
          'is-avatar': _vm.enableAvatar,
        },
      ],style:(_vm.listItemCss),on:{"click":function($event){$event.stopPropagation();return (() => _vm.selectItem(item)).apply(null, arguments)}}},[_c('label',{staticClass:"item-label"},[_vm._v(_vm._s(item.value))])])}),_c('validation-provider',{attrs:{"mode":"aggressive"}},[_c('div',{staticClass:"d-flex d-flex-c hidden-input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.validationValue),expression:"validationValue"}],ref:_vm.inputRef,staticClass:"list-items-input",attrs:{"name":_vm.label},domProps:{"value":(_vm.validationValue)},on:{"input":function($event){if($event.target.composing)return;_vm.validationValue=$event.target.value}}})])])],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }