export const OPTIMIZE_PLATFORM_TEXT_REQUEST = "OPTIMIZE_PLATFORM_TEXT_REQUEST";
export const OPTIMIZE_PLATFORM_TEXT_SUCCESS = "OPTIMIZE_PLATFORM_TEXT_SUCCESS";
export const OPTIMIZE_PLATFORM_TEXT_ERROR = "OPTIMIZE_PLATFORM_TEXT_ERROR";

export const GENERATE_HASHTAGS_REQUEST = "GENERATE_HASHTAGS_REQUEST";
export const GENERATE_HASHTAGS_SUCCESS = "GENERATE_HASHTAGS_SUCCESS";
export const GENERATE_HASHTAGS_ERROR = "GENERATE_HASHTAGS_ERROR";

export const REVISE_TEXT_REQUEST = "REVISE_TEXT_REQUEST";
export const REVISE_TEXT_SUCCESS = "REVISE_TEXT_SUCCESS";
export const REVISE_TEXT_ERROR = "REVISE_TEXT_ERROR";
