import httpClient from "./httpClient";

const PROJECT = "/project";

export const getProjects = (params) => httpClient.get(PROJECT, { params });
export const createProject = (payload = {}) =>
  httpClient.post(PROJECT, payload);
export const updateProject = (payload = {}) => httpClient.put(PROJECT, payload);
export const deleteProject = (project_id) =>
  httpClient.delete(`${PROJECT}?project_id=${project_id}`);
