<template>
  <label :class="['d-flex wrapper', { 'single-item': hasSiblings }]">{{
    labelText
  }}</label>
</template>

<script>
export default {
  name: "CustomLabel",
  props: {
    labelText: { type: String, default: "" },
    hasSiblings: { type: Boolean, default: true },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  height: fit-content;
  padding: 0.5em 1em;
  background-color: #eeeeff;
  color: #221c55;
  font-size: 0.75rem;
  border-radius: 6px;
  margin-right: 0.5em;
  margin-bottom: 0.5em;
  text-transform: capitalize;

  &.single-item {
    margin: 0;
  }
}
</style>
