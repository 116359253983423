<template>
  <modal title="Change payment method" @close="closeModal">
    <div slot="modal-content">
      <div v-if="requestSuccess" class="d-flex d-flex-c d-flex-center">
        <span class="sucess-text mt-2 mb-1"
          >Successfully updated payment method</span
        >
        <button class="settings-button" @click="closeModal">Close</button>
      </div>
      <div v-else class="d-flex d-flex-c">
        <div
          v-if="requestStatus == 'success' || redirectConfirmed"
          class="d-flex d-flex-c d-flex-center"
        >
          <span class="sucess-text mt-2 mb-2"> Successfully updated </span>
          <button class="settings-button" @click="closeModal">Close</button>
        </div>
        <payment-methods
          v-else
          class="mt-2"
          setupContext="updatePaymentMethod"
          :context="context"
          @on-success="setRequestSuccess"
        />
      </div>
    </div>
  </modal>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { Modal, PaymentMethods } from "@/components";
import { PAYMENT_METHOD_UPDATE } from "@/utils/subscribe.js";

export default {
  name: "PaymentModal",
  components: { Modal, PaymentMethods },
  props: {
    fields: { type: Array, default: () => [] },
    redirectConfirmed: { type: Boolean, default: false },
    redirectMessage: { type: String, default: null },
  },
  data() {
    return {
      context: PAYMENT_METHOD_UPDATE,
      requestSuccess: false,
    };
  },
  computed: {
    ...mapState({
      updatingProfile: (state) =>
        state.dashboard.status.requesting.updateProfile,
    }),
  },
  methods: {
    ...mapActions({
      updateProfile: "updateProfile",
    }),
    closeModal() {
      this.$emit("close-profile-modal");
    },
    setRequestSuccess(data) {
      // console.log("payment success data: ", data);
      this.requestSuccess = true;
    },
  },
};
</script>
