<template>
  <modal title="Text revision" @close="closeModal">
    <div slot="modal-content" class="modal-content-wrapper">
      <div class="d-flex d-flex-c d-flex-between">
        <div class="d-flex d-flex-r mb-2">
          <div class="d-flex text-wrapper mr-2">
            {{ text }}
          </div>
          <div class="text-wrapper" v-html="textHighlights"></div>
        </div>
        <dashboard-button
          class="d-flex d-align-self-end dashboard-button"
          label="Apply revisions"
          @button-click="confirm"
        />
      </div>
    </div>
  </modal>
</template>

<script>
import { Modal } from "@/components";
import { DashboardButton } from "@/components/ui/";
import { highlightDifferences } from "@/utils/text";
export default {
  name: "TextRevisionModal",
  components: { Modal, DashboardButton },
  props: {
    text: { type: String, default: "" },
    revisedText: { type: String, default: "" },
  },
  data() {
    return {
      textHighlights: this.revisedText,
    };
  },
  created() {
    // Sanitize the content to prevent XSS attacks
    // https://gomakethings.com/how-to-sanitize-html-strings-with-vanilla-js-to-reduce-your-risk-of-xss-attacks/
    const rawHighlights = highlightDifferences(this.text, this.revisedText);
    const parser = new DOMParser();
    const doc = parser.parseFromString(rawHighlights, "text/html");
    const html = doc.body || document.createElement("body");

    const invalidTags = html.querySelectorAll("body :not(mark):not(br)");

    for (let tag of invalidTags) {
      tag.remove();
    }
    this.cleanNodes(html);

    this.textHighlights = html.innerHTML;
  },
  methods: {
    isPossiblyDangerous(name, value) {
      const val = value.replace(/\s+/g, "").toLowerCase();
      if (["src", "href", "xlink:href"].includes(name)) {
        if (val.includes("javascript:") || val.includes("data:text/html"))
          return true;
      }
      if (name.startsWith("on")) return true;
    },
    cleanNodes(html) {
      const nodes = html.children;
      for (let node of nodes) {
        this.removeAttributes(node);
        this.cleanNodes(node);
      }
    },
    removeAttributes(elem) {
      // Loop through each attribute
      // If it's dangerous, remove it
      let atts = elem.attributes;
      for (let { name, value } of atts) {
        if (!this.isPossiblyDangerous(name, value)) continue;
        elem.removeAttribute(name);
      }
    },
    confirm() {
      this.$emit("modal-submit");
      this.closeModal();
    },
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>

<style lang="scss" scoped>
.text-wrapper {
  padding: 2em 0;
  width: 100%;
  min-width: 500px;
}
</style>
