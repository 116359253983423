<template>
  <modal
    title="Add channel to project"
    description="Connect channel(s) to projects to manage posts and analytics"
    :narrow="true"
    @close="closeModal"
  >
    <div slot="modal-content" class="modal-content-wrapper">
      <div class="main-wrapper">
        <validation-observer v-slot="{ invalid }">
          <form id="projectChannels" class="custom-scroll" @submit.prevent>
            <div class="channels-wrapper">
              <select-page
                v-for="(channel, index) in channels"
                :key="index"
                :name="channel.channel_name"
                :type="channel.platform_type"
                :picture="channel.picture_url"
                :id="channel.id.toString()"
                :is-selected="channel.isSelected"
              />
            </div>
          </form>
        </validation-observer>
      </div>
      <div class="d-flex d-flex-end button-wrapper">
        <dashboard-button
          label="Confirm"
          :invalid="invalid"
          @button-click="confirm"
        />
      </div>
    </div>
  </modal>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import { mapState } from "vuex";
import { eventBus } from "@/utils/event-bus";

import { Modal } from "@/components";
import { DashboardButton } from "@/components/ui/";
import { SelectPage } from "@/components/dashboard/content/channels";

export default {
  name: "EditProjectChannelsModal",
  components: { ValidationObserver, Modal, SelectPage, DashboardButton },
  props: {
    projectChannels: { type: Array, default: () => [] },
  },
  data: () => ({
    pages: null,
  }),
  computed: {
    ...mapState({
      channels(state) {
        return state.channel.channels.map((channel) => {
          return {
            ...channel,
            isSelected: this.projectChannels.some((el) => el.id === channel.id),
          };
        });
      },
    }),
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    confirm() {
      let altered_channels = [];
      const projectChannels = document.getElementById("projectChannels");
      const formData = new FormData(projectChannels);
      formData.forEach((value) => {
        altered_channels.push(value);
      });
      eventBus.$emit("alter-channels", altered_channels);
      this.closeModal();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.main-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  max-height: 325px;
  margin-top: 20px;
  overflow-x: hidden;

  .channels-wrapper {
    margin-bottom: 2em;
  }
}
</style>
