<template>
  <div class="range-selector-wrapper">
    <div class="background" />
    <div class="d-flex d-flex-r d-flex-between range-selector">
      <div class="d-flex d-flex-c">
        <div class="d-flex d-flex-c ranges-wrapper">
          <span
            v-for="(range, i) in intervalRanges"
            :key="i"
            :class="[
              'mb-1',
              {
                '--is-selected': i === selectedRangeIndex,
                '--is-hidden': range.hide,
              },
            ]"
            @click="() => selectRangeInterval(i)"
            >{{ range.label }}</span
          >
        </div>
      </div>
      <div
        class="d-flex d-flex-c"
        style="padding: 1em; background-color: white"
      >
        <div class="d-flex d-flex-r align-center mb-4" style="height: 300px">
          <span class="nav-button prev mr-2" @click="previousRange" />
          <div
            class="d-flex d-flex-c d-flex-start align-center mr-5"
            style="height: 100%"
          >
            <span class="mb-1"
              ><b>{{ previousMonthYear }}</b></span
            >
            <div class="d-flex d-flex-r mb-1 dow-wrapper">
              <div v-for="(dow, index) in dowLabels" :key="index">
                <span>{{ dow }}</span>
              </div>
            </div>
            <div class="calendar-display">
              <range-selector-day
                v-for="(day, index) in previousDays"
                :key="index"
                :day="day"
                :selectedDates="selectedDates"
                @day-click="selectRange"
              />
            </div>
          </div>
          <div
            class="d-flex d-flex-c d-flex-start align-center"
            style="height: 100%"
          >
            <span class="mb-1"
              ><b>{{ currentMonthYear }}</b></span
            >
            <div class="d-flex d-flex-r mb-1 dow-wrapper">
              <div v-for="(dow, index) in dowLabels" :key="index">
                <span>{{ dow }}</span>
              </div>
            </div>
            <div class="calendar-display">
              <range-selector-day
                v-for="(day, index) in currentDays"
                :key="index"
                :day="day"
                :selectedDates="selectedDates"
                @day-click="selectRange"
              />
            </div>
          </div>
          <span class="nav-button next ml-2" @click="nextRange" />
        </div>
        <div class="d-flex d-flex-between ml-5 mr-4">
          <div class="d-flex d-flex-between align-center range-info-wrapper">
            <span v-if="startRangeFormat" class="range-info">{{
              startRangeFormat
            }}</span>
            <i v-if="endRangeFormat">-</i>
            <span v-if="endRangeFormat" class="range-info">{{
              endRangeFormat
            }}</span>
          </div>
          <content-button
            label="Apply"
            :disabled="!startRangeFormat || !endRangeFormat"
            @button-click="applyRange"
          />
        </div>
      </div>
      <span class="close" @click="handleClose">×</span>
    </div>
  </div>
</template>

<script>
import dayjs from "@/utils/timezone";
import RangeSelectorDay from "./RangeSelectorDay.vue";
import { ContentButton } from "@/components/ui/";

const DATE_FORMAT = "YYYY-MM-DD";
const DAYS_LABELS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
//const DAYS_LABELS = ["S", "M", "T", "W", "T", "F", "S"];

const INTERVAL_RANGES = [
  {
    label: "Today",
    start: "today",
    frequency: "hourly",
  },
  {
    label: "Yesterday",
    start: "yesterday",
    frequency: "hourly",
  },
  {
    label: "This week",
    start: "week",
    frequency: "daily",
  },
  {
    label: "Last 7 days",
    unit: "day",
    count: 7,
    frequency: "daily",
  },
  {
    label: "Last 30 days",
    unit: "day",
    count: 30,
    frequency: "monthly",
  },
  {
    label: "Last 90 days",
    unit: "day",
    count: 90,
    frequency: "quartely",
  },
  {
    label: "Last 12 months",
    unit: "month",
    count: 12,
    frequency: "annualy",
  },
  {
    label: "This year(Jan-Today)",
    start: "year",
    frequency: "custom",
  },
  {
    label: "custom",
    start: "custom",
    frequency: "custom",
    hide: true,
  },
];

export default {
  name: "RangeSelector",
  components: { RangeSelectorDay, ContentButton },
  props: {
    initialStartRange: { type: Object, default: null },
    initialEndRange: { type: Object, default: null },
    initialRangeIndex: { type: Number, default: 4 },
  },
  data() {
    return {
      startRange: this.initialStartRange ? this.initialStartRange : dayjs(),
      selectedStartRange: this.initialStartRange,
      selectedEndRange: this.initialEndRange,
      dowLabels: DAYS_LABELS,
      intervalRanges: INTERVAL_RANGES,
      selectedRangeIndex: this.initialRangeIndex,
    };
  },
  computed: {
    selectedDates() {
      return [
        ...Object.values({
          ...(this.selectedStartRange && {
            startRange: this.selectedStartRange.format(DATE_FORMAT),
          }),
          ...(this.selectedEndRange && {
            endRange: this.selectedEndRange.format(DATE_FORMAT),
          }),
        }),
      ];
    },
    startRangeFormat() {
      return this.selectedStartRange
        ? dayjs(this.selectedStartRange).format("D MMM, YYYY")
        : null;
    },
    endRangeFormat() {
      return this.selectedEndRange
        ? dayjs(this.selectedEndRange).format("D MMM, YYYY")
        : null;
    },
    today() {
      return dayjs().format(DATE_FORMAT);
    },
    month() {
      return Number(this.startRange.format("MM"));
    },
    year() {
      return Number(this.startRange.format("YYYY"));
    },
    numberOfDaysInMonth() {
      return dayjs(this.startRange).daysInMonth();
    },
    numberOfDaysInPreviousMonth() {
      return dayjs(`${this.year}-${this.month}-01`)
        .subtract(1, "month")
        .daysInMonth();
    },
    currentDays() {
      return [...this.currentHiddenDays, ...this.currentMonthDays];
    },
    currentMonthYear() {
      return this.startRange.format("MMMM YYYY");
    },
    currentHiddenDays() {
      const firstDayOfTheMonthWeekday = this.getWeekday(
        this.currentMonthDays[0].date,
      );
      const visibleNumberOfDaysFromPreviousMonth = firstDayOfTheMonthWeekday
        ? firstDayOfTheMonthWeekday - 1
        : 6;
      return [...Array(visibleNumberOfDaysFromPreviousMonth)].map((_, i) => {
        return {
          isEmpty: true,
        };
      });
    },
    currentMonthDays() {
      return [...Array(this.numberOfDaysInMonth)].map((_, i) => {
        const date = dayjs(`${this.year}-${this.month}-${i + 1}`).format(
          DATE_FORMAT,
        );
        return {
          date,
        };
      });
    },
    previousDays() {
      return [...this.previousHiddenDays, ...this.previousMonthDays];
    },
    previousHiddenDays() {
      const firstDayOfTheMonthWeekday = this.getWeekday(
        this.previousMonthDays[0].date,
      );
      const visibleNumberOfDaysFromPreviousMonth = firstDayOfTheMonthWeekday
        ? firstDayOfTheMonthWeekday - 1
        : 6;
      return [...Array(visibleNumberOfDaysFromPreviousMonth)].map((_, i) => {
        return {
          isEmpty: true,
        };
      });
    },
    previousMonthYear() {
      return this.startRange.subtract(1, "month").format("MMMM YYYY");
    },
    previousMonthDays() {
      const previousMonth = dayjs(`${this.year}-${this.month}-01`).subtract(
        1,
        "month",
      );

      return [...Array(this.numberOfDaysInPreviousMonth)].map((_, i) => {
        const date = dayjs(
          `${previousMonth.year()}-${previousMonth.month() + 1}-${i + 1}`,
        ).format(DATE_FORMAT);
        return {
          date,
        };
      });
    },
  },
  methods: {
    handleClose() {
      this.$emit("close", false);
    },
    applyRange() {
      const ranges = {
        startRange: this.selectedStartRange,
        endRange: this.selectedEndRange,
      };

      const payload = {
        ranges,
        rangeIndex: this.selectedRangeIndex,
        ...(this.selectedRangeIndex && {
          timeframeData: this.intervalRanges[this.selectedRangeIndex],
        }),
      };

      this.$emit("apply-range", payload);
      this.handleClose();
    },
    selectRange(date) {
      if (!this.selectedStartRange) {
        this.selectedStartRange = dayjs(date);
      } else if (this.selectedStartRange.isSame(dayjs(date))) {
        this.selectedEndRange = dayjs(date);
      } else if (
        !this.selectedEndRange &&
        this.selectedStartRange.isBefore(date)
      ) {
        this.selectedEndRange = dayjs(date);
      } else if (
        !this.selectedEndRange &&
        this.selectedStartRange.isAfter(date)
      ) {
        this.selectedEndRange = dayjs(this.selectedStartRange);
        this.selectedStartRange = dayjs(date);
      } else if (this.selectedEndRange && this.selectedEndRange.isAfter(date)) {
        this.selectedStartRange = dayjs(date);
      } else if (
        this.selectedEndRange &&
        this.selectedEndRange.isBefore(date)
      ) {
        this.selectedStartRange = dayjs(date);
        this.selectedEndRange = null;
      }

      if (this.selectedRangeIndex !== 8) {
        this.selectedRangeIndex = 8;
      }
    },
    getWeekday(date) {
      return dayjs(date).weekday();
    },
    nextRange() {
      this.startRange = dayjs(`${this.year}-${this.month}-01`).add(2, "month");
    },
    previousRange() {
      this.startRange = dayjs(`${this.year}-${this.month}-01`).subtract(
        2,
        "month",
      );
    },
    selectRangeInterval(index) {
      this.selectedRangeIndex = index;
      const intervalRange = this.intervalRanges[this.selectedRangeIndex];
      this.selectedEndRange = dayjs().startOf("day");
      if (intervalRange.count) {
        this.selectedStartRange = dayjs(this.selectedEndRange).subtract(
          intervalRange.count,
          intervalRange.unit,
        );
      } else {
        if (intervalRange.start === "yesterday") {
          this.selectedEndRange = dayjs().subtract(1, "day");
          this.selectedStartRange = this.selectedEndRange;
        } else if (intervalRange.start === "today") {
          this.selectedEndRange = dayjs();
          this.selectedStartRange = this.selectedEndRange;
        } else if (intervalRange.start === "week") {
          this.selectedStartRange = dayjs()
            .startOf(intervalRange.start)
            .add(1, "day");
        } else {
          this.selectedStartRange = dayjs().startOf(intervalRange.start);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.range-selector-wrapper {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;

  .background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background: #2e2c3f;
    opacity: 0.9;
  }

  .range-selector {
    position: absolute;
    right: 5%;
    top: 100px;
    max-width: 1100px;

    border-radius: 6px;
    overflow: hidden;

    .ranges-wrapper {
      height: 100%;
      padding: 1em 1.5em;
      background-color: #2e2c3f;
      color: white;
      > span {
        cursor: pointer;
        width: 185px;

        &.--is-selected {
          font-weight: bold;
        }

        &.--is-hidden {
          display: none;
        }
      }
    }

    .close {
      display: block;
      position: absolute;
      top: 0;
      right: 10px;
      cursor: pointer;
      font-size: 1.5rem;
    }

    .nav-button {
      display: inline-block;
      padding: 8px 16px;
      background-color: #2e2c3f;
      text-decoration: none;
      color: white;
      cursor: pointer;

      &.prev {
        &::before {
          position: relative;
          display: block;
          content: "<<";
        }
      }
      &.next {
        &::before {
          position: relative;
          display: block;
          content: ">>";
        }
      }
    }
    .date-wrapper {
      max-width: 200px;
    }

    .dow-wrapper {
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
      max-width: 280px;

      > div {
        display: flex;
        max-width: 40px;
        padding: 0.25em 1.25em;
        justify-content: center;
        font-size: 0.85rem;
      }
    }
    .calendar-display {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      max-width: 280px;

      > div {
        max-width: 40px;
        cursor: pointer;
        margin-bottom: 10px;
        padding: 8px 16px;
        justify-content: center;
        font-size: 0.85rem;
      }

      > span.space {
        width: 160px;
      }
    }
    .range-info-wrapper {
      width: 265px;
      .range-info {
        padding: 0.75em 1em;
        width: fit-content;
        background-color: #e4e8f5;
        border-radius: 6px;
        font-size: 0.85rem;
      }
    }
  }
}
</style>
