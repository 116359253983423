<template>
  <div class="d-flex">
    <div class="d-flex d-flex-c flex-1">
      <span class="empty-library mb-1 mt-05" />
      <span class="empty-text">
        <p>You are currently not a member of any team</p>
        <p>Click on <b>"Create team"</b> and invite your team members.</p>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "EmptyState",
};
</script>

<style lang="scss" scoped>
.empty-library {
  width: 100%;
  height: 125px;
  background-image: url("/assets/images/layout/settings/teams/teams_empty.svg");
  background-repeat: no-repeat;
  background-size: contain;
}
.empty-text {
  text-align: center;
}
</style>
