<template>
  <div :class="['calendar-month', { 'month-view': monthView }]">
    <div class="calendar-month-header">
      <calendar-year-selector
        @yearSelected="selectDate"
        :isPostTime="isPostTime"
      />
      <calendar-months @monthSelected="selectDate" :isPostTime="isPostTime" />
    </div>
    <div class="d-flex d-flex-r flex-1">
      <div class="d-flex d-flex-c calendar-content-wrapper">
        <div v-if="!monthView" class="d-flex d-flex-between days-label-wrapper">
          <div
            v-for="(day_label, index) in days_labels"
            :key="index"
            class="day-label"
          >
            <span>{{ day_label }}</span>
          </div>
        </div>
        <div class="days-grid">
          <month-day-item
            v-for="(day, index) in days"
            :key="day.date"
            :dayLabel="monthView ? days_labels[index] : ''"
            :day="day"
            :isMonthView="monthView"
            :contentIds="day.contentIds"
            :contentsData="
              contents.filter((content) => day.contentIds.includes(content.id))
            "
            :projects="monthView ? projects : []"
            :userTimeZone="userTimeZone"
            :is-today="day.date === today"
            :isPostTime="isPostTime"
            :selectedContent="selectedContent"
            @select-date="selectDate"
            @select-content="selectContent"
          />
          <calendar-content-card
            v-if="monthView && selectedContent"
            v-outside-click="handleContentCardClose"
            :content="selectedContent"
            @close-content="resetContentSelection"
          />
        </div>
      </div>
      <slot name="schedule-feature" />
    </div>
  </div>
</template>
<script>
import dayjs, { formatDateWithTz } from "@/utils/timezone";
import { mapState, mapActions } from "vuex";
import {
  CalendarYearSelector,
  CalendarMonths,
  MonthDayItem,
  CalendarContentCard,
} from "./";

const DATE_FORMAT = "YYYY-MM-DD";
const DAYS_LABELS = ["S", "M", "T", "W", "T", "F", "S"];

export default {
  name: "MonthView",
  components: {
    CalendarYearSelector,
    CalendarMonths,
    MonthDayItem,
    CalendarContentCard,
  },
  props: {
    isPostTime: { type: Boolean, default: false }, // to distinguish between the calendar for fetching and scheduling post
    contents: { type: Array, default: () => [] },
    monthView: { type: Boolean, default: false },
  },
  data() {
    return {
      days_labels: DAYS_LABELS,
      selectedContent: null,
      selectedDate: null,
      previousMonthDays: [],
      currentMonthDays: [],
      nextMonthDays: [],
    };
  },
  computed: {
    ...mapState({
      computedDate: function (state) {
        return this.isPostTime
          ? state.schedule.scheduleModalSettings.selectedDate
          : state.content.contentSortSettings.selectedDate;
      },
      projects: (state) => state.project.projects,
      userTimeZone: (state) => state.dashboard.user.timezone,
      selectedProjectId: (state) =>
        state.content.contentSortSettings.selectedProjectId,
    }),
    days() {
      return [
        ...this.previousMonthDays,
        ...this.currentMonthDays,
        ...this.nextMonthDays,
      ];
    },
    today() {
      return dayjs().format("YYYY-MM-DD");
    },
    month() {
      return Number(this.computedDate.format("MM"));
    },
    year() {
      return Number(this.computedDate.format("YYYY"));
    },
    numberOfDaysInMonth() {
      return dayjs(this.computedDate).daysInMonth();
    },
  },
  watch: {
    computedDate(newDate, oldDate) {
      if (oldDate && newDate.format("YYYY-MM") !== oldDate.format("YYYY-MM")) {
        this.calculateCalendar();
        const contentIds = this.calculateScheduledContent(newDate).map(
          (content) => content.id,
        );
        this.sortContentByDate(contentIds);
      }
    },
  },
  created() {
    this.calculateCalendar();
    if (!this.monthView) {
      const contentIds = this.calculateScheduledContent(this.computedDate).map(
        (content) => content.id,
      );
      this.sortContentByDate(contentIds);
    }
  },
  methods: {
    ...mapActions({
      setCalendarDate: "setCalendarDate",
      sortContentByDate: "sortContentByDate",
    }),
    handleContentCardClose() {
      if (this.monthView) {
        this.selectedContent = null;
      }
    },
    selectDate(newSelectedDate, dateContents = []) {
      if (this.isPostTime) {
        this.$emit("setPostTimeDate", newSelectedDate);
      } else {
        const contentIds = dateContents;
        this.setCalendarDate(newSelectedDate);
        this.sortContentByDate(contentIds);
      }
    },
    selectContent(data) {
      this.selectedContent = data;
    },
    resetContentSelection() {
      this.selectedContent = null;
    },
    getWeekday(date) {
      return dayjs(date).weekday();
    },
    calculateCalendar() {
      // current month days
      this.currentMonthDays = [...Array(this.numberOfDaysInMonth)].map(
        (_, index) => {
          const date = dayjs(`${this.year}-${this.month}-${index + 1}`).format(
            "YYYY-MM-DD",
          );
          return {
            date,
            isCurrentMonth: true,
            contentIds: this.calculateScheduledContent(date).map(
              (content) => content.id,
            ),
          };
        },
      );
      // previous month days
      const firstDayOfTheMonthWeekday = this.getWeekday(
        this.currentMonthDays[0].date,
      );
      const previousMonth = dayjs(`${this.year}-${this.month}-01`).subtract(
        1,
        "month",
      );
      // Cover first day of the month being sunday (firstDayOfTheMonthWeekday === 0)
      const visibleNumberOfDaysFromPreviousMonth = firstDayOfTheMonthWeekday
        ? firstDayOfTheMonthWeekday - 1
        : 6;

      const previousMonthLastMondayDayOfMonth = dayjs(
        this.currentMonthDays[0].date,
      )
        .subtract(visibleNumberOfDaysFromPreviousMonth, "day")
        .date();

      this.previousMonthDays = [
        ...Array(visibleNumberOfDaysFromPreviousMonth),
      ].map((day, index) => {
        const date = dayjs(
          `${previousMonth.year()}-${previousMonth.month() + 1}-${
            previousMonthLastMondayDayOfMonth + index
          }`,
        ).format("YYYY-MM-DD");
        return {
          date,
          isCurrentMonth: false,
          contentIds: this.calculateScheduledContent(date).map(
            (content) => content.id,
          ),
        };
      });
      // next month days
      const lastDayOfTheMonthWeekday = this.getWeekday(
        `${this.year}-${this.month}-${this.currentMonthDays.length}`,
      );
      const nextMonth = dayjs(`${this.year}-${this.month}-01`).add(1, "month");
      const visibleNumberOfDaysFromNextMonth = lastDayOfTheMonthWeekday
        ? 7 - lastDayOfTheMonthWeekday
        : lastDayOfTheMonthWeekday;

      this.nextMonthDays = [...Array(visibleNumberOfDaysFromNextMonth)].map(
        (day, index) => {
          const date = dayjs(
            `${nextMonth.year()}-${nextMonth.month() + 1}-${index + 1}`,
          ).format("YYYY-MM-DD");
          return {
            date,
            isCurrentMonth: false,
            contentIds: this.calculateScheduledContent(date).map(
              (content) => content.id,
            ),
          };
        },
      );
    },
    calculateScheduledContent(date) {
      return this.contents.filter((content) => {
        const dateTz = formatDateWithTz(date, content.tz);
        const dateTzFm = dateTz.format(DATE_FORMAT);
        if (
          (content.end_date && dayjs(content.endDateFm).isBefore(dateTzFm)) ||
          dayjs(content.startDateFm).isAfter(dayjs(dateTzFm))
        ) {
          return false;
        } else {
          if (
            content.frequency === "weekly" &&
            content.repeat_weekdays.includes(dateTz.day())
          ) {
            return true;
          } else if (
            content.frequency === "monthly" &&
            content.startDateTz.date() === dateTz.date()
          ) {
            return true;
          } else if (
            content.frequency === "yearly" &&
            content.startDateTz.format("MM-DD") === dateTz.format("MM-DD")
          ) {
            return true;
          } else if (content.frequency === "daily") {
            return true;
          } else if (
            content.frequency === "never" &&
            content.startDateFm === dateTzFm
          ) {
            return true;
          } else {
            return false;
          }
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.calendar-month {
  position: relative;
  background-color: var(--grey-200);
  border: solid 1px var(--grey-300);
  .calendar-month-header {
    max-width: 625px;
  }

  .day-of-week {
    color: var(--grey-800);
    font-size: 18px;
    background-color: #fff;
    padding-bottom: 5px;
    padding-top: 10px;
  }
  .day-of-week,
  .days-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
  }

  .day-of-week > * {
    text-align: right;
    padding-right: 5px;
  }

  .calendar-content-wrapper {
    .days-grid,
    .days-label-wrapper {
      width: 400px;
      height: 300px;
      position: relative;
      grid-column-gap: var(--grid-gap);
      grid-row-gap: var(--grid-gap);
      border-top: solid 1px var(--grey-200);

      .day-label {
        width: 35px;
        height: 35px;
        padding: 5px;
        font-size: 1rem;
        font-weight: bold;
        text-align: center;
      }
    }

    .days-label-wrapper {
      width: 378px;
      height: unset;
    }
  }

  &.month-view {
    display: flex;
    flex-direction: column;
    min-height: 80vh;
    flex: 1;

    .calendar-content-wrapper {
      flex: 1;
      .days-grid {
        height: 100%;
        width: 100%;
        border-bottom: 1px solid;
        border-right: 1px solid;
        border-color: #ccc;

        .calendar-day {
          border-top: 1px solid;
          border-left: 1px solid;
          border-color: #ccc;
          min-height: 100px;
          width: 100%;
          height: 100%;
          display: block;

          &.calendar-day--disabled {
            background: white;
          }
        }
      }
    }
  }
}
</style>
