var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['d-flex d-flex-c', { 'mr-5': _vm.context === _vm.subscriptionSelection }]},[(_vm.context === _vm.subscriptionSelection)?_c('h2',[_vm._v("Payment Details")]):_vm._e(),_c('div',{staticClass:"d-flex d-flex-r mb-2"},_vm._l((_vm.paymentMethods),function(paymentMethod,index){return _c('span',{key:index,class:[
        'payment-label',
        {
          'is-selected': paymentMethod.type === _vm.selectedPaymentMethod.type,
        },
      ],on:{"click":() => _vm.changePaymentMethod(paymentMethod)}},[_vm._v(_vm._s(paymentMethod.label))])}),0),_c('div',{staticClass:"d-flex payment-wrapper"},[_c(_vm.selectedPaymentMethod.type,_vm._b({tag:"component",staticClass:"d-flex d-align-end",on:{"payment-complete":_vm.onPaymentComplete}},'component',{
        selectedProvider: _vm.selectedPaymentMethod.provider,
        selectedInterval: _vm.selectedInterval,
        selectedPlan: _vm.selectedPlan,
        setupContext: _vm.context,
      },false))],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }