<template>
  <modal :title="title" :description="description" @close="closeModal">
    <div slot="modal-content" class="modal-content-wrapper">
      <div class="main-wrapper">
        <validation-observer v-slot="{ invalid }">
          <list-items
            :name="listName"
            :label="listLabel"
            :items="alteredItems"
            :checkbox="true"
            :enableAvatar="enableAvatar"
            @trigger="selectItem"
          />
        </validation-observer>
      </div>
      <div
        class="d-flex d-flex-between button-wrapper"
        style="margin: 2em auto 0"
      >
        <blank-button @button-click="closeModal" label="Cancel" @click.stop />
        <content-button label="Save" @button-click="submit" @click.stop />
      </div>
    </div>
  </modal>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import { Modal } from "@/components";
import { ListItems, BlankButton, ContentButton } from "@/components/ui";

export default {
  name: "ListModal",
  components: {
    ValidationObserver,
    Modal,
    ListItems,
    BlankButton,
    ContentButton,
  },
  props: {
    title: { type: String, default: "" },
    description: { type: String, default: "" },
    listName: { type: String, default: "" },
    listLabel: { type: String, default: "" },
    enableAvatar: { type: Boolean, default: false },
    items: { type: Array, default: () => [] },
  },
  data() {
    return {
      alteredItems: JSON.parse(JSON.stringify(this.items)),
    };
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    selectItem(item) {
      this.alteredItems = this.alteredItems.map((i) => ({
        ...i,
        isSelected: item.id === i.id ? item.value : i.isSelected,
      }));
    },
    submit() {
      this.$emit("modal-submit", this.alteredItems);
      this.closeModal();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.main-wrapper {
  overflow: auto;
  max-height: 325px;
  width: 100%;

  .projects-wrapper {
    margin-bottom: 2em;
  }
}
</style>
