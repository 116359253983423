<template>
  <div class="d-flex d-flex-c container">
    <p class="mt-1 mb-1">
      Sign in to PayPal by clicking on the button below and we will add it as a
      recurring payment method to your account for future use.
    </p>
    <div class="d-flex-d-flex-c">
      <spinner
        v-if="!buttonElementLoaded || paypalInitializing"
        :showBackground="true"
        :height="24"
        :width="24"
        backgroundColor="#00000088"
        label="Initializing"
      />
      <div
        id="paypal-button-container"
        :class="[' mb-2', { 'is-loading': !buttonElementLoaded }]"
      >
        <!-- <span v-if="cardElementError" class="card-error">{{
      cardElementError
    }}</span> -->
      </div>
    </div>
  </div>
</template>
<script>
import {
  renderPayPalButton,
  billingSettings,
  PAYMENT_METHOD_UPDATE,
} from "@/utils/subscribe.js";
import { eventBus } from "@/utils/event-bus";
import { Spinner } from "@/components";
import { mapActions, mapState } from "vuex";

export default {
  name: "PayPal",
  components: { Spinner },
  props: {
    selectedProvider: { type: String, default: "paypal" },
    selectedPlan: { type: String },
    selectedInterval: { type: String },
    setupContext: { type: String, default: "" },
  },
  async created() {
    if (this.setupContext === PAYMENT_METHOD_UPDATE) {
      const params = {
        provider: this.selectedProvider,
        ...(this.selectedPlan && { tier: this.selectedPlan }),
        ...(this.selectedInterval && { interval: this.selectedInterval }),
      };

      await this.initializePayment(params);

      eventBus.$on("paypal-approved", this.onPaymentComplete);
    }
  },
  async mounted() {
    const rendered = await renderPayPalButton(
      this.confirm,
      billingSettings(
        this.selectedPlan,
        this.selectedProvider,
        this.selectedInterval,
      )[this.setupContext].returnUrl,
      this.setupContext !== PAYMENT_METHOD_UPDATE,
    );
    this.buttonElementLoaded = rendered;
  },
  beforeDestroy() {
    eventBus.$off("paypal-approved", this.onPaymentComplete);
  },
  data() {
    return {
      buttonElementLoaded: false,
      confirmedTier: null,
      confirmedBillingCycle: null,
    };
  },
  computed: {
    ...mapState({
      paypalInitializing: (state) =>
        state.subscription.status.requesting.initializePayment,
    }),
  },
  methods: {
    ...mapActions({
      initializePayment: "initializePayment",
      confirmPayment: "confirmPayment",
      finalizePayment: "finalizePayment",
    }),
    onPaymentComplete(subscriptionId) {
      // console.log("$$$$$$$$$$$$: ", subscriptionId);
      const data = {
        provider: this.selectedProvider,
        payload: {
          subscription_id: subscriptionId,
          tier: this.confirmedTier,
          recurring_interval: this.confirmedBillingCycle,
        },
      };
      this.finalizePayment(data).then((res) => {
        // console.log("###: ", res);
        this.$emit("payment-complete");
      });
    },
    confirm() {
      const data = {
        provider: this.selectedProvider,
        payload: {
          ...(this.selectedPlan && { tier: this.selectedPlan }),
          ...(this.selectedInterval && {
            recurring_interval: this.selectedInterval,
          }),
        },
      };
      return this.confirmPayment(data).then((res) => {
        // console.log("finished");
        this.confirmedTier = res.tier;
        this.confirmedBillingCycle = res.billing_cycle;
        return res;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  position: relative;
  max-width: 500px;
  #paypal-button-container {
    width: 500px;
    z-index: 50;

    &.is-loading {
      opacity: 0.5;
    }
  }
}
</style>
