<template>
  <div
    :class="['d-flex profile-item-wrapper', { 'outside-menu': outsideMenu }]"
  >
    <div
      class="d-flex d-flex-r align-center profile-item"
      @click="toggleProfileMenu"
    >
      <div class="d-flex d-flex-center profile-picture-wrapper">
        <profile-picture
          :name="team.name"
          :picture="userPicture"
          :is-team="isTeamProfile && isPersonal === false"
        />
      </div>
      <label class="profile-label ml-05"> My account </label>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ProfilePicture from "./ProfilePicture.vue";

export default {
  name: "ProfileItem",
  components: { ProfilePicture },
  props: {
    outsideMenu: { type: Boolean, default: true },
    isPersonal: { type: Boolean, default: false },
  },
  computed: {
    ...mapState({
      isTeamProfile: (state) => !!state.team.currentTeam.id,
      userPicture: function (state) {
        return !this.isPersonal && state.team.currentTeam.id
          ? null
          : state.dashboard.user.avatar;
      },
      team: (state) =>
        state.team.teams.find(
          (team) => team.id === state.team.currentTeam.id,
        ) || {},
    }),
  },
  methods: {
    toggleProfileMenu() {
      this.$emit("toggle-profile-menu");
    },
  },
};
</script>

<style lang="scss" scoped>
.profile-item-wrapper {
  position: relative;
  cursor: pointer;

  &.outside-menu {
    border-radius: 6px;
    &:hover {
      background-color: #27215a;
    }
    .profile-item {
      position: relative;
      .profile-picture-wrapper {
        width: 50px;
        height: 50px;
        margin-right: 2px;
      }
      .profile-label {
        position: relative;
        min-width: 150px;
        color: white;

        &::after {
          content: "";
          display: block;
          position: absolute;
          top: 50%;
          right: 25px;
          width: 16px;
          height: 10px;
          transform: translateY(-50%);
          background-image: url("/assets/images/icons/dashboard/sidemenu/arrow_down_icon_active.svg");
        }
      }
    }
  }
}
</style>
