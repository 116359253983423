<template>
  <div class="d-flex d-flex-r settings" @click="toggleSettings">
    <div v-if="settingsToggled" class="d-flex d-flex-c menu-items-wrapper">
      <span
        v-for="(item, index) in items"
        :key="index"
        :class="[
          'menu-item',
          { [item.class]: item.class, 'is-disabled': item.enabled === false },
        ]"
        @click="() => handleItemSelect(item)"
      >
        {{ item.label }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "DotMenu",
  props: { items: { type: Array, default: () => [] } },
  data() {
    return {
      settingsToggled: false,
    };
  },
  methods: {
    toggleSettings() {
      this.settingsToggled = !this.settingsToggled;
    },
    handleItemSelect(item) {
      if (item.enabled === false) {
        return;
      }
      this.$emit("menu-item-select", item);
    },
  },
};
</script>

<style lang="scss" scoped>
.settings {
  position: relative;
  width: 24px;
  height: 24px;
  background-image: url("/assets/images/icons/social/shared/dots-wide.svg");
  background-size: contain;
  cursor: pointer;

  .menu-items-wrapper {
    position: absolute;
    background-color: white;
    height: fit-content;
    min-width: 165px;
    box-shadow: 0px 0px 5px #7a7a7a29;
    border-radius: 3px;
    top: 25px;
    right: 0;
    font-size: 0.85rem;
    overflow: hidden;
    z-index: 100;

    > span {
      padding: 0.5em 2em;
      width: 100%;

      &.is-disabled {
        color: #ccc;
        cursor: not-allowed;

        &:hover {
          background-color: unset;
        }
      }

      &:hover {
        background-color: #aab4db;
      }

      &.delete,
      &.leave {
        color: red;
      }
    }
  }
}
</style>
