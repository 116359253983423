<template>
  <button class="comment-button" @click="handleClick">
    {{ label }}
  </button>
</template>

<script>
export default {
  name: "CommentButton",
  props: { label: { type: String, default: "" } },
  methods: {
    handleClick: function () {
      this.$emit("button-click");
    },
  },
};
</script>

<style lang="scss" scoped>
.comment-button {
  display: block;
  width: 48px;
  height: 44px;
  background-image: url("/assets/images/icons/dashboard/draft/comment_icon.webp");
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #221c55;
  border-radius: 40px;
  cursor: pointer;
  border: none;
}
</style>
