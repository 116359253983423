<template>
  <div
    :class="[
      'd-flex d-flex-between day-wrapper',
      {
        '--within-range': isWithinRange,
        '--is-first': isFirst,
        '--is-last': isLast,
        '--is-empty': isEmpty,
        '--is-selected': isSelected,
      },
    ]"
    @click="handleClick"
  >
    {{ dayLabel }}
  </div>
</template>

<script>
import dayjs from "@/utils/timezone";

export default {
  name: "RangeSelectorDay",
  props: {
    day: { type: Object, default: () => {} },
    selectedDates: { type: Array, default: () => [] },
  },
  computed: {
    isWithinRange() {
      return this.selectedDates.length > 1 && !this.isEmpty
        ? dayjs(this.selectedDates[0]).isSameOrBefore(dayjs(this.day.date)) &&
            dayjs(this.selectedDates[1]).isAfter(this.day.date)
        : false;
    },
    isFirst() {
      return this.selectedDates.length > 0
        ? dayjs(this.selectedDates[0]).isSame(dayjs(this.day.date))
        : false;
    },
    isLast() {
      return this.selectedDates.length > 1
        ? dayjs(this.selectedDates[1]).isSame(dayjs(this.day.date))
        : false;
    },
    isEmpty() {
      return this.day.isEmpty;
    },
    dayLabel() {
      return !this.isEmpty ? dayjs(this.day.date).format("D") : "";
    },
    isSelected() {
      return this.selectedDates.length === 1
        ? dayjs(this.selectedDates[0]).isSame(dayjs(this.day.date))
        : false;
    },
  },
  methods: {
    handleClick() {
      if (!this.isEmpty) {
        this.$emit("day-click", this.day.date);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.day-wrapper {
  flex: 1;
  padding: 0.5em;

  &.--within-range {
    background-color: #eeeeff;

    &.--is-first {
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
    }

    &.--is-last {
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
    }
  }

  &.--is-selected {
    background-color: #eeeeff;
    border-radius: 20px;
  }

  &.--is-empty {
    padding: 1.25em !important;
  }
}
</style>
