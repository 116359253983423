import { createFFmpeg, fetchFile } from "@ffmpeg/ffmpeg/dist/ffmpeg.min.js";

let duration = null;
const regex = /(\d{2}):(\d{2}):(\d{2}):(\d{2})/gm;
const ffmpegInstance = createFFmpeg({
  mainName: "main",
  corePath: "https://unpkg.com/@ffmpeg/core-st/dist/ffmpeg-core.js",
  //corePath: new URL("@ffmpeg/core/dist/ffmpeg-core.js", document.location).href,
  log: false,
});

ffmpegInstance.setLogger(({ type, message }) => {
  if (message && message.indexOf("timecode") > 0) {
    const matches = regex.exec(String(message));
    if (matches && !duration) {
      const h = Number(matches[1]);
      const m = Number(matches[2]);
      const s = Number(matches[3]);
      duration = h * 3600 + m * 60 + s;
    }
  }
  if (message && message.indexOf("Program terminated") > 0) {
    duration = null;
  }
});

let ffmpegLoadingPromise = ffmpegInstance.load();

const getFFmpeg = async () => {
  if (ffmpegLoadingPromise) {
    await ffmpegLoadingPromise;
    ffmpegLoadingPromise = undefined;
  }
  return ffmpegInstance;
};

export async function createThumb(file, maxWidth = 200, maxHeight = 200) {
  return new Promise((resolve, reject) => {
    if (file.type.includes("video")) {
      const thumbWidth = 320;
      const thumbHeight = 250;

      const video = document.createElement("video");
      video.preload = "metadata";

      video.src = URL.createObjectURL(file);
      // this is important
      video.muted = true;
      video.autoplay = true;

      video.onerror = async (e) => {
        const ffmpeg = await getFFmpeg();
        // console.log(
        //   "Video type not supported by browser. Using ffmpeg to load video file...",
        //   e,
        // );

        ffmpeg.FS("writeFile", file.name, await fetchFile(file));

        await ffmpeg.run(
          "-i",
          file.name,
          "-ss",
          "00:00:00",
          "-vframes",
          "1",
          "-s",
          `${thumbWidth}x${thumbHeight}`,
          "thumb.png",
        );

        const data = ffmpeg.FS("readFile", "thumb.png");
        const blob = new Blob([data.buffer], { type: "image/png" });
        const blobUrl = URL.createObjectURL(blob);

        const mediaDuration = duration;
        const mediaData = {
          file,
          src: blobUrl,
          thumbFile: blob,
          error: false,
          duration: mediaDuration,
        };

        ffmpeg.FS("unlink", file.name);
        ffmpeg.FS("unlink", "thumb.png");

        await ffmpeg.exit();
        await ffmpeg.load();
        resolve(mediaData);
      };

      video.onloadeddata = () => {
        const canvas = document.createElement("canvas");

        canvas.width = thumbWidth;
        canvas.height = thumbHeight;

        const ctx = canvas.getContext("2d");

        ctx.drawImage(video, 0, 0, thumbWidth, thumbHeight);
        video.pause();

        ctx.canvas.toBlob((blob) => {
          const blobUrl = URL.createObjectURL(blob);
          const mediaData = {
            file,
            src: blobUrl,
            thumbFile: blob,
            duration: video.duration,
          };
          resolve(mediaData);
        });
      };
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          const { width, height } = getNewDimensions(img, maxWidth, maxHeight);
          const canvas = document.createElement("canvas");

          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext("2d");

          ctx.scale(width / img.width, height / img.height);
          ctx.drawImage(img, 0, 0);
          ctx.canvas.toBlob((blob) => {
            const blobUrl = URL.createObjectURL(blob);
            // const compressedImage = blobToFile(blob, fileName);
            const mediaData = {
              file,
              src: blobUrl,
              dimensions: { width: img.width, height: img.height },
            };
            resolve(mediaData);
          });
        };
      };
    }
  });
}

/**
 * Compress an image to a smaller size
 * specifically, 240x240
 *
 * @param {File} file the file to be used
 * @param {String} fileName the file name to be used
 * @return {Promise}
 */

export async function compressImage(file, fileName) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async (e) => {
      const img = new Image();
      img.src = e.target.result;
      img.onload = () => {
        const maxWidth = 240;
        const maxHeight = 240;
        const { width, height } = getNewDimensions(img, maxWidth, maxHeight);
        const canvas = document.createElement("canvas");

        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext("2d");

        ctx.scale(width / img.width, height / img.height);
        ctx.drawImage(img, 0, 0);
        ctx.canvas.toBlob((blob) => {
          const blobUrl = URL.createObjectURL(blob);
          const compressedImage = blobToFile(blob, fileName);
          const imageData = {
            compressedImage,
            imgSrc: blobUrl,
            dimensions: { width: img.width, height: img.height },
          };
          resolve(imageData);
        });
      };
    };
  });
}

export async function resizeImage(id, url, maxWidth, maxHeight) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = url;
    img.onload = () => {
      const dimensions = getNewDimensions(img, maxWidth, maxHeight);

      resolve({ ...dimensions, id });
    };
  });
}

export async function getRatioByWidth(imgSrc, containerWidth) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = imgSrc;
    img.onload = () => {
      const dimensions = getDimensions(img, containerWidth);

      resolve(dimensions);
    };
  });
}

/**
 * Conserve aspect ratio of the original region. Useful when shrinking/enlarging
 * images to fit into a certain area.
 *
 * @param {Image} img the source image
 * @param {Number} maxWidth maximum available width
 * @param {Number} maxHeight maximum available height
 * @return {Object} { width, height }
 */

function getNewDimensions(img, maxWidth, maxHeight) {
  const { naturalWidth, naturalHeight, src } = img;
  const widthRatio = naturalWidth / naturalHeight;
  const heightRatio = naturalHeight / naturalWidth;

  return widthRatio >= heightRatio
    ? { width: maxWidth * widthRatio, height: maxHeight, src }
    : { width: maxWidth, height: maxHeight * heightRatio, src };
}

function getDimensions(img, containerWidth) {
  const { naturalWidth, naturalHeight, src } = img;

  const widthRatio = containerWidth / naturalWidth;
  const height = naturalHeight * widthRatio;
  return { width: containerWidth, height, src };
}

/**
 * Convert a blob type into a file type.
 * Useful for uploading formdata
 *
 * @param {Blob} blob the blob
 * @param {String} fileName the file name to be used
 * @return {File} { width, height }
 */

function blobToFile(blob, fileName) {
  return new File([blob], fileName, {
    lastModified: new Date().getTime(),
    type: blob.type,
  });
}

const sizeUnits = [
  "bytes",
  "KiB",
  "MiB",
  "GiB",
  "TiB",
  "PiB",
  "EiB",
  "ZiB",
  "YiB",
];

export const formatSize = (x = 0) => {
  let l = 0,
    n = parseInt(x, 10) || 0;

  while (n >= 1024 && ++l) {
    n = n / 1024;
  }

  return n.toFixed(n < 10 && l > 0 ? 1 : 0) + " " + sizeUnits[l];
};

export const formatDuration = (x = 0) => {
  const hours = Math.floor(x / 3600);
  const minutes = Math.floor((x % 3600) / 60);
  const seconds = x - hours * 3600 - minutes * 60;

  return (
    [`${hours}h`, `${minutes}m`, `${seconds}s`]
      // .filter((item) => item[0] !== "0")
      .join(" ")
  );
};
