import {
  GET_CHANNELS_REQUEST,
  GET_CHANNELS_SUCCESS,
  GET_CHANNELS_ERROR,
  UPDATE_CHANNEL_REQUEST,
  UPDATE_CHANNEL_SUCCESS,
  UPDATE_CHANNEL_ERROR,
  DELETE_CHANNEL_REQUEST,
  DELETE_CHANNEL_SUCCESS,
  DELETE_CHANNEL_ERROR,
  GET_CHANNEL_AUTH_URL_REQUEST,
  GET_CHANNEL_AUTH_URL_SUCCESS,
  GET_CHANNEL_AUTH_URL_ERROR,
  ADD_CHANNEL_REQUEST,
  ADD_CHANNEL_SUCCESS,
  ADD_CHANNEL_ERROR,
  ADD_CHANNEL_PAGE_REQUEST,
  ADD_CHANNEL_PAGE_SUCCESS,
  ADD_CHANNEL_PAGE_ERROR,
  DELETE_CACHED_PAGES_REQUEST,
  DELETE_CACHED_PAGES_SUCCESS,
  DELETE_CACHED_PAGES_ERROR,
  UPDATE_CHANNEL_STATE,
} from "../actions/channel";

import { RESET_MODULE_STATE } from "../actions/global";

import { CREATE_NOTIFICATION } from "../actions/notification";

import {
  getChannels,
  updateChannel,
  deleteChannel,
  getChannelAuth,
  addChannel,
  addChannelPage,
  deleteCachedPages,
} from "../../services/api/channel.api";

const getDefaultState = () => ({
  channels: [],
  status: {
    requesting: {
      getChannels: false,
      updateChannel: false,
      deleteChannel: false,
      getChannelAuth: false,
      addChannel: false,
      addChannelPage: false,
      deleteCachedPages: false,
    },
    errors: {
      getChannels: null,
      updateChannel: null,
      deleteChannel: null,
      getChannelAuth: false,
      addChannel: null,
      addChannelPage: null,
      deleteCachedPages: null,
    },
  },
  platform_accounts: [],
});

const state = getDefaultState();

const actions = {
  getChannels: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      commit(GET_CHANNELS_REQUEST);
      getChannels(params)
        .then((res) => {
          commit(GET_CHANNELS_SUCCESS, res.data);
          resolve(res.data);
        })
        .catch((error) => {
          commit(GET_CHANNELS_ERROR, error);
          reject(error);
        });
    });
  },
  updateChannel: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit(UPDATE_CHANNEL_REQUEST);
      updateChannel(payload)
        .then((res) => {
          commit(UPDATE_CHANNEL_SUCCESS, res.data);
          resolve(res.data);
        })
        .catch((error) => {
          commit(UPDATE_CHANNEL_ERROR, error);
          reject(error);
        });
    });
  },
  updateChannelState: ({ commit }, payload) => {
    const channels = payload.length ? payload : [].concat(payload);
    commit(UPDATE_CHANNEL_STATE, channels);
  },
  deleteChannel: ({ commit }, channel_id) => {
    return new Promise((resolve, reject) => {
      commit(DELETE_CHANNEL_REQUEST);
      deleteChannel(channel_id)
        .then((res) => {
          commit(DELETE_CHANNEL_SUCCESS, res.data);
          resolve(res.data);
          commit(CREATE_NOTIFICATION, {
            type: DELETE_CHANNEL_SUCCESS,
            status: "succeeded",
          });
        })
        .catch((error) => {
          commit(DELETE_CHANNEL_ERROR, error);
          reject(error);
          commit(CREATE_NOTIFICATION, {
            type: DELETE_CHANNEL_ERROR,
            status: "error",
          });
        });
    });
  },
  getChannelAuth: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      commit(GET_CHANNEL_AUTH_URL_REQUEST);
      getChannelAuth(params)
        .then((res) => {
          commit(GET_CHANNEL_AUTH_URL_SUCCESS);
          resolve(res);
        })
        .catch((error) => {
          commit(GET_CHANNEL_AUTH_URL_ERROR, error);
          reject(error);
          commit(CREATE_NOTIFICATION, {
            type: GET_CHANNEL_AUTH_URL_ERROR,
            status: "error",
          });
        });
    });
  },
  addChannel: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit(ADD_CHANNEL_REQUEST);
      addChannel(payload)
        .then((res) => {
          // commented the following line to prevent the dashboard from mounting
          // when adding a channel
          // commit(ADD_CHANNEL_SUCCESS);
          resolve(res);
        })
        .catch((error) => {
          commit(ADD_CHANNEL_ERROR, error);
          reject(error);
          commit(CREATE_NOTIFICATION, {
            type: ADD_CHANNEL_ERROR,
            status: "error",
          });
        });
    });
  },
  addChannelPage: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit(ADD_CHANNEL_PAGE_REQUEST);
      addChannelPage(payload)
        .then((res) => {
          commit(ADD_CHANNEL_PAGE_SUCCESS);
          commit(UPDATE_CHANNEL_STATE, res.data);
          resolve(res);
          commit(CREATE_NOTIFICATION, {
            type: ADD_CHANNEL_PAGE_SUCCESS,
            status: "succeeded",
          });
        })
        .catch((error) => {
          commit(ADD_CHANNEL_PAGE_ERROR, error);
          reject(error);
          commit(CREATE_NOTIFICATION, {
            type: ADD_CHANNEL_PAGE_ERROR,
            status: "error",
          });
        });
    });
  },
  deleteCachedPages: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit(DELETE_CACHED_PAGES_REQUEST);
      deleteCachedPages()
        .then((res) => {
          commit(DELETE_CACHED_PAGES_SUCCESS);
          resolve(res);
        })
        .catch((error) => {
          commit(DELETE_CACHED_PAGES_ERROR, error);
          reject(error);
        });
    });
  },
};

const mutations = {
  [GET_CHANNELS_REQUEST]: (state) => {
    state.status.requesting.getChannels = true;
  },
  [GET_CHANNELS_SUCCESS]: (state, channels) => {
    state.channels = channels;
    state.status.requesting.getChannels = false;
  },
  [GET_CHANNELS_ERROR]: (state, error) => {
    state.status.requesting.getChannels = false;
    state.status.errors.getChannels = error;
  },
  [UPDATE_CHANNEL_REQUEST]: (state) => {
    state.status.requesting.updateChannel = true;
  },
  [UPDATE_CHANNEL_SUCCESS]: (state, data) => {
    state.channels = state.channels.map((channel) =>
      channel.id === data.id ? data : channel,
    );
    state.status.requesting.updateChannel = false;
  },
  [UPDATE_CHANNEL_ERROR]: (state, error) => {
    state.status.requesting.updateChannel = false;
    state.status.errors.updateChannel = error;
  },
  [UPDATE_CHANNEL_STATE]: (state, channels) => {
    state.channels = state.channels
      .filter((channel) => !channels.some((c) => c.id === channel.id))
      .concat(channels);
  },
  [DELETE_CHANNEL_REQUEST]: (state) => {
    state.status.requesting.deleteChannel = true;
  },
  [DELETE_CHANNEL_SUCCESS]: (state, data) => {
    state.channels = state.channels.filter((channel) => channel.id !== data.id);
  },
  [DELETE_CHANNEL_ERROR]: (state, error) => {
    state.status.requesting.deleteChannel = false;
    state.status.errors.deleteChannel = error;
  },
  [GET_CHANNEL_AUTH_URL_REQUEST]: (state) => {
    state.status.requesting.getChannelAuth = true;
  },
  [GET_CHANNEL_AUTH_URL_SUCCESS]: (state) => {
    state.status.requesting.getChannelAuth = false;
  },
  [GET_CHANNEL_AUTH_URL_ERROR]: (state, error) => {
    state.status.requesting.getChannelAuth = false;
    state.status.errors.getChannelAuth = error;
  },
  [ADD_CHANNEL_REQUEST]: (state) => {
    state.status.requesting.addChannel = true;
  },
  [ADD_CHANNEL_SUCCESS]: (state) => {
    state.status.requesting.addChannel = false;
  },
  [ADD_CHANNEL_ERROR]: (state, error) => {
    state.status.requesting.addChannel = false;
    state.status.errors.addChannel = error;
  },
  [ADD_CHANNEL_PAGE_REQUEST]: (state) => {
    state.status.requesting.addChannelPage = true;
  },
  [ADD_CHANNEL_PAGE_SUCCESS]: (state) => {
    state.status.requesting.addChannelPage = false;
  },
  [ADD_CHANNEL_PAGE_ERROR]: (state, error) => {
    state.status.requesting.addChannelPage = false;
    state.status.errors.addChannelPage = error;
  },
  [DELETE_CACHED_PAGES_REQUEST]: (state) => {
    state.status.requesting.deleteCachedPages = true;
  },
  [DELETE_CACHED_PAGES_SUCCESS]: (state) => {
    state.status.requesting.deleteCachedPages = false;
  },
  [DELETE_CACHED_PAGES_ERROR]: (state, error) => {
    state.status.requesting.deleteCachedPages = false;
    state.status.errors.deleteCachedPages = error;
  },
  [RESET_MODULE_STATE]: (state) => {
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    // This mutation is called from the logout action!
    Object.assign(state, getDefaultState());
  },
};

export default {
  state,
  actions,
  mutations,
};
