<template>
  <div
    :class="[
      'd-flex d-flex-c chart-wrapper mb-1',
      wrapperType,
      { 'is-flex': isFlex },
    ]"
  >
    <label class="mb-1">{{ label }}</label>
    <div v-if="showCount" class="count">
      {{ chartConfig.count
      }}<span v-if="chartConfig.team" class="team-count"
        >/{{ chartConfig.teamCount }}</span
      >
    </div>
    <div v-if="chartType" style="height: calc(100% - 4em)">
      <chart :type="chartType" :config="chartConfig" />
    </div>
  </div>
</template>

<script>
export default {
  name: "ChartWrapper",
  components: {
    Chart: () => import("./Chart"),
  },
  props: {
    label: { type: String, default: "" },
    chartType: { type: String, default: "" },
    chartCount: { type: Number, default: null },
    chartConfig: { type: Object, default: null },
    wrapperType: { type: String, default: "small" },
    isFlex: { type: Boolean, default: false },
  },
  computed: {
    showCount() {
      return (
        this.chartType === "donut" ||
        this.chartType === "pie" ||
        this.chartType === "area" ||
        this.chartConfig.count ||
        this.chartConfig.count > -1
      );
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.chart-wrapper {
  background-color: white;
  border-radius: 6px;
  padding: 1em;
  height: 100%;

  &.small {
    width: 32%;
    max-height: 125px;
    > .count {
      margin-top: 1em;
    }
  }
  &.medium {
    width: 49%;
    max-height: 260px;

    &.-h {
      width: 48.5%;
      max-height: 48%;
    }
  }
  &.large {
    width: 100%;
    max-height: 400px;
    &.f-height {
      height: 400px;
    }
  }

  > label {
    opacity: 0.7;
  }

  > .count {
    font-size: 1.35rem;
    font-weight: bold;

    .team-count {
      color: #939393;
      font-size: 1rem;

      &::first-letter {
        font-size: 1.25rem;
      }
    }
  }
}
</style>
