<template>
  <div
    v-if="toRender"
    :class="[
      'd-flex d-flex-r upload-item-wrapper mb-2',
      { 'is-loaded': loaded && toRender, error: status === 'error' },
    ]"
  >
    <object class="selected-asset img-preview mr-05">
      <img v-if="previewImg" :src="previewImg" />
    </object>
    <div class="d-flex d-flex-c d-flex-between" style="width: 100%">
      <div
        class="d-flex d-flex-r d-flex-between mb-05"
        style="max-width: 288px"
      >
        <label class="item-name">{{ itemName }}</label>
        <span v-if="!isCompleted" class="upload-percentage"
          >{{ uploadProgress }}%</span
        >
      </div>
      <div
        :class="[
          'load-bar',
          { 'is-completed': isCompleted, 'has-error': hasError },
        ]"
      >
        <div class="progress" :style="`width:${uploadProgress}%`"></div>
      </div>
      <span
        :class="[
          'status-message',
          { 'is-completed': isCompleted, 'has-error': hasError },
        ]"
        >{{ statusMessage }}</span
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "UploadItem",
  props: {
    index: { type: Number },
    previewImg: { type: String, default: "" },
    itemName: { type: String, default: "" },
    status: { type: Object, default: () => ({}) },
    uploadProgress: { type: Number, default: 0 },
    toRender: { type: Boolean, default: false },
  },
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    isCompleted() {
      // return this.status === "completed"
      return this.status.type === "completed";
    },
    hasError() {
      return this.status.type === "error";
    },
    statusMessage() {
      return this.status.message;
    },
  },
  mounted() {
    setTimeout(() => {
      this.loaded = true;
    }, 100);
  },
  methods: {
    removeItem() {
      this.$emit("remove-upload-item", this.index);
    },
  },
};
</script>

<style lang="scss" scoped>
.upload-item-wrapper {
  position: relative;
  opacity: 0;
  transition: opacity 0.25s ease-in-out;

  &.is-loaded {
    opacity: 1;
  }
  .img-preview {
    border-radius: 6px;
    height: 40px;
    width: 40px;
    min-width: 40px;
    max-width: 40px;
    min-height: 40px;
    max-height: 40px;
    background-image: url("/assets/images/layout/dashboard/common/content_bg.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-color: #d5daef;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 6px;
    }
  }
  .item-name {
    font-size: 0.85rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 75%;
  }
  .load-bar {
    width: 100%;
    height: 8px;
    background-color: #d5daef;
    border-radius: 28px;

    &.is-completed {
      .progress {
        background-color: #2bd18a;
      }
    }

    &.has-error {
      .progress {
        background-color: #ff4e4e;
      }
    }

    .progress {
      height: 8px;
      border-radius: 28px;
      background-color: #221c55;
      transition: ease-in-out 0.5s;
    }
  }

  .status-message {
    font-size: 0.85rem;
    height: 10px;
    text-transform: capitalize;
    &.is-completed {
      color: #2bd18a;
    }

    &.has-error {
      color: #ff4e4e;
    }
  }
}
</style>
