export default {
  meta: {},
  misc: {},
  ui: {
    currently_active: "Currently active",
    try_trial: "Try free for 14 days",
    downgrade: "Downgrade for free",
    subscribe: "Subscribe",
    more: "More",
    less: "Less",
    trial_free: "Free for 14 days",
    unlimited: "Unlimited",
    best_for: "Best for",
    discount: "(2 months free)",
    billed: "Billed",
    monthly_abbr: "mo",
  },
  error: {
    error_page_title: "Houston, We have a problem",
    error_page_text:
      "We could not find what you were searching for, contact us so we can help you.",
  },
  pricing: {
    section_1_title: "Fully explore Socianis with a 14-day trial",
    platforms: {
      label: "Social networks",
      value: "",
    },
    channels: {
      label: "Channels",
      value: "{n} Channels",
    },
    posts: {
      label: "Post amount",
      value: "{n} posts a month",
    },
    media_libraries: {
      label: "Media libraries",
      value: "{n} library | {n} libraries",
    },
    media_library_storage: {
      label: "Media storage",
      value: "Up to {n} of storage",
    },
    image_editing: {
      label: "Image editing",
      value: "",
    },
    post_editing: {
      label: "Post editing",
      value: "",
    },
    post_customization: {
      label: "Customize post per platform",
      value: "",
    },
    calendar_view: {
      label: "Calendar view",
      value: "",
    },
    schedule: {
      label: "Post scheduling",
      value: "",
    },
    repeat_post: {
      label: "Repeat post",
      value: "",
    },
    multi_media_post: {
      label: "Multi media post",
      value: "",
    },
    video_post: {
      label: "Video posting",
      value: "",
    },
    projects: {
      label: "Project creation",
      value: "{n} project | {n} projects",
    },
    drafts: {
      label: "Draft creation",
      value: "",
    },
    draft_comments: {
      label: "Draft commenting",
      value: "",
    },
    draft_approval: {
      label: "Draft approvals",
      value: "",
    },
    analytics: {
      label: "Analytics",
      value: "",
    },
    analytics_history: {
      label: "Analytics history",
      value: "Up to {n} days",
    },
    ai_assistant: {
      label: "AI assistant",
      value: "",
    },
    team_collaboration: {
      label: "Team collaboration",
      value: "",
    },
    team_creation: {
      label: "Team creation",
      value: "{n} team | {n} teams",
    },
    client_collaboration: {
      label: "Client collaboration",
      value: "",
    },
    member_invite: {
      label: "Member invites",
      value: "{n} members per team",
    },
    team_insights: {
      label: "Team insights",
      value: "Up to {n} days",
    },
    timezone_management: {
      label: "Timezone management",
      value: "",
    },
    timezone_shifting: {
      label: "Automatic timezone shifting",
      value: "",
    },
    report_generation: {
      label: "Analytics report generation",
      value: "",
    },
    automatic_report_forwarding: {
      label: "Automatic report forwarding",
      value: "",
    },
  },

  cookie: {
    cookie_notice_text:
      "SOCIANIS uses cookies to offer you a better experience on our website.<br /> By continuing to use our website, you consent the use of cookies accordance with our <b><a class='white' href='https://socianis.com/privacy'>Privacy Policy</a></b>.",
    page_title: "Cookie Settings",
    cookie_sub_title:
      "With the usage of cookies, SOCIANIS can improve your experiences when you are using our tool. And we will use it to offer you our new functionality. You can review and manage your cookies below to control your privacy.",
    cookie_preferences_title: "Preferences",
    cookie_preferences_text:
      "Preferences cookies enable us to remember your preferences, like your preferred language or the region you are in. It also enables us to recognize you when you return to our services.",
    cookie_advertising_title: "Advertsing",
    cookie_advertising_text:
      "Advertising cookies and technologies like the advertising cookies on SOCIANIS enable us to place a targeted and specific advertisement on other sites you visit. And it allows us to measure your engagement with those ads.",
    cookie_analytics_title: "Analytics",
    cookie_analytics_text:
      "The analytics cookies are assisting us in understanding how our visitors are interacting with our services. It permits us to analyze and improve our services through third-party analytics.",
    cookie_authentication_title: "Authentication",
    cookie_authentication_text:
      "Authentication cookies permit SOCIANIS to remember your request to log in as you to use our services",
    cookie_fraud_prevention_title: "Fraud Prevention",
    cookie_fraud_prevention_text:
      "Fraud prevention cookies, and comparable technologies that is used throughout our website, support us to learn things about devices and browsers that are accessing the SOCIANIS services. These technologies assist us with monitoring for and detect potentially harmful or illegal use of our services. These cookies are mandatory for our services to work.",
    cookie_functionality_title: "Functionality",
    cookie_functionality_text:
      "Functionality cookies are necessary and must be present for our website to provide you with our services. The functionality cookies are essential to be able to access our features.",
    cookie_security_title: "Security",
    cookie_security_text:
      "Security cookies permit us to protect your user data from unauthorized access to your account.",
    "cookie_third-party_providers_title": "Third-party Provider",
    "cookie_third-party_providers_text":
      "Facebook, Instagram, Twitter, and Google are third parties we work with, who also set cookies for analytics and marketing purposes. This list may not be thoroughgoing however, we will commit to keeping it updated based on the information from the named providers. Please familiarize yourself with their policies.<br /><br /><p><b><a class='cookie-link' href='https://www.facebook.com/policy/cookies/' target='_blank'>Facebook Cookie Policy</a></b></p><p><b><a class='cookie-link' href='https://www.facebook.com/help/instagram/1896641480634370' target='_blank'>Instagram Cookie Policy</a></b></p><p><b><a class='cookie-link' href='https://help.twitter.com/en/rules-and-policies/twitter-cookies' target='_blank'>Twitter Cookie Policy</a></b></p><p><b><a class='cookie-link' href='https://policies.google.com/technologies/cookies' target='_blank'>Google Cookie Policy</a></b></p>",
  },
};
