import httpClient from "./httpClient";

const MEDIA_LIBRARY = "/library";

export const getMediaLibraries = (params) =>
  httpClient.get(MEDIA_LIBRARY, { params });
export const createMediaLibrary = (payload = {}) =>
  httpClient.post(MEDIA_LIBRARY, payload);
export const updateMediaLibrary = (data = {}, params = {}) =>
  httpClient.put(MEDIA_LIBRARY, data, { params });
export const deleteMediaLibrary = (params) =>
  httpClient.delete(MEDIA_LIBRARY, { params });
