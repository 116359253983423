// https://github.com/ashishks55/websocket-with-custom-reconnect-timer/blob/master/src/customSocket.js

export default class Timer {
  constructor(callback, timerCalc) {
    this.callback = callback;
    this.timerCalc = timerCalc;
    this.timer = null;
    this.tries = 0;
  }

  reset() {
    this.tries = 0;
    clearTimeout(this.timer);
  }

  scheduleTimeout() {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.tries = this.tries + 1;
      this.callback();
    }, this.timerCalc(this.tries + 1));
  }
}

export const reconnectAfterMs = (tries) => {
  return [1000, 2000, 5000, 10000][tries - 1] || 10000;
};
