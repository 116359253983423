e
<template>
  <modal
    @close="closeModal"
    title="Invite user"
    description="Send an invitiation to the members you  want to include in your team."
  >
    <div slot="modal-content" class="modal-content-wrapper">
      <div class="d-flex d-flex-c" style="flex: 1">
        <validation-observer
          ref="addMemberForm"
          v-slot="{ invalid }"
          class="d-flex d-flex-c d-flex-between"
          style="flex: 1"
        >
          <form
            @submit.prevent="() => ({})"
            class="d-flex d-flex-c d-flex-between"
            style="flex: 1"
          >
            <div class="input-wrapper mt-1 mb-2">
              <component
                v-for="(field, index) in fields"
                :key="field.key || index"
                :is="field.type"
                v-bind="field.props"
                v-model="formData[field.props.name]"
                @trigger="field.trigger"
              ></component>
            </div>
            <div class="button-wrapper">
              <dashboard-button
                label="Send invite"
                :is-requesting="isRequesting"
                :invalid="invalid"
                @button-click="formSubmit"
              />
            </div>
          </form>
        </validation-observer>
      </div>
    </div>
  </modal>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import { mapActions, mapState } from "vuex";
import { Modal } from "@/components";
import { ContentInput, FormSelect, DashboardButton } from "@/components/ui/";

export default {
  name: "AddMemberModal",
  components: {
    Modal,
    ValidationObserver,
    ContentInput,
    FormSelect,
    DashboardButton,
  },
  data() {
    return {
      formData: {
        email: "",
        role: {},
      },
    };
  },
  computed: {
    ...mapState({
      roles: (state) =>
        state.team.roles
          .filter((role) => role.name !== "owner")
          .map((role) => ({ key: role.id, value: role.name })),
      isRequesting: (state) => state.team.status.requesting.addMember,
    }),
    fields() {
      return [
        {
          type: "ContentInput",
          trigger: () => ({}),
          props: {
            name: "email",
            label: "Email",
            rules: "required",
          },
        },
        {
          type: "FormSelect",
          trigger: this.setRole,
          props: {
            name: "role",
            label: "User permission",
            items: this.roles,
            wide: true,
          },
        },
      ];
    },
  },
  created() {
    this.formData.role = this.roles[0];
  },
  methods: {
    ...mapActions({
      addMember: "addMember",
    }),
    setRole(role) {
      console.log(">>>>: ", role);
      this.formData.role = role;
    },
    closeModal() {
      this.$emit("closeModal");
    },

    formSubmit() {
      const payload = {
        data: {
          email: this.formData.email,
          role_id: this.formData.role.key,
        },
      };
      this.addMember(payload).then((res) => {
        this.closeModal();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.modal-content-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;

  .button-wrapper {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
