export const platforms = {
  Facebook: {
    actions: ["like", "comment", "share"],
    contentWidth: 680,
    maxContainerHeight: 530,
  },
  Instagram: {
    actions: ["like", "comment", "send", "favorites"],
    contentWidth: 320,
    maxContainerHeight: 400,
  },
  LinkedIn: {
    actions: ["like", "comment", "repost", "send"],
    contentWidth: 555,
    maxContainerHeight: 530,
  },
  Twitter: {
    actions: ["comment", "retweet", "like", "stats", "share"],
    contentWidth: 565,
    maxContainerHeight: 430,
  },
};

export const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const dashboardMenuItems = [
  // {
  //   name: "post",
  //   label: "create post",
  //   class: "post",
  // },
  {
    name: "Schedule",
    path: "/schedule",
    label: "schedule",
    class: "schedule",
    action: {
      type: "navigate",
      label: "Create post",
      params: { path: "new" },
    },
    filters: [
      {
        name: "contentProjects",
        label: "projects",
        itemsName: "projects",
        triggerName: "setContentProject",
      },
    ],
    hideSubMenu: true,
    children: [
      {
        name: "Post",
        path: "/schedule/new",
        label: "Create Post",
        class: "post",
        prevNavLabel: "back to calendar",
        hideTitle: true,
      },
      {
        name: "Post",
        path: "/schedule/edit",
        label: "Edit Post",
        class: "post",
        prevNavLabel: "back to calendar",
        hideTitle: true,
      },
    ],
  },
  {
    name: "Drafts",
    path: "/drafts",
    label: "create a draft",
    class: "drafts",
    action: {
      type: "navigate",
      label: "Create draft",
      params: { path: "new" },
    },
    filters: [
      {
        name: "draftProjects",
        label: "projects",
        itemsName: "projects",
        triggerName: "setDraftProject",
      },
    ],
    hideSubMenu: true,
    children: [
      {
        name: "Post",
        path: "/drafts/new",
        label: "Create draft",
        class: "draft",
        prevNavLabel: "back to drafts overview",
        hideTitle: true,
      },
      {
        name: "Post",
        path: "/drafts/edit",
        label: "Edit draft",
        class: "draft",
        prevNavLabel: "back to drafts overview",
        hideTitle: true,
      },
    ],
  },
  {
    name: "Analytics",
    path: "/analytics",
    label: "analytics",
    class: "analytics",
    filters: [
      {
        name: "metricProject",
        label: "project",
        itemsName: "projects",
        triggerName: "setMetricProject",
      },
      {
        name: "metricChannel",
        label: "channel",
        itemsName: "metricChannels",
        triggerName: "setMetricChannel",
      },
    ],
  },
  {
    name: "Workspace",
    path: "/workspace",
    label: "workspace",
    class: "workspace",
    children: [
      {
        name: "Insights",
        path: "/workspace/insights",
        label: "team insights",
        class: "insights",
        filters: [
          {
            name: "projects",
            label: "projects",
            itemsName: "projects",
            triggerName: "setInsightsProject",
          },
        ],
      },
      {
        name: "Team",
        path: "/workspace/team",
        label: "manage team",
        class: "team",
        action: {
          type: "modal",
          label: "Invite member",
          params: { name: "AddMemberModal" },
        },
      },
      {
        name: "Projects",
        path: "/workspace/projects",
        label: "manage projects",
        class: "projects",
        action: {
          type: "modal",
          label: "Create project",
          params: { name: "CreateProjectModal" },
        },
      },
      {
        name: "Channels",
        path: "/workspace/channels",
        label: "manage channels",
        class: "channels",
        action: {
          type: "modal",
          label: "Add channel",
          params: { name: "AddChannelModal" },
        },
      },
      {
        name: "Media",
        path: "/workspace/media",
        label: "media libraries",
        class: "media",
        action: {
          type: "modal",
          label: "Create media library",
          params: { name: "CreateMediaLibraryModal" },
        },
        children: [
          {
            name: "MediaEdit",
            path: "/workspace/media/edit",
            label: "Edit asset",
            class: "media",
            prevNavLabel: "Back to media libraries",
          },
        ],
      },
    ],
  },

  // {
  //   name: "Story",
  //   label: "story",
  //   class: "story",
  // },
  // {
  //   name: "Inbox",
  //   label: "inbox",
  //   class: "inbox",
  // },
  // {
  //   name: "Ads",
  //   label: "ads",
  //   class: "ads",
  // },
  // {
  //   name: "Trending",
  //   label: "trending",
  //   class: "trending",
  // },
];

export const side_menu_bottom_items = [
  {
    label: "consistency",
    class: "consistency",
  },
  {
    label: "feedback",
    class: "feedback",
  },
];

export const profileMenuItems = [
  {
    name: "settings",
    label: "Settings",
    class: "settings",
  },
  {
    name: "subscription",
    label: "Subscription",
    class: "subscription",
  },
  {
    name: "teams",
    label: "Teams",
    class: "team",
  },
];

export const teamRowItems = [
  {
    label: "Go to team",
    class: "navigate",
    action: "navigate",
    roles: ["owner", "maintainer", "editor", "client", "viewer"],
  },
  {
    label: "Manage team",
    class: "manage",
    action: "manage",
    roles: ["owner", "maintainer"],
  },
  {
    label: "Leave team",
    class: "leave",
    action: "leave",
    roles: ["maintainer", "editor", "client", "viewer"],
  },
  {
    label: "Edit team",
    class: "edit",
    action: "update",
    roles: ["owner", "maintainer"],
  },
  { label: "Delete team", class: "delete", action: "delete", roles: ["owner"] },
];

export const metriclIntervals = {
  custom: {
    interval: 0,
    steps: 0,
  },
  hourly: {
    interval: 1,
    steps: 1,
  },
  daily: {
    interval: 7,
    steps: 1,
  },
  weekly: {
    interval: 14,
    steps: 2,
  },
  monthly: {
    interval: 30,
    steps: 5,
  },
  quartely: {
    interval: 90,
    steps: 15,
  },
  biannual: {
    interval: 180,
    steps: 30,
  },
  annualy: {
    interval: 365,
    steps: 30,
  },
};

export const customIntervalRanges = [
  { interval: 300, steps: 30 },
  { interval: 180, steps: 30 },
  { interval: 90, steps: 15 },
  { interval: 60, steps: 10 },
  { interval: 30, steps: 5 },
  { interval: 20, steps: 4 },
  { interval: 14, steps: 2 },
  { interval: 7, steps: 1 },
  { interval: 1, steps: 1 },
];

export const timeTable = {
  0: "12AM",
  1: "1AM",
  2: "2AM",
  3: "3AM",
  4: "4AM",
  5: "5AM",
  6: "6AM",
  7: "7AM",
  8: "8AM",
  9: "9AM",
  10: "10AM",
  11: "11AM",
  12: "12PM",
  13: "1PM",
  14: "2PM",
  15: "3PM",
  16: "4PM",
  17: "5PM",
  18: "6PM",
  19: "7PM",
  20: "8PM",
  21: "9PM",
  22: "10PM",
  23: "11PM",
};

export const productTarget = {
  grounded: "starters",
  lift_off: "entrepreneurs",
  orbit: "small businesses",
  exosphere: "agencies",
};

// storage keys
export const SELECTED_PLATFORM = "selectedPlatform";
export const PLATFORM_POPUP = "platformPopup";
export const PLATFORM_AUTH = "platformAuth";
export const FACEBOOK_PAGES = "facebookPages";
export const NEW_CHANNEL = "newChannel";
export const SELECT_CHANNELS = "selectChannel";
export const TEAM_CONTEXT = "teamContext";
export const MANAGE_TEAM = "manageTeam";
