import httpClient from "./httpClient";

const BILLING = "/billing/subscription";
const PRODUCT = "/product";

export const getSubscriptionProducts = () => httpClient.get(PRODUCT);
export const initializePayment = (params) =>
  httpClient.get(`${BILLING}/initialize`, {
    params,
  });
export const getBillingInfo = () => httpClient.get(BILLING);

export const confirmPayment = (provider, payload) =>
  httpClient.post(`${BILLING}/confirm?provider=${provider}`, payload);
export const finalizePayment = (provider, payload) =>
  httpClient.post(`${BILLING}/finalize?provider=${provider}`, payload);
export const previewSubscriptionUpdate = (tier, interval) =>
  httpClient.get(`${BILLING}/preview?tier=${tier}&interval=${interval}`);
export const updateSubscription = (payload) =>
  httpClient.post(`${BILLING}`, payload);
