<template>
  <div
    v-if="profileLoading || globalRequesting"
    class="d-flex d-flex-center flex-1"
  >
    <loader />
  </div>
  <div id="profile" v-else class="d-flex d-flex-r profile">
    <profile-sidebar />
    <profile-content />
    <component
      :is="activeProfileModal.name"
      v-bind="activeProfileModal.props"
      @close-profile-modal="setActiveProfileModal"
    />
    <notification-center />
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";

import {
  Loader,
  ProfileSidebar,
  ProfileContent,
  ChangeTeamNameModal,
  ChangeFullNameModal,
  ChangeTimezoneModal,
  ChangePasswordModal,
  CreateTeamModal,
  PaymentModal,
  NotificationCenter,
} from "@/components";

export default {
  name: "Profile",
  components: {
    Loader,
    ProfileSidebar,
    ProfileContent,
    ChangeTeamNameModal,
    ChangeFullNameModal,
    ChangeTimezoneModal,
    ChangePasswordModal,
    CreateTeamModal,
    PaymentModal,
    NotificationCenter,
  },
  async created() {
    this.globalRequesting = true;

    await this.getProfile();
    const {
      path,
      query: { provider, redirect_status, setup_intent },
    } = this.$route;

    if (
      path === "/profile/subscription/confirmed" &&
      redirect_status === "succeeded"
    ) {
      const data = {
        provider,
        payload: {
          setup_intent,
        },
      };

      const { success, detail } = await this.finalizePayment(data);
      this.globalRequesting = false;
      this.setActiveProfileModal({
        name: "PaymentModal",
        props: {
          redirectConfirmed: success,
          redirectMessage: detail,
        },
      });
    } else {
      this.globalRequesting = false;
    }
  },
  beforeDestroy() {
    this.setActiveProfileModal();
  },
  data() {
    return {
      globalRequesting: false,
    };
  },
  computed: {
    ...mapState({
      activeProfileModal: (state) => state.dashboard.activeProfileModal,
      profileLoading: (state) => state.dashboard.status.requesting.getProfile,
    }),
  },
  methods: {
    ...mapActions({
      setActiveProfileModal: "setActiveProfileModal",
      getProfile: "getProfile",
      finalizePayment: "finalizePayment",
    }),
  },
};
</script>
<style lang="scss" scoped>
#profile {
  &.profile {
    position: relative;
    flex: 5;
    height: 100%;
    padding: 0 8vw;
  }
}
</style>
