<template>
  <div class="d-flex d-flex-r d-flex-center tag">
    <label>{{ label }}</label>
    <span class="close" @click.stop="$emit('remove', tagIndex)">×</span>
  </div>
</template>
<script>
export default {
  name: "Tag",
  props: {
    tagIndex: { type: Number },
    label: { type: String, default: "" },
  },
};
</script>
<style lang="scss" scoped>
.tag {
  padding: 0 0.5em;
  background-color: #bcc5e8;
  border-radius: 6px;
  margin-right: 0.25em;
  margin-bottom: 0.25em;
  height: fit-content;
  label {
    font-size: 0.75rem;
    margin-right: 0.5em;
    text-transform: capitalize;
  }
  .close {
    font-size: 1.5rem;
  }
}
</style>
