import axios from "axios";
import httpClient from "./httpClient";
import { generateId } from "@/utils/url";
import { eventBus } from "@/utils/event-bus";
import store from "../../store";

const MEDIA_BASE_URL = "https://static.socianis.com/";

const partSize = 1024 * 1024 * 5;

const axiosClient = axios.create();

const ASSET = "/asset";

export const getAssets = (params) => httpClient.get(ASSET, { params });
export const deleteAsset = (params) => httpClient.delete(ASSET, { params });
export const getPresignedUrl = (params) =>
  httpClient.get(`${ASSET}/signed`, { params });
export const initializeAssets = (payload = {}) =>
  httpClient.post(`${ASSET}/initialize`, payload);
export const completeAssetUpload = (payload = {}) =>
  httpClient.post(`${ASSET}/complete`, payload);
export const updateAsset = (data = {}, params = {}) =>
  httpClient.put(ASSET, data, { params });

export const signedMultipartUpload = async (uploadData = {}, metaInfo = {}) => {
  const {
    files = [],
    thumbnails = [],
    presignedUrls = [],
    uploadIds = [],
    objectNames = [],
  } = uploadData;

  for (const [i, urls] of presignedUrls.entries()) {
    const keys = Object.keys(urls);
    const promises = [];
    let uploadProgress = 0;
    for (const indexStr of keys) {
      const index = parseInt(indexStr);
      const file = files[i];
      const start = index * partSize;
      const end = (index + 1) * partSize;
      const blob =
        index < keys.length ? file.slice(start, end) : file.slice(start);

      let previousProgress = 0;
      const config = {
        onUploadProgress: (progress) => {
          uploadProgress += Math.abs(previousProgress - progress.loaded);
          previousProgress = progress.loaded;

          const progressPayload = {
            totalProgress: Number((uploadProgress / file.size) * 100).toFixed(
              0,
            ),
            assetId: file.name,
          };

          eventBus.$emit("asset-upload-progress", progressPayload);
        },
      };

      promises.push(axiosClient.put(urls[index], blob, config));
    }

    const uploadedParts = await Promise.all(promises);

    const uploadPartsMap = uploadedParts.map((part, index) => ({
      ETag: part.headers.etag,
      PartNumber: index + 1,
    }));

    const completionPayload = {
      object_name: objectNames[i],
      asset_id: files[i].name,
      upload_id: uploadIds[i],
      parts: uploadPartsMap,
    };

    const completedUpload = await completeAssetUpload(completionPayload);

    const progressPayload = {
      totalProgress: 100,
      assetId: files[i].name,
      status: { type: "completed", message: "completed" },
    };

    store.dispatch("appendAsset", completedUpload.data);
    eventBus.$emit("asset-upload-progress", progressPayload);
  }

  const thumbPromises = [];
  const updatePromises = [];

  for (const thumb of thumbnails) {
    thumbPromises.push(uploadImage(thumb, metaInfo.uploadContext));
  }

  const results = await Promise.all(thumbPromises);

  for (const [i, res] of results.entries()) {
    updatePromises.push(
      updateAsset({ thumbnail_url: res }, { asset_id: thumbnails[i].name }),
    );
  }
  const thumbRes = await Promise.all(updatePromises);

  for (const thumb of thumbRes) {
    store.dispatch("appendAsset", thumb.data);
  }
};

export const uploadImage = async (data, folder) => {
  return new Promise((resolve, reject) => {
    const dataType = data.type.split("/")[1];
    const key = `${folder}/${generateId()}.${dataType}`;
    const contentType = data.type;

    const params = {
      key,
      content_type: contentType,
    };

    getPresignedUrl(params)
      .then((res) => {
        const uploadUrl = res.data;

        return axiosClient.put(uploadUrl, data);
      })
      .then((res) => {
        const mediaUrl = MEDIA_BASE_URL + key;
        resolve(mediaUrl);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
