<template>
  <button class="blank-button" @click="handleClick">
    {{ label }}
  </button>
</template>
<script>
export default {
  name: "BlankButton",
  props: { label: { type: String, default: "" } },
  methods: {
    handleClick: function () {
      this.$emit("button-click");
    },
  },
};
</script>
<style lang="scss" scoped>
.blank-button {
  background-color: white;
  padding: 1em 2em;
  font-size: 1rem;
  border: solid 1px #ccc;
  border-radius: 52px;
  letter-spacing: 1px;
  height: 55px;
  &:disabled {
    opacity: 0.7;
  }
}
</style>
