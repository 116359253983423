<template>
  <modal
    @close="closeModal"
    :title="
      currentStep === 1 ? 'Media library name' : 'Select projects (optional)'
    "
    :description="currentStep === 1 ? 'Next: Select projects (optional)' : ' '"
    :stepInfo="`${currentStep} of 2`"
  >
    <div slot="modal-content" class="modal-content-wrapper">
      <div class="d-flex d-flex-c" style="flex: 1">
        <validation-observer
          ref="createMediaLibraryForm"
          v-slot="{ invalid }"
          class="d-flex d-flex-c d-flex-between"
          style="flex: 1"
        >
          <form
            @submit.prevent="() => ({})"
            class="d-flex d-flex-c d-flex-between"
            style="flex: 1"
          >
            <div class="input-wrapper mt-1 mb-2">
              <component
                v-for="(field, index) in stepData[`step_${currentStep}`].fields"
                :key="field.key || index"
                :is="field.type"
                v-bind="field.props"
                v-model="formData[field.props.name]"
                @trigger="field.trigger"
              ></component>
            </div>
            <div
              class="button-wrapper"
              :style="
                currentStep === 2 ? 'justify-content: space-between;' : ''
              "
            >
              <dashboard-button
                v-if="currentStep === 2"
                label="Previous"
                @button-click="changeStep"
              />

              <dashboard-button
                :label="currentStep === 1 ? 'Next' : 'Save'"
                :is-requesting="isRequesting"
                :invalid="invalid"
                @button-click="currentStep === 1 ? changeStep() : formSubmit()"
              />
            </div>
          </form>
        </validation-observer>
      </div>
    </div>
  </modal>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import { mapActions, mapState } from "vuex";
import { Modal } from "@/components";
import {
  ContentInput,
  FormSelect,
  ListItems,
  DashboardButton,
} from "@/components/ui/";

export default {
  name: "CreateMediaLibraryModal",
  components: {
    Modal,
    ValidationObserver,
    ContentInput,
    FormSelect,
    ListItems,
    DashboardButton,
  },
  data() {
    return {
      currentStep: 1,
      formData: {
        mediaLibraryName: "",
        projects: [],
      },
    };
  },
  computed: {
    ...mapState({
      isRequesting: (state) =>
        state.library.status.requesting.createMediaLibrary,
      projects: (state) => state.project.projects,
    }),
    stepData() {
      let scope = this;
      return {
        step_1: {
          fields: [
            {
              type: "ContentInput",
              trigger: () => ({}),
              props: {
                name: "mediaLibraryName",
                label: "Media library name",
                rules: "required",
              },
            },
          ],
        },
        step_2: {
          fields: [
            {
              type: "ListItems",
              trigger: this.alterProjects,
              props: {
                name: "projects",
                label: "Share media library with",
                checkbox: true,
                items: scope.projects.map((project) => {
                  return {
                    id: project.id,
                    key: project.name,
                    value: project.name,
                    picture: project.project_picture,
                    isSelected: scope.formData.projects.includes(project.id),
                  };
                }),
              },
            },
          ],
        },
      };
    },
  },
  methods: {
    ...mapActions({
      createMediaLibrary: "createMediaLibrary",
    }),
    closeModal() {
      this.$emit("closeModal");
    },
    alterProjects(project) {
      if (project.value) {
        this.formData.projects.push(project.id);
      } else {
        this.formData.projects = this.formData.projects.filter(
          (c) => c !== project.id,
        );
      }
    },
    changeStep() {
      if (this.currentStep === 1) {
        this.currentStep = 2;
      } else {
        this.currentStep = 1;
      }
    },
    formSubmit() {
      // console.log(this.formData);
      const payload = {
        name: this.formData.mediaLibraryName,
        projects: this.formData.projects,
      };
      this.createMediaLibrary(payload).then((res) => {
        this.closeModal();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.modal-content-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 400px;

  .button-wrapper {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
