export const GET_CURRENT_TEAM_REQUEST = "GET_TEAM_REQUEST";
export const GET_CURRENT_TEAM_SUCCESS = "GET_TEAM_SUCCESS";
export const GET_CURRENT_TEAM_ERROR = "GET_TEAM_ERROR";

export const GET_TEAMS_REQUEST = "GET_TEAMS_REQUEST";
export const GET_TEAMS_ERROR = "GET_TEAMS_ERROR";
export const GET_TEAMS_SUCCESS = "GET_TEAMS_SUCCESS";

export const GET_TEAM_ROLES_REQUEST = "GET_TEAM_ROLES_REQUEST";
export const GET_TEAM_ROLES_ERROR = "GET_TEAM_ROLES_ERROR";
export const GET_TEAM_ROLES_SUCCESS = "GET_TEAM_ROLES_SUCCESS";

export const CREATE_TEAM_REQUEST = "CREATE_TEAM_REQUEST";
export const CREATE_TEAM_SUCCESS = "CREATE_TEAM_SUCCESS";
export const CREATE_TEAM_ERROR = "CREATE_TEAM_ERROR";

export const UPDATE_TEAM_REQUEST = "UPDATE_TEAM_REQUEST";
export const UPDATE_TEAM_SUCCESS = "UPDATE_TEAM_SUCCESS";
export const UPDATE_TEAM_ERROR = "UPDATE_TEAM_ERROR";

export const DELETE_TEAM_REQUEST = "DELETE_TEAM_REQUEST";
export const DELETE_TEAM_SUCCESS = "DELETE_TEAM_SUCCESS";
export const DELETE_TEAM_ERROR = "DELETE_TEAM_ERROR";

export const LEAVE_TEAM_REQUEST = "LEAVE_TEAM_REQUEST";
export const LEAVE_TEAM_SUCCESS = "LEAVE_TEAM_SUCCESS";
export const LEAVE_TEAM_ERROR = "LEAVE_TEAM_ERROR";

export const ADD_TEAM_MEMBER_REQUEST = "ADD_TEAM_MEMBER_REQUEST";
export const ADD_TEAM_MEMBER_SUCCESS = "ADD_TEAM_MEMBER_SUCCESS";
export const ADD_TEAM_MEMBER_ERROR = "ADD_TEAM_MEMBER_ERROR";

export const UPDATE_TEAM_MEMBER_REQUEST = "UPDATE_TEAM_MEMBER_REQUEST";
export const UPDATE_TEAM_MEMBER_SUCCESS = "UPDATE_TEAM_MEMBER_SUCCESS";
export const UPDATE_TEAM_MEMBER_ERROR = "UPDATE_TEAM_MEMBER_ERROR";

export const REMOVE_TEAM_MEMBER_REQUEST = "REMOVE_TEAM_MEMBER_REQUEST";
export const REMOVE_TEAM_MEMBER_SUCCESS = "REMOVE_TEAM_MEMBER_SUCCESS";
export const REMOVE_TEAM_MEMBER_ERROR = "REMOVE_TEAM_MEMBER_ERROR";

export const CONFIRM_TEAM_INVITE_REQUEST = "CONFIRM_TEAM_INVITE_REQUEST";
export const CONFIRM_TEAM_INVITE_SUCCESS = "CONFIRM_TEAM_INVITE_SUCCESS";
export const CONFIRM_TEAM_INVITE_ERROR = "CONFIRM_TEAM_INVITE_ERROR";

export const GET_TEAM_INSIGHTS_REQUEST = "GET_TEAM_INSIGHTS_REQUEST";
export const GET_TEAM_INSIGHTS_SUCCESS = "GET_TEAM_INSIGHTS_SUCCESS";
export const GET_TEAM_INSIGHTS_ERROR = "GET_TEAM_INSIGHTS_ERROR";

export const CHANGE_TEAM = "CHANGE_TEAM";

export const SET_INSIGHTS_PROJECT = "SET_INSIGHTS_PROJECT";
