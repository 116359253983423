<template>
  <modal
    title="Upload file"
    :class="{ 'is-preview': isPreview }"
    @transition-end="loadUploadItems"
    @close="closeModal"
    @drop="dropHandler"
  >
    <div slot="after-content" class="after-content">
      <div
        class="d-flex d-flex-c"
        style="max-height: 100%; overflow-y: auto; overflow-x: hidden"
      >
        <upload-item
          v-for="(data, index) in uploadData"
          :key="index"
          :index="index"
          :item-name="data.file.name"
          :preview-img="data.thumb"
          :upload-progress="data.uploadProgress"
          :status="data.status"
          :to-render="renderUploadItems"
        />
      </div>
    </div>
    <div slot="modal-content" class="modal-content-wrapper">
      <div class="d-flex d-flex-c d-flex-center mt-3 main-wrapper">
        <!--UPLOAD-->
        <form class="mb-5" enctype="multipart/form-data" novalidate>
          <div class="d-flex d-flex-c d-flex-center dropzone">
            <input
              class="input-file"
              type="file"
              multiple
              accept="image/*, video/*"
              :name="uploadFormName"
              @change="filesChange($event.target.name, $event.target.files)"
            />
          </div>
        </form>

        <label
          class="content-button"
          data-toggle="tooltip"
          data-placement="top"
          title="Upload"
        >
          <input
            type="file"
            multiple
            :name="uploadFormName"
            accept="image/*, video/*"
            hidden
            @change="filesChange($event.target.name, $event.target.files)"
          />

          <span>Browse files</span>
        </label>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { createThumb } from "@/utils/media";
import { eventBus } from "@/utils/event-bus";
import { generateId } from "@/utils/url";
import { signedMultipartUpload } from "@/services/api/asset.api";

import { Modal } from "@/components";
import { UploadItem } from "@/components/ui";

const STATUS_INITIAL = 0,
  STATUS_LOADING = 1,
  STATUS_PREVIEW = 2;

export default {
  name: "UploadModal",
  components: {
    Modal,
    UploadItem,
  },
  props: {
    mediaLibraryId: { type: String, default: "" },
  },
  data() {
    return {
      currentStatus: STATUS_INITIAL,
      uploadData: [],
      uploadFormName: "media_upload",
      thumbnailsFormName: "thumbnails_upload",
      renderUploadItems: false,
    };
  },
  mounted() {
    eventBus.$on("asset-upload-progress", this.updateProgress);
  },
  beforeDestroy() {
    eventBus.$off("asset-upload-progress", this.updateProgress);
  },
  computed: {
    ...mapState({
      assetsInitializing: (state) =>
        state.asset.status.requesting.initializeAssets,
      userId: (state) => state.dashboard.user.id,
      uploadContext: (state) => {
        const teamId = state.team.currentTeam.id || "";
        const sep = teamId ? "/" : "";
        const userId = state.dashboard.user.id;

        return `${userId}${sep}${teamId}`;
      },
    }),
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isLoading() {
      return this.currentStatus === STATUS_LOADING;
    },
    isPreview() {
      return this.currentStatus === STATUS_PREVIEW;
    },
  },
  methods: {
    ...mapActions({
      initializeAssets: "initializeAssets",
    }),
    loadUploadItems(val) {
      this.renderUploadItems = val;
    },
    closeModal() {
      this.$emit("closeModal");
    },
    submit() {
      this.closeModal();
    },
    updateProgress(progressPayload) {
      const dataIndex = this.uploadData.findIndex(
        (data) => data.assetId === progressPayload.assetId,
      );

      this.uploadData[dataIndex].uploadProgress = Number(
        progressPayload.totalProgress,
      );

      if (progressPayload.status) {
        this.uploadData[dataIndex].status = progressPayload.status;
      }
    },
    async filesChange(fieldName, files) {
      if (this.uploadData.length === 0) {
        this.currentStatus = STATUS_LOADING;
      }

      const fileList = Array.from(files);

      const requestPayload = [];

      for await (const file of fileList) {
        const mediaData = await createThumb(file);
        const fileTypeData = file.type.split("/");
        const fileType = fileTypeData[0];
        this.uploadData.push({
          file,
          thumb: mediaData.src,
          thumbFile: mediaData.thumbFile,
          uploadProgress: 0,
          status: {},
          assetId: null,
        });
        requestPayload.push({
          object_name: `${this.uploadContext}/${generateId()}.${
            fileTypeData[1]
          }`,
          media_size: file.size,
          content_type: file.type,
          asset_type: fileType,
          asset_library_id: this.mediaLibraryId,
          ...(mediaData.dimensions && {
            media_width: mediaData.dimensions.width,
            media_height: mediaData.dimensions.height,
          }),
          ...(mediaData.duration && {
            media_duration: mediaData.duration,
          }),
        });
      }

      const objectNames = requestPayload.map((o) => o.object_name);

      this.currentStatus = STATUS_PREVIEW;

      await this.initializeAssets(requestPayload).then(async (res) => {
        const formData = new FormData();
        const thumbnails = new FormData();
        const presignedUrls = [];
        const uploadIds = [];
        res.forEach((asset, index) => {
          presignedUrls.push(asset.upload_parts);
          uploadIds.push(asset.upload_id);
          const calcIndex =
            this.uploadData.length > res.length
              ? this.uploadData.length - res.length + index
              : index;
          this.uploadData[calcIndex].assetId = res[index].id;
          formData.append(fieldName, fileList[index], asset.id);
          if (asset.asset_type === "video") {
            thumbnails.append(
              this.thumbnailsFormName,
              this.uploadData[calcIndex].thumbFile,
              asset.id,
            );
          }
        });
        const preparedData = {
          files: formData.getAll(this.uploadFormName),
          thumbnails: thumbnails.getAll(this.thumbnailsFormName),
          presignedUrls,
          uploadIds,
          objectNames,
        };
        const metaInfo = {
          assetLibraryId: this.mediaLibraryId,
          userId: this.userId,
          uploadContext: this.uploadContext,
        };

        await signedMultipartUpload(preparedData, metaInfo);
      });
    },
    async dropHandler(e) {
      e.preventDefault();
      await this.filesChange(this.uploadFormName, e.dataTransfer.files);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="scss">
.main-wrapper {
  overflow: auto;
  max-height: 325px;
  width: 100%;

  > form {
    width: 100%;
    height: 100%;
  }
  .dropzone {
    position: relative;
    width: 100%;
    height: 150px;
    background-image: url("/assets/images/icons/common/upload_icon.webp");
    background-size: 25%;
    background-repeat: no-repeat;
    background-position: center;

    &::after {
      content: "Drag and drop to upload media";
      position: absolute;
      bottom: -1em;
    }

    input {
      height: 100%;
      width: 100%;
      opacity: 0;
    }
  }
}
</style>
