<template>
  <div class="d-flex d-flex-c">
    <div v-if="title" class="row-title mt-2">
      <span>{{ title }}</span>
    </div>
    <div
      :class="[
        'd-flex d-flex-r d-flex-between align-center content-row',
        { 'no-divider': hideDivider },
      ]"
    >
      <div class="d-flex left">
        <slot name="content-left" />
      </div>
      <div class="d-flex right">
        <slot class="d-flex" name="content-right" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ContentRow",
  props: {
    title: {
      type: String,
      default: "",
    },
    hideDivider: { type: Boolean, defualt: false },
  },
};
</script>
<style lang="scss" scoped>
.content-row {
  padding: 3em 0;

  .left,
  .right {
    height: fit-content;
  }
}
.row-title {
  position: relative;
  text-transform: capitalize;
  font-weight: 600;
  color: #7b8994;
  padding-bottom: 1em;
}
.row-title,
.content-row {
  border-bottom: 2px solid #e5e5e5;

  &.no-divider {
    border: none;
  }
}
</style>
