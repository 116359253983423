<template>
  <div class="d-flex d-flex-c not-found">
    <span class="title mt-2 mb-1">Houston, We have a problem!</span>
    <span class="description"
      >We could not locate the page you were looking for.<br />
      Head back to the <router-link to="/">dashboard</router-link></span
    >
  </div>
</template>
<script>
export default {
  name: "PageNotFound",
};
</script>
<style lang="scss" scoped>
.not-found {
  width: 100%;
  height: 100%;
  flex: 1;
  background-image: url("/assets/images/layout/global/bg_waitlist.webp");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: white;

  .title {
    font-size: 3rem;
    font-weight: 600;
    text-align: center;
  }

  .description {
    font-size: 1.75rem;
    text-align: center;
  }
}
</style>
