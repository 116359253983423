<template>
  <div class="content-cards-wrapper">
    <content-card
      v-for="(card, index) in cardsWithImage"
      :key="index"
      :cardIndex="index"
      :cardInfo="card"
      :contentTimeZone="getTimeZone(card.project_id)"
      :isSelected="index === selectedCardIndex"
      :teamRole="currentTeamRole"
      @card-click="selectCard(index)"
      @edit-content="editContent"
      @card-button-click="changeStatus"
      @delete-button-click="removeContent"
    />
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import ContentCard from "./ContentCard.vue";

export default {
  name: "ContentCardsWrapper",
  components: { ContentCard },
  props: {
    cards: { type: Array, default: () => [] },
    pathContext: { type: String, default: "" },
  },
  data() {
    return {
      selectedCardIndex: null,
    };
  },
  computed: {
    ...mapState({
      projects: (state) => state.project.projects,
      timeZone: (state) => state.dashboard.user.timezone,
      assets: (state) => state.asset.assets,
      currentTeamRole: (state) =>
        (
          state.team.roles.find((role) => {
            return (
              role.id ===
              (
                state.team.currentTeam.members.find(
                  (member) => member.id === state.dashboard.user.id,
                ) || {}
              ).role_id
            );
          }) || {}
        ).name,
    }),
    cardsWithImage() {
      return this.cards.map((card) => ({
        ...card,
        card_avatar: (
          this.assets.find((asset) => asset.id === card.assets[0]) || {}
        ).media_url,
      }));
    },
  },

  methods: {
    ...mapActions({
      setActiveModal: "setActiveModal",
      saveContent: "saveContent",
      deleteContent: "deleteContent",
      removeContentFromList: "removeContentFromList",
      removeDraftFromList: "removeDraftFromList",
    }),
    selectCard(index) {
      if (index !== this.selectedCardIndex) {
        this.selectedCardIndex = index;
        this.$emit("card-click", index);
      }
    },
    editContent(card) {
      this.$router.push({
        path: this.pathContext,
        query: {
          id: card.id,
        },
      });
    },
    changeStatus(payload) {
      this.saveContent(payload);
    },
    removeContent(id) {
      this.deleteContent(id);
    },
    getTimeZone(project_id) {
      return project_id
        ? (this.projects.find((project) => project.id === project_id) || {})
            .timezone
        : this.timeZone;
    },
  },
};
</script>
<style lang="scss" scoped>
.content-cards-wrapper {
  overflow-y: scroll;
  padding-right: 1em;

  > div {
    margin-bottom: 1em;
  }
}
</style>
