<template>
  <div class="d-flex d-flex-r">
    <div class="schedule-info-wrapper">
      <schedule-labels
        :startTime="scheduleStartTime"
        :timeZone="timezone"
        :repeatFrequency="repeatFrequency"
        :repeatDays="repeatDays"
        :endTime="scheduleTimeEnd"
      />
    </div>
    <validation-provider mode="aggressive">
      <input
        :ref="startTimeName"
        :name="startTimeName"
        class="hidden-input"
        v-model="scheduleStartTime"
      />
    </validation-provider>
    <validation-provider mode="aggressive">
      <input
        :ref="timezoneName"
        :name="timezoneName"
        class="hidden-input"
        v-model="timezone"
      />
    </validation-provider>
    <validation-provider mode="aggressive">
      <input
        :ref="endDateName"
        :name="endDateName"
        class="hidden-input"
        v-model="scheduleTimeEnd"
      />
    </validation-provider>
    <validation-provider mode="aggressive">
      <input
        :ref="frequencyName"
        :name="frequencyName"
        class="hidden-input"
        v-model="repeatFrequency"
      />
    </validation-provider>
    <validation-provider mode="aggressive">
      <input
        :ref="weekdaysName"
        :name="weekdaysName"
        class="hidden-input"
        v-model="repeatDays"
      />
    </validation-provider>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { ValidationProvider } from "vee-validate";
import { getCurrentTime, formatDateWithTz } from "@/utils/timezone";
import { weekDays } from "@/utils/constants";

import ScheduleLabels from "../shared/ScheduleLabels.vue";

export default {
  components: { ValidationProvider, ScheduleLabels },
  props: {
    timezone: { type: String, default: "" },
    postNow: { type: Boolean, default: false },
  },
  mounted() {
    this.$refs[this.startTimeName].focus();
    this.$refs[this.startTimeName].blur();
    this.$refs[this.timezoneName].focus();
    this.$refs[this.timezoneName].blur();
    this.$refs[this.endDateName].focus();
    this.$refs[this.endDateName].blur();
    this.$refs[this.frequencyName].focus();
    this.$refs[this.frequencyName].blur();
    this.$refs[this.weekdaysName].focus();
    this.$refs[this.weekdaysName].blur();
  },
  data() {
    return {
      startTimeName: "schedule-time-date",
      timezoneName: "schedule-timezone",
      endDateName: "schedule-end-date",
      frequencyName: "schedule-frequency",
      weekdaysName: "schedule-weekdays",
      weekDays,
    };
  },
  computed: {
    ...mapState({
      scheduleStartTime(state) {
        if (this.postNow) {
          return getCurrentTime(this.timezone).format("h:mm A");
        } else if (state.schedule.contentPayload.start_date) {
          return formatDateWithTz(
            state.schedule.contentPayload.start_date,
            this.timezone,
          ).format("h:mm A");
        }
      },
      scheduleTimeEnd(state) {
        if (
          this.postNow &&
          state.schedule.contentPayload.repeat_type === "never" &&
          !state.schedule.contentPayload.end_date
        ) {
          return getCurrentTime(this.timezone)
            .endOf("day")
            .format("DD-MM-YYYY, h:mm A");
        } else if (state.schedule.contentPayload.end_date) {
          return formatDateWithTz(
            state.schedule.contentPayload.end_date,
            this.timezone,
          ).format("DD-MM-YYYY, h:mm A");
        }
      },
      repeatFrequency: (state) => state.schedule.contentPayload.repeat_type,
      repeatDays: (state) =>
        weekDays
          .filter((day, index) =>
            state.schedule.contentPayload.repeat_weekdays.includes(index),
          )
          .join(", "),
    }),
  },
};
</script>

<style lang="scss" scoped>
.schedule-info-wrapper {
  > label {
    margin-left: 0.25em;
    font-weight: 600;
    margin-bottom: 0.5em;
    font-size: 1.15rem;
  }

  .info-section {
    position: relative;
    flex-wrap: wrap;

    &.settings {
      padding-right: 2em;
      &::after {
        content: "";
        display: block;
        position: absolute;
        background-image: url("/assets/images/icons/dashboard/schedule/arrow_down_dark.svg");
        background-size: contain;
        width: 10px;
        height: 7px;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
      }
    }

    .info-wrapper {
      background-color: #d5daef;
      border-radius: 40px;
      padding: 0.5em;
      margin: 0 0.5em 0.5em 0;
      color: #000;
      font-size: 0.85rem;

      label {
        font-weight: 600;
        margin-right: 0.5em;
      }
    }
  }
}
</style>
