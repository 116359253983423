export const GET_MEDIA_LIBRARIES_REQUEST = "GET_MEDIA_LIBRARIES_REQUEST";
export const GET_MEDIA_LIBRARIES_SUCCESS = "GET_MEDIA_LIBRARIES_SUCCESS";
export const GET_MEDIA_LIBRARIES_ERROR = "GET_MEDIA_LIBRARIES_ERROR";

export const CREATE_MEDIA_LIBRARY_REQUEST = "CREATE_MEDIA_LIBRARY_REQUEST";
export const CREATE_MEDIA_LIBRARY_SUCCESS = "CREATE_MEDIA_LIBRARY_SUCCESS";
export const CREATE_MEDIA_LIBRARY_ERROR = "CREATE_MEDIA_LIBRARY_ERROR";

export const UPDATE_MEDIA_LIBRARY_REQUEST = "UPDATE_MEDIA_LIBRARY_REQUEST";
export const UPDATE_MEDIA_LIBRARY_SUCCESS = "UPDATE_MEDIA_LIBRARY_SUCCESS";
export const UPDATE_MEDIA_LIBRARY_ERROR = "UPDATE_MEDIA_LIBRARY_ERROR";

export const DELETE_MEDIA_LIBRARY_REQUEST = "DELETE_MEDIA_LIBRARY_REQUEST";
export const DELETE_MEDIA_LIBRARY_SUCCESS = "DELETE_MEDIA_LIBRARY_SUCCESS";
export const DELETE_MEDIA_LIBRARY_ERROR = "DELETE_MEDIA_LIBRARY_ERROR";
