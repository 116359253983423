<template>
  <div
    class="d-flex calendar-day"
    :class="{
      'calendar-day--not-current': !isCurrentMonth,
      'calendar-day--today': isToday,
      'calendar-day--selected': daySelected && !isMonthView,
      'calendar-day--disabled': disabled,
      'has-scheduled-content': hasScheduledContent && !isMonthView,
    }"
    @click="selectDay"
  >
    <span
      v-if="dayLabel"
      :class="['day-label', { 'month-view': isMonthView }]"
      >{{ dayLabel }}</span
    >
    <span
      :class="[
        'day-number',
        { 'month-view': isMonthView, 'mb-05': isMonthView },
      ]"
      >{{ label }}</span
    >
    <div v-if="isMonthView" class="d-flex d-flex-c day-items">
      <div
        v-for="data in sortedItems.slice(0, dataLimit)"
        :key="data.id"
        class="day-item"
      >
        <span
          v-html="formatItem(data)"
          @click="showContentDetails(data)"
          @click.stop
        />"
      </div>
      <div
        v-if="scheduledListToggled && !selectedContent"
        v-outside-click="handleClose"
        class="d-flex d-flex-c scheduled-list-container"
      >
        <span class="d-flex d-flex-end close" @click="toggleScheduleList"
          >×</span
        >
        <span class="day-title mb-1">{{ formattedDay }}</span>

        <div class="d-flex d-flex-c day-items-scroll">
          <span
            v-for="data in sortedItems"
            :key="data.id"
            class="day-item"
            v-html="formatItem(data)"
            @click="showContentDetails(data)"
            @click.stop
          />
        </div>
      </div>
    </div>
    <span
      v-if="isMonthView && hiddenItems > 0"
      class="show-more"
      @click="toggleScheduleList"
      @click.stop
      ><b>{{ hiddenItems }} more</b></span
    >
  </div>
</template>

<script>
import { mapState } from "vuex";
import dayjs, { sortContentByTime, formatDateWithTz } from "@/utils/timezone";

export default {
  name: "CalendarMonthDayItem",
  props: {
    day: {
      type: Object,
      required: true,
    },
    dayLabel: {
      type: String,
      required: false,
    },
    isMonthView: {
      type: Boolean,
      default: false,
    },
    contentsData: { type: Array, default: () => [] },
    projects: { type: Array, default: () => [] },
    userTimeZone: { type: String, default: "" },
    disabled: {
      type: Boolean,
      default: false,
    },
    isCurrentMonth: {
      type: Boolean,
      default: false,
    },
    isToday: {
      type: Boolean,
      default: false,
    },
    selectedContent: {
      type: Object,
      default: null,
    },
    isPostTime: { type: Boolean, default: false }, // to distinguish between the calendar for fetching and scheduling post
  },
  data() {
    return {
      dataLimit: 2,
      scheduledListToggled: false,
    };
  },
  computed: {
    ...mapState({
      selectedDate: function (state) {
        return this.isPostTime
          ? state.schedule.scheduleModalSettings.selectedDate
          : state.content.contentSortSettings.selectedDate;
      },
      selectedProjectId: (state) =>
        state.content.contentSortSettings.selectedProjectId,
    }),
    label() {
      return dayjs(this.day.date).format("D");
    },
    daySelected() {
      return (
        this.selectedDate.format("YYYY-MM-DD") ===
          dayjs(this.day.date).format("YYYY-MM-DD") && !this.disabled
      );
    },
    hiddenItems() {
      return this.contentsData.length - this.dataLimit;
    },
    sortedItems() {
      return [...this.contentsData].sort(sortContentByTime);
    },
    formattedDay() {
      return dayjs(this.day.date).format("ddd, D MMM");
    },
    hasScheduledContent() {
      return (
        this.contentsData &&
        this.contentsData.filter((content) => {
          if (this.selectedProjectId === null) {
            return true;
          } else {
            if (content.project_id === this.selectedProjectId) {
              return true;
            } else {
              return false;
            }
          }
        }).length > 0
      );
    },
  },
  methods: {
    selectDay() {
      if (!this.disabled) {
        this.$emit("select-date", dayjs(this.day.date), this.day.contentIds);
      }
    },
    toggleScheduleList() {
      this.scheduledListToggled = !this.scheduledListToggled;
    },
    handleClose() {
      this.scheduledListToggled = false;
    },
    showContentDetails(content) {
      const currentDateTime = dayjs(this.day.date)
        .hour(dayjs(content.start_date).hour())
        .minute(dayjs(content.start_date).minute());
      const timezone = content.project_id
        ? this.projects.find((project) => project.id === content.project_id)
            .timezone
        : this.userTimeZone;
      this.$emit("select-content", {
        ...content,
        date: formatDateWithTz(currentDateTime, timezone).format(
          "DD-MM-YYYY, h:mm A",
        ),
        start_date: formatDateWithTz(content.start_date, timezone),
        end_date: content.end_date
          ? formatDateWithTz(content.end_date, timezone).format(
              "DD-MM-YYYY, h:mm A",
            )
          : null,
      });
    },
    formatItem(item) {
      const timezone = item.project_id
        ? this.projects.find((project) => project.id === item.project_id)
            .timezone
        : this.userTimeZone;
      return `<span>${formatDateWithTz(item.start_date, timezone).format(
        "h:mm A",
      )} <b>${item.text.substring()}</b></span>`;
    },
  },
};
</script>

<style lang="scss">
.calendar-day {
  position: relative;
  justify-content: center;
  height: 35px;
  width: 35px;
  font-size: 1rem;
  background-color: #fff;
  color: var(--grey-800);
  padding: 5px;
  cursor: pointer;

  &.has-scheduled-content {
    &::after {
      content: "";
      position: absolute;
      background-color: #2bd18a;
      height: 5px;
      width: 5px;
      border-radius: 100%;
      bottom: -10px;
      z-index: 1;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.calendar-day > span {
  display: flex;
  justify-content: center;
  align-items: center;

  &.month-view {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}

.calendar-day--not-current {
  background-color: #fff;
  color: #000;
}

.calendar-day--today {
  .day-number {
    position: relative;
    color: #fff;
    z-index: 1;
    &::after {
      position: absolute;
      content: "";
      width: 30px;
      height: 30px;
      background-color: #aab4db;
      border-radius: 100%;
      z-index: -1;
    }
  }
}

.calendar-day--today > span {
  border-radius: 100%;
  background-color: var(--grey-800);
}

.calendar-day--selected {
  .day-number {
    position: relative;
    color: #fff;
    z-index: 1;
    &::after {
      position: absolute;
      content: "";
      width: 30px;
      height: 30px;
      background-color: #221c55;
      border-radius: 100%;
      z-index: -1;
    }
  }
}

.calendar-day--disabled {
  opacity: 0.5;
  cursor: default;
}

.day-label {
  color: black;
  font-weight: 600;

  &.month-view {
    & + .day-number {
      top: 25px;
    }
  }
}

.day-title {
  font-size: 1rem;
}

.scheduled-list-container {
  position: absolute;
  align-items: center;
  width: 200px;
  padding: 0.75em 0.75em 2em;
  max-height: 200px;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0px 3px 5px #00000029;
  top: -15px;
  left: -18px;
  z-index: 1;

  .close {
    font-size: 1rem;
    align-self: flex-end;
  }
}

.day-items {
  position: absolute;
  top: 40px;
  font-size: 0.75rem;
  .day-item {
    position: relative;
    width: 125px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 10px;

    &::after {
      display: block;
      content: "";
      position: absolute;
      height: 5px;
      width: 5px;
      border-radius: 100%;
      left: 0;
      top: 7.5px;
      background-color: black;
    }
    &:hover {
      text-decoration: underline;
    }
  }
}

.day-items-scroll {
  overflow: auto;
  width: 100%;
  padding-right: 5px;

  .day-item {
    width: 100%;
    min-height: 20px;
    overflow: hidden;
  }
}

.show-more {
  position: absolute;
  bottom: 5px;
  font-size: 0.75rem;
  &:hover {
    text-decoration: underline;
  }
}
</style>
