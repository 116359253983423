<template>
  <validation-provider v-slot="{ errors }" :rules="rules" mode="aggressive">
    <div class="d-flex d-flex-c">
      <label class="content-label">{{ label }}</label>
      <input
        :class="['content-input', { 'input-error': errors[0] }]"
        :name="name"
        :disabled="disabled"
        :type="password ? 'password' : 'text'"
        v-model="currentValue"
      />
      <span class="input-error">{{ errors[0] }}</span>
    </div>
  </validation-provider>
</template>
<script>
import { ValidationProvider } from "vee-validate";
import "@/utils/validation";

export default {
  name: "ContentInput",
  components: { ValidationProvider },
  props: {
    name: { type: String, default: "" },
    label: { type: String, default: "" },
    rules: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
    password: { type: Boolean, default: false },
    value: { type: String, default: "" },
  },
  computed: {
    currentValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
div {
  margin-bottom: 1em;
  .content-label {
    margin-bottom: 0.1em;
    font-size: 0.85rem;
    font-weight: bold;
  }

  .content-input {
    padding: 0.75em 1em;
    background-color: #f1f9ff;
    border: 1px solid #bce0fd;
    border-radius: 6px;
  }
  .input-error {
    color: red;
    border-color: red;
  }
  span {
    &.input-error {
      font-size: 0.75rem;
    }
  }
}
</style>
