var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'd-flex d-flex-c mr-1 ml-1 mb-2 plan-wrapper',
    { 'is-active': _vm.isActive },
  ]},[_c('div',{staticClass:"d-flex d-flex-center target-wrapper"},[_vm._v(" "+_vm._s(_vm.$t("ui.best_for"))+" "+_vm._s(_vm.target)+" ")]),_c('div',{staticClass:"d-flex d-flex-c plan"},[_c('div',{staticClass:"d-flex d-flex-between d-flex-r mt-1 mb-1"},[_c('span',{staticClass:"d-flex"},[_vm._v(_vm._s(_vm.subscriptionLabel))])]),_c('div',{staticClass:"d-flex d-flex-r d-flex-between align-center mb-05"},[_c('div',{staticClass:"d-flex d-flex-r align-center"},[_c('span',{staticClass:"d-flex price"},[_vm._v(_vm._s(_vm.formattedPrice))]),(!_vm.isFreeTier)?_c('span',{staticStyle:{"opacity":"0.75"}},[_vm._v("/"+_vm._s(_vm.$t("ui.monthly_abbr"))+" ")]):_vm._e()]),(!_vm.isFreeTier)?_c('span',{staticStyle:{"opacity":"0.75"}},[_vm._v(" "+_vm._s(_vm.$t("ui.billed"))+" "+_vm._s(_vm.billingCycle))]):_vm._e()]),_c('div',{staticClass:"d-flex d-flex-center mb-1"},[_c('span',{staticClass:"img-wrapper"},[_c('img',{attrs:{"src":`/assets/images/layout/subscription/${_vm.subscriptionType}_l.svg`}})])]),_c('div',{staticClass:"d-flex d-flex-c align-center"},[_c('div',{class:[
          'd-flex d-flex-c features-wrapper',
          { 'is-expanded': _vm.expanded },
        ]},_vm._l((_vm.subscriptionFeatures),function(feature,index){return _c('div',{key:index,staticClass:"mb-1"},[_c('div',{staticClass:"d-flex d-flex-r d-flex-between feature-row align-center"},[_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(_vm.$t(`pricing.${feature.name}.label`))+" ")]),_c('div',{staticClass:"d-flex d-flex-c"},[(feature.isList)?_c('div',{staticClass:"d-flex d-flex-r feature-list"},_vm._l((feature.subscription[
                    _vm.subscriptionType
                  ]),function(item,index){return _c('span',{key:index,class:['social ml-05', item]})}),0):(feature.isCheck)?_c('div',[_c('span',{class:[
                    'check',
                    { 'is-checked': feature.subscription[_vm.subscriptionType] },
                  ]})]):_c('div',[(feature.subscription[_vm.subscriptionType] === -1)?_c('span',[_vm._v(_vm._s(_vm.$t("ui.unlimited")))]):(!feature.subscription[_vm.subscriptionType])?_c('span',{staticClass:"not-available"}):_c('span',[_vm._v(_vm._s(_vm.$tc( `pricing.${feature.name}.value`, feature.subscription[_vm.subscriptionType], )))])])])])])}),0),_c('button',{class:[
          ' d-flex d-flex-r d-flex-center align-center mt-3 mb-1 expand-button',
          ,
          { 'is-expanded': _vm.expanded },
        ],on:{"click":_vm.handleExpand}},[_vm._v(" "+_vm._s(_vm.$t(`ui.${_vm.expandLabel}`))+" ")])])]),_c('button',{staticClass:"d-flex d-flex-r d-flex-center d-align-center plan-button",on:{"click":_vm.handlePlanSelect}},[_vm._v(" "+_vm._s(_vm.$t(`ui.${_vm.buttonLabel}`))+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }