import httpClient from "./httpClient";

const TEAM = "/team";
const TEAM_MEMBER = "/team/member";
const TEAM_INSIGHTS = "/team/insights";

export const getCurrentTeam = (params) =>
  httpClient.get(`${TEAM}/current`, { params });
export const getTeams = () => httpClient.get(TEAM);
export const getTeamRoles = () => httpClient.get(`${TEAM}/roles`);
export const createTeam = (payload = {}) => httpClient.post(TEAM, payload);
export const updateTeam = (data = {}, params = {}) =>
  httpClient.put(TEAM, data, { params });
export const leaveTeam = (params = {}) =>
  httpClient.put(`${TEAM}/leave`, null, { params });
export const deleteTeam = (params) => httpClient.delete(TEAM, { params });
export const addMember = (data = {}, params = {}) =>
  httpClient.post(TEAM_MEMBER, data, { params });
export const updateMember = (data = {}, params = {}) =>
  httpClient.put(TEAM_MEMBER, data, { params });
export const removeMember = (params = {}) =>
  httpClient.delete(TEAM_MEMBER, { params });
export const confirmTeamInvite = (params) =>
  httpClient.post(`${TEAM}/confirm`, null, { params });
export const getTeamInsights = (params) =>
  httpClient.get(TEAM_INSIGHTS, { params });
