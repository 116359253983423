var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex d-flex-r interval-wrapper"},_vm._l((_vm.intervals),function(interval,index){return _c('div',{key:index,class:[
      'interval-label-wrapper',
      {
        'is-selected': interval.type === _vm.selectedInterval.type,
        'full-width': interval.type === 'yearly',
      },
    ],on:{"click":function($event){return _vm.selectInterval(interval)}}},[_c('span',[_vm._v(_vm._s(interval.label)+" "),(interval.type === 'yearly')?_c('span',{staticClass:"discount"},[_vm._v(_vm._s(_vm.$t("ui.discount")))]):_vm._e()])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }