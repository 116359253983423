import {
  GET_DASHBOARD_USER_REQUEST,
  GET_DASHBOARD_USER_ERROR,
  GET_DASHBOARD_USER_SUCCESS,
  GET_USER_PROFILE_REQUEST,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_ERROR,
  UPDATE_USER_PROFILE_REQUEST,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_ERROR,
  UPDATE_USER_PASSWORD_REQUEST,
  UPDATE_USER_PASSWORD_SUCCESS,
  UPDATE_USER_PASSWORD_ERROR,
  SET_ACTIVE_PROFILE_MODAL,
  SET_ACTIVE_MODAL,
  SET_ACTIVE_SIDE_MODAL,
  TOGGLE_PAGES_SELECT,
} from "../actions/dashboard";

import { GET_PROJECTS_SUCCESS } from "../actions/project";
import { GET_CHANNELS_SUCCESS } from "../actions/channel";
import { GET_ASSETS_SUCCESS } from "../actions/asset";
import { GET_MEDIA_LIBRARIES_SUCCESS } from "../actions/library";
import {
  GET_TEAMS_SUCCESS,
  GET_CURRENT_TEAM_SUCCESS,
  GET_TEAM_ROLES_SUCCESS,
} from "../actions/team";

import { GET_CONTENTS_SUCCESS } from "../actions/content";

import { GET_USER_BILLING_INFO_SUCCESS } from "../actions/subscription";

import { RESET_MODULE_STATE } from "../actions/global";
import { AUTH_SUCCESS } from "../actions/auth";
import {
  getDashboard,
  getProfile,
  updateProfile,
  updatePassword,
} from "../../services/api/dashboard.api";

const getDefaultState = () => ({
  user: {
    teams: [],
  },
  status: {
    requesting: {
      getDashboard: false,
      getProfile: false,
      updateProfile: false,
      updatePassword: false,
    },
    errors: {
      getDashboard: null,
      getProfile: null,
      updateProfile: null,
      updatePassword: null,
    },
  },
  activeModal: { name: "", props: null, modalSubmit: null },
  activeSideModal: { name: "", props: null, modalSubmit: null },
  activeProfileModal: { name: "", props: null, modalSubmit: null },
  selectPagesToggled: false,
});

const state = getDefaultState();

const getters = {
  isLoading: (state) => !!state.status.loading,
};

const actions = {
  getDashboard: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit(GET_DASHBOARD_USER_REQUEST);
      getDashboard()
        .then((res) => {
          const {
            user,
            projects,
            channels,
            assets,
            asset_libraries,
            teams,
            current_team,
            roles,
            contents,
            drafts,
            user_subscription,
            user_card_info,
          } = res.data;
          commit(GET_DASHBOARD_USER_SUCCESS, user);
          commit(GET_USER_BILLING_INFO_SUCCESS, {
            ...user_subscription,
            payment_method_info: user_card_info,
          });
          commit(GET_PROJECTS_SUCCESS, projects);
          commit(GET_CHANNELS_SUCCESS, channels);
          commit(GET_ASSETS_SUCCESS, assets);
          commit(GET_MEDIA_LIBRARIES_SUCCESS, asset_libraries);
          commit(GET_TEAMS_SUCCESS, teams);
          commit(GET_CURRENT_TEAM_SUCCESS, { current_team, roles });
          commit(GET_CONTENTS_SUCCESS, { contents, drafts });
          commit(AUTH_SUCCESS);
          resolve(res);
        })
        .catch((error) => {
          commit(GET_DASHBOARD_USER_ERROR, error);
          reject(error);
        });
    });
  },
  getProfile: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit(GET_USER_PROFILE_REQUEST);
      getProfile()
        .then((res) => {
          commit(GET_USER_PROFILE_SUCCESS, res.data);
          resolve(res);
        })
        .catch((error) => {
          commit(GET_USER_PROFILE_ERROR, error);
          reject(error);
        });
    });
  },
  updateProfile: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit(UPDATE_USER_PROFILE_REQUEST);
      updateProfile(payload)
        .then((res) => {
          commit(UPDATE_USER_PROFILE_SUCCESS, res.data);
          resolve(res);
        })
        .catch((error) => {
          commit(UPDATE_USER_PROFILE_ERROR, error);
          reject(error);
        });
    });
  },
  updatePassword: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit(UPDATE_USER_PASSWORD_REQUEST);
      updatePassword(payload)
        .then((res) => {
          commit(UPDATE_USER_PASSWORD_SUCCESS);
          resolve(res);
        })
        .catch((error) => {
          commit(UPDATE_USER_PASSWORD_ERROR, error);
          reject(error);
        });
    });
  },
  setActiveProfileModal: (
    { commit },
    modal = { name: "", props: null, modalSubmit: null },
  ) => {
    commit(SET_ACTIVE_PROFILE_MODAL, modal);
  },
  setActiveModal: (
    { commit },
    modal = { name: "", props: null, modalSubmit: null },
  ) => {
    commit(SET_ACTIVE_MODAL, modal);
  },
  setActiveSideModal: (
    { commit },
    sideModal = { name: "", props: null, modalSubmit: null },
  ) => {
    commit(SET_ACTIVE_SIDE_MODAL, sideModal);
  },
  togglePagesSelect: ({ commit }, toggle) => {
    commit(TOGGLE_PAGES_SELECT, toggle);
  },
};

const mutations = {
  [GET_DASHBOARD_USER_REQUEST]: (state) => {
    state.status.requesting.getDashboard = true;
  },
  [GET_DASHBOARD_USER_SUCCESS]: (state, data) => {
    state.status.requesting.getDashboard = false;
    state.user = data;
  },
  [GET_DASHBOARD_USER_ERROR]: (state, error) => {
    state.status.requesting.getDashboard = false;
    state.status.errors.getDashboard = error;
  },
  [GET_USER_PROFILE_REQUEST]: (state) => {
    state.status.requesting.getProfile = true;
  },
  [GET_USER_PROFILE_SUCCESS]: (state, data) => {
    state.status.requesting.getProfile = false;
    state.user = data;
  },
  [GET_USER_PROFILE_ERROR]: (state, error) => {
    state.status.requesting.getProfile = false;
    state.status.errors.getProfile = error;
  },
  [UPDATE_USER_PROFILE_REQUEST]: (state) => {
    state.status.requesting.updateProfile = true;
  },
  [UPDATE_USER_PROFILE_SUCCESS]: (state, data) => {
    state.status.requesting.updateProfile = false;
    state.user = data;
  },
  [UPDATE_USER_PROFILE_ERROR]: (state, error) => {
    state.status.requesting.updateProfile = false;
    state.status.errors.updateProfile = error;
  },
  [UPDATE_USER_PASSWORD_REQUEST]: (state) => {
    state.status.requesting.updatePassword = true;
  },
  [UPDATE_USER_PASSWORD_SUCCESS]: (state) => {
    state.status.requesting.updatePassword = false;
  },
  [UPDATE_USER_PASSWORD_ERROR]: (state, error) => {
    state.status.requesting.updatePassword = false;
    state.status.errors.updatePassword = error;
  },
  [SET_ACTIVE_PROFILE_MODAL]: (state, modal) => {
    state.activeProfileModal = modal;
  },
  [SET_ACTIVE_MODAL]: (state, modalData) => {
    state.activeModal = modalData;
  },
  [SET_ACTIVE_SIDE_MODAL]: (state, sideModalData) => {
    state.activeSideModal = sideModalData;
  },
  [TOGGLE_PAGES_SELECT]: (state, toggle) => {
    if ("boolean" === typeof toggle) {
      state.selectPagesToggled = toggle;
    } else {
      state.selectPagesToggled = !state.selectPagesToggled;
    }
  },
  [RESET_MODULE_STATE]: (state) => {
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    // This mutation is called from the logout action!
    Object.assign(state, getDefaultState());
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
