<template>
  <label class="wrapper flex items-center">
    {{ label }}
    <input
      :name="name"
      class="checkbox"
      type="checkbox"
      :checked="isChecked"
      :value="value"
      :disabled="disabled"
      @change="updateInput"
    />
    <span class="checkmark" />
  </label>
</template>

<script>
export default {
  name: "FormCheckbox",
  model: {
    prop: "modelValue",
    event: "change",
  },
  props: {
    id: { type: [Number, String] },
    name: { type: [String, Number], default: "" },
    value: { type: String, default: "" },
    modelValue: { default: "" },
    label: { type: String, default: "" },
    trueValue: { type: Boolean, default: true },
    falseValue: { type: Boolean, default: false },
    isSelected: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  computed: {
    isChecked() {
      if (this.isSelected) {
        return this.trueValue;
      } else if (this.modelValue instanceof Array) {
        return this.modelValue.includes(this.value);
      }
      // Note that `true-value` and `false-value` are camelCase in the JS
      return this.modelValue === this.trueValue;
    },
  },
  methods: {
    updateInput(event) {
      let isChecked = event.target.checked;
      if (this.modelValue instanceof Array) {
        let newValue = [...this.modelValue];
        if (isChecked) {
          newValue.push(this.value);
        } else {
          newValue.splice(newValue.indexOf(this.value), 1);
        }
        this.$emit("change", this.id, newValue);
      } else {
        this.$emit(
          "change",
          this.id,
          isChecked ? this.trueValue : this.falseValue,
        );
      }
    },
  },
};
</script>

<style lang="postcss" scoped>
/* Customize the label (the wrapper) */
.wrapper {
  display: block;
  height: 20px;
  position: relative;
  padding-left: 35px;
  margin-bottom: 6px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 16px;
}
/* Hide the browser's default checkbox */
.wrapper input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.wrapper input:disabled ~ .checkmark {
  background-color: #ccc !important;
}

.wrapper input:disabled ~ .checkmark:after {
  display: none !important;
}
/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 21px;
  width: 21px;
  border-radius: 7px;
  background-color: white;
  border: 1px solid #ccc;
}
/* On mouse-over, add a grey background color */
.wrapper:hover input ~ .checkmark {
  background-color: #ccc;
}
/* When the checkbox is checked, add a blue background */
.wrapper input:checked ~ .checkmark {
  background-color: #1cd4a7;
}
/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
/* Show the checkmark when checked */
.wrapper input:checked ~ .checkmark:after {
  display: block;
}
/* Style the checkmark/indicator */
.wrapper .checkmark:after {
  left: 8px;
  top: 3px;
  width: 4px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>
