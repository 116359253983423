<template>
  <div class="schedule-select-wrapper">
    <dashboard-button
      class="mr-05"
      :label="currentOption.label"
      :invalid="actionDisabled"
      :is-requesting="savingContent"
      @button-click="contentAction"
    />

    <div class="custom-select">
      <div :class="['mb-1', 'select-options-container', { toggled }]">
        <span
          v-for="(option, index) in options"
          :key="index"
          @click="selectOption(option)"
        >
          {{ option.label }}
        </span>
      </div>
      <div class="select-button" @click="toggleSelect"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { DashboardButton } from "@/components/ui/";

const OPTIONS = [
  { label: "Schedule", status: "scheduled", action: "schedule" },
  { label: "Post now", status: "scheduled", action: "immediate" },
  { label: "Save", status: "pending", action: "save" },
];

export default {
  name: "ScheduleButton",
  components: { DashboardButton },
  props: {
    actionDisabled: { type: Boolean },
    optionIndex: { type: Number, default: 0 },
  },
  data() {
    return {
      options: OPTIONS,
      currentOption: OPTIONS[this.optionIndex],
      toggled: false,
    };
  },
  computed: {
    ...mapState({
      savingContent: (state) => state.content.status.requesting.saveContent,
    }),
  },
  methods: {
    contentAction() {
      this.$emit("scheduleContent", this.currentOption);
    },
    toggleSelect() {
      this.toggled = !this.toggled;
    },
    selectOption(option) {
      if (option.action === "immediate") {
        this.$emit("set-post-now-date", true);
      } else {
        this.$emit("set-post-now-date", false);
      }
      this.currentOption = option;
      this.toggled = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.schedule-select-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: relative;
  height: 55px;

  .schedule-settings {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 52px;
    background-color: #221c55;
    padding: 1em;
    margin-right: 0.5em;
    border-radius: 6px;
    cursor: pointer;

    &::after {
      display: block;
      content: "";
      background-image: url("/assets/images/icons/dashboard/schedule/settings.svg");
      width: 20px;
      height: 20px;
    }
  }

  .custom-select {
    .select-options-container {
      display: none;
      position: absolute;
      background-color: #221c55;
      padding: 0.5em 1em;
      border-radius: 6px;

      &.toggled {
        display: flex;
        flex-direction: column;
        width: 100%;
        left: 0;
        bottom: 50px;
      }

      > span {
        padding: 0.25em 1em;
        color: #fff;
        cursor: pointer;
        &.hover {
          background-color: #46435c34;
        }
      }
    }

    .select-button {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      width: 35px;
      height: 100%;
      background-color: #221c55;
      cursor: pointer;

      &::after {
        content: "";
        background-image: url("/assets/images/icons/dashboard/schedule/arrow_down.svg");
        width: 12px;
        height: 9px;
      }
    }
  }
}
</style>
