var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"outside-click",rawName:"v-outside-click",value:(_vm.handleClose),expression:"handleClose"}],staticClass:"d-flex d-flex-c",on:{"click":function($event){$event.stopPropagation();}}},[(_vm.label)?_c('label',[_vm._v(_vm._s(_vm.label))]):_vm._e(),_c('div',{class:[
      'select-wrapper',
      {
        toggled: _vm.toggled,
        timezone: _vm.timezone,
        disabled: _vm.disabled,
        'fit-content': _vm.fitContent,
        inverse: _vm.inverseDirection,
      },
    ],style:(_vm.width && `width:${_vm.width}px; justify-content:flex-start;`),on:{"click":_vm.toggle}},[_c('ul',{class:['select-items', { toggled: _vm.toggled }]},_vm._l((_vm.items),function(item,index){return _c('li',{key:index,class:['select-item', { 'is-selected': item === _vm.selectedItem }],on:{"click":function($event){return _vm.selectItem(item, index)}}},[_vm._v(" "+_vm._s(item)+" ")])}),0),_c('label',{staticClass:"selected-item"},[_vm._v(_vm._s(_vm.selectedItem))])])])
}
var staticRenderFns = []

export { render, staticRenderFns }