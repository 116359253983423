var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['d-flex d-flex-c menu', { 'team-view': _vm.teamSelection }]},[(!_vm.teamSelection)?_c('span',{staticClass:"item switch",on:{"click":function($event){$event.stopPropagation();return (() => _vm.toggleTeams(true)).apply(null, arguments)}}},[_vm._v("Switch teams")]):_vm._e(),(_vm.teamSelection)?_c('div',{staticClass:"d-flex d-flex-c teams"},[_c('div',{staticClass:"d-flex d-flex-r item",on:{"click":function($event){$event.stopPropagation();return (() => _vm.toggleTeams(false)).apply(null, arguments)}}},[_c('span',{staticClass:"back-icon"}),_c('label',[_vm._v("Back")])]),_c('profile-item',{class:[
        'd-flex align-center item',
        { 'is-selected': !_vm.selectedTeam.id },
      ],attrs:{"isPersonal":true,"outsideMenu":false},nativeOn:{"click":function($event){return (() => _vm.selectTeam()).apply(null, arguments)}}}),_vm._l((_vm.teams),function(team,index){return _c('team-item',{key:index,class:[
        'd-flex align-center item',
        { 'is-selected': _vm.selectedTeam.id === team.id },
      ],attrs:{"teamId":team.id,"name":team.name},on:{"item-click":_vm.selectTeam}})})],2):_c('div',{staticClass:"d-flex d-flex-c nav-links"},[_vm._l((_vm.menuItems),function(item,index){return _c('router-link',{key:index,class:[
        'item',
        item.class,
        { 'is-active': _vm.currentRouteName === item.name },
      ],attrs:{"to":`/profile/${item.name}`}},[_c('span',{class:item.class},[_vm._v(_vm._s(item.label))])])}),_c('div',{staticClass:"item",on:{"click":_vm.handleLogout}},[_c('span',[_vm._v("Sign out")])])],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }