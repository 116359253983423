<template>
  <div class="container">
    <div :class="['d-flex d-flex-c card-wrapper']">
      <spinner
        v-if="secretRequesting || !cardElementLoaded || globalRequesting"
        :showBackground="true"
        :height="24"
        :width="24"
        backgroundColor="#00000088"
        label="Initializing"
        class="mt-05"
      />
      <div id="payment-form" class="mt-1 mb-2">
        <span v-if="cardElementError" class="card-error">{{
          cardElementError
        }}</span>
      </div>
      <socianis-button
        v-if="!secretRequesting && cardElementLoaded && !cardElementError"
        style="
          width: 125px;
          display: block;
          text-align: center;
          align-self: flex-end;
        "
        :label="buttonText"
        @button-click="submitPayment"
      />
    </div>
  </div>
</template>
<script>
import {
  renderStripeDetails,
  billingSettings,
  confirmSetup,
  PAYMENT_METHOD_UPDATE,
  SUBSCRIPTION_PLAN_SELECTION,
} from "@/utils/subscribe.js";

import { SocianisButton, Spinner } from "@/components";
import { mapActions, mapState } from "vuex";

export default {
  name: "Stripe",
  components: { SocianisButton, Spinner },
  props: {
    selectedProvider: { type: String, default: "stripe" },
    selectedPlan: { type: String },
    selectedInterval: { type: String },
    setupContext: { type: String, default: "" },
  },
  async created() {
    const params = {
      provider: this.selectedProvider,
      ...(this.selectedPlan && { tier: this.selectedPlan }),
      ...(this.selectedInterval && { interval: this.selectedInterval }),
    };

    const response = await this.initializePayment(params);

    const rendered = await renderStripeDetails(response.secret);
    this.cardElementLoaded = rendered;
  },
  data() {
    return {
      cardElementLoaded: false,
      cardElementError: "",
      buttonText: billingSettings(this.selectedPlan, this.selectedProvider)[
        this.setupContext
      ].confirmButtonText,
      globalRequesting: false,
    };
  },
  computed: {
    ...mapState({
      secretRequesting: (state) =>
        state.subscription.status.requesting.initializePayment,
      finalizePaymentRequesting: (state) =>
        state.subscription.status.requesting.finalizePayment,
    }),
  },
  methods: {
    ...mapActions({
      initializePayment: "initializePayment",
      confirmPayment: "confirmPayment",
      finalizePayment: "finalizePayment",
    }),
    async submitPayment() {
      let confirmedTier = null;
      let confirmedBillingCycle = null;
      this.globalRequesting = true;
      const data = {
        provider: this.selectedProvider,
        payload: {
          ...(this.selectedPlan && { tier: this.selectedPlan }),
          ...(this.selectedInterval && {
            recurring_interval: this.selectedInterval,
          }),
        },
      };
      this.confirmPayment(data)
        .then((res) => {
          confirmedTier = res.tier;
          confirmedBillingCycle = res.billing_cycle;
          const returnUrl = billingSettings(
            this.selectedPlan,
            this.selectedProvider,
            this.selectedInterval,
          )[this.setupContext].returnUrl;
          return confirmSetup(
            returnUrl,
            this.setupContext === PAYMENT_METHOD_UPDATE
              ? "if_required"
              : "always",
          );
        })
        .then((res) => {
          const { id, client_secret } = res;
          const data = {
            provider: this.selectedProvider,
            payload: {
              setup_intent: id,
              tier: confirmedTier,
              recurring_interval: confirmedBillingCycle,
            },
          };
          return this.finalizePayment(data);
        })
        .then((res) => {
          this.globalRequesting = false;
          this.$emit("payment-complete", res);
        })
        .catch((err) => {
          this.globalRequesting = false;
          // console.log("---->", err);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.container,
.card-wrapper {
  position: relative;
  width: 500px;
  min-height: 375px;
  #payment-form {
    width: 100%;
    min-height: 100%;
  }
}
</style>
