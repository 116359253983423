<template>
  <div class="d-flex d-flex-c flex-1 content-wrapper">
    <div class="section-title">
      <span>{{ title }}</span>
    </div>
    <slot name="content" />
  </div>
</template>
<script>
export default {
  name: "ContentWrapper",
  props: { title: { type: String, default: "test" } },
};
</script>
<style lang="scss" scoped>
.content-wrapper {
  width: 100%;
  max-width: 1500px;
  min-height: 100%;
  margin-top: 0.5em;
  padding: 2em 5em;
  background-color: white;
  border-radius: 6px;

  .section-title {
    position: relative;
    padding-bottom: 1em;
    text-transform: capitalize;
    font-weight: 600;
    color: #7b8994;
    border-bottom: 2px solid #e5e5e5;
  }
}
</style>
