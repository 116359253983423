<template>
  <div class="collage-wrapper">
    <div :class="['collage-grid', `collage-grid-${cells}`]">
      <div
        v-for="(image, index) in images"
        :key="index"
        class="collage-image"
        :style="setBackgroundImage(image.src)"
      >
        <span class="close" @click="removeImage(index)" @click.stop>×</span>
        <span class="collage-more" v-if="index === cells - 1 && more > 0">{{
          `${more}+`
        }}</span>
        <span :key="index" class="loader" v-if="!image.uploaded" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    images: {
      type: Array,
      default: () => [],
    },
    displayLimit: { type: Number, default: 3 },
  },
  computed: {
    // grid cells
    cells() {
      return this.images.length > this.displayLimit
        ? this.displayLimit
        : this.images.length;
    },
    // hidden images
    more() {
      return this.images.length - this.cells;
    },
  },
  methods: {
    removeImage(index) {
      this.$emit("removeThumbnail", index);
    },
    setBackgroundImage(src) {
      return src && src.length > 0 ? `background-image: url("${src}")` : "";
    },
  },
};
</script>
<style lang="scss" scoped>
.collage-wrapper {
  position: absolute;
  width: 100%;
  height: 200px;
  z-index: 50;

  .collage-grid {
    position: relative;
    display: block;
    height: 100%;
  }

  .collage-grid .collage-image {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border-top: solid 2px #fff;
    border-right: solid 2px #fff;
    .collage-more {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      text-align: center;
      color: #fff;
      font-size: 3rem;
      background-color: rgba(0, 0, 0, 0.4);
    }

    .close {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 5px;
      right: 5px;
      background: rgba($color: #000000, $alpha: 0.6);
      width: 25px;
      height: 25px;
      border-radius: 100%;
      color: white;
      cursor: pointer;
      font-size: 1.5rem;
    }
  }

  .collage-grid-1 .collage-image {
    width: 100%;
    height: 100%;
  }

  .collage-grid-2 .collage-image,
  .collage-grid-3 .collage-image,
  .collage-grid-4 .collage-image,
  .collage-grid-5 .collage-image {
    width: 50%;
  }

  .collage-grid-2 .collage-image,
  .collage-grid-3 .collage-image:nth-child(1),
  .collage-grid-4 .collage-image:nth-child(1) {
    height: 100%;
  }

  .collage-grid-3 .collage-image:nth-child(2),
  .collage-grid-3 .collage-image:nth-child(3),
  .collage-grid-5 .collage-image:nth-child(1),
  .collage-grid-5 .collage-image:nth-child(2) {
    height: 50%;
  }

  .collage-image:last-child,
  .collage-grid-2 .collage-image:nth-child(2),
  .collage-grid-3 .collage-image:nth-child(2),
  .collage-grid-3 .collage-image:nth-child(3),
  .collage-grid-4 .collage-image:nth-child(2),
  .collage-grid-4 .collage-image:nth-child(3),
  .collage-grid-4 .collage-image:nth-child(4),
  .collage-grid-5 .collage-image:nth-child(3),
  .collage-grid-5 .collage-image:nth-child(4),
  .collage-grid-5 .collage-image:nth-child(5) {
    left: auto;
    right: 0;
    border-right: 0;
  }

  .collage-grid-3 .collage-image:nth-child(3),
  .collage-grid-4 .collage-image:nth-child(4),
  .collage-grid-5 .collage-image:nth-child(2),
  .collage-grid-5 .collage-image:nth-child(5) {
    bottom: 0;
    top: auto;
  }

  .collage-grid-4 .collage-image:nth-child(3),
  .collage-grid-5 .collage-image:nth-child(4) {
    top: 33.333333333333336%;
  }

  .collage-grid-4 .collage-image:nth-child(2),
  .collage-grid-4 .collage-image:nth-child(3),
  .collage-grid-4 .collage-image:nth-child(4),
  .collage-grid-5 .collage-image:nth-child(3),
  .collage-grid-5 .collage-image:nth-child(4),
  .collage-grid-5 .collage-image:nth-child(5) {
    height: 33.333333333333336%;
  }
}
.loader {
  display: block;
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #9962a9; /* Blue */
  border-radius: 50%;
  width: 45px;
  height: 45px;
  animation: spin 1.5s linear infinite;
}
/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
