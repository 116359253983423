<template>
  <div v-show="!billingInfoLoading" class="account-subscription">
    <content-row :hideDivider="true">
      <div slot="content-left">
        <div class="d-flex d-flex-c">
          <span class="d-flex d-flex-c">
            <span class="plan-label mb-2"
              >Current plan: <b>{{ billingInfo.product_name }}</b></span
            >

            <img
              :src="`/assets/images/layout/subscription/${billingInfo.product_tier}_l.svg`"
              width="200"
            />
          </span>
        </div>
      </div>
      <div slot="content-right">
        <socianis-button label="Change plan" @button-click="changePlan" />
      </div>
    </content-row>
    <content-row title="Billing details">
      <div slot="content-left">
        <content-col label="Billing history" text="Payments and receipts" />
      </div>
      <div slot="content-right">
        <button
          class="settings-button"
          @click="
            setActiveProfileModal({
              name: 'ChangeFullNameModal',
              props: {
                setting: 'full_name',
                fields: [
                  {
                    name: 'full_name',
                    label: 'Full Name',
                    value: user.full_name,
                    rules: 'required',
                  },
                ],
              },
            })
          "
        >
          Download receipts
        </button>
      </div>
    </content-row>
    <content-row>
      <div slot="content-left">
        <content-col label="Next payment" :text="nextPayment" />
      </div>
    </content-row>
    <content-row class="mt-5" title="Payment method">
      <div slot="content-left">
        <content-col label="Payment details" />
        <payment-method
          v-if="
            Object.keys(billingInfo).length > 0 &&
            billingInfo.payment_method_info
          "
          :cardBrand="billingInfo.payment_method_info.brand"
          :expiryMonth="billingInfo.payment_method_info.expiry_month"
          :expiryYear="billingInfo.payment_method_info.expiry_year"
          :lastFour="billingInfo.payment_method_info.last_four"
          :paymentMethodType="billingInfo.payment_method_info.type"
        />
      </div>
      <div slot="content-right">
        <button class="settings-button" @click="handleAddPayment">
          Change payment method
        </button>
      </div>
    </content-row>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { milliSecondsToDate } from "@/utils/timezone";
import { SocianisButton } from "@/components/ui/";
import { ContentRow, ContentCol, PaymentMethod } from "./";

export default {
  name: "Subscription",
  components: { ContentRow, ContentCol, SocianisButton, PaymentMethod },
  async created() {
    if (Object.keys(this.billingInfo).length === 0) {
      await this.getBillingInfo();
    } else {
      this.nextPayment = milliSecondsToDate(
        this.billingInfo.current_period_end * 1000,
      );
    }
  },
  data() {
    return {
      nextPayment: "N/A",
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.dashboard.user,
      billingInfo: (state) => state.subscription.billing,
      billingInfoLoading: (state) =>
        state.dashboard.status.requesting.getBillingInfo,
    }),
    paymentMethodAdded() {
      return Object.keys(this.billingInfo.payment_method_info).length > 0;
    },
    isFreeTier() {
      return this.billingInfo.product_tier === "grounded";
    },
    trialActivated() {
      return this.billingInfo.trial_activated;
    },
  },
  methods: {
    ...mapActions({
      updateProfile: "updateProfile",
      setActiveProfileModal: "setActiveProfileModal",
      getBillingInfo: "getBillingInfo",
    }),
    changePlan() {
      this.$router.push("/plans");
    },
    handleAddPayment() {
      if (
        !this.paymentMethodAdded ||
        (this.trialActivated && !this.isFreeTier)
      ) {
        this.setActiveProfileModal({
          name: "PaymentModal",
        });
      } else {
        this.$router.push({ path: "/plans", query: { action: "payment" } });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.subscription-img-wrapper {
  width: 200px;
}
</style>
