<template>
  <label class="prev-nav mr-1">{{ text }}</label>
</template>

<script>
export default {
  name: "PrevNavLabel",
  props: {
    text: { type: String, default: "" },
  },
};
</script>

<style lang="scss" scoped>
.prev-nav {
  font-size: 0.85rem;
  color: #65676b;
  cursor: pointer;

  &:hover {
    border-bottom: 1px solid #65676b;
  }

  &::before {
    content: "";
    border: solid #65676b;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    margin-right: 0.25em;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }
}
</style>
