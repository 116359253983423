<template>
  <div
    :class="[
      'd-flex d-flex-c align-center d-flex-between wrapper',
      {
        '--is-requesting': isRequesting,
        '--is-requesting-platform': requestingPlatform,
      },
    ]"
    @click="addChannel"
    @click.stop
  >
    <label class="platform-label"> {{ platform }} </label>
    <ellipsis v-if="isRequesting && requestingPlatform" />
    <span v-else class="add-button" />
  </div>
</template>

<script>
import { Ellipsis } from "@/components";
export default {
  components: { Ellipsis },
  props: {
    platform: { type: String, default: "" },
    requestingPlatform: { type: Boolean, default: false },
    isRequesting: { type: Boolean, default: false },
  },
  methods: {
    addChannel() {
      this.$emit("addChannel", this.platform);
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 135px;
  height: 135px;
  border-radius: 6px;
  background-color: #e4e8f5;
  padding: 1em 1em 1.5em;
  cursor: pointer;

  &:hover {
    background-color: #d5daef;
  }

  .platform-label {
    text-transform: uppercase;
    letter-spacing: 3px;
  }

  .add-button {
    position: relative;
    border-radius: 100%;
    width: 45px;
    height: 45px;
    border: 1px solid black;

    &:before {
      position: absolute;
      content: "+";
      display: block;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 1.5rem;
      font-weight: normal;
    }
  }

  &.--is-requesting {
    background-color: #eff1f8;
    cursor: default;
  }
  &.--is-requesting-platform {
    background-color: #e4e8f5;
  }
}
</style>
