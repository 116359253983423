<template>
  <modal title="Hash tag suggestions" @close="closeModal">
    <div
      slot="modal-content"
      class="d-flex d-flex-c mt-1 modal-content-wrapper"
    >
      <div class="d-flex d-flex-between mb-1">
        <span><b>Hashtag</b></span>
        <span><b>Relevancy</b></span>
      </div>
      <div class="d-flex d-flex-c hashtags-wrapper">
        <div
          v-for="(tag, index) in formattedHashtags"
          :key="index"
          class="d-flex align-center mb-05"
        >
          <div class="d-flex d-flex-r d-flex-between tag-wrapper">
            <span>{{ tag.hashtag }}</span>
            <span :class="['hashtag', tag.relevancy]">{{ tag.relevancy }}</span>
          </div>
          <div
            :class="['d-flex ml-1 c-check', { 'is-selected': tag.selected }]"
            @click="() => selectItem(index)"
          ></div>
        </div>
      </div>

      <dashboard-button
        class="d-flex d-align-self-end mt-2 dashboard-button"
        label="Apply hashtags"
        @button-click="confirm"
      />
    </div>
  </modal>
</template>

<script>
import { Modal } from "@/components";
import { DashboardButton } from "@/components/ui/";
export default {
  name: "HashtagsModal",
  components: { Modal, DashboardButton },
  props: { hashtags: { type: Array, default: () => [] } },
  data() {
    return {
      formattedHashtags: this.hashtags
        .slice()
        .sort((a, b) => {
          if (a.relatability > b.relatability) {
            return -1;
          }
          if (a.relatability < b.relatability) {
            return 1;
          }
          return 0;
        })
        .map((tag) => {
          return {
            hashtag: tag.hashtag,
            relevancy:
              tag.relatability > 75
                ? "high"
                : tag.relatability > 40
                ? "medium"
                : "low",
            selected: false,
          };
        }),
    };
  },

  methods: {
    confirm() {
      const selectedTags = this.formattedHashtags
        .filter((tag) => tag.selected)
        .map((tag) => tag.hashtag)
        .join(" ");
      this.$emit("modal-submit", selectedTags);
      this.closeModal();
    },
    closeModal() {
      this.$emit("closeModal");
    },
    selectItem(index) {
      this.formattedHashtags = this.formattedHashtags.map((tag, i) => {
        if (index === i) {
          return {
            ...tag,
            selected: !tag.selected,
          };
        }
        return tag;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.hashtags-wrapper {
  max-height: 290px;
  overflow-y: scroll;
  .tag-wrapper {
    width: 100%;

    > span {
      text-transform: capitalize;

      &.high {
        color: #8cd47e;
      }
      &.medium {
        color: #f8d66d;
      }
      &.low {
        color: #ff6961;
      }
    }
  }
  .c-check {
    display: block;
    cursor: pointer;

    &.is-selected {
      &::before {
        background-color: #221c55;
      }
    }

    &::before {
      display: block;
      content: "";
      width: 15px;
      height: 15px;
      margin-right: 1em;
      background-color: white;
      border: 2px solid white;
      border-radius: 3px;
      outline: 1px solid #ccc;
      order: 1;
    }
  }
}
</style>
