<template>
  <div class="d-flex d-flex-c" v-outside-click="handleClose" @click.stop>
    <label v-if="label">{{ label }}</label>
    <div
      :class="[
        'select-wrapper',
        {
          toggled,
          timezone,
          disabled,
          'fit-content': fitContent,
          inverse: inverseDirection,
        },
      ]"
      :style="width && `width:${width}px; justify-content:flex-start;`"
      @click="toggle"
    >
      <ul :class="['select-items', { toggled }]">
        <li
          v-for="(item, index) in items"
          :key="index"
          :class="['select-item', { 'is-selected': item === selectedItem }]"
          @click="selectItem(item, index)"
        >
          {{ item }}
        </li>
      </ul>
      <label class="selected-item">{{ selectedItem }}</label>
    </div>
  </div>
</template>

<script>
export default {
  name: "DropDown",
  props: {
    name: { type: String, default: "" },
    label: { type: String },
    width: { type: Number, default: 0 },
    items: { type: Array, default: () => [] },
    timezone: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    placeholder: { type: [String, Number], default: null },
    fitContent: { type: Boolean, default: false },
    inverseDirection: { type: Boolean, default: false },
  },
  data() {
    return {
      selectedItem: this.placeholder ? this.placeholder : this.items[0],
      toggled: false,
    };
  },
  methods: {
    selectItem(item, index) {
      this.selectedItem = item;
      this.$emit("select-event", item, index);
    },
    toggle() {
      if (!this.disabled) {
        this.toggled = !this.toggled;
      }
    },
    handleClose() {
      this.toggled = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.select-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e4e8f5;
  height: 40px;
  width: 65px;
  padding: 1em;
  border-radius: 6px;
  cursor: pointer;

  &.timezone {
    width: 100%;
    max-width: 525px;
    justify-content: flex-start;
    margin-bottom: 2em;
    background-color: #f1f9ff;
    border: 1px solid #bce0fd;
  }

  &.toggled {
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    &.inverse {
      border-top-right-radius: 6px;
      border-top-left-radius: 6px;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      .select-items {
        bottom: -40px;
        height: 40px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
      }
    }
  }

  &.disabled {
    opacity: 0.5;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    background-image: url("/assets/images/icons/dashboard/schedule/arrow_down_dark.svg");
    width: 12px;
    height: 9px;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
  .select-items {
    display: flex;
    flex-direction: column;
    background-color: #e4e8f5;
    position: absolute;
    height: 0;
    width: 100%;
    max-height: 125px;
    left: 0;
    bottom: 40px;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    overflow-y: auto;

    .select-item {
      padding: 0.5em 1em;
      cursor: pointer;
      &:hover {
        background-color: #aab4db;
      }
    }

    &.toggled {
      height: auto;
    }
  }
  &.fit-content {
    width: fit-content;
  }
  .selected-item {
    margin-right: 20px;
  }
}
</style>
