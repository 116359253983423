<template>
  <div class="content">
    <empty-state v-if="isEmpty" />
    <div v-else class="d-flex flex-1">
      <div class="d-flex d-flex-c content-left">
        <div class="tabs-wrapper">
          <div
            v-for="(tab, index) in tabs"
            :class="['tab', { selected: activeTab === tab.name }]"
            :key="index"
            @click="selectTab(tab.name)"
          >
            <label class="tab-label">{{ tab.label }}</label>
          </div>
        </div>
        <div
          class="d-flex d-flex-c d-flex-between"
          style="overflow: hidden; padding-right: 1em"
        >
          <div class="content-displays-wrapper">
            <channel-display
              v-for="(channel, index) in channels"
              :key="index"
              :channel_name="channel.channel_name"
              :channel_type="channel.platform_type"
              :picture_url="channel.picture_url"
              @display-click="selectChannel(channel)"
            />
          </div>
        </div>
      </div>
      <div class="content-right">
        <div
          :class="['content-items', 'channels', { empty: !!!selectedChannel }]"
        >
          <channels-edit
            :key="JSON.stringify(selectedChannel)"
            v-if="selectedChannel"
            :channelId="selectedChannel.id"
            :channelName="selectedChannel.channel_name"
            @update-selection="updateSelection"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ChannelDisplay from "./ChannelDisplay.vue";
import ChannelsEdit from "./ChannelsEdit.vue";
import EmptyState from "./EmptyState.vue";

// TODO: implement dynamic component for tab switching
const CHANNELS_TABS = [{ name: "channels", label: "Channels" }];

export default {
  name: "Channels",
  components: { ChannelDisplay, ChannelsEdit, EmptyState },
  data() {
    return {
      tabs: CHANNELS_TABS,
      activeTab: "channels",
      selectedChannel: null,
    };
  },
  computed: {
    ...mapState({
      channels: (state) => state.channel.channels,
      // channelProjects: function (state) {
      //   return state.project.projects.filter(
      //     (project) =>
      //       this.selectedChannel &&
      //       project.channels.includes(this.selectedChannel.id),
      //   );
      // },
      isEmpty() {
        return this.channels.length === 0;
      },
    }),
  },
  methods: {
    selectTab(tab) {
      this.activeTab = tab;
    },
    selectChannel(channel) {
      this.selectedChannel = channel;
    },
    updateSelection(updatedChannel) {
      if (updatedChannel) {
        this.selectedChannel = updatedChannel;
      } else {
        this.selectedChannel = null;
      }
    },
  },
};
</script>
<style lang="scss">
.content {
  .content-right {
    .content-items {
      &.channels {
        &.empty {
          &::after {
            background-image: url("/assets/images/layout/dashboard/channels/no_channels.svg");
            background-repeat: no-repeat;
            background-position: center bottom;
            top: 0;
            width: 100%;
            height: 100%;
          }
        }
        .content-input {
          max-width: 350px;
        }
        .container {
          max-width: 350px;
        }
      }
    }
  }
}
</style>
