<template>
  <modal title="Update password" @close="closeModal">
    <div slot="modal-content" class="modal-content-wrapper">
      <validation-observer
        ref="changePasswordForm"
        v-slot="{ invalid }"
        class="d-flex d-flex-c d-flex-between"
        style="flex: 1"
      >
        <form
          id="form-new-password"
          class="d-flex d-flex-c d-flex-between mt-2"
          style="flex: 1"
          @submit.prevent
        >
          <content-input
            v-for="(field, index) in fields"
            :key="index"
            :name="field.name"
            :label="field.label"
            :rules="field.rules"
            :password="true"
            v-model="formData[field.name]"
          />
          <div class="d-flex d-flex-r d-flex-end align-center mt-3">
            <dashboard-button
              label="Save"
              :is-requesting="updatingPassword"
              :invalid="invalid"
              @button-click="save"
            />
          </div>
        </form>
      </validation-observer>
    </div>
  </modal>
</template>
<script>
import { ValidationObserver } from "vee-validate";
import { mapActions, mapState } from "vuex";
import { Modal } from "@/components";
import { ContentInput, DashboardButton } from "@/components/ui/";

export default {
  name: "ChangePasswordModal",
  components: { Modal, ContentInput, DashboardButton, ValidationObserver },
  props: { fields: { type: Array, default: () => [] } },
  data() {
    return {
      formData: {
        password: "",
        new_password: "",
      },
    };
  },
  computed: {
    ...mapState({
      updatingPassword: (state) =>
        state.dashboard.status.requesting.updatePassword,
    }),
  },
  methods: {
    ...mapActions({
      updatePassword: "updatePassword",
    }),
    closeModal() {
      this.$emit("close-profile-modal");
    },
    save() {
      let payload = {};
      const formData = new FormData(
        document.getElementById("form-new-password"),
      );
      formData.forEach((value, key) => {
        payload[key] = value;
      });
      this.updatePassword(payload).then((res) => {
        this.closeModal();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-content-wrapper {
  display: flex;
  flex-direction: column;
  min-width: 400px;
  min-height: 250px;
}
</style>
