<template>
  <div
    class="d-flex d-flex-c schedule-info-wrapper"
    @click="openScheduleSettings"
  >
    <div class="info-section settings">
      <div class="info-wrapper">
        <span>{{
          scheduleTime && scheduleTime !== "Invalid Date"
            ? scheduleTime
            : "--/--"
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getCurrentTime, formatDateWithTz } from "@/utils/timezone";
export default {
  name: "ScheduleSettings",
  props: {
    postNow: { type: Boolean, default: false },
    timezone: { type: String, default: "" },
  },
  computed: {
    ...mapState({
      scheduleTime: function (state) {
        if (this.postNow) {
          return getCurrentTime(this.timezone).format("DD-MM-YYYY, h:mm A");
        } else if (state.schedule.contentPayload.start_date) {
          return formatDateWithTz(
            state.schedule.contentPayload.start_date,
            this.timezone,
          ).format("DD-MM-YYYY, h:mm A");
        }
      },
    }),
  },
  methods: {
    openScheduleSettings() {
      this.$emit("toggleScheduleSettings");
    },
  },
};
</script>

<style lang="scss" scoped>
.schedule-info-wrapper {
  background-color: #d5daef;
  border-radius: 6px;
  padding: 1em;
  cursor: pointer;
}
</style>
