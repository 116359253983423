<template>
  <modal
    @close="closeModal"
    :title="currentStep === 1 ? 'Choose a team name' : 'Invite people'"
    :description="
      currentStep === 1
        ? 'Next: Invite people (optional)'
        : 'Enter the email addresses of individuals you wish to invite to your team and we will send them an email.   '
    "
    :stepInfo="`${currentStep} of 2`"
    :isOptional="currentStep === 2"
  >
    <div slot="modal-content" class="modal-content-wrapper">
      <div class="d-flex d-flex-c" style="flex: 1">
        <validation-observer
          ref="createTeamForm"
          v-slot="{ invalid }"
          class="d-flex d-flex-c d-flex-between"
          style="flex: 1"
        >
          <form
            class="d-flex d-flex-c d-flex-between"
            style="flex: 1"
            @submit.prevent
          >
            <div class="input-wrapper mt-1 mb-2">
              <component
                v-for="(field, index) in stepData[`step_${currentStep}`].fields"
                :key="field.key || index"
                :is="field.type"
                v-bind="field.props"
                v-model="formData[field.props.name]"
                @trigger="field.trigger"
                @remove-trigger="field.removeTrigger"
              ></component>
            </div>
            <div
              class="button-wrapper"
              :style="
                currentStep === 2 ? 'justify-content: space-between;' : ''
              "
            >
              <dashboard-button
                v-if="currentStep === 2"
                label="Previous"
                @button-click="changeStep"
              />
              <dashboard-button
                :label="currentStep === 1 ? 'Next' : 'Create team'"
                :is-requesting="isRequesting"
                :invalid="invalid"
                @button-click="currentStep === 1 ? changeStep() : formSubmit()"
              />
            </div>
          </form>
        </validation-observer>
      </div>
    </div>
  </modal>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import { mapActions, mapState } from "vuex";
import { Modal } from "@/components";
import {
  ContentInput,
  FormSelect,
  TagsWrapper,
  DashboardButton,
} from "@/components/ui/";

export default {
  name: "CreateTeamModal",
  components: {
    Modal,
    ValidationObserver,
    ContentInput,
    FormSelect,
    TagsWrapper,
    DashboardButton,
  },
  data() {
    return {
      currentStep: 1,
      formData: {
        teamName: "",
        members: [],
      },
    };
  },
  computed: {
    ...mapState({
      isRequesting: (state) => state.team.status.requesting.createTeam,
      roles: (state) => state.team.roles,
    }),
    stepData() {
      return {
        step_1: {
          fields: [
            {
              type: "ContentInput",
              trigger: () => ({}),
              removeTrigger: () => ({}),
              props: {
                name: "teamName",
                label: "Team name",
                rules: "required",
              },
            },
          ],
        },
        step_2: {
          fields: [
            {
              type: "TagsWrapper",
              trigger: this.addMember,
              removeTrigger: this.removeMember,
              props: {
                name: "members",
                rules: "email",
                isInput: true,
                disableSpace: true,
                tags: this.formData.members,
              },
            },
          ],
        },
      };
    },
  },
  methods: {
    ...mapActions({
      createTeam: "createTeam",
    }),
    closeModal() {
      this.$emit("close-profile-modal");
    },
    addMember(member) {
      const membersLength = this.formData.members.length;
      const index = membersLength === 0 ? 0 : membersLength - 1;
      this.formData.members.push({ id: index, label: member });
    },
    removeMember(index) {
      this.formData.members = this.formData.members.filter(
        (_, i) => i !== index,
      );
    },
    changeStep() {
      if (this.currentStep === 1) {
        this.currentStep = 2;
      } else {
        this.currentStep = 1;
      }
    },
    formSubmit() {
      const members = this.formData.members.map((member) => ({
        email: member.label,
        role_id: (this.roles.find((role) => role.name === "viewer") || {}).id,
      }));
      const payload = {
        name: this.formData.teamName,
        members,
      };

      this.createTeam(payload).then((res) => {
        this.closeModal();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.modal-content-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;

  .button-wrapper {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
