<template>
  <div class="d-flex d-flex-r interval-wrapper">
    <div
      v-for="(interval, index) in intervals"
      :key="index"
      :class="[
        'interval-label-wrapper',
        {
          'is-selected': interval.type === selectedInterval.type,
          'full-width': interval.type === 'yearly',
        },
      ]"
      @click="selectInterval(interval)"
    >
      <span
        >{{ interval.label }}
        <span class="discount" v-if="interval.type === 'yearly'">{{
          $t("ui.discount")
        }}</span></span
      >
    </div>
  </div>
</template>
<script>
const intervals = [
  { type: "monthly", label: "Monthly" },
  { type: "yearly", label: "Annual" },
];
export default {
  name: "IntervalSelector",
  props: {
    currentInterval: { type: String, default: "monthly" },
  },
  data() {
    return {
      intervals,
      selectedInterval: intervals.find((i) => i.type === this.currentInterval),
    };
  },
  methods: {
    selectInterval(interval) {
      if (interval.type !== this.selectedInterval.type) {
        this.$emit("interval-click", interval.type);
        this.selectedInterval = interval;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.interval-wrapper {
  padding: 0.2em;
  height: 58px;
  width: fit-content;
  background-color: #f5f7ff;
  border-radius: 35px;
  width: 335px;

  .interval-label-wrapper {
    padding: 1em 1.5em;
    font-weight: 700;
    cursor: pointer;

    &.full-width {
      width: 100%;
    }

    &.is-selected {
      background-color: #221c55;
      color: white;
      border-radius: 45px;
    }

    span.discount {
      color: #ff9100;
      font-size: 0.75rem;
      width: 100%;
    }
  }
}
</style>
