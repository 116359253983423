<template>
  <button class="content-button" @click="handleClick">
    {{ label }}
  </button>
</template>
<script>
export default {
  name: "ContentButton",
  props: { label: { type: String, default: "" } },
  methods: {
    handleClick: function () {
      this.$emit("button-click");
    },
  },
};
</script>
<style lang="scss">
.content-button {
  background-color: #221c55;
  border-radius: 52px;
  padding: 1em 2em;
  font-size: 1rem;
  color: #fff;
  border: unset;
  cursor: pointer;
  &:disabled {
    opacity: 0.7;
  }
}
</style>
