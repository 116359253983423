import {
  GET_MEDIA_LIBRARIES_REQUEST,
  GET_MEDIA_LIBRARIES_SUCCESS,
  GET_MEDIA_LIBRARIES_ERROR,
  CREATE_MEDIA_LIBRARY_REQUEST,
  CREATE_MEDIA_LIBRARY_SUCCESS,
  CREATE_MEDIA_LIBRARY_ERROR,
  UPDATE_MEDIA_LIBRARY_REQUEST,
  UPDATE_MEDIA_LIBRARY_SUCCESS,
  UPDATE_MEDIA_LIBRARY_ERROR,
  DELETE_MEDIA_LIBRARY_REQUEST,
  DELETE_MEDIA_LIBRARY_SUCCESS,
  DELETE_MEDIA_LIBRARY_ERROR,
} from "../actions/library";

import { APPEND_ASSET } from "../actions/asset";

import { RESET_MODULE_STATE } from "../actions/global";

import { CREATE_NOTIFICATION } from "../actions/notification";

import {
  getMediaLibraries,
  createMediaLibrary,
  updateMediaLibrary,
  deleteMediaLibrary,
} from "../../services/api/library.api";

const getDefaultState = () => ({
  mediaLibraries: [],
  status: {
    requesting: {
      getMediaLibraries: false,
      createMediaLibrary: false,
      updateMediaLibrary: false,
      deleteMediaLibrary: false,
    },
    errors: {
      getMediaLibraries: null,
      createMediaLibrary: null,
      updateMediaLibrary: null,
      deleteMediaLibrary: null,
    },
  },
});

const state = getDefaultState();

const actions = {
  getMediaLibraries: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      commit(GET_MEDIA_LIBRARIES_REQUEST);
      getMediaLibraries(params)
        .then((res) => {
          commit(GET_MEDIA_LIBRARIES_SUCCESS, res.data);
          resolve(res.data);
        })
        .catch((error) => {
          commit(GET_MEDIA_LIBRARIES_ERROR, error);
          reject(error);
        });
    });
  },
  createMediaLibrary: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit(CREATE_MEDIA_LIBRARY_REQUEST);
      createMediaLibrary(payload)
        .then((res) => {
          commit(CREATE_MEDIA_LIBRARY_SUCCESS, res.data);
          resolve(res.data);
          commit(CREATE_NOTIFICATION, {
            type: CREATE_MEDIA_LIBRARY_SUCCESS,
            status: "succeeded",
          });
        })
        .catch((error) => {
          commit(CREATE_MEDIA_LIBRARY_ERROR, error);
          reject(error);
          commit(CREATE_NOTIFICATION, {
            type: CREATE_MEDIA_LIBRARY_ERROR,
            status: "error",
          });
        });
    });
  },
  updateMediaLibrary: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit(UPDATE_MEDIA_LIBRARY_REQUEST);
      updateMediaLibrary(payload.data, payload.params)
        .then((res) => {
          commit(UPDATE_MEDIA_LIBRARY_SUCCESS, res.data);
          resolve(res.data);
          commit(CREATE_NOTIFICATION, {
            type: UPDATE_MEDIA_LIBRARY_SUCCESS,
            status: "succeeded",
          });
        })
        .catch((error) => {
          commit(UPDATE_MEDIA_LIBRARY_ERROR, error);
          reject(error);
          commit(CREATE_NOTIFICATION, {
            type: UPDATE_MEDIA_LIBRARY_ERROR,
            status: "error",
          });
        });
    });
  },
  deleteMediaLibrary: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      commit(DELETE_MEDIA_LIBRARY_REQUEST);
      deleteMediaLibrary(params)
        .then((res) => {
          commit(DELETE_MEDIA_LIBRARY_SUCCESS, res.data);
          resolve(res.data);
          commit(CREATE_NOTIFICATION, {
            type: DELETE_MEDIA_LIBRARY_SUCCESS,
            status: "succeeded",
          });
        })
        .catch((error) => {
          commit(DELETE_MEDIA_LIBRARY_ERROR, error);
          reject(error);
          commit(CREATE_NOTIFICATION, {
            type: DELETE_MEDIA_LIBRARY_ERROR,
            status: "error",
          });
        });
    });
  },
};

const mutations = {
  [GET_MEDIA_LIBRARIES_REQUEST]: (state) => {
    state.status.requesting.getMediaLibraries = true;
  },
  [GET_MEDIA_LIBRARIES_SUCCESS]: (state, mediaLibraries) => {
    state.mediaLibraries = mediaLibraries;
    state.status.requesting.getMediaLibraries = false;
  },
  [GET_MEDIA_LIBRARIES_ERROR]: (state, error) => {
    state.status.requesting.getMediaLibraries = false;
    state.status.errors.getMediaLibraries = error;
  },
  [CREATE_MEDIA_LIBRARY_REQUEST]: (state) => {
    state.status.requesting.createMediaLibrary = true;
  },
  [CREATE_MEDIA_LIBRARY_SUCCESS]: (state, data) => {
    state.mediaLibraries.push(data);
    state.status.requesting.createMediaLibrary = false;
  },
  [CREATE_MEDIA_LIBRARY_ERROR]: (state, error) => {
    state.status.requesting.createMediaLibrary = false;
    state.status.errors.createMediaLibrary = error;
  },
  [UPDATE_MEDIA_LIBRARY_REQUEST]: (state) => {
    state.status.requesting.updateMediaLibrary = true;
  },
  [UPDATE_MEDIA_LIBRARY_SUCCESS]: (state, data) => {
    const { id, name, projects, asset_images_removed, asset_videos_removed } =
      data;

    state.mediaLibraries = state.mediaLibraries.map((library) =>
      library.id === id
        ? {
            ...library,
            name,
            projects,
            asset_images: library.asset_images.filter(
              (i) => !asset_images_removed.includes(i),
            ),
            asset_videos: library.asset_videos.filter(
              (i) => !asset_videos_removed.includes(i),
            ),
          }
        : library,
    );

    state.status.requesting.updateMediaLibrary = false;
  },
  [UPDATE_MEDIA_LIBRARY_ERROR]: (state, error) => {
    state.status.requesting.mediaLibraries = false;
    state.status.errors.mediaLibraries = error;
  },
  [DELETE_MEDIA_LIBRARY_REQUEST]: (state) => {
    state.status.requesting.deleteMediaLibrary = true;
  },
  [DELETE_MEDIA_LIBRARY_SUCCESS]: (state, data) => {
    state.mediaLibraries = state.mediaLibraries.filter(
      (library) => library.id !== data.id,
    );
    state.status.requesting.deleteMediaLibrary = false;
  },
  [DELETE_MEDIA_LIBRARY_ERROR]: (state, error) => {
    state.status.requesting.deleteMediaLibrary = false;
    state.status.errors.deleteMediaLibrary = error;
  },
  [APPEND_ASSET]: (state, asset) => {
    const { asset_type } = asset;
    state.mediaLibraries = state.mediaLibraries.map((library) =>
      library.id === asset.asset_library_id
        ? {
            ...library,
            ...(asset_type === "image" && {
              asset_images: [asset.id].concat(library.asset_images),
            }),
            ...(asset_type === "video" && {
              asset_videos: [asset.id].concat(library.asset_videos),
            }),
          }
        : library,
    );
  },
  [RESET_MODULE_STATE]: (state) => {
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    // This mutation is called from the logout action!
    Object.assign(state, getDefaultState());
  },
};

export default {
  state,
  actions,
  mutations,
};
