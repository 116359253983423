import {
  GET_CURRENT_TEAM_REQUEST,
  GET_CURRENT_TEAM_SUCCESS,
  GET_CURRENT_TEAM_ERROR,
  GET_TEAMS_REQUEST,
  GET_TEAMS_SUCCESS,
  GET_TEAMS_ERROR,
  GET_TEAM_ROLES_REQUEST,
  GET_TEAM_ROLES_SUCCESS,
  GET_TEAM_ROLES_ERROR,
  CREATE_TEAM_REQUEST,
  CREATE_TEAM_SUCCESS,
  CREATE_TEAM_ERROR,
  UPDATE_TEAM_REQUEST,
  UPDATE_TEAM_SUCCESS,
  UPDATE_TEAM_ERROR,
  DELETE_TEAM_REQUEST,
  DELETE_TEAM_SUCCESS,
  DELETE_TEAM_ERROR,
  LEAVE_TEAM_REQUEST,
  LEAVE_TEAM_SUCCESS,
  LEAVE_TEAM_ERROR,
  ADD_TEAM_MEMBER_REQUEST,
  ADD_TEAM_MEMBER_SUCCESS,
  ADD_TEAM_MEMBER_ERROR,
  UPDATE_TEAM_MEMBER_REQUEST,
  UPDATE_TEAM_MEMBER_SUCCESS,
  UPDATE_TEAM_MEMBER_ERROR,
  REMOVE_TEAM_MEMBER_REQUEST,
  REMOVE_TEAM_MEMBER_SUCCESS,
  REMOVE_TEAM_MEMBER_ERROR,
  CONFIRM_TEAM_INVITE_REQUEST,
  CONFIRM_TEAM_INVITE_SUCCESS,
  CONFIRM_TEAM_INVITE_ERROR,
  GET_TEAM_INSIGHTS_REQUEST,
  GET_TEAM_INSIGHTS_SUCCESS,
  GET_TEAM_INSIGHTS_ERROR,
  CHANGE_TEAM,
  SET_INSIGHTS_PROJECT,
} from "../actions/team";

import { RESET_MODULE_STATE } from "../actions/global";

import {
  getCurrentTeam,
  getTeams,
  getTeamRoles,
  createTeam,
  updateTeam,
  deleteTeam,
  leaveTeam,
  addMember,
  updateMember,
  removeMember,
  confirmTeamInvite,
  getTeamInsights,
} from "../../services/api/team.api";

import { TEAM_CONTEXT } from "../../utils/constants";

import { CREATE_NOTIFICATION } from "../actions/notification";

import { REMOVE_SESSION_ITEM } from "../actions/session";

const getDefaultState = () => ({
  currentTeam: {
    id: null,
    members: [],
  },
  teams: [],
  roles: [],
  insights: [],
  selectedProjectId: null,
  status: {
    requesting: {
      getCurrentTeam: false,
      getTeams: false,
      getTeamRoles: false,
      createTeam: false,
      updateTeam: false,
      deleteTeam: false,
      leaveTeam: false,
      addMember: false,
      updateMember: false,
      removeMember: false,
      confirmTeamInvite: false,
      getTeamInsights: false,
    },
    errors: {
      getCurrentTeam: null,
      getTeams: null,
      getTeamRoles: null,
      createTeam: null,
      updateTeam: null,
      deleteTeam: null,
      leaveTeam: null,
      addMember: null,
      updateMember: null,
      removeMember: null,
      confirmTeamInvite: null,
      getTeamInsights: null,
    },
  },
});

const state = getDefaultState();

const actions = {
  getCurrentTeam: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      commit(GET_CURRENT_TEAM_REQUEST);
      getCurrentTeam(params)
        .then((res) => {
          commit(GET_CURRENT_TEAM_SUCCESS, res.data);
          resolve(res.data);
        })
        .catch((error) => {
          commit(GET_CURRENT_TEAM_ERROR, error);
          reject(error);
        });
    });
  },
  getTeams: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit(GET_TEAMS_REQUEST);
      getTeams(payload)
        .then((res) => {
          commit(GET_TEAMS_SUCCESS, res.data);
          resolve(res.data);
        })
        .catch((error) => {
          commit(GET_TEAMS_ERROR, error);
          reject(error);
        });
    });
  },
  getTeamRoles: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit(GET_TEAM_ROLES_REQUEST);
      getTeamRoles()
        .then((res) => {
          commit(GET_TEAM_ROLES_SUCCESS, res.data);
          resolve(res.data);
        })
        .catch((error) => {
          commit(GET_TEAM_ROLES_ERROR, error);
          reject(error);
        });
    });
  },
  createTeam: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit(CREATE_TEAM_REQUEST);
      createTeam(payload)
        .then((res) => {
          commit(CREATE_TEAM_SUCCESS, res.data);
          resolve(res.data);
          commit(CREATE_NOTIFICATION, {
            type: CREATE_TEAM_SUCCESS,
            status: "succeeded",
          });
        })
        .catch((error) => {
          commit(CREATE_TEAM_ERROR, error);
          reject(error);
          commit(CREATE_NOTIFICATION, {
            type: CREATE_TEAM_ERROR,
            status: "error",
          });
        });
    });
  },
  updateTeam: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit(UPDATE_TEAM_REQUEST);
      updateTeam(payload.data, payload.params)
        .then((res) => {
          commit(UPDATE_TEAM_SUCCESS, res.data);
          resolve(res.data);
          commit(CREATE_NOTIFICATION, {
            type: UPDATE_TEAM_SUCCESS,
            status: "succeeded",
          });
        })
        .catch((error) => {
          commit(UPDATE_TEAM_ERROR, error);
          reject(error);
          commit(CREATE_NOTIFICATION, {
            type: UPDATE_TEAM_ERROR,
            status: "error",
          });
        });
    });
  },
  deleteTeam: ({ commit, state }, params) => {
    return new Promise((resolve, reject) => {
      commit(DELETE_TEAM_REQUEST);
      deleteTeam(params)
        .then((res) => {
          const isCurrentTeam = state.currentTeam.id === res.data.id;
          if (isCurrentTeam) {
            commit(REMOVE_SESSION_ITEM, TEAM_CONTEXT);
          }
          commit(DELETE_TEAM_SUCCESS, { ...res.data, isCurrentTeam });
          resolve(res.data);
          commit(CREATE_NOTIFICATION, {
            type: DELETE_TEAM_SUCCESS,
            status: "succeeded",
          });
        })
        .catch((error) => {
          commit(DELETE_TEAM_ERROR, error);
          reject(error);
          commit(CREATE_NOTIFICATION, {
            type: DELETE_TEAM_ERROR,
            status: "error",
          });
        });
    });
  },
  leaveTeam: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      commit(LEAVE_TEAM_REQUEST);
      leaveTeam(params)
        .then((res) => {
          const isCurrentTeam = state.currentTeam.id === res.data.id;
          if (isCurrentTeam) {
            commit(REMOVE_SESSION_ITEM, TEAM_CONTEXT);
          }
          commit(LEAVE_TEAM_SUCCESS, res.data);
          resolve(res.data);
          commit(CREATE_NOTIFICATION, {
            type: LEAVE_TEAM_SUCCESS,
            status: "succeeded",
          });
        })
        .catch((error) => {
          commit(LEAVE_TEAM_ERROR, error);
          reject(error);
          commit(CREATE_NOTIFICATION, {
            type: LEAVE_TEAM_ERROR,
            status: "error",
          });
        });
    });
  },
  addMember: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit(ADD_TEAM_MEMBER_REQUEST);
      addMember(payload.data, payload.params)
        .then((res) => {
          commit(ADD_TEAM_MEMBER_SUCCESS, res.data);
          resolve(res.data);
          commit(CREATE_NOTIFICATION, {
            type: ADD_TEAM_MEMBER_SUCCESS,
            status: "succeeded",
          });
        })
        .catch((error) => {
          commit(ADD_TEAM_MEMBER_ERROR, error);
          reject(error);
          commit(CREATE_NOTIFICATION, {
            type: ADD_TEAM_MEMBER_ERROR,
            status: "error",
          });
        });
    });
  },
  updateMember: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit(UPDATE_TEAM_MEMBER_REQUEST);
      updateMember(payload.data, payload.params)
        .then((res) => {
          commit(UPDATE_TEAM_MEMBER_SUCCESS, res.data);
          resolve(res.data);
          commit(CREATE_NOTIFICATION, {
            type: UPDATE_TEAM_MEMBER_SUCCESS,
            status: "succeeded",
          });
        })
        .catch((error) => {
          commit(UPDATE_TEAM_MEMBER_ERROR, error);
          reject(error);
          commit(CREATE_NOTIFICATION, {
            type: UPDATE_TEAM_MEMBER_ERROR,
            status: "error",
          });
        });
    });
  },
  removeMember: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      commit(REMOVE_TEAM_MEMBER_REQUEST);
      removeMember(params)
        .then((res) => {
          commit(REMOVE_TEAM_MEMBER_SUCCESS, res.data);
          resolve(res.data);
          commit(CREATE_NOTIFICATION, {
            type: REMOVE_TEAM_MEMBER_SUCCESS,
            status: "succeeded",
          });
        })
        .catch((error) => {
          commit(REMOVE_TEAM_MEMBER_ERROR, error);
          reject(error);
          commit(CREATE_NOTIFICATION, {
            type: REMOVE_TEAM_MEMBER_ERROR,
            status: "error",
          });
        });
    });
  },
  confirmTeamInvite: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      commit(CONFIRM_TEAM_INVITE_REQUEST);
      confirmTeamInvite(params)
        .then((res) => {
          commit(CONFIRM_TEAM_INVITE_SUCCESS, res.data);
          resolve(res.data);
          commit(CREATE_NOTIFICATION, {
            type: CONFIRM_TEAM_INVITE_SUCCESS,
            status: "succeeded",
          });
        })
        .catch((error) => {
          commit(CONFIRM_TEAM_INVITE_ERROR, error);
          reject(error);
          commit(CREATE_NOTIFICATION, {
            type: CONFIRM_TEAM_INVITE_ERROR,
            status: "error",
          });
        });
    });
  },
  getTeamInsights: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      commit(GET_TEAM_INSIGHTS_REQUEST);
      getTeamInsights(params)
        .then((res) => {
          commit(GET_TEAM_INSIGHTS_SUCCESS);
          resolve(res.data);
        })
        .catch((error) => {
          commit(GET_TEAM_INSIGHTS_ERROR, error);
          reject(error);
        });
    });
  },
  changeTeam({ commit }, teamId) {
    commit(CHANGE_TEAM, teamId);
  },
  setInsightsViewProject: ({ commit }, projectId) => {
    commit(SET_INSIGHTS_PROJECT, projectId);
  },
};

const mutations = {
  [GET_CURRENT_TEAM_REQUEST]: (state) => {
    state.status.requesting.getCurrentTeam = true;
  },
  [GET_CURRENT_TEAM_SUCCESS]: (state, data = {}) => {
    const { roles, current_team } = data;

    if (current_team && current_team.id) {
      state.roles = roles;
      state.currentTeam = current_team;
    }
    state.status.requesting.getCurrentTeam = false;
  },
  [GET_CURRENT_TEAM_ERROR]: (state, error) => {
    state.status.requesting.getCurrentTeam = false;
    state.status.errors.getCurrentTeam = error;
  },
  [GET_TEAMS_REQUEST]: (state) => {
    state.status.requesting.getTeams = true;
  },
  [GET_TEAMS_SUCCESS]: (state, data = []) => {
    state.teams = data;
    state.status.requesting.getTeams = false;
  },
  [GET_TEAMS_ERROR]: (state, error) => {
    state.status.requesting.getTeams = false;
    state.status.errors.getTeams = error;
  },
  [GET_TEAM_ROLES_REQUEST]: (state) => {
    state.status.requesting.getTeamRoles = true;
  },
  [GET_TEAM_ROLES_SUCCESS]: (state, data = []) => {
    state.roles = data;
    state.status.requesting.getTeamRoles = false;
  },
  [GET_TEAM_ROLES_ERROR]: (state, error) => {
    state.status.requesting.getTeamRoles = false;
    state.status.errors.getTeamRoles = error;
  },
  [CREATE_TEAM_REQUEST]: (state) => {
    state.status.requesting.createTeam = true;
  },
  [CREATE_TEAM_SUCCESS]: (state, data) => {
    state.teams.push(data);
    state.status.requesting.createTeam = false;
  },
  [CREATE_TEAM_ERROR]: (state, error) => {
    state.status.requesting.createTeam = false;
    state.status.errors.createTeam = error;
  },
  [UPDATE_TEAM_REQUEST]: (state) => {
    state.status.requesting.updateTeam = true;
  },
  [UPDATE_TEAM_SUCCESS]: (state, data) => {
    state.teams = state.teams.map((team) =>
      team.id === data.id ? data : team,
    );
    state.status.requesting.updateTeam = false;
  },
  [UPDATE_TEAM_ERROR]: (state, error) => {
    state.status.requesting.teams = false;
    state.status.errors.teams = error;
  },
  [DELETE_TEAM_REQUEST]: (state) => {
    state.status.requesting.deleteTeam = true;
  },
  [DELETE_TEAM_SUCCESS]: (state, data) => {
    if (data.isCurrentTeam) {
      state.currentTeam = getDefaultState().currentTeam;
    }
    state.teams = state.teams.filter((team) => team.id !== data.id);
    state.status.requesting.deleteTeam = false;
  },
  [DELETE_TEAM_ERROR]: (state, error) => {
    state.status.requesting.deleteTeam = false;
    state.status.errors.deleteTeam = error;
  },
  [LEAVE_TEAM_REQUEST]: (state) => {
    state.status.requesting.leaveTeam = true;
  },
  [LEAVE_TEAM_SUCCESS]: (state, data) => {
    if (data.isCurrentTeam) {
      state.currentTeam = getDefaultState().currentTeam;
    }
    state.teams = state.teams.filter((team) => team.id !== data.team_id);
    state.status.requesting.leaveTeam = false;
  },
  [LEAVE_TEAM_ERROR]: (state, error) => {
    state.status.requesting.leaveTeam = false;
    state.status.errors.leaveTeam = error;
  },
  [ADD_TEAM_MEMBER_REQUEST]: (state) => {
    state.status.requesting.addMember = true;
  },
  [ADD_TEAM_MEMBER_SUCCESS]: (state, data) => {
    state.status.requesting.addMember = false;
    if (data) {
      state.currentTeam.members = state.currentTeam.members.concat(data);
    }
  },
  [ADD_TEAM_MEMBER_ERROR]: (state, error) => {
    state.status.requesting.addMember = false;
    state.status.errors.addMember = error;
  },
  [UPDATE_TEAM_MEMBER_REQUEST]: (state) => {
    state.status.requesting.updateMember = true;
  },
  [UPDATE_TEAM_MEMBER_SUCCESS]: (state, data) => {
    state.status.requesting.updateMember = false;
    state.currentTeam.members = state.currentTeam.members.map((member) => {
      if (member.id === data.id) {
        return data;
      }
      return member;
    });
  },
  [UPDATE_TEAM_MEMBER_ERROR]: (state, error) => {
    state.status.requesting.updateMember = false;
    state.status.errors.updateMember = error;
  },
  [REMOVE_TEAM_MEMBER_REQUEST]: (state) => {
    state.status.requesting.removeMember = true;
  },
  [REMOVE_TEAM_MEMBER_SUCCESS]: (state, data) => {
    state.status.requesting.removeMember = false;
    state.currentTeam.members = state.currentTeam.members.filter(
      (member) => member.id !== data.id,
    );
  },
  [REMOVE_TEAM_MEMBER_ERROR]: (state, error) => {
    state.status.requesting.removeMember = false;
  },
  [CONFIRM_TEAM_INVITE_REQUEST]: (state) => {
    state.status.requesting.confirmTeamInvite = true;
  },
  [CONFIRM_TEAM_INVITE_SUCCESS]: (state, data) => {
    state.status.requesting.confirmTeamInvite = false;
    state.currentTeam.members = state.currentTeam.members.filter(
      (member) => member.id !== data.id,
    );
  },
  [CONFIRM_TEAM_INVITE_ERROR]: (state, error) => {
    state.status.requesting.confirmTeamInvite = false;
  },
  [GET_TEAM_INSIGHTS_REQUEST]: (state) => {
    state.status.requesting.getTeamInsights = true;
  },
  [GET_TEAM_INSIGHTS_SUCCESS]: (state, data) => {
    state.status.requesting.getTeamInsights = false;
    state.insights = data;
  },
  [GET_TEAM_INSIGHTS_ERROR]: (state, error) => {
    state.status.requesting.getTeamInsights = false;
    state.status.errors.getTeamInsights = error;
  },
  [CHANGE_TEAM]: (state, teamId) => {
    state.currentTeam.id = teamId;
  },
  [SET_INSIGHTS_PROJECT]: (state, projectId) => {
    state.selectedProjectId = projectId;
  },
  [RESET_MODULE_STATE]: (state) => {
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    // This mutation is called from the logout action!
    Object.assign(state, getDefaultState());
  },
};

export default {
  state,
  actions,
  mutations,
};
