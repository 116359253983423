<template>
  <div class="d-flex d-flex-c notification-wrapper">
    <notification
      v-for="notification in notifications"
      :key="notification.id"
      :id="notification.id"
      :status="notification.status"
      :text="notification.text"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import Notification from "../ui/Notification.vue";

export default {
  name: "NotificationCenter",
  components: { Notification },
  props: {
    notificationLimit: { type: Number, default: 5 },
  },
  data() {
    return {
      timeoutIds: [],
    };
  },
  computed: {
    ...mapState({
      notifications: function (state) {
        return state.notification.notifications.slice(
          0,
          this.notificationLimit,
        );
      },
    }),
  },
};
</script>

<style lang="scss" scoped>
.notification-wrapper {
  position: absolute;
  right: 0;
  top: 100px;
  height: 88vh;
  width: 100%;
  max-width: 300px !important;
  padding: 1em;
}
</style>
