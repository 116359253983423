export default {
  API_HOST: `${process.env.VUE_APP_API_HOST}`,
  API_PORT: `${process.env.VUE_APP_API_PORT}`,
  WS_HOST: `${process.env.VUE_APP_WEBSOCKET_HOST}`,
  CLIENT_REDIRECT_URL: `${process.env.VUE_APP_CLIENT_REDIRECT_URL}`,
  LOCALE: `${process.env.VUE_APP_I18N_LOCALE}`,
  FALLBACK_LOCALE: `${process.env.VUE_APP_I18N_FALLBACK_LOCALE}`,
  STRIPE_PUBLIC_KEY: `${process.env.VUE_APP_STRIPE_PUBLIC_KEY}`,
  PAYPAL_PUBLIC_KEY: `${process.env.VUE_APP_PAYPAL_PUBLIC_KEY}`,
  URL_SALT: `${process.env.VUE_APP_URL_SALT}`,
  APP_VERSION: require("../../package.json"),
};
