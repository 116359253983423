<template>
  <router-link
    :class="[
      'd-flex d-flex-r align-center sub-menu-item',
      { 'is-selected': isSelected },
    ]"
    :to="path"
  >
    <span :class="['label']">{{ label }}</span>
  </router-link>
</template>

<script>
export default {
  name: "MenuItem",
  props: {
    isSelected: { type: Boolean, default: false },
    name: { type: String, default: "" },
    path: { type: String, default: "" },
    label: { type: String, default: "" },
  },
};
</script>

<style lang="scss" scoped>
.sub-menu-item {
  min-width: 200px;
  padding: 0.75em 1.5em;
  margin: 0.25em 0.5em;
  border-radius: 6px;
  color: white;

  &:hover,
  &.is-selected {
    background-color: #1b1644;
  }
}
</style>
