var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex calendar-day",class:{
    'calendar-day--not-current': !_vm.isCurrentMonth,
    'calendar-day--today': _vm.isToday,
    'calendar-day--selected': _vm.daySelected && !_vm.isMonthView,
    'calendar-day--disabled': _vm.disabled,
    'has-scheduled-content': _vm.hasScheduledContent && !_vm.isMonthView,
  },on:{"click":_vm.selectDay}},[(_vm.dayLabel)?_c('span',{class:['day-label', { 'month-view': _vm.isMonthView }]},[_vm._v(_vm._s(_vm.dayLabel))]):_vm._e(),_c('span',{class:[
      'day-number',
      { 'month-view': _vm.isMonthView, 'mb-05': _vm.isMonthView },
    ]},[_vm._v(_vm._s(_vm.label))]),(_vm.isMonthView)?_c('div',{staticClass:"d-flex d-flex-c day-items"},[_vm._l((_vm.sortedItems.slice(0, _vm.dataLimit)),function(data){return _c('div',{key:data.id,staticClass:"day-item"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.formatItem(data))},on:{"click":[function($event){return _vm.showContentDetails(data)},function($event){$event.stopPropagation();}]}}),_vm._v("\" ")])}),(_vm.scheduledListToggled && !_vm.selectedContent)?_c('div',{directives:[{name:"outside-click",rawName:"v-outside-click",value:(_vm.handleClose),expression:"handleClose"}],staticClass:"d-flex d-flex-c scheduled-list-container"},[_c('span',{staticClass:"d-flex d-flex-end close",on:{"click":_vm.toggleScheduleList}},[_vm._v("×")]),_c('span',{staticClass:"day-title mb-1"},[_vm._v(_vm._s(_vm.formattedDay))]),_c('div',{staticClass:"d-flex d-flex-c day-items-scroll"},_vm._l((_vm.sortedItems),function(data){return _c('span',{key:data.id,staticClass:"day-item",domProps:{"innerHTML":_vm._s(_vm.formatItem(data))},on:{"click":[function($event){return _vm.showContentDetails(data)},function($event){$event.stopPropagation();}]}})}),0)]):_vm._e()],2):_vm._e(),(_vm.isMonthView && _vm.hiddenItems > 0)?_c('span',{staticClass:"show-more",on:{"click":[_vm.toggleScheduleList,function($event){$event.stopPropagation();}]}},[_c('b',[_vm._v(_vm._s(_vm.hiddenItems)+" more")])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }