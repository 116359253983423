<template>
  <div class="d-flex d-flex-r flex-1 d-flex-between">
    <div class="d-flex d-flex-c">
      <span class="empty-channels mb-2" />
      <span class="empty-text">
        <p>You currently have not added any channels. Click on</p>
        <p><b>"Add channel"</b> to start managing your channels</p>
      </span>
    </div>
    <div class="d-flex right">
      <span class="empty-channel-info" />
    </div>
  </div>
</template>

<script>
export default {
  name: "EmptyState",
};
</script>

<style lang="scss" scoped>
.empty-channels {
  width: 428px;
  height: 190px;
  background-image: url("/assets/images/layout/dashboard/channels/channels_empty.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.empty-channel-info {
  width: 520px;
  height: 540px;
  background-image: url("/assets/images/layout/dashboard/channels/channel_info_empty.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.empty-text {
  text-align: center;
}

.right {
  width: 50%;
  background-color: #f9f9f9;
  padding: 1em;
  border: 6px;
}
</style>
