<!-- CalendarYearSelector.vue -->
<template>
  <div class="calendar-year-selector">
    <span class="year-nav prev" @click="selectPrevious" />
    <span class="current-year">{{ currentYear }}</span>
    <span class="year-nav next" @click="selectNext" />
  </div>
</template>
<script>
import dayjs from "@/utils/timezone";
import { mapState } from "vuex";
export default {
  props: {
    isPostTime: { type: Boolean, default: false }, // to distinguish between the calendar for fetching and scheduling post
  },
  computed: {
    ...mapState({
      selectedDate: function (state) {
        return this.isPostTime
          ? state.schedule.scheduleModalSettings.selectedDate
          : state.content.contentSortSettings.selectedDate;
      },
    }),
    currentYear() {
      return this.selectedDate.format("YYYY");
    },
  },
  methods: {
    selectPrevious() {
      const newSelectedDate = dayjs(this.selectedDate).subtract(1, "year");
      this.$emit("yearSelected", newSelectedDate);
    },
    selectNext() {
      const newSelectedDate = dayjs(this.selectedDate).add(1, "year");
      this.$emit("yearSelected", newSelectedDate);
    },
  },
};
</script>
<style lang="scss" scoped>
.calendar-year-selector {
  display: flex;
  flex-direction: row;
  align-items: center;

  .year-nav {
    display: block;
    width: 22px;
    height: 22px;
    cursor: pointer;
    &.prev {
      background-image: url("/assets/images/icons/dashboard/schedule/arrow_prev.svg");
    }

    &.next {
      background-image: url("/assets/images/icons/dashboard/schedule/arrow_next.svg");
    }
  }

  .current-year {
    margin: 0 1em;
    font-size: 1.35rem;
    font-weight: bold;
  }
}
</style>
