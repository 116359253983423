<template>
  <side-modal title="Media Libraries" @close="closeModal">
    <div slot="content-left" class="side-content-wrapper">
      <div class="d-flex d-flex-c">
        <span
          v-for="(library, index) in assetLibraries"
          :key="index"
          :class="[
            'library-item mb-1',
            { 'is-selected': selectedLibraryIndex === index },
          ]"
          @click="() => selectLibraryItem(index)"
          >{{ library.name }}</span
        >
      </div>
    </div>
    <div slot="content-right" class="side-content-wrapper">
      <div class="d-flex d-flex-c d-flex-between">
        <div class="d-flex d-flex-c d-flex-between">
          <view-options
            class="mb-1"
            :options="viewOptions"
            @select-option="selectViewOption"
          />
          <div class="d-flex d-flex-r gallery">
            <object
              :key="index"
              v-for="(asset, index) in viewAssets"
              :class="[
                'asset',
                {
                  'is-selected': selectedAssets.includes(asset.id),
                },
              ]"
              @click="() => selectAsset(asset.id)"
            >
              <img
                v-if="asset.media_url"
                :src="
                  asset.asset_type === 'image'
                    ? asset.media_url
                    : asset.thumbnail_url
                "
                @error="defaultBg"
              />
            </object>
          </div>
        </div>
        <div class="d-flex d-flex-r d-flex-between">
          <content-button
            label="Upload"
            @button-click="
              setActiveModal({
                name: 'UploadModal',
                props: {
                  mediaLibraryId,
                },
                modalSubmit: () => ({}),
              })
            "
          />
          <content-button label="Done" @button-click="done" />
        </div>
      </div>
    </div>
  </side-modal>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { SideModal } from "@/components";
import { ViewOptions, ContentButton } from "@/components/ui";

const options = ["images", "videos"];
export default {
  name: "AssetSelectionModal",
  components: { SideModal, ViewOptions, ContentButton },
  props: {
    projectId: { type: String, default: "" },
    assetIds: { type: Array, default: () => [] },
  },
  data() {
    return {
      viewOptions: options,
      selectedViewOption: options[0],
      selectedLibraryIndex: 0,
      selectedAssets: this.assetIds.slice(),
    };
  },
  computed: {
    ...mapState({
      assetLibraries(state) {
        return state.library.mediaLibraries.filter(
          (library) =>
            library.projects.includes(this.projectId) ||
            (!state.team.currentTeam.id && library.projects.length === 0),
        );
      },
      libraryAssets(state) {
        return state.asset.assets.filter(
          (asset) =>
            this.assetLibraries[this.selectedLibraryIndex] &&
            asset.asset_library_id ===
              this.assetLibraries[this.selectedLibraryIndex].id,
        );
      },
    }),
    mediaLibraryId() {
      return this.assetLibraries[this.selectedLibraryIndex].id;
    },
    assetVideos() {
      return this.libraryAssets.filter((asset) => asset.asset_type === "video");
    },
    assetImages() {
      return this.libraryAssets.filter((asset) => asset.asset_type === "image");
    },
    viewAssets() {
      return this.selectedViewOption === "images"
        ? this.assetImages
        : this.assetVideos;
    },
  },
  methods: {
    ...mapActions({
      setActiveModal: "setActiveModal",
    }),
    closeModal() {
      this.$emit("closeModal");
    },
    selectViewOption(index) {
      this.selectedViewOption = this.viewOptions[index];
    },
    selectLibraryItem(index) {
      this.selectedLibraryIndex = index;
    },
    selectAsset(assetId) {
      const found = this.selectedAssets.includes(assetId);

      if (found) {
        this.selectedAssets = this.selectedAssets.filter(
          (id) => id !== assetId,
        );
      } else {
        this.selectedAssets.push(assetId);
      }
    },
    defaultBg(e) {
      e.target.src = "/assets/images/layout/dashboard/common/content_bg.svg";
    },
    done() {
      this.$emit("modal-submit", this.selectedAssets);
      this.closeModal();
    },
  },
};
</script>

<style lang="scss" scoped>
.side-content-wrapper {
  height: 100%;

  > div {
    height: 100%;
  }

  .library-item {
    padding: 1em;
    border-radius: 6px;
    cursor: pointer;

    &:hover {
      background-color: #aab4db;
    }

    &.is-selected {
      background-color: #d5daef;
    }
  }

  .gallery {
    flex-wrap: wrap;
    max-height: calc(100vh - 100px - 11em);
    overflow: auto;
    align-content: flex-start;
    .asset {
      position: relative;
      margin: 0 1em 1em 0;
      cursor: pointer;
      border-radius: 6px;
      width: 100px;
      height: 100px;
      background-image: url("/assets/images/layout/dashboard/common/content_bg.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      background-color: #d5daef;
      &.is-selected {
        &::before {
          content: "";
          position: absolute;
          background-image: url("/assets/images/icons/common/check_icon.webp");
          background-position: center;
          background-repeat: no-repeat;
          background-size: 50%;
          width: 100%;
          height: 100%;
          z-index: 100;
        }
        &::after {
          content: "";
          position: absolute;
          background-color: #221c55;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          border-radius: 6px;
          opacity: 0.85;
          z-index: 50;
        }
      }
      img {
        width: 100%;
        height: 100%;
        border-radius: 6px;
        object-fit: cover;
      }
    }
  }
}
</style>
