export const GET_ASSETS_REQUEST = "GET_ASSETS_REQUEST";
export const GET_ASSETS_SUCCESS = "GET_ASSETS_SUCCESS";
export const GET_ASSETS_ERROR = "GET_ASSETS_ERROR";

export const INITIALIZE_ASSET_REQUEST = "INITIALIZE_ASSET_REQUEST";
export const INITIALIZE_ASSET_SUCCESS = "INITIALIZE_ASSET_SUCCESS";
export const INITIALIZE_ASSET_ERROR = "INITIALIZE_ASSET_ERROR";

export const DELETE_ASSET_REQUEST = "DELETE_ASSET_REQUEST";
export const DELETE_ASSET_SUCCESS = "DELETE_ASSET_SUCCESS";
export const DELETE_ASSET_ERROR = "DELETE_ASSET_ERROR";

export const UPDATE_ASSET_REQUEST = "UPDATE_ASSET_REQUEST";
export const UPDATE_ASSET_SUCCESS = "UPDATE_ASSET_SUCCESS";
export const UPDATE_ASSET_ERROR = "UPDATE_ASSET_ERROR";

export const GET_PRESIGNED_URL_REQUEST = "GET_PRESIGNED_URL_REQUEST";
export const GET_PRESIGNED_URL_SUCCESS = "GET_PRESIGNED_URL_SUCCESS";
export const GET_PRESIGNED_URL_ERROR = "GET_PRESIGNED_URL_ERROR";

export const APPEND_ASSET = "APPEND_ASSET";
