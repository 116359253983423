var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'd-flex d-flex-c align-center d-flex-between wrapper',
    {
      '--is-requesting': _vm.isRequesting,
      '--is-requesting-platform': _vm.requestingPlatform,
    },
  ],on:{"click":[_vm.addChannel,function($event){$event.stopPropagation();}]}},[_c('label',{staticClass:"platform-label"},[_vm._v(" "+_vm._s(_vm.platform)+" ")]),(_vm.isRequesting && _vm.requestingPlatform)?_c('ellipsis'):_c('span',{staticClass:"add-button"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }