<template>
  <div class="d-flex d-flex-c mb-05">
    <label v-if="label" class="component-label mb-05"
      ><b>{{ label }}</b></label
    >
    <div class="d-flex d-flex-r align-center members-avatar-wrapper">
      <profile-picture
        v-for="(member, index) in membersDisplay"
        :key="index"
        :picture="member.avatar"
        :name="member.name ? member.name : member.email"
        :enableHover="enableHover"
      />
      <span class="hidden-members" v-if="hiddenMembers"
        >{{ hiddenMembers }}+</span
      >
    </div>

    <span v-if="actionLabel" class="action" @click="$emit('trigger')">{{
      actionLabel
    }}</span>
    <validation-provider v-if="isInput" mode="aggressive">
      <input
        :ref="inputRef"
        :name="label"
        class="members-input"
        v-model="currentValue"
      />
    </validation-provider>
  </div>
</template>

<script>
import { ValidationProvider } from "vee-validate";
export default {
  name: "MemberAvatars",
  components: {
    ProfilePicture: () =>
      import("@/components/").then((res) => {
        return res.ProfilePicture;
      }),
    ValidationProvider,
  },
  props: {
    label: { type: String, default: "" },
    actionLabel: { type: String, default: "" },
    members: { type: Array, default: () => [] },
    displayLimit: { type: Number, default: 4 },
    value: { type: Array },
    isInput: { type: Boolean, default: false },
    enableHover: { type: Boolean, default: false },
  },
  data() {
    return {
      inputRef: `members-input`,
    };
  },
  computed: {
    currentValue: {
      get() {
        if (Array.isArray(this.value)) {
          return this.value.map((v) => v).toString();
        } else {
          return this.value;
        }
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    membersDisplay() {
      return this.members.filter((m, index) => index < this.displayLimit);
    },
    hiddenMembers() {
      return this.members.length > this.displayLimit
        ? this.members.length - this.displayLimit
        : 0;
    },
  },
  mounted() {
    // The input must be focused/blurred so that
    // the changed validation will trigger on value change
    if (this.isInput) {
      this.$refs[this.inputRef].focus();
      this.$refs[this.inputRef].blur();
    }
  },
};
</script>

<style lang="scss" scoped>
.members-avatar-wrapper {
  width: 200px;
}

.component-label {
  font-size: 0.85rem;
}
.action {
  font-size: 0.75rem;
  text-align: left;
  padding-top: 0.25em;
  color: #39a4fc;
  text-decoration: underline;
  cursor: pointer;
}

.members-input {
  height: 0;
  width: 0;
  border: none;
  opacity: 0;
}
</style>
