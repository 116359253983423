/**
 * Automatically imports all the plugins and exports as a single plugin object
 */

const requirePlugin = require.context(".", false, /\.plugin\.js$/);
const plugins = [];

requirePlugin.keys().forEach((filename) => {
  plugins.push(requirePlugin(filename).default.apply());
});

export default plugins;
