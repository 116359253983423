import Vue from "vue";
import router from "./routes";
import store from "./store";
import i18n from "./locales";
import { Layout } from "./dashboard";

Vue.directive("outside-click", {
  bind: function (el, binding, vnode) {
    el.eventOnClick = function (event) {
      if (!(el == event.target || el.contains(event.target))) {
        // call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.addEventListener("click", el.eventOnClick);
  },
  unbind: function (el) {
    document.removeEventListener("click", el.eventOnClick);
  },
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(Layout),
}).$mount("#app");
