import {
  OPTIMIZE_PLATFORM_TEXT_REQUEST,
  OPTIMIZE_PLATFORM_TEXT_SUCCESS,
  OPTIMIZE_PLATFORM_TEXT_ERROR,
  GENERATE_HASHTAGS_REQUEST,
  GENERATE_HASHTAGS_SUCCESS,
  GENERATE_HASHTAGS_ERROR,
  REVISE_TEXT_REQUEST,
  REVISE_TEXT_SUCCESS,
  REVISE_TEXT_ERROR,
} from "../actions/openai";

import { RESET_MODULE_STATE } from "../actions/global";

import {
  optimizePlatformText,
  generateHashtags,
  reviseText,
} from "../../services/api/openai.api";

const getDefaultState = () => ({
  status: {
    requesting: {
      optimizePlatformText: false,
      generateHashtags: false,
      reviseText: false,
    },
    errors: {
      optimizePlatformText: null,
      generateHashtags: null,
      reviseText: null,
    },
  },
});

const state = getDefaultState();

const actions = {
  optimizePlatformText: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit(OPTIMIZE_PLATFORM_TEXT_REQUEST);
      optimizePlatformText(payload)
        .then((res) => {
          commit(OPTIMIZE_PLATFORM_TEXT_SUCCESS, res.data);
          resolve(res.data);
        })
        .catch((error) => {
          commit(OPTIMIZE_PLATFORM_TEXT_ERROR, error);
          reject(error);
        });
    });
  },
  generateHashtags: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit(GENERATE_HASHTAGS_REQUEST);
      generateHashtags(payload)
        .then((res) => {
          commit(GENERATE_HASHTAGS_SUCCESS, res.data);
          resolve(res.data);
        })
        .catch((error) => {
          commit(GENERATE_HASHTAGS_ERROR, error);
          reject(error);
        });
    });
  },
  reviseText: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit(REVISE_TEXT_REQUEST);
      reviseText(payload)
        .then((res) => {
          commit(REVISE_TEXT_SUCCESS, res.data);
          resolve(res.data);
        })
        .catch((error) => {
          commit(REVISE_TEXT_ERROR, error);
          reject(error);
        });
    });
  },
};

const mutations = {
  [OPTIMIZE_PLATFORM_TEXT_REQUEST]: (state) => {
    state.status.requesting.optimizePlatformText = true;
  },
  [OPTIMIZE_PLATFORM_TEXT_SUCCESS]: (state, data) => {
    state.status.requesting.optimizePlatformText = false;
  },
  [OPTIMIZE_PLATFORM_TEXT_ERROR]: (state, error) => {
    state.status.requesting.optimizePlatformText = false;
    state.status.errors.optimizePlatformText = error;
  },
  [GENERATE_HASHTAGS_REQUEST]: (state) => {
    state.status.requesting.generateHashtags = true;
  },
  [GENERATE_HASHTAGS_SUCCESS]: (state, data) => {
    state.status.requesting.generateHashtags = false;
  },
  [GENERATE_HASHTAGS_ERROR]: (state, error) => {
    state.status.requesting.generateHashtags = false;
    state.status.errors.generateHashtags = error;
  },
  [REVISE_TEXT_REQUEST]: (state) => {
    state.status.requesting.reviseText = true;
  },
  [REVISE_TEXT_SUCCESS]: (state, data) => {
    state.status.requesting.reviseText = false;
  },
  [REVISE_TEXT_ERROR]: (state, error) => {
    state.status.requesting.reviseText = false;
    state.status.errors.reviseText = error;
  },
  [RESET_MODULE_STATE]: (state) => {
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    // This mutation is called from the logout action!
    Object.assign(state, getDefaultState());
  },
};

export default {
  state,
  actions,
  mutations,
};
