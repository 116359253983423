<template>
  <div v-if="isLoading" class="d-flex d-flex-center flex-1">
    <loader />
  </div>
  <div v-else id="subscription" class="d-flex d-flex-r flex-1 subscription">
    <router-view />
    <component
      :is="activeModal.name"
      v-bind="activeModal.props"
      @close-subscription-modal="setActiveSubscriptionModal"
    />
    <notification-center />
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { getUrlParam, decryptParam } from "@/utils/url";
import CONFIG from "../config";

import { Loader, ChangePlanModal, NotificationCenter } from "@/components";
export default {
  name: "Subscription",
  components: { Loader, ChangePlanModal, NotificationCenter },
  async created() {
    // const params = getUrlParam(window.location.href);
    const {
      query,
      params: { plan },
    } = this.$route;
    if (query.redirect_status === "succeeded") {
      let data = {
        provider: query.provider,
        payload: { tier: plan, recurring_interval: query.cycle },
      };
      if (query.setup_intent) {
        data.payload.setup_intent = query.setup_intent;
      } else if (query.redirect_id) {
        data.payload.subscription_id = decryptParam(
          CONFIG.URL_SALT,
          query.redirect_id,
        );
      }
      this.finalizePayment(data);
    } else {
      if (!this.pageInfoLoaded) {
        await Promise.all([
          this.getBillingInfo(),
          this.getSubscriptionProducts(),
        ]);
      }
    }
  },
  beforeDestroy() {
    this.setActiveSubscriptionModal();
  },
  computed: {
    ...mapState({
      activeModal: (state) => state.subscription.activeModal,
      productsRequesting: (state) =>
        state.subscription.status.requesting.getSubscriptionProducts,
      billingInfoRequesting: (state) =>
        state.subscription.status.requesting.getBillingInfo,
      finalizePaymentRequesting: (state) =>
        state.subscription.status.requesting.finalizePayment,
      pageInfoLoaded: (state) =>
        state.subscription.products.length > 0 &&
        Object.keys(state.subscription.billing).length > 0,
    }),
    isLoading() {
      return (
        this.productsRequesting ||
        this.billingInfoRequesting ||
        this.finalizePaymentRequesting
      );
    },
  },
  methods: {
    ...mapActions({
      setActiveSubscriptionModal: "setActiveSubscriptionModal",
      getSubscriptionProducts: "getSubscriptionProducts",
      finalizePayment: "finalizePayment",
      getProfile: "getProfile",
      getBillingInfo: "getBillingInfo",
    }),
  },
};
</script>
<style lang="scss" scoped>
#subscription {
  &.subscription {
    background-color: white;
    padding: 0 10em;
  }
}
</style>
