/*
|-------------------------------------------------------------------------------
| routes.js
|-------------------------------------------------------------------------------
| Contains all of the routes for the application
*/

/*
    Imports Vue and VueRouter to extend with the routes.
*/
import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

import { TEAM_CONTEXT } from "@/utils/constants";
// import { Dashboard } from "../dashboard";

/*
    Extends Vue to use Vue Router
*/
Vue.use(VueRouter);

/*
    Makes a new VueRouter that we will use to run all of the routes
    for the app.
*/
let router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/login",
      name: "Login",
      component: () => import("@/dashboard/login.vue"),
    },
    {
      path: "/register",
      name: "Register",
      component: () => import("@/dashboard/register.vue"),
      children: [
        {
          path: "verify",
          name: "verify",
        },
      ],
    },
    {
      path: "/invite",
      name: "Invite",
      component: () => import("@/dashboard/invite.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/",
      name: "Dashboard",
      component: () => import("@/dashboard/dashboard.vue"),
      redirect: "/schedule",
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: "oauth",
          name: "oauth",
          children: [
            {
              path: ":platform",
              name: "platform",
            },
          ],
        },
        {
          path: "schedule",
          name: "schedule",
          children: [
            {
              path: "new",
              name: "post",
            },
            {
              path: "edit",
              name: "post",
            },
          ],
        },
        {
          path: "drafts",
          name: "drafts",
          children: [
            {
              path: "new",
              name: "post",
            },
            {
              path: "edit",
              name: "post",
            },
          ],
        },
        {
          path: "analytics",
          name: "analytics",
        },
        {
          path: "workspace",
          name: "workspace",
          children: [
            {
              path: "team",
              name: "team",
            },
            {
              path: "projects",
              name: "projects",
            },
            {
              path: "channels",
              name: "channels",
            },
            {
              path: "insights",
              name: "insights",
            },
            {
              path: "media",
              name: "media",
              children: [
                {
                  path: "edit",
                  name: "media_edit",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: "/profile",
      name: "Profile",
      component: () => import("@/dashboard/profile.vue"),
      meta: {
        requiresAuth: true,
      },
      redirect: "/profile/settings",
      children: [
        {
          path: "settings",
          name: "settings",
        },
        {
          path: "subscription",
          name: "subscription",
          children: [
            {
              path: "confirmed",
              name: "subscription",
            },
          ],
        },
        {
          path: "teams",
          name: "teams",
        },
      ],
    },
    {
      path: "/plans",
      name: "Subscription",
      component: () => import("@/dashboard/subscription.vue"),
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: "/",
          name: "plan_selection",
          component: () =>
            import("@/components/subscription/SubscriptionSelection.vue"),
        },
        {
          path: ":plan",
          name: "payment",
          component: () =>
            import("@/components/subscription/PaymentSelection.vue"),
        },
        {
          path: ":plan/confirmed",
          name: "payment_confirmed",
          component: () =>
            import("@/components/subscription/SubscriptionSelection.vue"),
        },
      ],
    },
    {
      path: "/:catchAll(.*)*",
      name: "PageNotFound",
      component: () => import("@/dashboard/404.vue"),
    },
  ],
});

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.state.auth.isAuthenticated) {
      next();
      return;
    } else {
      next({
        path: "/login",
        query: {
          ...to.query,
        },
      });
      return;
    }
  } else if (to.path === "/login" || to.path === "/register") {
    if (store.state.auth.isAuthenticated) {
      next(from.path || "/");
      return;
    } else {
      store.dispatch("removeSessionItem", TEAM_CONTEXT);
      next();
      return;
    }
  } else {
    next();
    return;
  }
});

export default router;
