<template>
  <a
    :class="[
      'd-flex d-flex-r align-center menu-item',
      { 'is-selected': isSelected, 'is-focused': isFocused },
    ]"
    :to="path"
    @click="toNavigate"
    @mouseover="expandItem"
  >
    <span :class="['image', customClass]" />
    <span :class="['label', customClass]">{{ label }}</span>
  </a>
</template>

<script>
export default {
  name: "MenuItem",
  props: {
    isSelected: { type: Boolean, default: false },
    isFocused: { type: Boolean, default: false },
    customClass: { type: String, default: "" },
    name: { type: String, default: "" },
    path: { type: String, default: "" },
    label: { type: String, default: "" },
    childItems: { type: Array, default: () => [] },
  },
  methods: {
    expandItem() {
      this.$emit("expand-item", this.childItems);
    },
    toNavigate(e) {
      if (this.childItems && this.childItems.length > 0) {
        e.preventDefault();
      } else {
        this.$router.push({ path: this.path });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-item {
  min-width: 200px;
  margin: 0.25em 0;
  border-radius: 6px;
  color: white;
  cursor: pointer;
  &.is-selected {
    background-color: #8f88bc;
  }
  &:hover,
  &.is-focused {
    background-color: #1b1644;
  }
  .image {
    display: block;
    background-size: 45%;
    background-position: center center;
    background-repeat: no-repeat;
    min-width: 50px;
    height: 50px;
    margin-right: 10px;
    border-radius: 6px;
    &.schedule {
      background-image: url("/assets/images/icons/dashboard/sidemenu/schedule_icon_active.svg");
    }
    &.projects {
      background-image: url("/assets/images/icons/dashboard/sidemenu/project_icon_active.svg");
    }
    &.drafts {
      background-image: url("/assets/images/icons/dashboard/sidemenu/draft_icon_active.svg");
    }
    &.workspace {
      background-image: url("/assets/images/icons/dashboard/sidemenu/workspace_icon_active.svg");
    }
    &.story {
      background-image: url("/assets/images/icons/dashboard/sidemenu/story_icon_active.svg");
    }
    &.inbox {
      background-image: url("/assets/images/icons/dashboard/sidemenu/inbox_icon_active.svg");
    }
    &.ads {
      background-image: url("/assets/images/icons/dashboard/sidemenu/ads_icon_active.svg");
    }
    &.analytics {
      background-image: url("/assets/images/icons/dashboard/sidemenu/analytics_icon_active.svg");
    }
    &.trending {
      background-image: url("/assets/images/icons/dashboard/sidemenu/trending_icon_active.svg");
    }
    &.channels {
      background-image: url("/assets/images/icons/dashboard/sidemenu/channels_icon_active.svg");
    }
    &.consistency {
      background-image: url("/assets/images/icons/dashboard/sidemenu/consistency_icon_active.svg");
    }
    &.feedback {
      background-image: url("/assets/images/icons/dashboard/sidemenu/feedback_icon_active.svg");
    }
  }
}
</style>
