var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"account-settings"},[_c('content-row',{attrs:{"hideDivider":true}},[_c('div',{attrs:{"slot":"content-left"},slot:"content-left"},[_c('div',{staticClass:"d-flex d-flex-c"},[_c('single-image-upload',{ref:"profileAvatar",staticClass:"mb-2",attrs:{"initialThumbnail":_vm.user.avatar,"round":true,"hideIcon":true,"folder":_vm.uploadContext},on:{"image-file-upload":_vm.onAvatarChange}}),(_vm.avatarChanged)?_c('socianis-button',{staticClass:"d-flex-center",attrs:{"label":"Update"},on:{"button-click":_vm.updateAvatar}}):_vm._e()],1)]),_c('div',{attrs:{"slot":"content-right"},slot:"content-right"},[_c('button',{staticClass:"settings-button",on:{"click":_vm.changeAvatar}},[_vm._v(" Change picture ")])])]),_c('content-row',{attrs:{"title":"Personal information"}},[_c('div',{attrs:{"slot":"content-left"},slot:"content-left"},[_c('content-col',{attrs:{"label":"Full name","text":_vm.user.full_name}})],1),_c('div',{attrs:{"slot":"content-right"},slot:"content-right"},[_c('button',{staticClass:"settings-button",on:{"click":function($event){return _vm.setActiveProfileModal({
            name: 'ChangeFullNameModal',
            props: {
              setting: 'full_name',
              fields: [
                {
                  name: 'full_name',
                  label: 'Full Name',
                  value: _vm.user.full_name,
                  rules: 'required',
                },
              ],
            },
          })}}},[_vm._v(" Edit ")])])]),_c('content-row',[_c('div',{attrs:{"slot":"content-left"},slot:"content-left"},[_c('content-col',{attrs:{"label":"Email","text":_vm.user.email}})],1)]),_c('content-row',[_c('div',{attrs:{"slot":"content-left"},slot:"content-left"},[_c('content-col',{attrs:{"label":"Password","text":"*********"}})],1),_c('div',{attrs:{"slot":"content-right"},slot:"content-right"},[_c('button',{staticClass:"settings-button",on:{"click":function($event){return _vm.setActiveProfileModal({
            name: 'ChangePasswordModal',
            props: {
              setting: 'password',
              fields: [
                {
                  name: 'password',
                  label: 'Current password',
                  rules: 'required',
                },
                {
                  name: 'new_password',
                  label: 'New password',
                  rules: 'required',
                },
              ],
            },
          })}}},[_vm._v(" Change password ")])])]),_c('content-row',{staticClass:"mt-5",attrs:{"title":"Time Zone"}},[_c('div',{attrs:{"slot":"content-left"},slot:"content-left"},[_c('content-col',{attrs:{"label":"Time Zone","text":_vm.user.timezone}})],1),_c('div',{attrs:{"slot":"content-right"},slot:"content-right"},[_c('button',{staticClass:"settings-button",on:{"click":function($event){return _vm.setActiveProfileModal({
            name: 'ChangeTimezoneModal',
            props: {
              initalZone: { value: _vm.user.timezone },
            },
          })}}},[_vm._v(" Change Time Zone ")])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }