<template>
  <div class="d-flex d-flex-c d-flex-center login-wrapper">
    <div class="d-flex logo-wrapper mb-2">
      <a href="https://socianis.com"
        ><img
          src="/assets/images/layout/logos/socianis_light.svg"
          alt="socianis-logo"
      /></a>
    </div>

    <login-form label="Login" class="mb-2" />
    <div class="d-flex signup-wrapper">
      <span class="d-flex">
        Don't have an account?
        <router-link class="ml-05 register-link" to="/register"
          >Sign up here</router-link
        ></span
      >
    </div>
  </div>
</template>

<script>
import { LoginForm } from "@/components/";

export default {
  name: "Login",
  components: { LoginForm },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.login-wrapper {
  background-color: #221c55;
  height: 100vh;
  padding: 1em;

  .logo-wrapper,
  .login-form,
  .signup-wrapper {
    max-width: 650px;
    width: 100%;
  }

  .logo-wrapper {
    max-width: 650px;
    width: 100%;
    img {
      height: 50px;
    }
  }
  .signup-wrapper {
    color: white;

    .register-link {
      color: #f18904;
    }
  }
}

@media only screen and (max-width: 568px) {
  .login-wrapper {
    .signup-wrapper {
      color: white;
      font-size: 0.85rem;
    }
  }
}
</style>
