import httpClient from "./httpClient";

const CHANNEL = "/channel";

export const getChannels = (params) => httpClient.get(CHANNEL, { params });
export const updateChannel = (payload = {}) => httpClient.put(CHANNEL, payload);
export const deleteChannel = (channel_id) =>
  httpClient.delete(`${CHANNEL}?channel_id=${channel_id}`);
export const getChannelAuth = (params = {}) =>
  httpClient.get(`${CHANNEL}/auth`, {
    params: {
      ...params,
    },
  });
export const addChannel = (payload = {}) =>
  httpClient.post(`${CHANNEL}/auth?platform=${payload.platform}`, payload);
export const addChannelPage = (payload = {}) =>
  httpClient.post(`${CHANNEL}/page`, payload);
export const deleteCachedPages = () => httpClient.delete(`${CHANNEL}/cache`);
