<template>
  <div class="d-flex d-flex-c" v-outside-click="handleClose" @click.stop>
    <label v-if="label">{{ label }}</label>
    <div
      :class="['select-wrapper', 'd-flex', 'd-flex-between', { toggled, wide }]"
      @click="toggle"
    >
      <span
        v-if="selectedItem.type"
        :class="[
          'selected-item-bg',
          'item-bg',
          'social',
          selectedItem.type.toLowerCase(),
        ]"
      />
      <span
        v-else
        class="selected-item-bg"
        :style="setBg(selectedItem.picture)"
      />
      <label class="selected-item">
        <span>{{ selectedItem.value }}</span></label
      >
      <ul :class="['select-items', { toggled, 'is-active': show }]">
        <li
          v-for="(item, index) in items"
          :key="index"
          :class="[
            'select-item d-flex d-flex-start',

            {
              'default-item': item.key === 0,
              'is-selected': item.value === selectedItem.value,
              'is-radio': radio,
            },
          ]"
          style="align-items: center"
          @click="selectItem(item)"
        >
          <div
            class="d-flex d-flex-between align-center"
            style="overflow: hidden"
          >
            <span
              v-if="item.type"
              :class="['item-bg', 'social', item.type.toLowerCase()]"
            />
            <span v-else class="item-bg" :style="setBg(item.picture)" />
            <span class="ellipsis">{{ item.value }}</span>
          </div>
          <span
            ><form-checkbox
              :id="item.id"
              v-if="checkEnabled"
              :name="item.key"
              :value="item.value"
              :is-selected="item.isSelected"
              @change="checkboxSelect"
          /></span>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import FormCheckbox from "@/components/ui/forms/FormCheckbox.vue";

export default {
  name: "ContentInput",
  components: { FormCheckbox },
  props: {
    name: { type: String, default: "" },
    label: { type: String, default: "" },
    items: { type: Array, default: () => [] },
    wide: { type: Boolean, default: false },
    radio: { type: Boolean, default: false },
    checkEnabled: { type: Boolean, defauklt: false },
  },
  data() {
    return {
      selectedItem: { value: `No ${this.name} selected`, type: "all" },
      toggled: false,
      show: false,
    };
  },
  methods: {
    selectItem(item) {
      if (!this.checkEnabled) {
        this.selectedItem = item;
        this.$emit("trigger", this.name, item.id);
      }
    },
    checkboxSelect(id, value) {
      if (this.checkEnabled) {
        this.$emit("trigger", this.name, { id, value });
      }
    },
    toggle() {
      if (this.items.length > 0) {
        this.show = !this.toggled;
        setTimeout(() => {
          this.toggled = !this.toggled;
        }, 150);
      }
    },
    handleClose() {
      this.show = false;
      setTimeout(() => {
        this.toggled = false;
      }, 150);
    },
    setBg(src) {
      return src && src.length > 0
        ? `background-image: url("${src}")`
        : "background-image: url('/assets/images/layout/dashboard/common/content_bg.svg')";
    },
  },
};
</script>
<style lang="scss" scoped>
.select-wrapper {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #d5daef;
  height: 45px;
  width: 325px;
  margin-right: 2em;
  padding: 1em;
  border-radius: 6px;
  cursor: pointer;

  .selected-item-bg,
  .item-bg {
    display: block;
    width: 30px;
    height: 30px;
    min-width: 30px;
    min-height: 30px;
    border-radius: 100%;
    margin-right: 1em;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    &.social {
      height: 24px;
      width: 24px;
      min-height: 24px;
      min-width: 24px;
    }

    &.facebook {
      background-image: url("/assets/images/icons/social/facebook_channel.png");
    }
    &.twitter {
      background-image: url("/assets/images/icons/social/twitter_channel.png");
    }
    &.instagram {
      background-image: url("/assets/images/icons/social/instagram_channel.png");
    }
    &.linkedin {
      background-image: url("/assets/images/icons/social/linkedin_channel.png");
    }
  }

  &.wide {
    width: 100%;
    max-width: 525px;
    justify-content: flex-start;
    margin-bottom: 2em;
    background-color: #f1f9ff;
    border: 1px solid #bce0fd;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    background-image: url("/assets/images/icons/dashboard/schedule/arrow_down_dark.svg");
    background-repeat: no-repeat;
    background-size: 65%;
    background-position: center;
    width: 12px;
    height: 9px;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
  .select-items {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    position: absolute;
    height: 0;
    width: 100%;
    max-height: 264px;
    left: 0;
    top: 50px;
    border-radius: 6px;
    box-shadow: 0px 1px 3px #00000029;
    transition: opacity 100ms ease;
    opacity: 0;
    overflow-y: auto;

    .select-item {
      display: flex;
      padding: 0.5em 1em;
      margin: 0.15em 1em;
      border-radius: 6px;

      cursor: pointer;

      &:hover {
        background-color: #aab4db;
      }

      &.is-selected {
        background-color: #d5daef;
        &.is-radio,
        &.is-checkbox {
          &::before {
            background-color: black;
          }
        }
      }

      &.default-item {
        .item-bg {
          display: none !important;
        }
      }

      &.is-radio,
      &.is-checkbox {
        &::before {
          display: block;
          content: "";
          width: 15px;
          height: 15px;
          min-width: 15px;
          min-height: 15px;
          margin-right: 1em;
          background-color: white;
          border: 3px solid white;
          border-radius: 100%;
          outline: 1px solid #ccc;
        }
      }

      .item-bg {
        width: 30px;
        height: 30px;
        min-width: 30px;
        min-height: 30px;
        &.social {
          height: 24px;
          width: 24px;
          min-width: 24px;
          min-height: 24px;
        }
      }
    }

    &.toggled {
      padding: 0.5em 0;
      height: auto;
      z-index: 100;
    }

    &.is-active {
      padding: 0.5em 0;
      height: auto;
      opacity: 1;
      z-index: 100;
    }
  }
  .selected-item {
    margin-right: 20px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    input {
      height: 0;
      width: 0;
      border: none;
      opacity: 0;
    }
  }
  label.wrapper {
    margin-bottom: 0;
  }
}
</style>
