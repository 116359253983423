<template>
  <div class="d-flex d-flex-r d-flex-between">
    <div class="d-flex d-flex-c">
      <div class="d-flex d-flex-c info-wrapper">
        <label><b>Plan</b></label
        ><span>{{ plan }}</span>
      </div>
      <div class="d-flex d-flex-c info-wrapper">
        <label><b>Subscription</b></label
        ><span style="text-transform: capitalize"
          >{{ interval }} subscription</span
        >
      </div>
      <div class="d-flex d-flex-c info-wrapper">
        <label><b>Total</b></label
        ><span>{{
          `${currencySymbols[currency.toLowerCase()]} ${price}`
        }}</span>
      </div>
    </div>
    <div class="d-flex">
      <span class="img-wrapper">
        <img
          :src="`/assets/images/layout/subscription/${plan.toLowerCase()}_l.svg`"
          width="220"
      /></span>
    </div>
  </div>
</template>
<script>
export default {
  name: "PlanSummary",
  props: {
    plan: { type: String, default: "" },
    interval: { type: String, default: "" },
    currency: { type: String, default: "" },
    price: { type: [Number, String] },
  },
  data() {
    return {
      currencySymbols: {
        eur: "€",
      },
    };
  },
};
</script>
<style lang="scss" scoped>
.info-wrapper {
  margin-bottom: 1.5em;
}
</style>
