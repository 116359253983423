<template>
  <div class="d-flex d-flex-c" v-outside-click="handleClose" @click.stop>
    <label v-if="label">{{ label }}</label>
    <div
      :class="['select-wrapper', 'd-flex', 'd-flex-between', { toggled, wide }]"
      @click="toggle"
    >
      <span
        v-if="currentValue.type"
        :class="[
          'selected-item-bg',
          'item-bg',
          'social',
          currentValue.type.toLowerCase(),
        ]"
      />
      <span
        v-else
        class="selected-item-bg"
        :style="setBg(currentValue.picture)"
      />
      <label class="selected-item">
        <span>{{ currentValue.value }}</span
        ><span v-if="selectedCount"> (+{{ selectedCount }} more)</span></label
      >
      <ul :class="['select-items', { toggled, 'is-active': show }]">
        <li
          v-for="(item, index) in items"
          :key="index"
          :class="[
            'select-item d-flex d-flex-start',
            {
              'default-item': item.key === 0,
              'is-selected':
                item.value === currentValue.value || item.isSelected,
              'is-radio': radio,
              'is-checkbox': checkbox,
            },
          ]"
          style="align-items: center"
          @click.stop="selectItem(item)"
        >
          <div
            class="d-flex d-flex-between align-center"
            style="overflow: hidden"
          >
            <span
              v-if="item.type"
              :class="['item-bg', 'social', item.type.toLowerCase()]"
            />
            <span v-else class="item-bg" :style="setBg(item.picture)" />
            <span class="ellipsis">{{ item.value }}</span>
          </div>
        </li>
      </ul>
      <validation-provider mode="aggressive">
        <div class="d-flex d-flex-c hidden-input">
          <input
            :ref="inputRef"
            :name="label"
            class="advanced-select-input"
            v-model="validationValue"
          />
        </div>
      </validation-provider>
    </div>
  </div>
</template>
<script>
import { ValidationProvider } from "vee-validate";
import { eventBus } from "@/utils/event-bus";
import "@/utils/validation";

export default {
  name: "AdvancedFormSelect",
  components: { ValidationProvider },
  props: {
    name: { type: [String, Number], default: "" },
    label: { type: String, default: "" },
    items: { type: Array, default: () => [] },
    wide: { type: Boolean, default: false },
    value: { type: [Array, Object] },
    radio: { type: Boolean, default: false },
    checkbox: { type: Boolean, default: false },
    checkEnabled: { type: Boolean, default: false },
  },
  created() {
    if (this.isArray) {
      this.defaultValue = this.value.slice();
    }
  },
  mounted() {
    if (this.name === "channels") {
      eventBus.$on("project-select", () => {
        if (this.toggled) {
          this.toggled = false;
        }
      });
    }
    // The input must be focused/blurred so that
    // the changed validation will trigger on value change
    this.$refs[this.inputRef].focus();
    this.$refs[this.inputRef].blur();
  },
  data() {
    return {
      inputRef: `${this.name}-select`,
      toggled: false,
      isArray: !!Array.isArray(this.value),
      defaultValue: null,
      show: false,
    };
  },
  computed: {
    validationValue() {
      if (this.isArray) {
        const isDefault =
          this.defaultValue.length &&
          this.value.length === this.defaultValue.length &&
          this.defaultValue.every((v) => this.value.includes(v));
        return isDefault ? this.defaultValue.toString() : this.value.toString();
      } else {
        return this.currentValue.value;
      }
    },
    selectedCount: {
      get() {
        if (Array.isArray(this.value)) {
          if (this.value.length > 1) {
            return this.value.length - 1;
          }
          return null;
        } else {
          return null;
        }
      },
    },
    currentValue: {
      get() {
        if (Array.isArray(this.value)) {
          return (
            this.items.find((item) => this.value.includes(item.id)) || {
              id: null,
              key: "",
              value: "Select channels",
              picture: "",
              type: "",
              isSelected: false,
            }
          );
        } else {
          return this.value;
        }
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    selectItem(item) {
      if (this.checkEnabled) {
        this.$emit("checkbox-trigger", {
          id: item.id,
          value: !item.isSelected,
        });
      } else {
        if (this.name === "projects") {
          eventBus.$emit("project-select");
        }
        this.$emit("trigger", this.name, item);
        this.currentValue = item;
        this.show = false;
        setTimeout(() => {
          this.toggled = false;
        }, 150);
      }
    },
    toggle() {
      if (this.items.length > 0) {
        this.show = !this.toggled;
        setTimeout(() => {
          this.toggled = !this.toggled;
        }, 150);
      }
    },
    setBg(src) {
      return src && src.length > 0
        ? `background-image: url("${src}")`
        : "background-image: url('/assets/images/layout/dashboard/common/content_bg.svg')";
    },
    handleClose() {
      this.show = false;
      setTimeout(() => {
        this.toggled = false;
      }, 150);
    },
  },
};
</script>
<style lang="scss" scoped>
.select-wrapper {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #d5daef;
  height: 45px;
  width: 350px;
  margin-right: 2em;
  padding: 1em;
  border-radius: 6px;
  cursor: pointer;

  .selected-item-bg,
  .item-bg {
    display: block;
    width: 30px;
    height: 30px;
    min-width: 30px;
    min-height: 30px;
    border-radius: 100%;
    margin-right: 1em;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    &.social {
      max-height: 24px;
      max-width: 24px;
      min-height: 24px;
      min-width: 24px;
    }

    &.facebook {
      background-image: url("/assets/images/icons/social/facebook_channel.png");
    }
    &.twitter {
      background-image: url("/assets/images/icons/social/twitter_channel.png");
    }
    &.instagram {
      background-image: url("/assets/images/icons/social/instagram_channel.png");
    }
    &.linkedin {
      background-image: url("/assets/images/icons/social/linkedin_channel.png");
    }
  }

  &.wide {
    width: 400px;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    background-image: url("/assets/images/icons/dashboard/schedule/arrow_down_dark.svg");
    background-repeat: no-repeat;
    background-size: 65%;
    background-position: center;
    width: 12px;
    height: 9px;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
  .select-items {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    position: absolute;
    height: 0;
    width: 100%;
    max-height: 264px;
    left: 0;
    top: 50px;
    border-radius: 6px;
    box-shadow: 0px 1px 3px #00000029;
    overflow-y: auto;
    transition: opacity 100ms ease;
    opacity: 0;

    .select-item {
      display: flex;
      padding: 0.5em 1em;
      margin: 0.15em 1em;
      border-radius: 6px;
      cursor: pointer;

      .item-bg {
        width: 30px;
        height: 30px;
        min-width: 30px;
        min-height: 30px;
        &.social {
          max-height: 24px;
          max-width: 24px;
          min-height: 24px;
          min-width: 24px;
          height: 24px;
          width: 24px;
        }
      }

      &:hover {
        background-color: #aab4db;
      }

      &.is-selected {
        background-color: #d5daef;
        &.is-radio,
        &.is-checkbox {
          &::before {
            background-color: black;
          }
        }
      }

      &.default-item {
        .item-bg {
          display: none !important;
        }
      }

      &.is-radio,
      &.is-checkbox {
        &::before {
          display: block;
          content: "";
          width: 15px;
          height: 15px;
          min-width: 15px;
          min-height: 15px;
          margin-right: 1em;
          background-color: white;
          border: 3px solid white;
          border-radius: 100%;
          outline: 1px solid #ccc;
        }
      }

      &.is-checkbox {
        &::before {
          width: 15px;
          height: 15px;
          border-width: 2px;
          border-radius: 3px;
        }
      }
    }

    &.toggled {
      padding: 0.5em 0;
      height: auto;
      z-index: 100;
    }

    &.is-active {
      padding: 0.5em 0;
      height: auto;
      opacity: 1;
      z-index: 100;
    }
  }
  .selected-item {
    margin-right: 20px;

    input {
      height: 0;
      width: 0;
      border: none;
      opacity: 0;
    }
  }
  .hidden-input {
    input {
      height: 0;
      width: 0;
      border: none;
      opacity: 0;
    }
  }
  label.wrapper {
    margin-bottom: 0;
  }
}
</style>
