<template>
  <div class="d-flex d-flex-r d-flex-between align-center team-row">
    <team-item :team-id="teamId" :name="name" style="min-width: 200px" />
    <member-avatars :members="members" />
    <div class="d-flex d-flex-r">
      <custom-label :label-text="role" :has-siblings="false" />
    </div>
    <dot-menu :items="availableMenuItems" @menu-item-select="handleRowClick" />
  </div>
</template>

<script>
import { teamRowItems } from "@/utils/constants";

import { TeamItem } from "@/components/dashboard/menu";
import { CustomLabel, MemberAvatars, DotMenu } from "@/components";

export default {
  name: "Team",
  components: { TeamItem, CustomLabel, MemberAvatars, DotMenu },
  props: {
    teamId: { type: String, default: "" },
    name: { type: String, default: "" },
    members: { type: Array, default: () => [] },
    role: { type: String, default: "" },
  },
  computed: {
    availableMenuItems() {
      return teamRowItems.filter((item) => item.roles.includes(this.role));
    },
  },
  methods: {
    handleRowClick(item) {
      this.$emit("row-action", this.teamId, item.action);
    },
  },
};
</script>

<style lang="scss" scoped>
.team-row {
  padding: 1em;
  border-radius: 6px;
  min-width: 0;
  border: 1px solid #f9f9f9;
}
</style>
