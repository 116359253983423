<template>
  <div class="modal-wrapper">
    <modal title="Select LinkedIn account type" @close="closeModal">
      <div slot="modal-content" class="modal-content-wrapper mt-2">
        <div class="d-flex d-flex-c mb-2" style="min-width: 100%">
          <type-card
            v-for="(linkedIn, index) in linkedInTypes"
            :key="index"
            :type="linkedIn.type"
            :title="linkedIn.title"
            :detail="linkedIn.detail"
            :isSelected="linkedIn.type === selectedType"
            @type-card-click="changeType(linkedIn.type)"
          />
        </div>
        <div
          class="d-flex button-wrapper"
          style="justify-content: space-between"
        >
          <blank-button @button-click="closeModal" label="Cancel" @click.stop />
          <content-button
            :disabled="authUrlRequesting"
            label="Connect account"
            @button-click="openPlatformWindow"
            @click.stop
          />
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { Modal } from "@/components";
import { TypeCard } from "@/components/dashboard";
import { ContentButton, BlankButton } from "@/components/ui";
import { PLATFORM_AUTH, SELECTED_PLATFORM } from "@/utils/constants";
export default {
  name: "SelectLinkedInType",
  components: { Modal, ContentButton, BlankButton, TypeCard },
  data() {
    return {
      selectedType: "company",
      linkedInTypes: [
        {
          type: "company",
          title: "Add LinkedIn page",
          detail: "A company profile that can be followed",
        },
        {
          type: "personal",
          title: "Add LinkedIn profile",
          detail: "An individual personal profile",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      authUrlRequesting: (state) => state.channel.status.requesting.getAuthUrl,
    }),
  },
  methods: {
    ...mapActions({
      getChannelAuth: "getChannelAuth",
    }),
    changeType(type) {
      this.selectedType = type;
    },
    closeModal() {
      this.$emit("closeModal");
    },
    openPlatformWindow() {
      const platform = "linkedin";
      if (!this.authUrlRequesting) {
        const params = { platform: platform, platform_type: this.selectedType };
        this.getChannelAuth(params).then((res) => {
          window.sessionStorage.setItem(SELECTED_PLATFORM, platform);
          window.sessionStorage.setItem(PLATFORM_AUTH, true);
          window.open(res.data, "_self");
        });
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.modal-wrapper {
  /deep/.dialog-container {
    width: 725px;
  }
  .modal:nth-child(2) {
    z-index: 1060;
  }
}
</style>
