<template>
  <form enctype="multipart/form-data" novalidate>
    <div :class="['wrapper', { round }]">
      <input
        class="image-file-input"
        type="file"
        :name="uploadFieldName"
        accept="image/*"
        @change="filesChange($event.target.name, $event.target.files)"
      />
      <validation-provider mode="aggressive">
        <input :ref="inputRef" class="content-input" v-model="currentValue" />
      </validation-provider>
      <span v-if="!hideIcon" class="upload-button" />
      <span
        v-if="thumbnail"
        :class="['thumbnail', { round }]"
        :style="setBg(thumbnail.src)"
      />
    </div>
  </form>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import { mapState } from "vuex";
import { uploadImage } from "@/services/api/asset.api";
import { compressImage } from "@/utils/media";

export default {
  components: { ValidationProvider },
  props: {
    initialThumbnail: { default: null },
    value: { type: [Object, String, Number], default: null },
    folder: { type: String, default: null },
    round: { type: Boolean, default: false },
    hideIcon: { type: Boolean, default: false },
    changeCallback: { type: Boolean, default: false },
  },
  mounted() {
    // The input must be focused/blurred so that
    // the changed validation will trigger on value change
    this.$refs[this.inputRef].focus();
    this.$refs[this.inputRef].blur();
  },
  data() {
    return {
      inputRef: `${this.name}-input`,
      thumbnail: { src: this.initialThumbnail },
      uploadData: null,
      uploadError: null,
      currentStatus: 0,
      uploadFieldName: "project_picture",
    };
  },
  computed: {
    ...mapState({
      uploadedImages: (state) =>
        state.schedule.fileUploadSettings.uploadedImages,
    }),
    currentValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    async save() {
      return await uploadImage(this.uploadData, this.folder);
    },
    async loadThumbnail(src) {
      this.thumbnail.src = src;
      this.currentValue = this.thumbnail;
    },
    async filesChange(fieldName, files) {
      // handle file changes
      if (!files.length) return;
      const formData = new FormData();
      const file = files[0];
      const fileName = `${Math.random().toString(36)}.${file.name}`;
      const { compressedImage, imgSrc } = await compressImage(file, fileName);
      formData.append(fieldName, compressedImage, fileName);
      this.uploadData = formData.get(this.uploadFieldName);
      this.loadThumbnail(imgSrc);
      if (this.$listeners["image-file-upload"]) {
        this.$emit("image-file-upload");
      }
    },
    setBg(thumbnail) {
      return thumbnail && thumbnail.length > 0
        ? `background-image: url("${thumbnail}")`
        : "";
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: block;
  position: relative;
  width: 170px;
  height: 170px;
  background-image: url("/assets/images/layout/dashboard/common/content_bg.svg");
  background-size: cover;
  background-position: center center;
  border-radius: 6px;

  &.round {
    border-radius: 100%;
  }

  .image-file-input {
    opacity: 0; /* invisible but it's there! */
    cursor: pointer;
    z-index: 45;
    position: relative;
    width: 170px;
    height: 170px;
    border-radius: 6px;
    z-index: 49;
  }
  .upload-button {
    display: block;
    position: absolute;
    width: 40px;
    height: 40px;
    background-image: url("/assets/images/layout/dashboard/common/upload_icon.svg");
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 50;
  }

  .thumbnail {
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    &.round {
      border-radius: 100%;
    }
  }

  .content-input {
    height: 0;
    width: 0;
    opacity: 0;
  }
}
</style>
