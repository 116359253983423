<template>
  <modal @close="closeModal" :maxWidth="false">
    <div slot="modal-content" class="modal-content-wrapper">
      <div class="d-flex d-flex-c">
        <month-view :isPostTime="true" @setPostTimeDate="selectDate">
          <repeat-settings
            :repeatInfo="repeatInfo"
            @setPostTimeDate="selectDate"
            slot="schedule-feature"
          />
        </month-view>
        <div class="d-flex d-flex-r d-flex-between">
          <time-container
            :startDate="dateInfo"
            :timezone="repeatInfo.timezone"
            :timeInfo="timeInfo"
            @set-time="setTime"
          />
          <dashboard-button
            class="d-flex d-align-self-end mt-2 dashboard-button"
            label="Done"
            @button-click="confirm"
          />
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import dayjs, { formatDateWithTz, getDateTz } from "@/utils/timezone";
import { mapState, mapActions } from "vuex";
import { eventBus } from "@/utils/event-bus";
import { Modal } from "@/components";
import { DashboardButton } from "@/components/ui/";
import {
  TimeContainer,
  RepeatSettings,
} from "@/components/dashboard/content/schedule";
import { MonthView } from "@/components/calendar";

export default {
  name: "SelectPostTimeModal",
  components: {
    Modal,
    DashboardButton,
    MonthView,
    TimeContainer,
    RepeatSettings,
  },
  props: {
    repeatInfo: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      dateInfo: null,
      timeInfo: {
        hour: 1,
        minute: 0,
        time: "AM",
      },
    };
  },
  computed: {
    ...mapState({
      postScheduleTime: (state) =>
        state.schedule.scheduleModalSettings.selectedDate,
    }),
  },
  created() {
    this.dateInfo =
      this.repeatInfo && this.repeatInfo.start_date
        ? dayjs(this.repeatInfo.start_date)
        : dayjs(this.postScheduleTime)
            .set("hour", this.timeInfo.hour)
            .set("minute", this.timeInfo.minute);
    const formattedHour = formatDateWithTz(
      this.dateInfo,
      this.repeatInfo.timezone,
    ).hour();
    this.timeInfo.hour = formattedHour;
    this.timeInfo.minute = this.dateInfo.minute();
    this.timeInfo.time = formattedHour <= 12 ? "AM" : "PM";
  },
  beforeDestroy() {
    this.resetScheduleDate();
  },
  methods: {
    ...mapActions({
      setScheduleDate: "setScheduleDate",
      confirmPostScheduleTime: "confirmPostScheduleTime",
      resetScheduleDate: "resetScheduleDate",
    }),
    setTime(key, val) {
      //console.log(key, val);
      this.timeInfo[key] = val;
    },
    selectDate(newDate) {
      this.dateInfo = dayjs();
      const mergedDate = this.postScheduleTime
        .set("date", newDate.get("date"))
        .set("month", newDate.get("month"))
        .set("year", newDate.get("year"));

      this.setScheduleDate(mergedDate);
    },
    closeModal() {
      // this.resetScheduleDate();
      this.$emit("closeModal");
    },
    confirm() {
      eventBus.$emit("confirm-repeat-settings");
      const confirmedDate = getDateTz(
        this.postScheduleTime
          .set("hour", this.timeInfo.hour)
          .set("minute", this.timeInfo.minute),
        this.repeatInfo.timezone,
      );

      // this.setScheduleDate(confirmedDate);
      this.confirmPostScheduleTime(confirmedDate);
      this.closeModal();
    },
  },
};
</script>
<style lang="scss" scoped>
.modal-content-wrapper {
  display: flex;
  flex-direction: column;

  .calendar-month {
    margin-bottom: 2em;
  }
}
</style>
