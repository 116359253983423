export const GET_CONTENTS_REQUEST = "GET_CONTENTS_REQUEST";
export const GET_CONTENTS_SUCCESS = "GET_CONTENTS_SUCCESS";
export const GET_CONTENTS_ERROR = "GET_CONTENTS_ERROR";

export const SAVE_CONTENT_REQUEST = "SAVE_CONTENT_REQUEST";
export const SAVE_CONTENT_SUCCESS = "SAVE_CONTENT_SUCCESS";
export const SAVE_CONTENT_ERROR = "SAVE_CONTENT_ERROR";

export const DELETE_CONTENT_REQUEST = "DELETE_CONTENT_REQUEST";
export const DELETE_CONTENT_SUCCESS = "DELETE_CONTENT_SUCCESS";
export const DELETE_CONTENT_ERROR = "DELETE_CONTENT_ERROR";

export const REMOVE_CONTENT_FROM_LIST = "REMOVE_CONTENT_FROM_LIST";
export const REMOVE_DRAFT_FROM_LIST = "REMOVE_DRAFT_FROM_LIST";

export const INIT_DRAFT_IMAGES = "INIT_DRAFT_IMAGES";
export const RESET_DRAFT_IMAGES = "RESET_DRAFT_IMAGES";

export const SEND_MESSAGE_REQUEST = "SEND_MESSAGE_REQUEST";
export const SEND_MESSAGE_SUCCESS = "SEND_MESSAGE_SUCCESS";
export const SEND_MESSAGE_ERROR = "SEND_MESSAGE_ERROR";

export const SET_CALENDAR_DATE = "SET_CALENDAR_DATE";
export const SET_CONTENT_VIEW_PROJECT = "SET_CONTENT_VIEW_PROJECT";
export const SET_DRAFT_VIEW_PROJECT = "SET_DRAFT_VIEW_PROJECT";
export const SET_CONTENT_VIEW_CHANNELS = "SET_CONTENT_VIEW_CHANNELS";
export const SELECT_DATE = "SELECT_DATE";
export const SORT_CONTENT_BY_DATE = "SORT_CONTENT_BY_DATE";
