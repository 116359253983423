export const getUrlParam = (url = "") => {
  let params = {};
  url.split(/\?|&/).forEach((p, i) => {
    // skip first index, which would be the hostname
    if (i > 0) {
      const [param, ...rest] = p.split("=");
      const value = rest.join("=");
      params[param] = value;
    }
  });
  return params;
};

export const removeUrlParams = (...params) => {
  const newUrl = new URL(window.location.href);
  params.forEach((param) => {
    newUrl.searchParams.delete(param);
  });
  window.history.replaceState({}, document.title, newUrl.href);
};

export const encryptParam = (salt, text) => {
  const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
  const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
  const applySaltToChar = (code) =>
    textToChars(salt).reduce((a, b) => a ^ b, code);

  return text
    .split("")
    .map(textToChars)
    .map(applySaltToChar)
    .map(byteHex)
    .join("");
};

export const decryptParam = (salt, encoded) => {
  const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
  const applySaltToChar = (code) =>
    textToChars(salt).reduce((a, b) => a ^ b, code);
  return encoded
    .match(/.{1,2}/g)
    .map((hex) => parseInt(hex, 16))
    .map(applySaltToChar)
    .map((charCode) => String.fromCharCode(charCode))
    .join("");
};

export const generateId = () =>
  (+new Date() * (Math.random() + 1)).toString(36).substring(0, 8);

export const getFileTypeFromUrl = (url = "") => {
  return url.split(/[#?]/)[0].split(".").pop().trim();
};
