<template>
  <div class="d-flex d-flex-c mb-1 payment-method">
    <div class="d-flex d-flex-r">
      <div class="d-flex payment-method-icon mb-1">
        <img
          class="payment-icon mr-1"
          :src="`/assets/images/icons/payment/${
            paymentMethodType === 'card' ? cardBrand : paymentMethodType
          }-dark.svg`"
        />
      </div>
      <div class="d-flex d-flex-c payment-method-info">
        <span v-if="cardBrand && lastFour">{{
          `${cardBrand} **** ${lastFour}`
        }}</span>
        <span v-if="expiryMonth && expiryYear" class="expire">{{
          `Expires ${expiryMonth}/${expiryYear}`
        }}</span>
      </div>
    </div>
    <div class="d-flex d-flex-r d-flex-end">
      <span class="action-span mr-1">Remove</span>
      <span class="action-span">Update</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "PaymentMethod",
  props: {
    cardBrand: { type: String },
    expiryYear: { type: Number },
    expiryMonth: { type: Number },
    lastFour: { type: Number },
    paymentMethodType: { type: String },
  },
};
</script>
<style lang="scss" scoped>
.payment-method {
  width: 300px;
  padding: 1em;
  border-radius: 6px;
  text-transform: capitalize;
  box-shadow: 0px 3px 5px #00000029;

  .payment-icon {
    width: 75px;
  }

  .expire {
    font-size: 0.85rem;
    opacity: 0.5;
  }

  .action-span {
    font-size: 0.85rem;
    cursor: pointer;
  }
}
</style>
