// Wrapper for shared mutations and properties for local storage
import {
  INIT_LOCAL_STORE,
  SET_STORAGE_ITEM,
  REMOVE_STORAGE_ITEM,
  RESET_LOCAL_STORAGE,
} from "../actions/local";

import { RESET_MODULE_STATE } from "../actions/global";

const getDefaultState = () => ({
  newChannel: null,
  facebookPages: null,
});

const state = getDefaultState();

const actions = {
  initLocalStore: ({ commit, state }) => {
    const keys = Object.keys(state);
    commit(INIT_LOCAL_STORE, keys);
  },
  setStorageItem: ({ commit }, payload = {}) => {
    commit(SET_STORAGE_ITEM, payload);
  },
  getStorageItem: (context, key = "") => {
    return new Promise((resolve) => {
      resolve(localStorage.getItem(key));
    });
  },
  getJSONStorageItem: (context, key = "") => {
    return new Promise((resolve) => {
      resolve(JSON.parse(localStorage.getItem(key)));
    });
  },
  removeStorageItem: ({ commit }, key = "") => {
    commit(REMOVE_STORAGE_ITEM, key);
  },
  resetLocalStorage: ({ commit }) => {
    commit(RESET_LOCAL_STORAGE);
  },
};

const mutations = {
  [INIT_LOCAL_STORE]: (state, keys) => {
    keys.forEach((key) => {
      const value = localStorage.getItem(key);
      if (value) {
        state[key] = value;
      }
    });
  },
  [SET_STORAGE_ITEM]: (state, payload) => {
    const { key, value } = payload;
    localStorage.setItem(key, value);
    state[key] = value;
  },
  [REMOVE_STORAGE_ITEM]: (state, key) => {
    localStorage.removeItem(key);
    state[key] = null;
  },
  [RESET_LOCAL_STORAGE]: (state) => {
    const keys = Object.keys(state);
    keys.forEach((key) => {
      localStorage.removeItem(key);
    });
    state.facebookPages = null;
  },
  [RESET_MODULE_STATE]: (state) => {
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    // This mutation is called from the logout action!
    Object.assign(state, getDefaultState());
  },
};

export default {
  state,
  actions,
  mutations,
};
