<template>
  <div class="d-flex d-flex-c profile-content">
    <div class="d-flex profile-button">
      <content-button
        v-if="currentRouteName === 'Teams'"
        label="Create team"
        @button-click="
          setActiveProfileModal({
            name: 'CreateTeamModal',
          })
        "
      />
    </div>
    <keep-alive>
      <content-wrapper
        :title="`${
          currentRouteName === 'Settings' ? 'Account' : currentRouteName
        } Settings`"
      >
        <div slot="content" style="height: 100%">
          <component v-bind:is="currentRouteName" />
        </div>
      </content-wrapper>
    </keep-alive>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import {
  ContentWrapper,
  Settings,
  Subscription,
  Teams,
} from "@/components/profile/content";
import { ContentButton } from "@/components/ui/";
export default {
  name: "ProfileContent",
  components: { ContentWrapper, ContentButton, Settings, Subscription, Teams },
  computed: {
    currentRouteName() {
      return this.$route.name.replace(
        /^(\w)(.+)/,
        (match, i, j) => i.toUpperCase() + j.toLowerCase(),
      );
    },
  },
  methods: {
    ...mapActions({
      setActiveProfileModal: "setActiveProfileModal",
    }),
  },
};
</script>
<style lang="scss" scoped>
.profile-content {
  flex: 4;
  position: relative;
  padding: 7em 0 5em;
  z-index: 100;
}

.profile-button {
  position: absolute;
  top: 2em;
  right: 0;
}
</style>
