<template>
  <div
    :class="[
      'd-flex d-flex-r type-card mb-05',
      type,
      { 'is-selected': isSelected, 'is-disabled': disabled },
    ]"
    @click="handleClick"
  >
    <div class="type-card-image ml-1 mr-1"></div>
    <div class="d-flex d-flex-c d-justify-center type-card-info">
      <span class="type-card-title mb-1"
        ><b>{{ title }}</b></span
      >
      <span class="type-card-detail">{{ detail }}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "TypeCard",
  props: {
    type: { type: String, default: "" },
    title: { type: String, default: "" },
    detail: { type: String, default: "" },
    isSelected: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  methods: {
    handleClick() {
      if (!this.disabled) {
        this.$emit("type-card-click");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.type-card {
  padding: 1em 2em;
  min-height: 120px;
  background-color: white;
  border-radius: 6px;
  cursor: pointer;

  .type-card-image {
    min-height: 58px;
    max-height: 58px;
    min-width: 50px;
    border-radius: 6px;
    background-repeat: no-repeat;
  }

  .type-card-title {
    text-transform: capitalize;
  }

  &.personal {
    .type-card-image {
      background-image: url("/assets/images/icons/dashboard/channels/channel_profile.webp");
    }
  }

  &.company {
    .type-card-image {
      background-image: url("/assets/images/icons/dashboard/channels/channel_page.webp");
    }
  }
  &.maintainer {
    .type-card-image {
      background-image: url("/assets/images/icons/dashboard/teams/role_maintainer.webp");
    }
  }

  &.editor {
    .type-card-image {
      background-image: url("/assets/images/icons/dashboard/teams/role_editor.webp");
    }
  }
  &.client {
    .type-card-image {
      background-image: url("/assets/images/icons/dashboard/teams/role_client.webp");
    }
  }

  &.viewer {
    .type-card-image {
      background-image: url("/assets/images/icons/dashboard/teams/role_viewer.webp");
    }
  }

  &.is-selected {
    background-color: #d5daef;

    &::before {
      background-color: black;
    }
  }

  &.is-disabled {
    opacity: 0.5;
    cursor: default;

    &:hover {
      background-color: white;
    }
  }

  &:hover {
    background-color: #aab4db;
  }

  &::before {
    display: flex;
    align-self: center;
    content: "";
    min-width: 20px;
    min-height: 20px;
    margin-right: 1em;
    background-color: white;
    border: 5px solid white;
    border-radius: 100%;
    outline: 1px solid #ccc;
  }
}
</style>
