/** store/index.js **/

import Vue from "vue";
import Vuex from "vuex";

// import the auto exporter
import modules from "./modules";
import plugins from "./plugins";

Vue.use(Vuex);

export default new Vuex.Store({
  modules,
  plugins, //: [socket()],
});
