<template>
  <div class="content-display-wrapper">
    <div
      class="picture"
      :style="setBg(picture_url)"
      @click="$emit('display-click')"
    >
      <span :class="['channel-type', channel_type.toLowerCase()]" />
    </div>
    <label class="content-display-label">{{ channel_name }}</label>
  </div>
</template>
<script>
export default {
  props: {
    picture_url: { type: String, default: "" },
    channel_name: { type: String, default: "" },
    channel_type: { type: String },
  },
  methods: {
    setBg(background) {
      // console.log(background);
      return background && background.length > 0
        ? `background-image: url("${background}")`
        : "background-image: url('/assets/images/layout/dashboard/common/content_bg.svg')";
    },
  },
};
</script>
<style lang="scss" scoped>
.content-display-wrapper {
  margin: 0 0 2em 0;
  flex-basis: 33%;
  cursor: pointer;
  .picture {
    display: block;
    position: relative;
    width: 205px;
    height: 220px;
    margin: 0 auto 0.5em;
    border-radius: 6px;
    background-size: cover;
    background-position: center center;
    background-image: url("/assets/images/layout/dashboard/common/content_bg.svg");

    .channel-type {
      position: absolute;
      height: 24px;
      width: 24px;
      top: 10px;
      right: 10px;

      &.facebook {
        background-image: url("/assets/images/icons/social/facebook_channel.png");
      }
      &.twitter {
        background-image: url("/assets/images/icons/social/twitter_channel.png");
      }
      &.instagram {
        background-image: url("/assets/images/icons/social/instagram_channel.png");
      }
      &.linkedin {
        background-image: url("/assets/images/icons/social/linkedin_channel.png");
      }
    }
    &:hover {
      opacity: 0.75;
    }
  }

  .content-display-label {
    display: block;
    width: 205px;
    margin: 0 auto;
    text-align: center;
    overflow-wrap: break-word;
  }
}
</style>
