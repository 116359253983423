var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'd-flex d-flex-between day-wrapper',
    {
      '--within-range': _vm.isWithinRange,
      '--is-first': _vm.isFirst,
      '--is-last': _vm.isLast,
      '--is-empty': _vm.isEmpty,
      '--is-selected': _vm.isSelected,
    },
  ],on:{"click":_vm.handleClick}},[_vm._v(" "+_vm._s(_vm.dayLabel)+" ")])
}
var staticRenderFns = []

export { render, staticRenderFns }