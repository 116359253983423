<template>
  <modal :title="modalTitle" @close="closeModal">
    <div
      v-if="previewSubscriptionRequesting"
      class="d-flex d-flex-center flex-1"
      slot="modal-content"
    >
      <loader style="margin-top: 2em" />
    </div>
    <div v-else slot="modal-content" class="modal-content-wrapper">
      <div v-if="requestSuccess" class="d-flex d-flex-c d-flex-center">
        <span class="sucess-text mt-2 mb-1"
          >Successfully updated subscription!</span
        >
        <span class="mb-2"
          >New plan: {{ planDetails.subscriptionLabel }} billed
          {{ interval }}</span
        >
        <button class="settings-button" @click="closeModal">Close</button>
      </div>
      <div v-else class="d-flex d-flex-c">
        <div class="d-flex d-flex-c">
          <div
            class="d-flex d-flex-r d-flex-between mt-2"
            style="min-width: 600px"
          >
            <div class="d-flex d-flex-c mr-5">
              <div class="d-flex d-flex-c mb-1">
                <label><b>Current plan</b></label>
                <p>
                  {{ planDetails.currentSubscription }}
                </p>
              </div>
              <div class="d-flex d-flex-c mb-1">
                <label><b>New plan</b></label>
                <p>
                  {{ planDetails.subscriptionLabel }}
                </p>
              </div>
              <div class="d-flex d-flex-c mb-1">
                <label><b>Start invoice date</b></label>
                <p v-if="billingDate">{{ billingDate }}</p>
              </div>
              <div v-if="refundAmount" class="d-flex d-flex-c mb-1">
                <label><b>Current plan remaining credit(refund)</b></label>
                <p v-if="refundAmount">{{ refundAmount }}</p>
              </div>
              <div class="d-flex d-flex-c">
                <label><b>Total cost for new plan: </b></label>
                <p>
                  {{ planPrice }}
                </p>
              </div>
            </div>
            <span class="img-wrapper">
              <img
                :src="`/assets/images/layout/subscription/${plan}_l.svg`"
                width="175"
            /></span>
          </div>
        </div>
        <div class="d-flex d-flex-r d-flex-between align-center mt-3">
          <button class="settings-button" @click="closeModal">Cancel</button>
          <socianis-button
            label="Subscribe"
            @button-click="subscribe"
            :disabled="updateSubscriptionRequesting"
          />
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
import { mapState, mapActions } from "vuex";
import dayjs from "@/utils/timezone";

import { Modal, SocianisButton, Loader } from "@/components";

const currencySymbols = {
  eur: "€",
};
export default {
  name: "ChangePlanModal",
  components: { Modal, SocianisButton, Loader },
  props: {
    plan: { type: String, default: "" },
    interval: { type: String, default: "" },
    planDetails: { type: Object, default: () => ({}) },
  },
  async created() {
    const previewData = await this.previewSubscriptionUpdate({
      tier: this.plan,
      interval: this.interval,
    });
    this.billingDate = dayjs(previewData.start_date).format("DD MMM, YYYY");
    this.price = previewData.price;
    this.refundAmount = previewData.refund_amount;
  },
  data() {
    return {
      requestSuccess: false,

      price: 0,
      billingDate: null,
      refundAmount: null,
    };
  },
  computed: {
    ...mapState({
      previewSubscriptionRequesting: (state) =>
        state.subscription.status.requesting.previewSubscriptionUpdate,
      updateSubscriptionRequesting: (state) =>
        state.subscription.status.requesting.updateSubscription,
    }),
    modalTitle() {
      return this.previewSubscriptionRequesting
        ? ""
        : "Subscription summary for your new plan";
    },
    planPrice() {
      if (this.price === "free") {
        return this.$i18n.t(`ui.trial_free`);
      } else {
        return `${currencySymbols[this.planDetails.currency.toLowerCase()]} ${
          this.price / 100
        }`;
      }
    },
  },
  methods: {
    ...mapActions({
      previewSubscriptionUpdate: "previewSubscriptionUpdate",
      updateSubscription: "updateSubscription",
    }),
    subscribe() {
      this.updateSubscription({
        tier: this.plan,
        recurring_interval: this.interval,
      }).then((res) => {
        this.requestSuccess = true;
      });
    },
    closeModal() {
      this.$emit("close-subscription-modal");
    },
  },
};
</script>

<style lang="scss" scoped></style>
