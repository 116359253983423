<template>
  <div class="account-settings">
    <content-row :hideDivider="true">
      <div slot="content-left">
        <div class="d-flex d-flex-c">
          <single-image-upload
            ref="profileAvatar"
            class="mb-2"
            :initialThumbnail="user.avatar"
            :round="true"
            :hideIcon="true"
            :folder="uploadContext"
            @image-file-upload="onAvatarChange"
          />
          <socianis-button
            v-if="avatarChanged"
            @button-click="updateAvatar"
            label="Update"
            class="d-flex-center"
          />
        </div>
      </div>
      <div slot="content-right">
        <button class="settings-button" @click="changeAvatar">
          Change picture
        </button>
      </div>
    </content-row>
    <content-row title="Personal information">
      <div slot="content-left">
        <content-col label="Full name" :text="user.full_name" />
      </div>
      <div slot="content-right">
        <button
          class="settings-button"
          @click="
            setActiveProfileModal({
              name: 'ChangeFullNameModal',
              props: {
                setting: 'full_name',
                fields: [
                  {
                    name: 'full_name',
                    label: 'Full Name',
                    value: user.full_name,
                    rules: 'required',
                  },
                ],
              },
            })
          "
        >
          Edit
        </button>
      </div>
    </content-row>
    <content-row>
      <div slot="content-left">
        <content-col label="Email" :text="user.email" />
      </div>
    </content-row>
    <content-row>
      <div slot="content-left">
        <content-col label="Password" text="*********" />
      </div>
      <div slot="content-right">
        <button
          class="settings-button"
          @click="
            setActiveProfileModal({
              name: 'ChangePasswordModal',
              props: {
                setting: 'password',
                fields: [
                  {
                    name: 'password',
                    label: 'Current password',
                    rules: 'required',
                  },
                  {
                    name: 'new_password',
                    label: 'New password',
                    rules: 'required',
                  },
                ],
              },
            })
          "
        >
          Change password
        </button>
      </div>
    </content-row>
    <content-row class="mt-5" title="Time Zone">
      <div slot="content-left">
        <content-col label="Time Zone" :text="user.timezone" />
      </div>
      <div slot="content-right">
        <button
          class="settings-button"
          @click="
            setActiveProfileModal({
              name: 'ChangeTimezoneModal',
              props: {
                initalZone: { value: user.timezone },
              },
            })
          "
        >
          Change Time Zone
        </button>
      </div>
    </content-row>
    <!-- <content-row class="delete mt-5" title="Delete account">
      <div slot="content-left">
        <span style="color: rgb(255, 93, 103)"
          >Deleting your Socianis account will remove all your data.</span
        >
      </div>
      <div slot="content-right">
        <button class="settings-button" @click="changeAvatar">
          Delete account
        </button>
      </div>
    </content-row> -->
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { SingleImageUpload, SocianisButton } from "@/components/ui/";
import { ContentRow, ContentCol } from "./";

export default {
  name: "Settings",
  components: { ContentRow, SingleImageUpload, SocianisButton, ContentCol },
  data() {
    return {
      avatarChanged: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.dashboard.user,
      uploadContext: (state) => state.dashboard.user.id,
    }),
  },
  methods: {
    ...mapActions({
      updateProfile: "updateProfile",
      setActiveProfileModal: "setActiveProfileModal",
    }),
    changeAvatar() {
      this.$refs["profileAvatar"].$el
        .getElementsByClassName("image-file-input")[0]
        .click();
    },
    onAvatarChange() {
      this.avatarChanged = true;
    },
    async updateAvatar() {
      const response = await this.$refs["profileAvatar"].save();
      const avatar = response;
      this.updateProfile({ avatar }).then(() => {
        this.avatarChanged = false;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.profile-picture {
  height: 100px;
  width: 100px;
  border-radius: 100%;
  border: solid 1px #ccc;
}
.delete {
  /deep/ .row-title {
    color: rgb(255, 93, 103);
  }
}
</style>
