import Vue from "vue";
import VueI18n from "vue-i18n";

import CONFIG from "../config";

Vue.use(VueI18n);

const loadLocaleMessages = () => {
  const localeFiles = require.context(".", false, /\.locale\.js$/);
  const messages = {};

  localeFiles.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);

    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = localeFiles(key).default || localeFiles(key);
    }
  });

  return messages;
};

export default new VueI18n({
  locale: CONFIG.VUE_APP_I18N_LOCALE || "en-US",
  fallbackLocale: CONFIG.VUE_APP_I18N_FALLBACK_LOCALE || "en-US",
  messages: loadLocaleMessages(),
});
