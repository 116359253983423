<template>
  <div class="wrapper">
    <label class="title">Time</label>
    <div class="time-container">
      <drop-down
        :items="hours"
        @select-event="setSchedulePostHour"
        :placeholder="selectedHour"
      />
      :
      <drop-down
        :items="minutes"
        @select-event="setSchedulePostMinute"
        :placeholder="selectedMinute"
      />
      <drop-down
        :items="time"
        @select-event="setSchedulePostTime"
        :placeholder="selectedTime"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import DropDown from "@/components/dashboard/ui/DropDown.vue";

const hours = new Array(13)
  .fill("")
  .map((_, index) => {
    if (index > 0) {
      return `${index}`;
    }
  })
  .filter((hour) => hour !== undefined); // remove the undefined indexes from array;

const minutes = new Array(60)
  .fill("")
  .map((_, index) => {
    if (index % 5 === 0) {
      if (index < 10) {
        return `0${index}`;
      } else {
        return `${index}`;
      }
    }
  })
  .filter((minute) => minute !== undefined); // remove the undefined indexes from array

const time = ["AM", "PM"];

export default {
  components: { DropDown },
  props: {
    startDate: { type: [Object, String] },
    timezone: { type: String, default: "" },
    timeInfo: {
      type: Object,
      default: () => ({ hour: 1, minute: 0, time: "AM" }),
    },
  },
  data() {
    return {
      hours,
      minutes,
      time,
      currentTime: "AM",
    };
  },
  computed: {
    ...mapState({
      postScheduleTime: (state) =>
        state.schedule.scheduleModalSettings.selectedDate,
    }),
    selectedHour() {
      if (this.timeInfo.hour <= 12) {
        return this.timeInfo.hour;
      } else {
        return this.timeInfo.hour - 12;
      }
    },
    selectedMinute() {
      return this.timeInfo.minute;
    },
    selectedTime() {
      return this.timeInfo.time;
    },
  },
  created() {
    if (this.startDate) {
      this.setScheduleDate(this.startDate);
    }
  },
  methods: {
    ...mapActions({
      setScheduleDate: "setScheduleDate",
    }),
    setSchedulePostHour(hour) {
      const parsedHour = parseInt(hour);
      let newHour;

      if (
        (this.selectedTime === "AM" && parsedHour < 12) ||
        (this.selectedTime === "PM" && parsedHour === 12)
      ) {
        newHour = parsedHour;
      } else if (this.selectedTime === "AM" && parsedHour === 12) {
        newHour = 0;
      } else if (this.selectedTime === "PM" && parsedHour < 12) {
        newHour = parsedHour + 12;
      }

      this.$emit("set-time", "hour", newHour);
    },
    setSchedulePostMinute(minute) {
      this.$emit("set-time", "minute", minute);
    },
    setSchedulePostTime(time) {
      if (time !== this.selectedTime) {
        const currentScheduledHour = this.timeInfo.hour;
        let newScheduledHour;

        if (time === "AM" && currentScheduledHour >= 12) {
          newScheduledHour = currentScheduledHour - 12;
        } else if (time === "PM" && currentScheduledHour < 12) {
          newScheduledHour = currentScheduledHour + 12;
        }
        this.currentTime = time;

        this.$emit("set-time", "time", time);
        this.$emit("set-time", "hour", newScheduledHour);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;

  .title {
    font-weight: 600;
    font-size: 1.15rem;
    margin-bottom: 0.5em;
  }

  .time-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 225px;
  }
}
</style>
