<template>
  <div class="d-flex d-flex-c feature-wrapper">
    <!-- <div class="d-flex d-flex-c mb-1">
      <label class="feature-title mb-05">Best time</label>
      <div class="d-flex d-flex-r d-flex-between">
        <label>Post when most users are active</label>
        <rounded-switch :value="bestTime" />
      </div>
    </div> -->
    <div class="d-flex d-flex-c mb-2">
      <label class="feature-title mb-05">Repeat</label>
      <div class="d-flex d-flex-r d-flex-between">
        <div class="d-flex d-flex-r d-flex-between" style="width: 75%">
          <div
            class="d-flex d-flex-c"
            v-for="(frequency, index) in filteredFrequencies"
            :key="index"
          >
            <label :for="frequency.value" class="mb-05">{{
              frequency.label
            }}</label>
            <div
              :class="[
                'd-flex d-flex-center c-radio',
                {
                  'is-selected': frequency.enabled,
                  disabled: !repeatEnabled,
                },
              ]"
              @click="selectFrequency(frequency.value)"
            ></div>
          </div>
        </div>
        <rounded-switch @button-click="toggleRepeat" v-model="repeatEnabled" />
      </div>
    </div>
    <div
      v-if="repeatFrequency.some((r) => r.value === 'weekly' && r.enabled)"
      class="d-flex d-flex-c mb-2"
    >
      <label class="feature-title mb-05">Only on</label>
      <div class="d-flex d-flex-r d-flex-between">
        <div class="d-flex d-flex-c" v-for="(day, index) in dow" :key="index">
          <label :for="day.value" class="mb-05">{{ day.label }}</label>
          <div
            :class="[
              'd-flex d-flex-center c-check',
              {
                'is-selected': repeatDays.includes(day.value),
                disabled: !repeatEnabled,
              },
            ]"
            @click="alterRepeatDays(day.value, !repeatDays.includes(day.value))"
          ></div>
        </div>
      </div>
    </div>
    <div class="d-flex d-flex-c">
      <label class="feature-title mb-1">End date</label>
      <div class="d-flex d-flex-r d-flex-between">
        <div class="d-flex d-flex-r date-container">
          <drop-down
            class="day"
            :disabled="!endDateEnabled"
            :placeholder="selectedDay"
            :items="days"
            @select-event="selectEndDay"
          />
          <drop-down
            class="month"
            :disabled="!endDateEnabled"
            :placeholder="selectedMonth"
            :items="months"
            @select-event="selectEndMonth"
          />
          <drop-down
            class="year"
            :disabled="!endDateEnabled"
            :placeholder="selectedYear"
            :items="years"
            @select-event="selectEndYear"
          />
        </div>
        <rounded-switch
          @button-click="toggleEndDate"
          v-model="endDateEnabled"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import dayjs from "@/utils/timezone";
import { eventBus } from "@/utils/event-bus";
import { RoundedSwitch } from "@/components";
import DropDown from "@/components/dashboard/ui/DropDown.vue";

const repeatIntervals = [
  { label: "Never", value: "never", enabled: false },
  { label: "Every day", value: "daily", enabled: false },
  { label: "Week", value: "weekly", enabled: false },
  { label: "Month", value: "monthly", enabled: false },
  { label: "Year", value: "yearly", enabled: false },
];

const dow = [
  { label: "Sun", value: 0 },
  { label: "Mon", value: 1 },
  { label: "Tue", value: 2 },
  { label: "Wed", value: 3 },
  { label: "Thu", value: 4 },
  { label: "Fri", value: 5 },
  { label: "Sat", value: 6 },
];

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const years = [...Array(3)].map((_, index) => {
  if (index > 0) {
    return dayjs().year() + index;
  }
  return dayjs().year();
});

export default {
  name: "RepeatSettings",
  components: { RoundedSwitch, DropDown },
  props: {
    repeatInfo: { type: Object, default: () => ({}) },
  },
  created() {
    eventBus.$on("confirm-repeat-settings", this.listener);
  },
  beforeDestroy() {
    eventBus.$off("confirm-repeat-settings", this.listener);
  },
  data() {
    const {
      repeatInfo: { frequency, repeat_weekdays, end_date },
    } = this;

    return {
      dow,
      months,
      years,
      endDateEnabled: !!end_date && frequency !== "never",
      repeatEnabled: !!frequency && frequency !== "never",
      repeatFrequency: repeatIntervals.map((i) => {
        return {
          ...i,
          enabled: i.value === frequency,
        };
      }),
      repeatDays: repeat_weekdays ? repeat_weekdays.slice() : [],
      endDate: end_date ? dayjs(end_date) : dayjs().hour(0).minute(0).second(0),
      dateSelected: false, // flag necessary to determine whether the placeholder should be the provided date or default to text representation
    };
  },
  computed: {
    filteredFrequencies() {
      return this.repeatFrequency.filter((freq) => freq.value !== "never");
    },
    days() {
      return [...Array(this.endDate.daysInMonth())].map(
        (_, index) => index + 1,
      );
    },
    selectedDay() {
      return !this.repeatInfo.end_date ? "Day" : this.endDate.date();
    },
    selectedMonth() {
      return !this.repeatInfo.end_date ? "Month" : months[this.endDate.month()];
    },
    selectedYear() {
      return !this.repeatInfo.end_date ? "Year" : this.endDate.year();
    },
  },
  methods: {
    ...mapActions({
      confirmRepeatSettings: "confirmRepeatSettings",
    }),
    selectFrequency(frequency) {
      if (this.repeatEnabled) {
        this.repeatFrequency = repeatIntervals.map((i) => {
          return {
            ...i,
            enabled: i.value === frequency,
          };
        });
      }
    },
    alterRepeatDays(day, value) {
      if (this.repeatEnabled) {
        if (value) {
          this.repeatDays.push(day);
        } else {
          this.repeatDays = this.repeatDays.filter((d) => d !== day);
        }
      }
    },
    toggleRepeat(value) {
      if (!value) {
        this.endDateEnabled = false;
        this.repeatFrequency = repeatIntervals.map((i) => {
          return {
            ...i,
            enabled: i.value === "never",
          };
        });
      }
      if (this.repeatInfo.frequency === "never" && value) {
        this.repeatFrequency = repeatIntervals.map((i) => {
          return {
            ...i,
            enabled: i.value === "daily",
          };
        });
      } else if (this.repeatInfo.frequency !== "never" && value) {
        this.repeatFrequency = repeatIntervals.map((i) => {
          return {
            ...i,
            enabled: i.value === this.repeatInfo.frequency,
          };
        });
      }

      this.repeatEnabled = value;
    },
    toggleEndDate() {
      if (this.repeatEnabled) {
        this.endDateEnabled = !this.endDateEnabled;
      }
    },
    selectEndDay(value) {
      if (this.endDateEnabled) {
        this.endDate = dayjs(this.endDate).endOf("day").date(value);
      }
    },
    selectEndMonth(value, index) {
      if (this.endDateEnabled) {
        this.endDate = dayjs(this.endDate).endOf("day").month(index);
      }
    },
    selectEndYear(value) {
      if (this.endDateEnabled) {
        this.endDate = dayjs(this.endDate).endOf("day").year(value);
      }
    },
    listener() {
      const frequency =
        this.repeatFrequency
          .slice()
          .reverse()
          .find((i) => i.enabled) || {};
      const payload = {
        frequency: frequency.value,
        repeat_type: frequency.value,
        end_date: this.endDateEnabled ? this.endDate : null,
        repeat_weekdays: this.repeatDays,
      };
      this.confirmRepeatSettings(payload);
    },
  },
};
</script>
<style lang="scss" scoped>
.feature-wrapper {
  width: 400px;
  padding: 1em;
  background-color: #221c55;
  border-radius: 6px;
  color: white;

  .feature-title {
    font-weight: 600;
  }
  .c-radio,
  .c-check {
    &::after {
      display: block;
      content: "";
      width: 15px;
      height: 15px;
      background-color: white;
      border: 4px solid white;
      cursor: pointer;
    }

    &.disabled {
      &::after {
        opacity: 0.5;
        cursor: default;
      }
    }
  }
  .c-radio,
  .c-check {
    &.is-selected {
      &::after {
        background-color: #221c55;
      }
    }
  }
  .c-radio {
    &::after {
      border-radius: 100%;
    }
  }
  .date-container {
    color: black;
    /deep/ .select-wrapper {
      justify-content: flex-start;
    }
    .day {
      /deep/ .select-wrapper {
        width: 75px !important;
      }
    }
    .month {
      /deep/ .select-wrapper {
        width: 95px;
        margin: 0 1em;
      }
    }

    .year {
      /deep/ .select-wrapper {
        width: 82.5px;
      }
    }
  }
}
</style>
