export const GET_CHANNELS_REQUEST = "GET_CHANNELS_REQUEST";
export const GET_CHANNELS_SUCCESS = "GET_CHANNELS_SUCCESS";
export const GET_CHANNELS_ERROR = "GET_CHANNELS_ERROR";

export const UPDATE_CHANNEL_REQUEST = "UPDATE_CHANNEL_REQUEST";
export const UPDATE_CHANNEL_SUCCESS = "UPDATE_CHANNEL_SUCCESS";
export const UPDATE_CHANNEL_ERROR = "UPDATE_CHANNEL_ERROR";

export const DELETE_CHANNEL_REQUEST = "DELETE_CHANNEL_REQUEST";
export const DELETE_CHANNEL_SUCCESS = "DELETE_CHANNEL_SUCCESS";
export const DELETE_CHANNEL_ERROR = "DELETE_CHANNEL_ERROR";

export const GET_CHANNEL_AUTH_URL_REQUEST = "GET_CHANNEL_AUTH_URL_REQUEST";
export const GET_CHANNEL_AUTH_URL_SUCCESS = "GET_CHANNEL_AUTH_URL_SUCCESS";
export const GET_CHANNEL_AUTH_URL_ERROR = "GET_CHANNEL_AUTH_URL_ERROR";

export const ADD_CHANNEL_REQUEST = "ADD_CHANNEL_REQUEST";
export const ADD_CHANNEL_SUCCESS = "ADD_CHANNEL_SUCCESS";
export const ADD_CHANNEL_ERROR = "ADD_CHANNEL_ERROR";

export const DELETE_CACHED_PAGES_REQUEST = "DELETE_CACHED_PAGES_REQUEST";
export const DELETE_CACHED_PAGES_SUCCESS = "DELETE_CACHED_PAGES_SUCCESS";
export const DELETE_CACHED_PAGES_ERROR = "DELETE_CACHED_PAGES_ERROR";

export const ADD_CHANNEL_PAGE_REQUEST = "ADD_CHANNEL_PAGE_REQUEST";
export const ADD_CHANNEL_PAGE_SUCCESS = "ADD_CHANNEL_PAGE_SUCCESS";
export const ADD_CHANNEL_PAGE_ERROR = "ADD_CHANNEL_PAGE_ERROR";

export const UPDATE_CHANNEL_STATE = "UPDATE_CHANNEL_STATE";

export const SUB_CHANNEL_SELECTION = "SUB_CHANNEL_SELECTION"; // For Facebook pages
