<template>
  <div id="main-layout">
    <router-view />
  </div>
</template>

<script>
import { mapActions } from "vuex";
// import { NEW_CHANNEL, FACEBOOK_PAGES } from "@/utils/constants";

export default {
  name: "Layout",
  async created() {
    this.initLocalStore();
    this.initSessionStore();
  },
  mounted() {
    // window.onstorage = async ({ key, newValue }) => {
    //   console.log(this.platformPopup, key, newValue);
    //   if (!this.platformPopup) {
    //     if (!newValue) {
    //       console.log("removvveee", key);
    //       this.removeStorageItem(key);
    //     }
    //     if (key === NEW_CHANNEL) {
    //       const channel = await this.getJSONStorageItem(NEW_CHANNEL);
    //       this.updateChannelState(channel);
    //       this.removeStorageItem(NEW_CHANNEL);
    //       this.togglePagesSelect();
    //     }
    //     if (key === FACEBOOK_PAGES) {
    //       this.togglePagesSelect();
    //     }
    //   }
    // };
  },
  methods: {
    ...mapActions({
      invalidateCookie: "invalidateCookie",
      initLocalStore: "initLocalStore",
      initSessionStore: "initSessionStore",
      // removeStorageItem: "removeStorageItem",
      // getJSONStorageItem: "getJSONStorageItem",
      // updateChannelState: "updateChannelState",
      // togglePagesSelect: "togglePagesSelect",
    }),
  },
};
</script>

<style lang="scss" scoped>
#main-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  // min-width: 1280px;
  z-index: 1000;
  overflow-x: hidden;

  .dashboard,
  .profile,
  .subscription,
  .not-found,
  .team-invite {
    position: relative;
    min-width: 1280px;
    min-height: 720px;

    background-color: #eaeaef;
    // height: 100vh;
    z-index: 1000;

    /deep/ .modal {
      z-index: 1050;
    }
  }

  /deep/ button.settings-button {
    padding: 1em 2em;
    background-color: white;
    border: solid 1px #ccc;
    border-radius: 25px;
    letter-spacing: 1px;
  }
  // /deep/ .dashboard-button {
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   background-color: #221c55;
  //   height: 55px;
  //   padding: 1em 2.5em;
  //   margin-right: 0.5em;
  //   border: unset;
  //   border-radius: 6px;
  //   color: #fff;

  //   &:disabled {
  //     background-color: #46435c34;
  //     cursor: default;
  //   }
  // }
}
</style>
