<template>
  <div
    :class="[
      'd-flex d-flex-r sidebar',
      { toggled: toggled, 'is-expanded': expanded },
    ]"
    @mouseenter="() => setToggled(true)"
    @mouseleave="setToggled(false)"
  >
    <div class="d-flex d-flex-c d-flex-between main-menu-wrapper">
      <div class="d-flex d-flex-c">
        <div class="d-flex"></div>
        <div class="d-flex d-flex-c">
          <menu-logo class="mb-2" />
          <menu-item
            v-for="(item, index) in menuItems"
            :key="index"
            :is-selected="
              currentRoutePath === item.path ||
              (item.children &&
                item.children.map((i) => i.path).includes(currentRoutePath))
            "
            :is-focused="focusedItemName === item.class && expanded"
            :custom-class="item.class"
            :name="item.name"
            :path="item.path"
            :label="item.label"
            :childItems="item.hideSubMenu ? [] : item.children"
            @mouseenter.native="
              () => expandMenu(!item.hideSubMenu && item.children, item.name)
            "
            @mouseleave.native="() => setExpanded(false)"
          />
        </div>
      </div>
      <div class="d-flex d-flex-c d-flex-end">
        <profile-menu v-if="profileMenuToggled" class="mb-05" />
        <profile-item @toggle-profile-menu="toggleProfileMenu" />
      </div>
    </div>

    <div
      class="d-flex d-flex-c sub-menu-wrapper"
      @mouseenter="() => setExpanded(true)"
      @mouseleave="() => setExpanded(false)"
    >
      <div class="active-menu-title">
        <b>{{ focusedItemName }}</b>
      </div>
      <sub-menu-item
        v-for="(item, index) in subMenuItems"
        :key="index"
        :is-selected="currentRouteName === item.class"
        :custom-class="item.class"
        :name="item.name"
        :path="item.path"
        :label="item.label"
      />
    </div>
  </div>
</template>
<script>
import { dashboardMenuItems } from "@/utils/constants";

import {
  MenuItem,
  SubMenuItem,
  MenuLogo,
  ProfileItem,
  ProfileMenu,
} from "@/components/dashboard/menu";

export default {
  name: "SideBar",
  components: { MenuItem, SubMenuItem, MenuLogo, ProfileItem, ProfileMenu },
  data: () => ({
    toggled: false,
    expanded: false,
    profileMenuToggled: false,
    menuItems: dashboardMenuItems,
    subMenuItems: null,
    focusedItemName: null,
  }),
  computed: {
    currentRouteName() {
      return this.$route.name.toLowerCase();
    },
    currentRoutePath() {
      return this.$route.path;
    },
  },
  methods: {
    expandMenu(items, itemName) {
      if (items && items.length > 0) {
        this.subMenuItems = items;
        this.focusedItemName = itemName.toLowerCase();
        this.expanded = true;
      }
    },
    setExpanded(value) {
      this.expanded = value;
    },
    setToggled(value) {
      if (value === false) {
        this.profileMenuToggled = false;
      }
      this.toggled = value;
    },
    toggleProfileMenu(value) {
      this.profileMenuToggled = !this.profileMenuToggled;
    },
  },
};
</script>
<style lang="scss" scoped>
.sidebar {
  position: fixed;
  height: 100%;
  left: 0;
  text-transform: capitalize;
  z-index: 1025;

  &.is-expanded {
    .main-menu-wrapper {
      /deep/.menu-item {
        &.is-selected {
          background-color: #8f88bc;
        }
      }
    }
  }

  &:hover {
    .main-menu-wrapper {
      width: 300px;
    }
  }

  &.is-expanded,
  &.is-expanded:hover {
    .sub-menu-wrapper {
      width: 250px;
    }
  }

  .main-menu-wrapper {
    position: relative;
    background-color: hsl(246, 50%, 22%);
    padding: 1em 10px;
    width: 70px;
    min-height: 100vh;
    transition: width 0.25s ease-in-out;
    overflow-x: hidden;

    &:hover {
      /deep/.menu-item {
        &.is-selected {
          background-color: #8f88bc;
        }
      }
    }

    /deep/.menu-item {
      &.is-selected {
        background-color: unset;
        .image {
          background-color: #8f88bc;
        }
      }
    }
  }
  .menu {
    width: 75%;
    /deep/.item {
      width: 100%;
    }
  }

  .sub-menu-wrapper {
    position: relative;
    width: 0;
    background-color: #040026;
    transition: width 0.15s ease-in-out;
    overflow: hidden;

    .active-menu-title {
      margin: 1em 2em;
      font-size: 16px;
      color: white;
    }
  }
}
</style>
