var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"outside-click",rawName:"v-outside-click",value:(_vm.handleClose),expression:"handleClose"}],staticClass:"d-flex d-flex-c",on:{"click":function($event){$event.stopPropagation();}}},[(_vm.label)?_c('label',[_vm._v(_vm._s(_vm.label))]):_vm._e(),_c('div',{class:['select-wrapper', 'd-flex', 'd-flex-between', { toggled: _vm.toggled, wide: _vm.wide }],on:{"click":_vm.toggle}},[(_vm.selectedItem.type)?_c('span',{class:[
        'selected-item-bg',
        'item-bg',
        'social',
        _vm.selectedItem.type.toLowerCase(),
      ]}):_c('span',{staticClass:"selected-item-bg",style:(_vm.setBg(_vm.selectedItem.picture))}),_c('label',{staticClass:"selected-item"},[_c('span',[_vm._v(_vm._s(_vm.selectedItem.value))])]),_c('ul',{class:['select-items', { toggled: _vm.toggled, 'is-active': _vm.show }]},_vm._l((_vm.items),function(item,index){return _c('li',{key:index,class:[
          'select-item d-flex d-flex-start',

          {
            'default-item': item.key === 0,
            'is-selected': item.value === _vm.selectedItem.value,
            'is-radio': _vm.radio,
          },
        ],staticStyle:{"align-items":"center"},on:{"click":function($event){return _vm.selectItem(item)}}},[_c('div',{staticClass:"d-flex d-flex-between align-center",staticStyle:{"overflow":"hidden"}},[(item.type)?_c('span',{class:['item-bg', 'social', item.type.toLowerCase()]}):_c('span',{staticClass:"item-bg",style:(_vm.setBg(item.picture))}),_c('span',{staticClass:"ellipsis"},[_vm._v(_vm._s(item.value))])]),_c('span',[(_vm.checkEnabled)?_c('form-checkbox',{attrs:{"id":item.id,"name":item.key,"value":item.value,"is-selected":item.isSelected},on:{"change":_vm.checkboxSelect}}):_vm._e()],1)])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }