import {
  GET_PROJECTS_REQUEST,
  GET_PROJECTS_SUCCESS,
  GET_PROJECTS_ERROR,
  CREATE_PROJECT_REQUEST,
  CREATE_PROJECT_SUCCESS,
  CREATE_PROJECT_ERROR,
  UPDATE_PROJECT_REQUEST,
  UPDATE_PROJECT_SUCCESS,
  UPDATE_PROJECT_ERROR,
  DELETE_PROJECT_REQUEST,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_ERROR,
} from "../actions/project";

import { RESET_MODULE_STATE } from "../actions/global";

import { CREATE_NOTIFICATION } from "../actions/notification";

import {
  getProjects,
  createProject,
  updateProject,
  deleteProject,
} from "../../services/api/project.api";

const getDefaultState = () => ({
  projects: [],
  status: {
    requesting: {
      getProjects: false,
      createProject: false,
      updateProject: false,
      deleteProject: false,
    },
    errors: {
      getProjects: null,
      createProject: null,
      updateProject: null,
      deleteProject: null,
    },
  },
});

const state = getDefaultState();

const actions = {
  getProjects: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      commit(GET_PROJECTS_REQUEST);
      getProjects(params)
        .then((res) => {
          commit(GET_PROJECTS_SUCCESS, res.data);
          resolve(res.data);
        })
        .catch((error) => {
          commit(GET_PROJECTS_ERROR, error);
          reject(error);
        });
    });
  },
  createProject: ({ commit }, project) => {
    return new Promise((resolve, reject) => {
      commit(CREATE_PROJECT_REQUEST);
      createProject(project)
        .then((res) => {
          commit(CREATE_PROJECT_SUCCESS, res.data);
          resolve(res.data);
          commit(CREATE_NOTIFICATION, {
            type: CREATE_PROJECT_SUCCESS,
            status: "succeeded",
          });
        })
        .catch((error) => {
          commit(CREATE_PROJECT_ERROR, error);
          reject(error);
          commit(CREATE_NOTIFICATION, {
            type: CREATE_PROJECT_ERROR,
            status: "error",
          });
        });
    });
  },
  updateProject: ({ commit }, project) => {
    return new Promise((resolve, reject) => {
      commit(UPDATE_PROJECT_REQUEST);
      updateProject(project)
        .then((res) => {
          commit(UPDATE_PROJECT_SUCCESS, res.data);
          resolve(res.data);
          commit(CREATE_NOTIFICATION, {
            type: UPDATE_PROJECT_SUCCESS,
            status: "succeeded",
          });
        })
        .catch((error) => {
          commit(UPDATE_PROJECT_ERROR, error);
          reject(error);
          commit(CREATE_NOTIFICATION, {
            type: UPDATE_PROJECT_ERROR,
            status: "error",
          });
        });
    });
  },
  deleteProject: ({ commit }, project_id) => {
    return new Promise((resolve, reject) => {
      commit(DELETE_PROJECT_REQUEST);
      deleteProject(project_id)
        .then((res) => {
          commit(DELETE_PROJECT_SUCCESS, res.data);
          resolve(res.data);
          commit(CREATE_NOTIFICATION, {
            type: DELETE_PROJECT_SUCCESS,
            status: "succeeded",
          });
        })
        .catch((error) => {
          commit(DELETE_PROJECT_ERROR, error);
          reject(error);
          commit(CREATE_NOTIFICATION, {
            type: DELETE_PROJECT_ERROR,
            status: "error",
          });
        });
    });
  },
};

const mutations = {
  [GET_PROJECTS_REQUEST]: (state) => {
    state.status.requesting.getProjects = true;
  },
  [GET_PROJECTS_SUCCESS]: (state, projects) => {
    state.projects = projects;
    state.status.requesting.getProjects = false;
  },
  [GET_PROJECTS_ERROR]: (state, error) => {
    state.status.requesting.getProjects = false;
    state.status.errors.getProjects = error;
  },
  [CREATE_PROJECT_REQUEST]: (state) => {
    state.status.requesting.createProject = true;
  },
  [CREATE_PROJECT_SUCCESS]: (state, data) => {
    state.projects.push(data);
    state.status.requesting.createProject = false;
  },
  [CREATE_PROJECT_ERROR]: (state, error) => {
    state.status.requesting.createProject = false;
    state.status.errors.createProject = error;
  },
  [UPDATE_PROJECT_REQUEST]: (state) => {
    state.status.requesting.updateProject = true;
  },
  [UPDATE_PROJECT_SUCCESS]: (state, data) => {
    const { projects } = state;
    state.projects = projects.map((project) =>
      project.id === data.id ? data : project,
    );
    state.status.requesting.updateProject = false;
  },
  [UPDATE_PROJECT_ERROR]: (state, error) => {
    state.status.requesting.updateProject = false;
    state.status.errors.updateProject = error;
  },
  [DELETE_PROJECT_REQUEST]: (state) => {
    state.status.requesting.deleteProject = true;
  },
  [DELETE_PROJECT_SUCCESS]: (state, data) => {
    const { projects } = state;
    state.projects = projects.filter((project) => project.id !== data.id);
    state.status.requesting.deleteProject = false;
  },
  [DELETE_PROJECT_ERROR]: (state, error) => {
    state.status.requesting.deleteProject = false;
    state.status.errors.deleteProject = error;
  },
  [RESET_MODULE_STATE]: (state) => {
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    // This mutation is called from the logout action!
    Object.assign(state, getDefaultState());
  },
};

export default {
  state,
  actions,
  mutations,
};
