import {
  GET_CHANNEL_METRICS_REQUEST,
  GET_CHANNEL_METRICS_SUCCESS,
  GET_CHANNEL_METRICS_ERROR,
  SET_METRIC_PROJECT,
  SET_METRIC_CHANNEL,
  RESET_METRICS,
} from "../actions/analytics";

import { RESET_MODULE_STATE } from "../actions/global";

import { getChannelMetrics } from "../../services/api/analytics.api";

const getDefaultState = () => ({
  channelMetrics: [],
  currentMaxStartRange: null,
  currentMaxEndRange: null,
  selectedProjectId: null,
  selectedChannelId: null,
  status: {
    requesting: {
      getChannelMetrics: false,
    },
    errors: {
      getChannelMetrics: null,
    },
  },
});

const state = getDefaultState();

const actions = {
  getChannelMetrics: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      const { channelId, params } = payload;
      commit(GET_CHANNEL_METRICS_REQUEST);
      getChannelMetrics(channelId, params)
        .then((res) => {
          commit(GET_CHANNEL_METRICS_SUCCESS, res.data);
          resolve(res.data);
        })
        .catch((error) => {
          commit(GET_CHANNEL_METRICS_ERROR, error);
          reject(error);
        });
    });
  },
  setMetricProject({ commit }, projectId) {
    commit(SET_METRIC_PROJECT, projectId);
  },
  setMetricChannel({ commit }, channelId) {
    commit(SET_METRIC_CHANNEL, channelId);
  },
  resetMetrics({ commit }) {
    commit(RESET_METRICS);
  },
};

const mutations = {
  [GET_CHANNEL_METRICS_REQUEST]: (state) => {
    state.status.requesting.getChannelMetrics = true;
  },
  [GET_CHANNEL_METRICS_SUCCESS]: (state, data = {}) => {
    if (data) {
      state.channelMetrics = data;
      // const { roles, ...rest } = data;
      // state.roles = roles;
      // state.currentTeam = rest;
    }
    state.status.requesting.getChannelMetrics = false;
  },
  [GET_CHANNEL_METRICS_ERROR]: (state, error) => {
    state.status.requesting.getChannelMetrics = false;
    state.status.errors.getChannelMetrics = error;
  },
  [SET_METRIC_PROJECT]: (state, projectId) => {
    state.selectedProjectId = projectId;
  },
  [SET_METRIC_CHANNEL]: (state, channelId) => {
    state.selectedChannelId = channelId;
  },
  [RESET_METRICS]: (state) => {
    Object.assign(state, getDefaultState());
  },
  [RESET_MODULE_STATE]: (state) => {
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    // This mutation is called from the logout action!
    Object.assign(state, getDefaultState());
  },
};

export default {
  state,
  actions,
  mutations,
};
