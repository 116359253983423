import httpClient from "./httpClient";

const AI = "/ai";

export const optimizePlatformText = (payload = {}) =>
  httpClient.post(`${AI}/text/optimize`, payload);

export const generateHashtags = (payload = {}) =>
  httpClient.post(`${AI}/text/hashtag`, payload);

export const reviseText = (payload = {}) =>
  httpClient.post(`${AI}/text/revise`, payload);
