<template>
  <div class="d-flex button-wrapper">
    <div v-if="settingsToggled" class="d-flex d-flex-c menu-items-wrapper">
      <span
        v-for="(item, index) in items"
        :key="index"
        :class="[
          'menu-item',
          { [item.class]: item.class, 'is-disabled': item.enabled === false },
        ]"
        @click="() => handleItemSelect(item)"
      >
        {{ item.label }}
      </span>
    </div>
    <div class="blank-action-button" @click="toggleSettings">
      <label>{{ label }}</label>
    </div>
  </div>
</template>
<script>
export default {
  name: "BlankActionButton",
  props: {
    label: { type: String, default: "" },
    items: { type: Array, default: () => [] },
  },
  data() {
    return {
      settingsToggled: false,
    };
  },
  methods: {
    toggleSettings() {
      this.settingsToggled = !this.settingsToggled;
    },
    handleItemSelect(item) {
      if (item.enabled === false) {
        return;
      }
      this.settingsToggled = false;
      this.$emit("menu-item-select", item);
    },
  },
};
</script>
<style lang="scss" scoped>
.button-wrapper {
  .blank-action-button {
    position: relative;
    background-color: white;
    padding: 0.5em 2em 0.5em 1em;
    font-size: 1rem;
    border: solid 1px #ccc;
    border-radius: 52px;
    letter-spacing: 1px;
    font-size: 0.85rem;
    cursor: pointer;
    > * {
      cursor: pointer;
    }
    &:disabled {
      opacity: 0.7;
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      background-image: url("/assets/images/icons/dashboard/schedule/arrow_down_dark.svg");
      background-size: contain;
      width: 10px;
      height: 7px;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }

  .menu-items-wrapper {
    position: absolute;
    background-color: white;
    height: fit-content;
    min-width: 165px;
    box-shadow: 0px 0px 5px #7a7a7a29;
    border-radius: 3px;
    bottom: 50px;
    right: 0;
    font-size: 0.85rem;
    overflow: hidden;
    z-index: 100;

    > span {
      padding: 0.5em 2em;
      width: 100%;
      cursor: pointer;

      &.is-disabled {
        color: #ccc;
        cursor: not-allowed;

        &:hover {
          background-color: unset;
        }
      }

      &:hover {
        background-color: #aab4db;
      }

      &.delete,
      &.leave {
        color: red;
      }
    }
  }
}
</style>
