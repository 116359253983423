<template>
  <!-- <validation-observer
    v-slot="{ invalid, changed }"
    style="display: flex; flex: 1"
  > -->
  <div class="d-flex d-flex-c d-flex-between" style="flex: 1">
    <div class="d-flex d-flex-r">
      <div class="d-flex d-flex-c" style="flex: 1; padding-right: 15em">
        <form>
          <content-input
            name="channel_name"
            label="Channel name"
            :value="channelName"
            :disabled="true"
          />
        </form>
      </div>
    </div>
    <div class="d-flex d-flex-r d-flex-between">
      <content-button
        label="Delete channel"
        @button-click="removeChannel(channelId)"
        :disabled="isDeleting"
        style="background-color: #ff5d67"
      />
      <!-- <content-button
          label="Save"
          :disabled="!changed || invalid || isSaving"
          @button-click="submit"
        /> -->
    </div>
  </div>
  <!-- </validation-observer> -->
</template>

<script>
// import { ValidationObserver } from "vee-validate";
import { mapState, mapActions } from "vuex";
// import { eventBus } from "@/utils/event-bus";
import { ContentInput, ContentButton, TagsWrapper } from "@/components/ui/";

export default {
  components: {
    ContentInput,
    ContentButton,
    // TagsWrapper,
    // ValidationObserver,
  },
  props: {
    channelId: { type: String, default: null },
    channelName: { type: String, default: "" },
    // projects: { type: Array, default: () => [] },
  },
  // mounted() {
  //   eventBus.$on("alter-projects", (data = []) => {
  //     this.alteredProjects = this.allProjects
  //       .filter((channel) => data.includes(channel.id.toString()))
  //       .map((project) => {
  //         return {
  //           id: project.id,
  //           label: project.name,
  //           type: "",
  //         };
  //       });
  //   });
  // },
  // data() {
  //   return {
  //     alteredProjects: this.projects.map((project) => {
  //       return {
  //         id: project.id,
  //         label: project.name,
  //         type: "",
  //       };
  //     }),
  //   };
  // },
  computed: {
    ...mapState({
      // isSaving: (state) => state.channel.status.requesting.updateChannel,
      isDeleting: (state) => state.channel.status.requesting.updateChannel,
      // allProjects: (state) => state.project.projects,
    }),
  },
  methods: {
    ...mapActions({
      // updateChannel: "updateChannel",
      deleteChannel: "deleteChannel",
      // deleteProject: "deleteProject",
      // setActiveModal: "setActiveModal",
    }),
    removeChannel(channel_id) {
      this.deleteChannel(channel_id).then((res) => {
        this.$emit("update-selection");
      });
    },
    // async submit() {
    //   const defaultProjectIds = this.projects.map((project) => project.id);
    //   const updatedProjectIds = this.alteredProjects.map(
    //     (project) => project.id,
    //   );

    //   const payload = {
    //     id: this.channelId,
    //     name: this.channelName,
    //     projects: {
    //       projects_add: updatedProjectIds.filter(
    //         (id) => !defaultProjectIds.includes(id),
    //       ),
    //       projects_remove: defaultProjectIds.filter(
    //         (id) => !updatedProjectIds.includes(id),
    //       ),
    //     },
    //   };

    //   this.updateChannel(payload).then((updatedChannel) => {
    //     this.$emit("update-selection", updatedChannel);
    //   });
    // },
  },
};
</script>
