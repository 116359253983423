import {
  CREATE_NOTIFICATION,
  REMOVE_NOTIFICATION,
} from "../actions/notification";

import { RESET_MODULE_STATE } from "../actions/global";

import { notificationMessages } from "@/utils/notification-messages";
import { generateId } from "@/utils/url";

const getDefaultState = () => ({
  notifications: [],
});

const state = getDefaultState();

const actions = {
  createNotification: ({ commit }, payload) => {
    commit(CREATE_NOTIFICATION, payload);
  },
  removeNotification: ({ commit }, index) => {
    commit(REMOVE_NOTIFICATION, index);
  },
};

const mutations = {
  [CREATE_NOTIFICATION]: (state, payload) => {
    const notification = {
      id: generateId(),
      text: payload.text ? payload.text : notificationMessages[payload.type],
      status: payload.status,
    };
    state.notifications.unshift(notification);
  },
  [REMOVE_NOTIFICATION]: (state, id) => {
    state.notifications = state.notifications.filter(
      (notification) => notification.id !== id,
    );
  },
  [RESET_MODULE_STATE]: (state) => {
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    // This mutation is called from the logout action!
    Object.assign(state, getDefaultState());
  },
};

export default {
  state,
  actions,
  mutations,
};
