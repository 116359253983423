<template>
  <ValidationProvider
    v-slot="{ errors }"
    :mode="validationMode"
    :vid="name"
    :rules="rules"
  >
    <div class="form-wrapper">
      <label v-if="label" class="input-label" />
      <input
        v-model="value"
        :class="['c-input', { 'input-error': errors[0] }]"
        :type="type"
        :name="name"
        :placeholder="label"
      />
      <label :class="['input-placeholder', { 'input-error': errors[0] }]">{{
        label
      }}</label>
      <span class="input-error">{{ errors[0] }}</span>
    </div>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import "@/utils/validation";

export default {
  name: "FormInput",
  components: { ValidationProvider },
  props: {
    label: { type: String, default: "" },
    name: { type: String, default: "" },
    type: { type: String, default: "" },
    rules: { type: String, default: "" },
    validationMode: { type: String, default: "aggressive" },
  },
  data: () => ({ value: "" }),
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.form-wrapper {
  position: relative;

  .c-input {
    display: block;
    width: 100%;
    height: 45px;
    padding: 0 1.25em;
    background-color: #f1f9ff;
    border: 1px solid #66bbff;
    font-size: 0.8rem;
    color: #2699fb;
    border-radius: 6px;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: #2699fb;
      opacity: 0; /* Firefox */
    }

    &:-ms-input-placeholder {
      color: #2699fb;
    }

    &::-ms-input-placeholder {
      color: #2699fb;
    }
    &:not(:placeholder-shown) + .input-placeholder {
      top: -20px;
    }
  }

  .input-placeholder {
    position: absolute;
    font-size: 0.75rem;
    color: #2699fb;
    top: 15px;
    left: 16px;
    opacity: 1;
    transition: top 0.5s ease;
    margin-bottom: 0;

    &.input-error {
      color: red;
    }
  }

  input.input-error {
    background-color: #ffcece;
    border-color: red;
  }

  span.input-error {
    display: block;
    position: absolute;
    width: 100%;
    text-align: right;
    font-size: 0.75rem;
    color: red;
  }
}
@media only screen and (min-width: 1024px) {
  .form-wrapper {
    .input-label {
      margin-bottom: 1.5em !important;
    }
  }
}
</style>
