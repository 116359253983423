<template>
  <div class="d-flex d-flex-c menu">
    <router-link
      v-for="(item, index) in menu_items"
      :key="index"
      :class="[
        'item',
        item.class,
        { 'is-active': currentRouteName === item.name },
      ]"
      :to="`/profile/${item.name}`"
    >
      <span :class="item.class">{{ item.label }}</span>
    </router-link>
    <div v-if="displaySignout" class="item" @click="handleLogout">
      <span>Sign out</span>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "SidebarMenu",
  props: {
    // items prop is expected to be an array of objects
    menu_items: { type: Array, default: () => [] },
    displaySignout: { type: Boolean, default: true },
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    ...mapActions({
      logout: "logout",
    }),
    handleLogout() {
      this.logout().then((res) => {
        this.$router.push("/login");
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.menu {
  .item {
    width: 200px;
    padding: 0.5em 0.75em;
    background-color: white;
    color: black;
    cursor: pointer;

    &.is-active,
    &:hover {
      background-color: #aab4db;
    }

    &:first-child {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }
    &:last-child {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }
}
</style>
