<template>
  <modal title="Edit team name" @close="closeModal">
    <div slot="modal-content" class="modal-content-wrapper">
      <validation-observer
        ref="teamForm"
        v-slot="{ invalid }"
        class="d-flex d-flex-c d-flex-between"
        style="flex: 1"
      >
        <form
          id="form-teamName"
          class="d-flex d-flex-c d-flex-between mt-2"
          style="flex: 1"
          @submit.prevent
        >
          <content-input
            v-for="(field, index) in fields"
            :key="index"
            :name="field.name"
            :label="field.label"
            :rules="field.rules"
            v-model="field.value"
          />
          <div class="d-flex d-flex-r d-flex-end align-center mt-3">
            <dashboard-button
              label="Save"
              :is-requesting="updatingTeam"
              :invalid="invalid"
              @button-click="save"
            />
          </div>
        </form>
      </validation-observer>
    </div>
  </modal>
</template>
<script>
import { ValidationObserver } from "vee-validate";
import { mapActions, mapState } from "vuex";
import { Modal } from "@/components";
import { ContentInput, DashboardButton } from "@/components/ui/";

export default {
  name: "ChangeTeamNameModal",
  components: { Modal, ContentInput, DashboardButton, ValidationObserver },
  props: {
    teamId: { type: String, default: "" },
    fields: { type: Array, default: () => [] },
  },
  computed: {
    ...mapState({
      updatingTeam: (state) => state.team.status.requesting.updateTeam,
    }),
  },
  methods: {
    ...mapActions({
      updateTeam: "updateTeam",
    }),
    closeModal() {
      this.$emit("close-profile-modal");
    },
    save() {
      let data = {};
      const formData = new FormData(document.getElementById("form-teamName"));
      formData.forEach((value, key) => {
        data[key] = value;
      });

      const payload = {
        data,
        params: { team_id: this.teamId },
      };

      // console.log("payload; ", payload);
      this.updateTeam(payload).then((res) => {
        this.closeModal();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.modal-content-wrapper {
  display: flex;
  flex-direction: column;
  min-width: 400px;
  min-height: 250px;
}
</style>
