<template>
  <div class="d-flex d-flex-r align-center">
    <div class="c-switch mr-05" @click="$emit('button-click', !currentValue)">
      <input type="checkbox" v-model="currentValue" />
      <span class="slider" />
    </div>
    <label v-if="!!label">{{ label }}</label>
  </div>
</template>

<script>
// Implement value binding
export default {
  name: "RoundedSwitch",
  props: {
    value: { type: [Boolean, Number, String] },
    label: { type: String, default: "" },
  },
  computed: {
    currentValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
/* The switch - the box around the slider */
.c-switch {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 17px;
}

/* Hide default HTML checkbox */
.c-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 17px;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 4px;
  bottom: 2px;
  background-color: white;
  border-radius: 50%;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #21f34f;
}

input:checked + .slider:before {
  left: 19px;
}
</style>
