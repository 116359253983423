<template>
  <ol class="months-container mt-2">
    <li
      v-for="(month, index) in months"
      :key="index"
      :class="[{ selected: month === selectedMonth }, 'month']"
      @click="selectMonth(index)"
    >
      {{ month }}
    </li>
  </ol>
</template>

<script>
import dayjs from "@/utils/timezone";
import { mapState } from "vuex";

const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export default {
  name: "CalendarMonths",
  props: {
    isPostTime: { type: Boolean, default: false }, // to distinguish between the calendar for fetching and scheduling post
  },
  data: function () {
    return {
      months: MONTHS,
    };
  },
  computed: {
    ...mapState({
      selectedDate: function (state) {
        return this.isPostTime
          ? state.schedule.scheduleModalSettings.selectedDate
          : state.content.contentSortSettings.selectedDate;
      },
    }),
    selectedMonth() {
      return this.selectedDate.format("MMM");
    },
  },
  methods: {
    selectMonth(index) {
      const selectedDate = dayjs(this.selectedDate).month(index);
      this.$emit("monthSelected", selectedDate);
    },
  },
};
</script>
<style lang="scss" scoped>
.months-container {
  display: flex;
  justify-content: space-between;
  width: 90%;
  padding: 0;
  list-style: none;

  li {
    cursor: pointer;
    text-transform: uppercase;
    color: #ccc;

    &.selected {
      color: #000;
      font-weight: 600;

      &::after {
        content: "";
        display: block;
        width: 100%;
        height: 2px;
        border-bottom: 2px solid #000;
      }
    }
  }
}
</style>
