<template>
  <div :class="['notification mb-1', status, { 'is-active': show }]">
    <span class="close" @click="() => dismissNotification(id)">×</span>
    {{ text }}
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Notification",
  props: {
    id: { type: String, default: "" },
    status: { type: String, default: "" },
    text: { type: String, default: "" },
  },
  data() {
    return {
      show: false,
    };
  },
  created() {
    setTimeout(() => {
      this.show = true;
      setTimeout(() => {
        setTimeout(() => {
          this.removeNotification(this.id);
        }, 75);
        this.show = false;
      }, 3500);
    }, 50);
  },
  methods: {
    ...mapActions({
      removeNotification: "removeNotification",
    }),
    dismissNotification(id) {
      this.show = false;
      setTimeout(() => {
        this.removeNotification(id);
      }, 75);
    },
  },
};
</script>

<style lang="scss" scoped>
.notification {
  display: block;
  position: relative;
  width: 100%;
  min-height: 60px;
  padding: 0.5em 1.5em 1em 1em;
  background-color: #fff;
  border-radius: 6px;
  font-size: 0.85rem;
  opacity: 0;
  transition: opacity 0.25s ease-in-out, transform 0.5s ease-out;
  transform: translateX(100%);
  z-index: 1200;

  &.is-active {
    opacity: 1;
    transform: translateX(0);
  }

  &.succeeded {
    border: 1px solid #2bd18a;
  }

  &.error {
    border: 1px solid #d12b2b;
  }

  &.warning {
    border: 1px solid #d1812b;
  }

  .close {
    position: absolute;
    right: 0.5em;
    top: 0;
    font-size: 1.25rem;
    cursor: pointer;
  }
}
</style>
