<template>
  <div class="d-flex d-flex-c" style="height: 100%">
    <empty-state v-if="isEmpty" />
    <div v-else class="d-flex d-flex-c team-settings">
      <div class="d-flex d-flex-c">
        <team-row
          v-for="(team, index) in teams"
          :key="index"
          :name="team.name"
          :team-id="team.id"
          :role="(roles.find((role) => role.id === team.role_id) || {}).name"
          :members="team.members"
          class="mb-1"
          @row-action="handleRowAction"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";

import { TeamRow, EmptyState } from "./";

import { TEAM_CONTEXT, MANAGE_TEAM } from "@/utils/constants";
export default {
  name: "Teams",
  components: { TeamRow, EmptyState },
  computed: {
    ...mapState({
      teams: (state) => state.team.teams,
      roles: (state) => state.team.roles,
    }),
    isEmpty() {
      return this.teams.length === 0;
    },
  },
  async created() {
    if (Object.keys(this.teams).length === 0) {
      await this.getTeams();
    }
    if (Object.keys(this.roles).length === 0) {
      await this.getTeamRoles();
    }
  },
  methods: {
    ...mapActions({
      getTeams: "getTeams",
      getTeamRoles: "getTeamRoles",
      deleteTeam: "deleteTeam",
      leaveTeam: "leaveTeam",
      setSessionItem: "setSessionItem",
      setActiveProfileModal: "setActiveProfileModal",
    }),

    handleRowAction(teamId, action) {
      if (action === "delete") {
        const params = { team_id: teamId };
        this.deleteTeam(params);
      } else if (action === "leave") {
        const params = { team_id: teamId };
        this.leaveTeam(params);
      } else if (action === "update") {
        this.setActiveProfileModal({
          name: "ChangeTeamNameModal",
          props: {
            teamId: teamId,
            fields: [
              {
                name: "name",
                label: "Team name",
                value: (this.teams.find((team) => team.id === teamId) || {})
                  .name,
                rules: "required",
              },
            ],
          },
        });
      } else if (action === "manage" || action === "navigate") {
        this.setSessionItem({
          key: TEAM_CONTEXT,
          value: teamId,
        });
        if (action === "manage") {
          this.setSessionItem({
            key: MANAGE_TEAM,
            value: true,
          });
        }
        this.$router.push({ path: "/" });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.team-settings {
  height: 100%;
  max-height: 75vh;
  overflow-y: auto;
  padding-top: 1em;
  padding-left: 3px;
  padding-right: 3px;
}
</style>
