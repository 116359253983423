import {
  GET_SUBSCRIPTION_PRODUCTS_REQUEST,
  GET_SUBSCRIPTION_PRODUCTS_SUCCESS,
  GET_SUBSCRIPTION_PRODUCTS_ERROR,
  GET_USER_BILLING_INFO_REQUEST,
  GET_USER_BILLING_INFO_SUCCESS,
  GET_USER_BILLING_INFO_ERROR,
  INITIALIZE_PAYMENT_REQUEST,
  INITIALIZE_PAYMENT_SUCCESS,
  INITIALIZE_PAYMENT_ERROR,
  CONFIRM_PAYMENT_REQUEST,
  CONFIRM_PAYMENT_SUCCESS,
  CONFIRM_PAYMENT_ERR0R,
  FINALIZE_PAYMENT_REQUEST,
  FINALIZE_PAYMENT_SUCCESS,
  FINALIZE_PAYMENT_ERR0R,
  PREVIEW_SUBSCRIPTION_UPDATE_REQUEST,
  PREVIEW_SUBSCRIPTION_UPDATE_SUCCESS,
  PREVIEW_SUBSCRIPTION_UPDATE_ERROR,
  UPDATE_SUBSCRIPTION_REQUEST,
  UPDATE_SUBSCRIPTION_SUCCESS,
  UPDATE_SUBSCRIPTION_ERROR,
  RENDER_PAYMENT_COMPLETION,
  SET_ACTIVE_SUBSCRIPTION_MODAL,
} from "../actions/subscription";

import { RESET_MODULE_STATE } from "../actions/global";

import {
  getSubscriptionProducts,
  getBillingInfo,
  initializePayment,
  confirmPayment,
  finalizePayment,
  previewSubscriptionUpdate,
  updateSubscription,
} from "../../services/api/subscription.api";

const getDefaultState = () => ({
  products: [],
  features: [],
  billing: {},
  status: {
    render: {
      paymentCompletion: false,
    },
    requesting: {
      getSubscriptionProducts: false,
      getBillingInfo: false,
      initializePayment: false,
      confirmPayment: false,
      finalizePayment: false,
      previewSubscriptionUpdate: false,
      updateSubscription: false,
    },
    errors: {
      getSubscriptionProducts: null,
      getBillingInfo: null,
      initializePayment: null,
      confirmPayment: null,
      finalizePayment: null,
      previewSubscriptionUpdate: null,
      updateSubscription: null,
    },
  },
  activeModal: { name: "", props: null },
});

const state = getDefaultState();

const actions = {
  getSubscriptionProducts: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit(GET_SUBSCRIPTION_PRODUCTS_REQUEST);
      getSubscriptionProducts()
        .then((res) => {
          commit(GET_SUBSCRIPTION_PRODUCTS_SUCCESS, res.data);
          resolve(res.data);
        })
        .catch((error) => {
          commit(GET_SUBSCRIPTION_PRODUCTS_ERROR, error);
          reject(error);
        });
    });
  },
  getBillingInfo: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit(GET_USER_BILLING_INFO_REQUEST);
      getBillingInfo()
        .then((res) => {
          commit(GET_USER_BILLING_INFO_SUCCESS, res.data);
          resolve(res.data);
        })
        .catch((error) => {
          commit(GET_USER_BILLING_INFO_ERROR, error);
          reject(error);
        });
    });
  },
  initializePayment: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      commit(INITIALIZE_PAYMENT_REQUEST);
      initializePayment(params)
        .then((res) => {
          commit(INITIALIZE_PAYMENT_SUCCESS, res.data);
          resolve(res.data);
        })
        .catch((error) => {
          commit(INITIALIZE_PAYMENT_ERROR, error);
          reject(error);
        });
    });
  },
  confirmPayment: ({ commit }, { provider, payload }) => {
    return new Promise((resolve, reject) => {
      commit(CONFIRM_PAYMENT_REQUEST);
      confirmPayment(provider, payload)
        .then((res) => {
          commit(CONFIRM_PAYMENT_SUCCESS, res.data);
          resolve(res.data);
        })
        .catch((error) => {
          commit(CONFIRM_PAYMENT_ERR0R, error);
          reject(error);
        });
    });
  },
  finalizePayment: ({ commit }, { provider, payload }) => {
    return new Promise((resolve, reject) => {
      commit(FINALIZE_PAYMENT_REQUEST);
      commit(RENDER_PAYMENT_COMPLETION, true);
      finalizePayment(provider, payload)
        .then((res) => {
          commit(FINALIZE_PAYMENT_SUCCESS, res.data);
          resolve(res.data);
        })
        .catch((error) => {
          commit(FINALIZE_PAYMENT_ERR0R, error);
          reject(error);
        });
    });
  },
  previewSubscriptionUpdate: ({ commit }, { tier, interval }) => {
    return new Promise((resolve, reject) => {
      commit(PREVIEW_SUBSCRIPTION_UPDATE_REQUEST);
      previewSubscriptionUpdate(tier, interval)
        .then((res) => {
          commit(PREVIEW_SUBSCRIPTION_UPDATE_SUCCESS, res.data);
          resolve(res.data);
        })
        .catch((error) => {
          commit(PREVIEW_SUBSCRIPTION_UPDATE_ERROR, error);
          reject(error);
        });
    });
  },
  updateSubscription: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit(UPDATE_SUBSCRIPTION_REQUEST);
      updateSubscription(payload)
        .then((res) => {
          commit(UPDATE_SUBSCRIPTION_SUCCESS, res.data);
          resolve(res.data);
        })
        .catch((error) => {
          commit(UPDATE_SUBSCRIPTION_ERROR);
          reject(error);
        });
    });
  },
  resetPaymentCompletion: ({ commit }) => {
    commit(RENDER_PAYMENT_COMPLETION, false);
  },
  setActiveSubscriptionModal: (
    { commit },
    modal = { name: "", props: null },
  ) => {
    commit(SET_ACTIVE_SUBSCRIPTION_MODAL, modal);
  },
};

const mutations = {
  [GET_SUBSCRIPTION_PRODUCTS_REQUEST]: (state) => {
    state.status.requesting.getSubscriptionProducts = true;
  },
  [GET_SUBSCRIPTION_PRODUCTS_SUCCESS]: (state, data) => {
    state.status.requesting.getSubscriptionProducts = false;
    state.products = data.products.map((product) => {
      return {
        subscriptionPlan: product.plan.toLowerCase(),
        subscriptionLabel: product.name,
        currency: product.currency,
        price: Object.assign(
          {},
          ...product.prices.map((price) => ({ ...price })),
        ),
      };
    });
    state.features = data.features;
  },
  [GET_SUBSCRIPTION_PRODUCTS_ERROR]: (state, error) => {
    state.status.requesting.getSubscriptionProducts = false;
    state.status.errors.getSubscriptionProducts = error;
  },
  [GET_USER_BILLING_INFO_REQUEST]: (state) => {
    state.status.requesting.getBillingInfo = true;
  },
  [GET_USER_BILLING_INFO_SUCCESS]: (state, data) => {
    state.status.requesting.getBillingInfo = false;
    state.billing = data;
  },
  [GET_USER_BILLING_INFO_ERROR]: (state, error) => {
    state.status.requesting.getBillingInfo = false;
    state.status.errors.getBillingInfo = error;
  },
  [INITIALIZE_PAYMENT_REQUEST]: (state) => {
    state.status.requesting.initializePayment = true;
  },
  [INITIALIZE_PAYMENT_SUCCESS]: (state, data) => {
    state.status.requesting.initializePayment = false;
  },
  [INITIALIZE_PAYMENT_ERROR]: (state, error) => {
    state.status.requesting.initializePayment = false;
    state.status.errors.initializePayment = error;
  },
  [CONFIRM_PAYMENT_REQUEST]: (state) => {
    state.status.requesting.confirmPayment = true;
  },
  [CONFIRM_PAYMENT_SUCCESS]: (state, data) => {
    state.status.requesting.confirmPayment = false;
  },
  [CONFIRM_PAYMENT_ERR0R]: (state, error) => {
    state.status.requesting.confirmPayment = false;
    state.status.errors.confirmPayment = error;
  },
  [FINALIZE_PAYMENT_REQUEST]: (state) => {
    state.status.requesting.finalizePayment = true;
  },
  [FINALIZE_PAYMENT_SUCCESS]: (state, data) => {
    state.status.requesting.finalizePayment = false;
  },
  [FINALIZE_PAYMENT_ERR0R]: (state, error) => {
    state.status.requesting.finalizePayment = false;
    state.status.errors.finalizePayment = error;
  },
  [PREVIEW_SUBSCRIPTION_UPDATE_REQUEST]: (state) => {
    state.status.requesting.previewSubscriptionUpdate = true;
  },
  [PREVIEW_SUBSCRIPTION_UPDATE_SUCCESS]: (state, data) => {
    state.status.requesting.previewSubscriptionUpdate = false;
  },
  [PREVIEW_SUBSCRIPTION_UPDATE_ERROR]: (state, error) => {
    state.status.requesting.previewSubscriptionUpdate = false;
    state.status.errors.previewSubscriptionUpdate = error;
  },
  [UPDATE_SUBSCRIPTION_REQUEST]: (state) => {
    state.status.requesting.updateSubscription = true;
  },
  [UPDATE_SUBSCRIPTION_SUCCESS]: (state, data) => {
    state.status.requesting.updateSubscription = false;
    state.billing = data;
  },
  [UPDATE_SUBSCRIPTION_ERROR]: (state, error) => {
    state.status.requesting.updateSubscription = false;
    state.status.errors.updateSubscription = error;
  },
  [RENDER_PAYMENT_COMPLETION]: (state, value) => {
    state.status.render.paymentCompletion = value;
  },
  [SET_ACTIVE_SUBSCRIPTION_MODAL]: (state, modal) => {
    state.activeModal = modal;
  },
  [RESET_MODULE_STATE]: (state) => {
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    // This mutation is called from the logout action!
    Object.assign(state, getDefaultState());
  },
};

export default {
  state,
  actions,
  mutations,
};
