<template>
  <span
    :class="[
      'd-flex d-flex-center profile-picture',
      { 'is-team': isTeam },
      firstLetter,
    ]"
    :style="{ 'background-image': profileBg }"
  >
    <label v-if="isTeam && firstLetter">{{ firstLetter }}</label>
    <label v-if="enableHover" class="hover-label">{{ name }}</label>
  </span>
</template>

<script>
export default {
  name: "ProfilePicture",
  props: {
    isTeam: { type: Boolean, default: false },
    picture: { type: String, default: "" },
    name: { type: String, default: "" },
    enableHover: { type: Boolean, default: false },
  },
  computed: {
    firstLetter() {
      return this.picture ? null : this.name.charAt(0).toLowerCase();
    },
    profileBg() {
      return this.picture ? `url(${this.picture})` : null;
    },
  },
};
</script>

<style lang="scss" scoped>
.profile-picture {
  position: relative;
  width: 35px;
  height: 35px;
  min-width: 35px;
  min-height: 35px;
  border-radius: 100%;
  background-image: url("/assets/images/icons/dashboard/sidemenu/profile_icon.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  &.is-team {
    background-image: none;
    background-color: #a276ed;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;

    &.m {
      background-color: #ef8677;
    }

    &.s {
      background-color: #546cb8;
    }
    &.c {
      background-color: #fadfbc;
    }
    &.q {
      background-color: #d9dbae;
    }
  }
  label {
    font-size: 1.5rem;
    color: white;
    opacity: 0.75;
  }

  .hover-label {
    display: none;
    position: absolute;
    padding: 0.25em;
    background-color: black;
    font-size: 0.6rem;
    border-radius: 6px;
    top: 100%;
    left: 100%;
  }

  &:hover {
    .hover-label {
      display: block;
    }
  }
}
</style>
