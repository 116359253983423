import router from "@/routes";

import {
  AUTH_REQUEST,
  AUTH_ERROR,
  AUTH_SUCCESS,
  AUTH_LOGOUT,
  AUTH_LOGOUT_REQUEST,
  AUTH_LOGOUT_SUCCESS,
  AUTH_LOGOUT_ERROR,
  REGISTER_REQUEST,
  REGISTER_ERROR,
  REGISTER_SUCCESS,
  VERIFY_REGISTRATION_REQUEST,
  VERIFY_REGISTRATION_SUCCESS,
  VERIFY_REGISTRATION_ERROR,
} from "@/store/actions/auth";

import { RESET_MODULE_STATE } from "../actions/global";

import {
  login,
  register,
  verifyRegistration,
  logout,
} from "@/services/api/auth.api";
import { getCookie, deleteCookie } from "@/utils/cookie";

const getDefaultState = () => ({
  isAuthenticated: !!getCookie("client_auth"),
  status: {
    requesting: {
      login: false,
      logout: false,
      register: false,
      verifyRegistration: false,
    },
    errors: {
      login: null,
      logout: null,
      register: null,
      verifyRegistration: null,
    },
  },
});

const state = getDefaultState();

const getters = {
  authStatus: (state) => state.status,
};

const actions = {
  login: ({ commit }, user) => {
    return new Promise((resolve, reject) => {
      commit(AUTH_REQUEST);
      login(user)
        .then((res) => {
          const { login_success } = res.data;
          commit(AUTH_SUCCESS, login_success);
          resolve(res);
        })
        .catch((error) => {
          commit(AUTH_ERROR, error);
        });
    });
  },
  register: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit(REGISTER_REQUEST);
      register(payload.data, payload.params)
        .then((res) => {
          commit(REGISTER_SUCCESS);
          resolve(res);
        })
        .catch((error) => {
          commit(REGISTER_ERROR, error);
        });
    });
  },
  verifyRegistration: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      commit(VERIFY_REGISTRATION_REQUEST);
      verifyRegistration(params)
        .then((res) => {
          commit(VERIFY_REGISTRATION_SUCCESS);
          resolve(res);
        })
        .catch((error) => {
          commit(VERIFY_REGISTRATION_ERROR, error);
        });
    });
  },
  logout: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit(AUTH_LOGOUT_REQUEST);
      logout()
        .then((res) => {
          commit(AUTH_LOGOUT_SUCCESS);
          commit(RESET_MODULE_STATE);
          deleteCookie("client_auth");
          router.push("/login").catch((err) => {});
          resolve(res);
        })
        .catch((error) => {
          commit(AUTH_LOGOUT_ERROR, error);
        });
    });
  },
  resetStore: ({ commit }) => {
    commit(RESET_MODULE_STATE);
  },
};

const mutations = {
  [AUTH_REQUEST]: (state) => {
    state.status.requesting.login = true;
  },
  [AUTH_SUCCESS]: (state) => {
    state.isAuthenticated = true;
    state.status.requesting.login = false;
  },
  [AUTH_ERROR]: (state, error) => {
    state.status.requesting.login = false;
    state.status.errors.login = error;
  },
  [AUTH_LOGOUT_REQUEST]: (state) => {
    state.status.requesting.logout = true;
  },
  [AUTH_LOGOUT_SUCCESS]: (state) => {
    state.isAuthenticated = false;
    state.status.errors.login = false;
    state.status.requesting.logout = false;
  },
  [AUTH_LOGOUT_ERROR]: (state, error) => {
    state.status.requesting.logout = false;
    state.status.errors.logout = error;
  },
  [AUTH_LOGOUT]: (state) => {
    state.isAuthenticated = false;
    state.status.errors.login = false;
    state.status.requesting.logout = false;
  },
  [REGISTER_REQUEST]: (state) => {
    state.status.requesting.register = true;
  },
  [REGISTER_SUCCESS]: (state) => {
    state.status.requesting.register = false;
    state.status.errors.register = null;
  },
  [REGISTER_ERROR]: (state, error) => {
    state.status.requesting.register = false;
    state.status.errors.register = error;
  },
  [VERIFY_REGISTRATION_REQUEST]: (state) => {
    state.status.requesting.verifyRegistration = true;
  },
  [VERIFY_REGISTRATION_SUCCESS]: (state) => {
    state.status.requesting.verifyRegistration = false;
  },
  [VERIFY_REGISTRATION_ERROR]: (state, error) => {
    state.status.requesting.verifyRegistration = false;
    state.status.errors.verifyRegistration = error;
  },
  [RESET_MODULE_STATE]: (state) => {
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    // This mutation is called from the logout action!
    Object.assign(state, getDefaultState());
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
