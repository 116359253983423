<template>
  <div :class="['d-flex d-flex-r side-modal-wrapper', { 'is-active': show }]">
    <div class="background" />
    <div class="modal-content">
      <div class="d-flex d-flex-c modal-left">
        <div class="d-flex d-flex-r d-flex-between align-center mb-1">
          <span
            ><b>{{ title }}</b></span
          >
          <span class="close" @click="handleClose">×</span>
        </div>
        <slot name="content-left" />
      </div>
      <div class="d-flex d-flex-c modal-right">
        <slot name="content-right" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SideModal",
  props: {
    title: { type: String, default: "" },
  },
  data() {
    return {
      containerElement: null,
      show: false,
    };
  },
  mounted() {
    this.containerElement = this.$el.getElementsByClassName("modal-content")[0];
    this.$el.addEventListener("click", this.handleClose);
    setTimeout(() => {
      this.show = true;
    }, 10);
  },
  methods: {
    handleClose(e) {
      if (
        !this.containerElement.contains(e.target) ||
        e.target.className === "close"
      ) {
        this.show = false;
        setTimeout(() => {
          this.$emit("close");
        }, 300);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.side-modal-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  right: 0;
  top: 0;
  z-index: 1030;
  &.is-active {
    .background {
      background-color: #221c55;
    }

    .modal-content {
      transform: translateX(-100vw);
    }
  }
  .modal-content {
    width: 100%;
    height: 100%;
    max-width: 1280px;
    transition: transform 0.3s ease-in;
    right: -100%;
    top: 0;
    position: absolute;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    .modal-left {
      position: absolute;
      height: 100%;
      width: 30%;
      padding: 2em;
      background-color: white;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
      .close {
        font-size: 1.5rem;
      }
    }

    .modal-right {
      position: absolute;
      height: 100%;
      width: 70%;
      right: 0;
      padding: 2em 2em 1em;
      background-color: #f9f9f9;
    }
  }

  .background {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    transition: background-color 150ms ease;
    opacity: 0.9;
  }
}
</style>
