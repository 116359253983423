<template>
  <div
    :class="['d-flex d-flex-c', { 'mr-5': context === subscriptionSelection }]"
  >
    <h2 v-if="context === subscriptionSelection">Payment Details</h2>
    <div class="d-flex d-flex-r mb-2">
      <span
        v-for="(paymentMethod, index) in paymentMethods"
        :key="index"
        :class="[
          'payment-label',
          {
            'is-selected': paymentMethod.type === selectedPaymentMethod.type,
          },
        ]"
        @click="() => changePaymentMethod(paymentMethod)"
        >{{ paymentMethod.label }}</span
      >
    </div>
    <div class="d-flex payment-wrapper">
      <component
        class="d-flex d-align-end"
        :is="selectedPaymentMethod.type"
        v-bind="{
          selectedProvider: selectedPaymentMethod.provider,
          selectedInterval,
          selectedPlan,
          setupContext: context,
        }"
        @payment-complete="onPaymentComplete"
      />
    </div>
  </div>
</template>
<script>
import { Stripe, PayPal } from ".";
import {
  paymentMethods,
  SUBSCRIPTION_PLAN_SELECTION,
} from "@/utils/subscribe.js";

export default {
  name: "PaymentMethods",
  components: { Stripe, PayPal },
  props: {
    context: { type: String, default: "" },
    selectedInterval: { type: String, default: "" },
    selectedPlan: { type: String, default: "" },
  },
  data() {
    return {
      selectedPaymentMethod: paymentMethods[0],
      paymentMethods,
      subscriptionSelection: SUBSCRIPTION_PLAN_SELECTION,
    };
  },
  methods: {
    changePaymentMethod(paymentMethod) {
      this.selectedPaymentMethod = paymentMethod;
    },
    onPaymentComplete(data) {
      // console.log("compelte");
      this.$emit("on-success", data);
    },
  },
};
</script>

<style lang="scss" scoped>
.payment-label {
  padding-bottom: 0.5em;
  cursor: pointer;
  margin-right: 1em;

  &.is-selected {
    border-bottom: 2px solid black;
  }
}
</style>
