import CONFIG from "@/config";
import { AUTH_SUCCESS, AUTH_LOGOUT_SUCCESS } from "@/store/actions/auth";
import { SEND_MESSAGE_SUCCESS } from "../actions/content";
import Timer, { reconnectAfterMs } from "@/utils/socket";

function disconnectWS(ws) {
  ws.onclose = function () {};
  ws.close();
}
const establishWSConnection = (url, store, state) => {
  const ws = new WebSocket(url);

  const reconnectionTimer = new Timer(() => {
    disconnectWS(ws), establishWSConnection(url, store, state);
  }, reconnectAfterMs);

  // function connect() {
  ws.onopen = (evt) => {
    // console.log(">>>>: ", evt);
  };

  ws.onmessage = (evt) => {
    const { data } = evt;
    const parsedData = JSON.parse(data);
    // console.log("masssage: ", parsedData);
    if (parsedData) {
      if (
        parsedData.event_type === "draft_message" &&
        parsedData.data.user_id !== state.dashboard.user.id
      ) {
        store.commit(SEND_MESSAGE_SUCCESS, parsedData.data);
      }
    }
  };

  ws.onclose = (evt) => {
    reconnectionTimer.scheduleTimeout();
  };

  ws.onerror = (err) => {
    console.log("<<<<: ", err);
    ws.close();
  };
};

export default function createWebSocketPlugin() {
  return (store) => {
    store.subscribe((mutation, state) => {
      if (mutation.type === AUTH_SUCCESS && state.team.currentTeam.id) {
        const url = `${CONFIG.WS_HOST}/${state.team.currentTeam.id}`;
        establishWSConnection(url, store, state);
      }
    });
  };
}
