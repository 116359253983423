<template>
  <modal title="Change time zone" @close="closeModal">
    <div slot="modal-content" class="modal-content-wrapper">
      <validation-observer
        ref="timezoneSettingsForm"
        v-slot="{ invalid }"
        class="d-flex d-flex-c d-flex-between"
        style="flex: 1"
      >
        <form
          id="form-timezone"
          class="d-flex d-flex-c d-flex-between mt-2"
          style="flex: 1"
          @submit.prevent
        >
          <component
            v-for="(field, index) in fields"
            :key="field.key || index"
            :is="field.type"
            v-bind="field.props"
            v-model="formData[field.props.name]"
            @trigger="field.trigger"
          ></component>

          <div class="d-flex d-flex-r d-flex-end align-center mt-3">
            <dashboard-button
              label="Save"
              :is-requesting="updatingProfile"
              :invalid="invalid"
              @button-click="save"
            />
          </div>
        </form>
      </validation-observer>
    </div>
  </modal>
</template>
<script>
import { ValidationObserver } from "vee-validate";
import { mapActions, mapState } from "vuex";
import { Modal } from "@/components";
import { FormSelect, DashboardButton } from "@/components/ui/";
import {
  getZonesByCountry,
  getCountryByTimezone,
  COUNTRIES,
  USER_COUNTRY,
} from "@/utils/timezone";

export default {
  name: "ChangeTimezoneModal",
  components: { Modal, FormSelect, DashboardButton, ValidationObserver },
  props: { initalZone: { type: Object, default: () => ({ value: null }) } },
  created() {
    if (this.initalZone.value) {
      const userCountry = {
        key: "country",
        value: getCountryByTimezone(this.initalZone.value),
      };
      this.formData.country = userCountry;
      this.formData.timezone = this.initalZone;
    } else {
      this.formData.country = USER_COUNTRY;
      this.formData.timezone = getZonesByCountry(USER_COUNTRY.value)[0];
    }
  },
  data() {
    return {
      formData: {
        country: {},
        timezone: {},
      },
    };
  },
  computed: {
    ...mapState({
      updatingProfile: (state) =>
        state.dashboard.status.requesting.updateProfile,
    }),
    fields() {
      return [
        {
          type: "FormSelect",
          trigger: this.changeTimezone,
          props: {
            name: "country",
            label: "Time zone",
            items: COUNTRIES,
            wide: true,
          },
        },
        {
          type: "FormSelect",
          key: this.formData.timezone.value, // to force a render
          trigger: () => ({}),
          props: {
            name: "timezone",
            items: getZonesByCountry(this.formData.country.value),
            wide: true,
          },
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      updateProfile: "updateProfile",
    }),
    closeModal() {
      this.$emit("close-profile-modal");
    },
    changeTimezone(country) {
      this.formData.timezone = getZonesByCountry(country.value)[0];
    },
    save() {
      let payload = { timezone: this.formData.timezone.key };
      this.updateProfile(payload).then((res) => {
        this.closeModal();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-content-wrapper {
  display: flex;
  flex-direction: column;
  min-width: 400px;
  min-height: 250px;
}
</style>
