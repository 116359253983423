<template>
  <div />
</template>

<script>
export default {
  name: "Signup",
  components: {},
};
</script>

<style lang="scss" scoped></style>
