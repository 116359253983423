<template>
  <div v-if="showLoader" class="d-flex d-flex-center align-center flex-1">
    <social-auth-loader v-if="isAddingChannel" />
    <loader v-else />
  </div>
  <div v-else id="dashboard" class="dashboard">
    <side-bar />
    <dashboard-content />
    <component
      :is="activeModal.name"
      v-bind="activeModal.props"
      @modal-submit="activeModal.modalSubmit"
      @closeModal="setActiveModal"
    />
    <component
      :is="activeSideModal.name"
      v-bind="activeSideModal.props"
      @modal-submit="activeSideModal.modalSubmit"
      @closeModal="setActiveSideModal"
    />
    <notification-center />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import {
  FACEBOOK_PAGES,
  PLATFORM_AUTH,
  SELECTED_PLATFORM,
  SELECT_CHANNELS,
  TEAM_CONTEXT,
  MANAGE_TEAM,
} from "@/utils/constants";
import { ADD_CHANNEL_SUCCESS } from "@/store/actions/channel";
import { getUrlParam } from "@/utils/url";
import CONFIG from "@/config";

export default {
  name: "Dashboard",
  components: {
    SideBar: () =>
      import("@/components/").then((res) => {
        return res.SideBar;
      }),
    DashboardContent: () =>
      import("@/components/").then((res) => {
        return res.DashboardContent;
      }),
    AddChannelModal: () =>
      import("@/components/").then((res) => {
        return res.AddChannelModal;
      }),
    AddMemberModal: () =>
      import("@/components/").then((res) => {
        return res.AddMemberModal;
      }),

    CreateProjectModal: () =>
      import("@/components/").then((res) => {
        return res.CreateProjectModal;
      }),
    TextRevisionModal: () =>
      import("@/components/").then((res) => {
        return res.TextRevisionModal;
      }),
    HashtagsModal: () =>
      import("@/components/").then((res) => {
        return res.HashtagsModal;
      }),
    OptimizePlatformModal: () =>
      import("@/components/").then((res) => {
        return res.OptimizePlatformModal;
      }),
    EditProjectChannelsModal: () =>
      import("@/components/").then((res) => {
        return res.EditProjectChannelsModal;
      }),
    SelectPostTimeModal: () =>
      import("@/components/").then((res) => {
        return res.SelectPostTimeModal;
      }),
    AssetSelectionModal: () =>
      import("@/components/").then((res) => {
        return res.AssetSelectionModal;
      }),
    AssetDetailsModal: () =>
      import("@/components/").then((res) => {
        return res.AssetDetailsModal;
      }),
    CreateMediaLibraryModal: () =>
      import("@/components/").then((res) => {
        return res.CreateMediaLibraryModal;
      }),
    ListModal: () =>
      import("@/components/").then((res) => {
        return res.ListModal;
      }),
    UploadModal: () =>
      import("@/components/").then((res) => {
        return res.UploadModal;
      }),
    Loader: () =>
      import("@/components/").then((res) => {
        return res.Loader;
      }),
    SocialAuthLoader: () =>
      import("@/components/").then((res) => {
        return res.SocialAuthLoader;
      }),
    NotificationCenter: () =>
      import("@/components/").then((res) => {
        return res.NotificationCenter;
      }),
  },
  data() {
    return {
      isRedirecting: false,
    };
  },
  async created() {
    const params = getUrlParam(window.location.href);
    const teamContext = await this.getSessionItem(TEAM_CONTEXT);

    if (teamContext) {
      this.changeTeam(teamContext);
    }
    if (
      this.platformAuth &&
      (params.code || (params.oauth_token && params.oauth_verifier))
    ) {
      let payload = { ...params, platform: this.selectedPlatform };
      this.addChannel(payload)
        .then((res) => {
          const { platform_pages } = res.data;
          this.setSessionItem({
            key: SELECT_CHANNELS,
            value: true,
          });
          if (platform_pages) {
            this.setStorageItem({
              key: FACEBOOK_PAGES,
              value: JSON.stringify(platform_pages),
            });
          }
          window.location.replace(CONFIG.CLIENT_REDIRECT_URL);
        })
        .catch((err) => {
          this.isRedirecting = true;
          window.location.replace(CONFIG.CLIENT_REDIRECT_URL);
        });
    } else {
      this.getDashboard().then((res) => {
        if (sessionStorage.getItem(SELECT_CHANNELS)) {
          this.$router.push("/workspace/channels");
          this.setActiveModal({
            name: "AddChannelModal",
            props: null,
            modalSubmit: () => ({}),
          });
          if (localStorage.getItem(FACEBOOK_PAGES)) {
            this.togglePagesSelect();
          } else {
            const payload = {
              type: ADD_CHANNEL_SUCCESS,
              status: "succeeded",
            };
            this.createNotification(payload);
          }
          this.removeSessionItem(SELECTED_PLATFORM);
          this.removeSessionItem(PLATFORM_AUTH);
          this.removeSessionItem(SELECT_CHANNELS);
        } else if (sessionStorage.getItem(MANAGE_TEAM)) {
          this.$router.push("/workspace/team");
          this.removeSessionItem(MANAGE_TEAM);
        } else {
          // to prevent duplicate route navigation error message
          if (this.$route.name === "schedule") {
            return;
          } else {
            this.$router.push("/");
          }
        }
      });
    }
  },
  beforeDestroy() {
    this.setActiveModal();
  },
  watch: {
    teamId(newValue, oldValue) {
      if (!this.isLoading) {
        if (!newValue) {
          this.removeSessionItem(TEAM_CONTEXT);
          this.resetStore();
        } else if (newValue !== oldValue && !this.isLoading) {
          this.setSessionItem({
            key: TEAM_CONTEXT,
            value: newValue,
          });
        }
        this.getDashboard();
      }
    },
  },
  computed: {
    ...mapState({
      teamId: (state) => state.team.currentTeam.id,
      activeModal: (state) => state.dashboard.activeModal,
      activeSideModal: (state) => state.dashboard.activeSideModal,
      isAddingChannel: (state) => state.channel.status.requesting.addChannel,
      isConfirmingInvite: (state) =>
        state.team.status.requesting.confirmTeamInvite,
      isLoading: (state) => state.dashboard.status.requesting.getDashboard,
      selectedPlatform: (state) => state.session.selectedPlatform,
      platformAuth: (state) => state.session.platformAuth,
      platformPopup: (state) => state.session.platformPopup,
    }),
    showLoader() {
      return (
        this.isLoading ||
        this.isAddingChannel ||
        this.isRedirecting ||
        this.isConfirmingInvite
      );
    },
  },
  methods: {
    ...mapActions({
      getDashboard: "getDashboard",
      changeTeam: "changeTeam",
      addChannel: "addChannel",
      setActiveModal: "setActiveModal",
      setActiveSideModal: "setActiveSideModal",
      togglePagesSelect: "togglePagesSelect",
      getSessionItem: "getSessionItem",
      setStorageItem: "setStorageItem",
      removeSessionItem: "removeSessionItem",
      setSessionItem: "setSessionItem",
      createNotification: "createNotification",
      resetStore: "resetStore",
    }),
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#dashboard {
  .dashboard {
    .side-menu,
    .dashboard-content {
      box-sizing: border-box;
    }
  }
}
</style>
