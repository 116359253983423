import httpClient from "./httpClient";

const DASHBOARD = "/dashboard";

export const getDashboard = (params) => httpClient.get(DASHBOARD, { params });
export const getProfile = () => httpClient.get(`${DASHBOARD}/profile`);
export const updateProfile = (payload = {}) =>
  httpClient.put(`${DASHBOARD}/profile`, payload);
export const updatePassword = (payload = {}) =>
  httpClient.put(`${DASHBOARD}/profile/password`, payload);
