<template>
  <modal
    @close="closeModal"
    :title="currentStep === 1 ? 'Project name' : 'Time zone'"
    :description="isMultiStep && currentStep === 1 ? 'Next: Time zone' : ' '"
    :stepInfo="isMultiStep ? `${currentStep} of 2` : ''"
  >
    <div slot="modal-content" class="modal-content-wrapper">
      <div class="d-flex d-flex-c" style="flex: 1">
        <validation-observer
          ref="createProjectForm"
          v-slot="{ invalid }"
          class="d-flex d-flex-c d-flex-between"
          style="flex: 1"
        >
          <form
            @submit.prevent="() => ({})"
            class="d-flex d-flex-c d-flex-between"
            style="flex: 1"
          >
            <div class="input-wrapper">
              <component
                v-for="(field, index) in stepData[`step_${currentStep}`].fields"
                :key="field.key || index"
                :is="field.type"
                v-bind="field.props"
                v-model="formData[field.props.name]"
                @trigger="field.trigger"
              ></component>
            </div>
            <div
              class="button-wrapper"
              :style="
                currentStep === 2 ? 'justify-content: space-between;' : ''
              "
            >
              <dashboard-button
                v-if="isMultiStep && currentStep === 2"
                label="Previous"
                @button-click="changeStep"
              />

              <dashboard-button
                :label="isMultiStep && currentStep === 1 ? 'Next' : 'Finish'"
                :is-requesting="isRequesting"
                :invalid="invalid"
                @button-click="
                  isMultiStep && currentStep === 1 ? changeStep() : formSubmit()
                "
              />
            </div>
          </form>
        </validation-observer>
      </div>
    </div>
  </modal>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import { mapActions, mapState } from "vuex";
import { Modal } from "@/components";
import { ContentInput, FormSelect, DashboardButton } from "@/components/ui/";
import { getZonesByCountry, COUNTRIES, USER_COUNTRY } from "@/utils/timezone";

export default {
  name: "CreateProjectModal",
  components: {
    Modal,
    ValidationObserver,
    ContentInput,
    FormSelect,
    DashboardButton,
  },
  created() {
    const initialZone = getZonesByCountry(USER_COUNTRY.value);
    this.formData.timezone = initialZone[0];
  },
  data() {
    return {
      currentStep: 1,
      selectedTimezone:
        (USER_COUNTRY && USER_COUNTRY.value) || COUNTRIES[0].value,
      formData: {
        projectName: "",
        country: USER_COUNTRY,
        timezone: {},
      },
    };
  },
  computed: {
    ...mapState({
      isRequesting: (state) => state.project.status.requesting.createProject,
      enableTimeZone: (state) =>
        state.subscription.billing.product_tier !== "grounded" &&
        state.subscription.billing.product_tier !== "lift_off",
    }),

    stepData() {
      let scope = this;
      return {
        step_1: {
          fields: [
            {
              type: "ContentInput",
              trigger: () => ({}),
              props: {
                name: "projectName",
                label: "Project name",
                rules: "required",
              },
            },
          ],
        },

        ...(this.enableTimeZone && {
          step_2: {
            fields: [
              {
                type: "FormSelect",
                trigger: scope.changeTz,
                props: {
                  name: "country",
                  label: "Time zone",
                  items: COUNTRIES,
                  wide: true,
                },
              },
              {
                type: "FormSelect",
                key: scope.formData.country.value, // to force a render
                trigger: () => ({}),
                props: {
                  name: "timezone",
                  items: getZonesByCountry(scope.formData.country.value),
                  wide: true,
                },
              },
            ],
          },
        }),
      };
    },
    totalSteps() {
      return Object.keys(this.stepData).length;
    },
    isMultiStep() {
      return this.totalSteps > 1;
    },
  },
  methods: {
    ...mapActions({
      createProject: "createProject",
    }),
    closeModal() {
      this.$emit("closeModal");
    },
    changeStep() {
      if (this.currentStep === 1) {
        this.currentStep = 2;
      } else {
        this.currentStep = 1;
      }
    },
    changeTz(country) {
      this.formData.timezone = getZonesByCountry(country.value)[0];
    },
    formSubmit() {
      // console.log(this.formData);
      const payload = {
        name: this.formData.projectName,
        ...(this.enableTimeZone && { timezone: this.formData.timezone.key }),
      };
      this.createProject(payload).then((res) => {
        this.closeModal();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.modal-content-wrapper {
  display: flex;
  flex-direction: column;
  min-width: 600px;
  min-height: 300px;
  .input-wrapper {
    margin-top: 3em;
  }
  .button-wrapper {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
