<template>
  <div :class="['d-flex d-flex-c menu', { 'team-view': teamSelection }]">
    <span
      v-if="!teamSelection"
      class="item switch"
      @click.stop="() => toggleTeams(true)"
      >Switch teams</span
    >
    <div v-if="teamSelection" class="d-flex d-flex-c teams">
      <div class="d-flex d-flex-r item" @click.stop="() => toggleTeams(false)">
        <span class="back-icon" /><label>Back</label>
      </div>
      <profile-item
        :class="[
          'd-flex align-center item',
          { 'is-selected': !selectedTeam.id },
        ]"
        :isPersonal="true"
        :outsideMenu="false"
        @click.native="() => selectTeam()"
      />
      <team-item
        v-for="(team, index) in teams"
        :key="index"
        :teamId="team.id"
        :name="team.name"
        :class="[
          'd-flex align-center item',
          { 'is-selected': selectedTeam.id === team.id },
        ]"
        @item-click="selectTeam"
      />
    </div>
    <div v-else class="d-flex d-flex-c nav-links">
      <router-link
        v-for="(item, index) in menuItems"
        :key="index"
        :class="[
          'item',
          item.class,
          { 'is-active': currentRouteName === item.name },
        ]"
        :to="`/profile/${item.name}`"
      >
        <span :class="item.class">{{ item.label }}</span>
      </router-link>
      <div class="item" @click="handleLogout">
        <span>Sign out</span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { profileMenuItems } from "@/utils/constants";

import TeamItem from "./TeamItem.vue";
import ProfileItem from "./ProfileItem.vue";

export default {
  name: "ProfileMenu",
  components: {
    TeamItem,
    ProfileItem,
  },
  data: () => ({
    menuItems: profileMenuItems.filter((item) => item.name !== "team"),
    teamSelection: false,
  }),
  computed: {
    ...mapState({
      selectedTeam: (state) =>
        state.team.teams.find(
          (team) => team.id === state.team.currentTeam.id,
        ) || {},
      teams: (state) => state.team.teams,
    }),
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    ...mapActions({
      changeTeam: "changeTeam",
      logout: "logout",
    }),
    handleLogout() {
      this.logout().then((res) => {
        this.$router.push("/login");
      });
    },
    toggleTeams(value) {
      this.teamSelection = value;
    },
    selectTeam(teamId = "") {
      this.changeTeam(teamId);
    },
  },
};
</script>
<style lang="scss" scoped>
.menu {
  background-color: white;
  border-radius: 6px;
  overflow: hidden;
  height: 160px;
  transition: height 0.25s ease-in-out;

  .item {
    position: relative;
    width: 250px;
    padding: 0.5em 0.75em;
    color: black;
    cursor: pointer;

    span.back-icon {
      width: 32px;
      height: 100%;
      background-image: url("/assets/images/icons/common/back_icon.png");
      background-size: contain;
      background-repeat: no-repeat;
    }

    &.is-active,
    &:hover {
      background-color: #aab4db;
    }

    &.is-selected {
      &::after {
        content: "";
        position: relative;
        width: 9px;
        height: 6px;
        margin-left: 10px;
        border-right: 2px solid black;
        border-top: 2px solid black;

        opacity: 1;
        transform: rotate(135deg);
      }
    }
  }

  &.team-view {
    height: 244px;
    overflow-y: auto;
  }
}
</style>
