<template>
  <div class="d-flex d-flex-c settings-col-wrapper">
    <label class="mb-1">{{ label }}</label>
    <span class="settings-col">{{ text }}</span>
  </div>
</template>
<script>
export default {
  name: "ContentCol",
  props: {
    label: { type: String, default: "" },
    text: { type: [String, Number] },
  },
};
</script>
<style lang="scss" scoped>
.settings-col-wrapper {
  label,
  span {
    color: #7b8994;
  }
  label {
    font-size: 0.9rem;
  }
}
</style>
