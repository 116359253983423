var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.billingInfoLoading),expression:"!billingInfoLoading"}],staticClass:"account-subscription"},[_c('content-row',{attrs:{"hideDivider":true}},[_c('div',{attrs:{"slot":"content-left"},slot:"content-left"},[_c('div',{staticClass:"d-flex d-flex-c"},[_c('span',{staticClass:"d-flex d-flex-c"},[_c('span',{staticClass:"plan-label mb-2"},[_vm._v("Current plan: "),_c('b',[_vm._v(_vm._s(_vm.billingInfo.product_name))])]),_c('img',{attrs:{"src":`/assets/images/layout/subscription/${_vm.billingInfo.product_tier}_l.svg`,"width":"200"}})])])]),_c('div',{attrs:{"slot":"content-right"},slot:"content-right"},[_c('socianis-button',{attrs:{"label":"Change plan"},on:{"button-click":_vm.changePlan}})],1)]),_c('content-row',{attrs:{"title":"Billing details"}},[_c('div',{attrs:{"slot":"content-left"},slot:"content-left"},[_c('content-col',{attrs:{"label":"Billing history","text":"Payments and receipts"}})],1),_c('div',{attrs:{"slot":"content-right"},slot:"content-right"},[_c('button',{staticClass:"settings-button",on:{"click":function($event){return _vm.setActiveProfileModal({
            name: 'ChangeFullNameModal',
            props: {
              setting: 'full_name',
              fields: [
                {
                  name: 'full_name',
                  label: 'Full Name',
                  value: _vm.user.full_name,
                  rules: 'required',
                },
              ],
            },
          })}}},[_vm._v(" Download receipts ")])])]),_c('content-row',[_c('div',{attrs:{"slot":"content-left"},slot:"content-left"},[_c('content-col',{attrs:{"label":"Next payment","text":_vm.nextPayment}})],1)]),_c('content-row',{staticClass:"mt-5",attrs:{"title":"Payment method"}},[_c('div',{attrs:{"slot":"content-left"},slot:"content-left"},[_c('content-col',{attrs:{"label":"Payment details"}}),(
          Object.keys(_vm.billingInfo).length > 0 &&
          _vm.billingInfo.payment_method_info
        )?_c('payment-method',{attrs:{"cardBrand":_vm.billingInfo.payment_method_info.brand,"expiryMonth":_vm.billingInfo.payment_method_info.expiry_month,"expiryYear":_vm.billingInfo.payment_method_info.expiry_year,"lastFour":_vm.billingInfo.payment_method_info.last_four,"paymentMethodType":_vm.billingInfo.payment_method_info.type}}):_vm._e()],1),_c('div',{attrs:{"slot":"content-right"},slot:"content-right"},[_c('button',{staticClass:"settings-button",on:{"click":_vm.handleAddPayment}},[_vm._v(" Change payment method ")])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }