<template>
  <div class="d-flex d-flex-r options">
    <span
      v-for="(option, index) in options"
      :key="index"
      :class="[
        'd-flex d-flex-c d-flex-center mr-2 option',
        { 'is-selected': index === selectedIndex },
      ]"
      @click="() => selectOption(index)"
      >{{ option }}</span
    >
  </div>
</template>

<script>
export default {
  name: "ViewOptions",
  props: {
    options: { type: Array, default: () => [] },
  },
  data() {
    return {
      selectedIndex: 0,
    };
  },
  methods: {
    selectOption(index) {
      this.selectedIndex = index;
      this.$emit("select-option", index);
    },
  },
};
</script>

<style lang="scss" scoped>
.options {
  .option {
    position: relative;
    height: 45px;
    min-width: 100px;
    border-radius: 6px;
    cursor: pointer;
    text-transform: capitalize;

    &.is-selected {
      background-color: #e4e8f5;
    }

    &::after {
      content: "";
      position: absolute;
      right: -1em;
      width: 2px;
      height: 50%;
      background-color: black;
      top: 50%;
      transform: translateY(-50%);
    }

    &:last-child {
      &::after {
        display: none;
      }
    }
  }
}
</style>
