var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{staticClass:"login-form",attrs:{"id":"loginForm"}},[_c('validation-observer',{ref:"loginForm",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"d-flex d-flex-center"},[_vm._v(_vm._s(_vm.label))]),_vm._l((_vm.fields),function(field,index){return _c('div',{key:index,staticClass:"field mt-2"},[_c('form-input',{attrs:{"label":field.label,"name":field.name,"type":field.type,"rules":field.rules,"validation-mode":field.validationMode,"v-model":field.value}})],1)}),(
          _vm.requestError &&
          errors.email &&
          errors.email.length == 0 &&
          errors.password &&
          errors.password.length == 0
        )?_c('span',{staticClass:"error"},[_vm._v(_vm._s(_vm.requestError))]):_vm._e(),_c('div',{staticClass:"d-flex d-flex-center password_misc_container"},[_c('a',{attrs:{"href":"#"}},[_vm._v("Forgot password?")])]),_c('div',{staticClass:"d-flex d-flex-center"},[_c('socianis-button',{attrs:{"label":"Login","isRequesting":_vm.isRequesting},on:{"button-click":_vm.handleSubmit}})],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }