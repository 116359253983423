import {
  GET_ASSETS_REQUEST,
  GET_ASSETS_SUCCESS,
  GET_ASSETS_ERROR,
  DELETE_ASSET_REQUEST,
  DELETE_ASSET_SUCCESS,
  DELETE_ASSET_ERROR,
  INITIALIZE_ASSET_REQUEST,
  INITIALIZE_ASSET_SUCCESS,
  INITIALIZE_ASSET_ERROR,
  UPDATE_ASSET_REQUEST,
  UPDATE_ASSET_SUCCESS,
  UPDATE_ASSET_ERROR,
  GET_PRESIGNED_URL_REQUEST,
  GET_PRESIGNED_URL_SUCCESS,
  GET_PRESIGNED_URL_ERROR,
  APPEND_ASSET,
} from "../actions/asset";

import {
  UPDATE_MEDIA_LIBRARY_SUCCESS,
  DELETE_MEDIA_LIBRARY_SUCCESS,
} from "../actions/library";

import { RESET_MODULE_STATE } from "../actions/global";

import {
  getAssets,
  deleteAsset,
  initializeAssets,
  updateAsset,
  getPresignedUrl,
} from "../../services/api/asset.api";

const getDefaultState = () => ({
  assets: [],
  status: {
    requesting: {
      getAssets: false,
      deleteAsset: false,
      initializeAssets: false,
      updateAsset: false,
      getPresignedUrl: false,
    },
    errors: {
      getAssets: null,
      deleteAsset: null,
      initializeAssets: null,
      updateAsset: null,
      getPresignedUrl: null,
    },
  },
});

const state = getDefaultState();

const actions = {
  getAssets: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      commit(GET_ASSETS_REQUEST);
      getAssets(params)
        .then((res) => {
          commit(GET_ASSETS_SUCCESS, res.data);
          resolve(res.data);
        })
        .catch((error) => {
          commit(GET_ASSETS_ERROR, error);
          reject(error);
        });
    });
  },
  deleteAsset: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      commit(DELETE_ASSET_REQUEST);
      deleteAsset(params)
        .then((res) => {
          commit(DELETE_ASSET_SUCCESS, res.data);
          resolve(res.data);
        })
        .catch((error) => {
          commit(DELETE_ASSET_ERROR, error);
          reject(error);
        });
    });
  },
  initializeAssets: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit(INITIALIZE_ASSET_REQUEST);
      initializeAssets(payload)
        .then((res) => {
          commit(INITIALIZE_ASSET_SUCCESS, res.data);
          resolve(res.data);
        })
        .catch((error) => {
          commit(INITIALIZE_ASSET_ERROR, error);
          reject(error);
        });
    });
  },
  updateAsset: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit(UPDATE_ASSET_REQUEST);
      updateAsset(payload.data, payload.params)
        .then((res) => {
          commit(UPDATE_ASSET_SUCCESS, res.data);
          resolve(res.data);
        })
        .catch((error) => {
          commit(UPDATE_ASSET_ERROR);
          reject(error);
        });
    });
  },
  getPresignedUrl: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      commit(GET_PRESIGNED_URL_REQUEST);
      getPresignedUrl(params)
        .then((res) => {
          commit(GET_PRESIGNED_URL_SUCCESS);
          resolve(res);
        })
        .catch((error) => {
          commit(GET_PRESIGNED_URL_ERROR, error);
          reject(error);
        });
    });
  },
  appendAsset: ({ commit }, payload) => {
    commit(APPEND_ASSET, payload);
  },
};

const mutations = {
  [GET_ASSETS_REQUEST]: (state) => {
    state.status.requesting.getAssets = true;
  },
  [GET_ASSETS_SUCCESS]: (state, assets) => {
    state.assets = assets;
    state.status.requesting.getAssets = false;
  },
  [GET_ASSETS_ERROR]: (state, error) => {
    state.status.requesting.getAssets = false;
    state.status.errors.getAssets = error;
  },
  [DELETE_ASSET_REQUEST]: (state) => {
    state.status.requesting.deleteAsset = true;
  },
  [DELETE_ASSET_SUCCESS]: (state, asset) => {
    state.assets = state.assets.filter((a) => a.id !== asset.id);
    state.status.requesting.deleteAsset = false;
  },
  [DELETE_ASSET_ERROR]: (state, error) => {
    state.status.requesting.deleteAsset = false;
    state.status.errors.deleteAsset = error;
  },
  [INITIALIZE_ASSET_REQUEST]: (state) => {
    state.status.requesting.initializeAssets = true;
  },
  [INITIALIZE_ASSET_SUCCESS]: (state, assets) => {
    state.status.requesting.initializeAssets = false;
  },
  [INITIALIZE_ASSET_ERROR]: (state, error) => {
    state.status.requesting.initializeAssets = false;
    state.status.errors.initializeAssets = error;
  },
  [UPDATE_ASSET_REQUEST]: (state) => {
    state.status.requesting.updateAsset = true;
  },
  [UPDATE_ASSET_SUCCESS]: (state, asset) => {
    state.assets = state.assets.map((a) =>
      a.id === asset.id ? { ...a, ...asset } : a,
    );
    state.status.requesting.updateAsset = false;
  },
  [UPDATE_ASSET_ERROR]: (state, error) => {
    state.status.requesting.updateAsset = false;
    state.status.errors.updateAsset = error;
  },

  [UPDATE_MEDIA_LIBRARY_SUCCESS]: (state, data) => {
    const { asset_images_removed, asset_videos_removed } = data;
    const mergedAssets = asset_images_removed.concat(asset_videos_removed);

    state.assets = state.assets.filter(
      (asset) => !mergedAssets.includes(asset.id),
    );
  },
  [GET_PRESIGNED_URL_REQUEST]: (state) => {
    state.status.requesting.getPresignedUrl = true;
  },
  [GET_PRESIGNED_URL_SUCCESS]: (state) => {
    state.status.requesting.getPresignedUrl = false;
  },
  [GET_PRESIGNED_URL_ERROR]: (state, error) => {
    state.status.requesting.getPresignedUrl = false;
    state.status.errors.getPresignedUrl = error;
  },
  [DELETE_MEDIA_LIBRARY_SUCCESS]: (state, data) => {
    state.assets = state.assets.filter(
      (asset) => asset.asset_library_id !== data.id,
    );
  },
  [APPEND_ASSET]: (state, asset) => {
    const isUpdate = state.assets.some((a) => a.id === asset.id);
    if (isUpdate) {
      state.assets = state.assets.map((a) =>
        a.id === asset.id ? { ...a, ...asset } : a,
      );
    } else {
      state.assets = [asset].concat(state.assets);
    }
  },
  [RESET_MODULE_STATE]: (state) => {
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    // This mutation is called from the logout action!
    Object.assign(state, getDefaultState());
  },
};

export default {
  state,
  actions,
  mutations,
};
