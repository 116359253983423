<template>
  <div :class="['modal', { 'is-active': show }]">
    <div class="background" />
    <div :class="['dialog-container', { narrow, 'max-width': maxWidth }]">
      <span class="step-info">{{ stepInfo }}</span>
      <span class="close" @click="handleClose">×</span>
      <h2 :class="['title', { 'has-description': !!description }]">
        {{ title }}<span v-if="isOptional" class="optional"> (Optional)</span>
      </h2>
      <p class="description">{{ description }}</p>
      <slot name="modal-content" />
      <slot name="after-content" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  props: {
    title: { type: String, default: "" },
    description: { type: String, default: "" },
    stepInfo: { type: String, default: null },
    narrow: { type: Boolean, default: false },
    isOptional: { type: Boolean, default: false },
    maxWidth: { type: Boolean, default: true },
  },
  data() {
    return {
      containerElement: null,
      afterElement: null,
      transitionRender: true,
      show: false,
    };
  },
  mounted() {
    this.containerElement =
      this.$el.getElementsByClassName("dialog-container")[0];
    this.$el.addEventListener("click", this.handleClose);
    this.afterElement = this.$el.getElementsByClassName("after-content")[0];

    setTimeout(() => {
      this.show = true;
    }, 10);

    if (this.afterElement) {
      this.afterElement.addEventListener(
        "transitionend",
        this.handleTransitionEnd,
      );
    }
  },
  beforeDestroy() {
    // const containerElement  = this.$el.getElementsByClassName('dialog-container')[0]
    this.$el.removeEventListener("click", this.handleClose);
    if (this.afterElement) {
      this.afterElement.removeEventListener(
        "transitionend",
        this.handleTransitionEnd,
      );
    }
  },
  methods: {
    handleClose(e) {
      if (
        !this.containerElement.contains(e.target) ||
        e.target.className === "close"
      ) {
        this.show = false;
        setTimeout(() => {
          this.$emit("close");
        }, 160);
      }
    },
    handleTransitionEnd(e) {
      if (this.transitionRender) {
        this.transitionRender = false;
        this.$emit("transition-end", true);
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.modal {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  &.is-active {
    .background {
      background-color: #221c55;
    }

    .dialog-container {
      opacity: 1;
    }
  }

  .background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    // background: #221c55;
    opacity: 0.9;
    transition: background-color 150ms ease;
    z-index: 1150;
  }

  &.is-preview {
    .dialog-container {
      transform: translate(calc(-175px + -50%), -50%);
    }
  }

  .dialog-container {
    position: absolute;
    min-height: 250px;
    min-width: 475px;
    opacity: 0;
    transition: opacity 150ms ease;
    max-height: 850px;
    top: 50%;
    left: 50%;
    transform: translate(calc(25px + -50%), -50%);
    padding: 3em 4em;
    align-items: center;
    justify-content: flex-start;
    background-color: $--color-white;
    transition: transform 0.5s ease-in;
    overflow: visible;
    border-radius: 6px;
    z-index: 1151;

    &.narrow {
      min-width: 350px;
      max-width: 400px;
    }

    .step-info {
      position: absolute;
      top: 25px;
      left: 30px;
    }

    .close {
      display: block;
      position: absolute;
      top: 15px;
      right: 30px;
      cursor: pointer;
      font-size: 2rem;
    }

    .title {
      margin: 0;
      font-size: 1.15rem;
      font-weight: 600;

      &.has-description {
        margin-bottom: 1em;
      }
    }

    .optional {
      font-size: 0.85rem;
      font-weight: 400;
    }

    .description {
      max-width: 500px;
    }

    .after-content {
      position: absolute;
      height: 100%;
      width: 0;
      left: 98%;
      top: 0;
      background-color: #f9f9f9;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      z-index: 1160;
      transition: 0.5s ease-in-out;
      transform: scaleX(0);
      transform-origin: left;
    }
  }

  &.is-preview {
    .dialog-container {
      .after-content {
        width: 400px;
        padding: 2em;
        transform: scaleX(1);
      }
    }
  }
}
</style>
