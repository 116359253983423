import dayjs, { formatDateWithTz } from "../../utils/timezone";

import {
  GET_CONTENTS_REQUEST,
  GET_CONTENTS_SUCCESS,
  GET_CONTENTS_ERROR,
  SAVE_CONTENT_REQUEST,
  SAVE_CONTENT_SUCCESS,
  SAVE_CONTENT_ERROR,
  DELETE_CONTENT_REQUEST,
  DELETE_CONTENT_SUCCESS,
  DELETE_CONTENT_ERROR,
  REMOVE_CONTENT_FROM_LIST,
  REMOVE_DRAFT_FROM_LIST,
  SEND_MESSAGE_REQUEST,
  SEND_MESSAGE_SUCCESS,
  SEND_MESSAGE_ERROR,
  SET_CALENDAR_DATE,
  SELECT_DATE,
  SET_CONTENT_VIEW_PROJECT,
  SET_DRAFT_VIEW_PROJECT,
  SORT_CONTENT_BY_DATE,
} from "../actions/content";

import { CREATE_NOTIFICATION } from "../actions/notification";

import { RESET_MODULE_STATE } from "../actions/global";

import {
  getContents,
  saveContent,
  deleteContent,
  sendMessage,
} from "../../services/api/content.api";

const getDefaultState = () => ({
  contents: [],
  drafts: [],
  status: {
    requesting: {
      getContents: false,
      saveContent: false,
      deleteContent: false,
      sendMessage: false,
    },
    errors: {
      getContents: null,
      saveContent: null,
      deleteContent: null,
      sendMessage: null,
    },
  },
  contentSortSettings: {
    selectedDate: dayjs().hour(1).minute(0).second(0),
    selectedProjectId: null,
    selectedContentIds: [],
  },
  draftSortSettings: {
    selectedProjectId: null,
  },
});

const state = getDefaultState();

const actions = {
  getContents: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      commit(GET_CONTENTS_REQUEST);
      getContents(params)
        .then((res) => {
          commit(GET_CONTENTS_SUCCESS, res.data);
          resolve(res.data);
        })
        .catch((error) => {
          commit(GET_CONTENTS_ERROR, error);
          reject(error);
        });
    });
  },
  saveContent: ({ state, commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit(SAVE_CONTENT_REQUEST);
      saveContent(payload)
        .then((res) => {
          const updateLists = res.data.some(
            (content) => content.status !== payload.oldStatus,
          );
          res.data.forEach((content) => {
            if (updateLists && content.status === "scheduled") {
              const date1 = dayjs(state.contentSortSettings.selectedDate);
              const date2 = dayjs(content.start_date);
              if (date1.diff(date2, "day") === 0) {
                commit(
                  SORT_CONTENT_BY_DATE,
                  state.contentSortSettings.selectedContentIds.concat(
                    content.id,
                  ),
                );
              }
            }
            if (updateLists && payload.oldStatus === "pending") {
              commit(REMOVE_DRAFT_FROM_LIST, content);
            } else if (updateLists && content.oldStatus === "scheduled") {
              commit(REMOVE_CONTENT_FROM_LIST, content);
            }
            commit(SAVE_CONTENT_SUCCESS, { data: content, updateLists });
          });
          resolve(res.data);
          commit(CREATE_NOTIFICATION, {
            type: SAVE_CONTENT_SUCCESS,
            status: "succeeded",
          });
        })
        .catch((error) => {
          commit(SAVE_CONTENT_ERROR, error);
          reject(error);
          commit(CREATE_NOTIFICATION, {
            type: SAVE_CONTENT_ERROR,
            status: "error",
          });
        });
    });
  },
  deleteContent: ({ commit }, id) => {
    return new Promise((resolve, reject) => {
      commit(DELETE_CONTENT_REQUEST);
      deleteContent(id)
        .then((res) => {
          commit(DELETE_CONTENT_SUCCESS, res.data);
          resolve(res.data);
          commit(CREATE_NOTIFICATION, {
            type: DELETE_CONTENT_SUCCESS,
            status: "succeeded",
          });
        })
        .catch((error) => {
          commit(DELETE_CONTENT_ERROR, error);
          reject(error);
          commit(CREATE_NOTIFICATION, {
            type: DELETE_CONTENT_ERROR,
            status: "error",
          });
        });
    });
  },
  sendMessage: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit(SEND_MESSAGE_REQUEST);
      sendMessage(payload)
        .then((res) => {
          commit(SEND_MESSAGE_SUCCESS, res.data);
          resolve(res);
        })
        .catch((error) => {
          commit(SEND_MESSAGE_ERROR, error);
          reject(error);
        });
    });
  },
  removeContentFromList: ({ commit }, data) => {
    commit(REMOVE_CONTENT_FROM_LIST, data);
  },
  removeDraftFromList: ({ commit }, data) => {
    commit(REMOVE_DRAFT_FROM_LIST, data);
  },
  setCalendarDate: ({ commit }, date) => {
    commit(SET_CALENDAR_DATE, date);
  },
  setDraftViewProject: ({ commit }, projectId) => {
    commit(SET_DRAFT_VIEW_PROJECT, projectId);
  },
  setContentViewProject: ({ commit }, projectId) => {
    commit(SET_CONTENT_VIEW_PROJECT, projectId);
  },
  setSelectedDate: ({ commit }, date) => {
    commit(SELECT_DATE, date);
  },
  sortContentByDate: ({ commit }, contentIds) => {
    commit(SORT_CONTENT_BY_DATE, contentIds);
  },
};

const mutations = {
  [GET_CONTENTS_REQUEST]: (state) => {
    state.status.requesting.getContents = true;
  },
  [GET_CONTENTS_SUCCESS]: function (state, data) {
    const userTz = this.state.dashboard.user.timezone;
    const projects = this.state.project.projects;
    const DATE_FORMAT = "YYYY-MM-DD";
    state.contents = data.contents.map((content) => {
      const tz =
        (projects.find((project) => project.id === content.project_id) || {})
          .timezone || userTz;
      const startDateTz = formatDateWithTz(content.start_date, tz);
      const endDateTz = formatDateWithTz(content.end_date, tz);
      return {
        ...content,
        startDateTz,
        endDateTz,
        startDateFm: startDateTz.format(DATE_FORMAT),
        endDateFm: endDateTz.format(DATE_FORMAT),
        tz,
      };
    });

    state.drafts = data.drafts.map((draft) => {
      const tz =
        (projects.find((project) => project.id === draft.project_id) || {})
          .timezone || userTz;
      const startDateTz = formatDateWithTz(draft.start_date, tz);
      const endDateTz = formatDateWithTz(draft.end_date, tz);
      return {
        ...draft,
        startDateTz,
        endDateTz,
        startDateFm: startDateTz.format(DATE_FORMAT),
        endDateFm: endDateTz.format(DATE_FORMAT),
        tz,
      };
    });
    state.status.requesting.getContents = false;
  },
  [GET_CONTENTS_ERROR]: (state, error) => {
    state.status.requesting.getContents = false;
    state.status.errors.getContents = error;
  },
  [SAVE_CONTENT_REQUEST]: (state) => {
    state.status.requesting.saveContent = true;
  },
  [SAVE_CONTENT_SUCCESS]: function (state, response) {
    const { contents, drafts } = state;
    const { data, updateLists } = response;
    const userTz = this.state.dashboard.user.timezone;
    const projects = this.state.project.projects;
    const tz =
      (projects.find((project) => project.id === data.project_id) || {})
        .timezone || userTz;
    const DATE_FORMAT = "YYYY-MM-DD";
    const startDateTz = formatDateWithTz(data.start_date, tz);
    const endDateTz = formatDateWithTz(data.end_date, tz);
    const dataWithTz = {
      ...data,
      startDateTz,
      endDateTz,
      startDateFm: startDateTz.format(DATE_FORMAT),
      endDateFm: endDateTz.format(DATE_FORMAT),
      tz,
    };
    if (data.new_content) {
      if (data.status === "scheduled") {
        state.contents = contents.concat(dataWithTz);
      } else {
        state.drafts = drafts.concat(dataWithTz);
      }
    } else {
      if (data.status === "scheduled") {
        if (updateLists) {
          state.contents = contents.concat(dataWithTz);
        } else {
          state.contents = contents.map((content) => {
            if (content.id === dataWithTz.id) {
              return dataWithTz;
            }
            return content;
          });
        }
      } else {
        if (updateLists) {
          state.drafts = drafts.concat(dataWithTz);
        } else {
          state.drafts = drafts.map((draft) => {
            if (draft.id === dataWithTz.id) {
              return {
                ...draft,
                ...Object.fromEntries(
                  Object.entries(dataWithTz).filter(
                    ([_, v]) =>
                      (v && v.length > 0) || (v !== null && !Array.isArray(v)),
                  ),
                ),
              };
            }
            return draft;
          });
        }
      }
    }
    state.status.requesting.saveContent = false;
  },
  [SAVE_CONTENT_ERROR]: (state, error) => {
    state.status.requesting.saveContent = false;
    state.status.errors.saveContent = error;
  },
  [DELETE_CONTENT_REQUEST]: (state) => {
    state.status.requesting.deleteContent = true;
  },
  [DELETE_CONTENT_SUCCESS]: (state, data) => {
    const { contents, drafts } = state;
    if (data.status === "scheduled") {
      state.contents = contents.filter((content) => content.id !== data.id);
    } else {
      state.drafts = drafts.filter((draft) => draft.id !== data.id);
    }
    state.status.requesting.deleteContent = false;
  },
  [DELETE_CONTENT_ERROR]: (state, error) => {
    state.status.requesting.deleteContent = false;
    state.status.errors.deleteContent = error;
  },
  [SEND_MESSAGE_REQUEST]: (state) => {
    state.status.requesting.sendMessage = true;
  },
  [SEND_MESSAGE_SUCCESS]: (state, data) => {
    const { drafts } = state;
    const draft = drafts.find((draft) => draft.id === data.content_id);
    if (draft) {
      const { messages } = draft;
      draft.messages = [data].concat(messages);

      state.drafts = drafts.map((d) => {
        if (d.id === draft.id) {
          return draft;
        } else {
          return d;
        }
      });
    }
    state.status.requesting.sendMessage = false;
  },
  [SEND_MESSAGE_ERROR]: (state, error) => {
    state.status.requesting.sendMessage = false;
    state.status.errors.sendMessage = error;
  },
  [REMOVE_CONTENT_FROM_LIST]: (state, data) => {
    state.contents = state.contents.filter((content) => content.id !== data.id);
  },
  [REMOVE_DRAFT_FROM_LIST]: (state, data) => {
    state.drafts = state.drafts.filter((draft) => draft.id !== data.id);
  },
  [SET_CALENDAR_DATE]: (state, date) => {
    state.contentSortSettings.selectedDate = date;
  },
  [SET_CONTENT_VIEW_PROJECT]: (state, projectId) => {
    state.contentSortSettings.selectedProjectId = projectId;
  },
  [SET_DRAFT_VIEW_PROJECT]: (state, projectId) => {
    state.draftSortSettings.selectedProjectId = projectId;
  },
  [SELECT_DATE]: (state, date) => {
    state.contentSortSettings.selectedDate = dayjs(date).format("YYYY-MM-DD");
  },
  [SORT_CONTENT_BY_DATE]: (state, contentIds) => {
    state.contentSortSettings.selectedContentIds = contentIds;
  },
  [RESET_MODULE_STATE]: (state) => {
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    // This mutation is called from the logout action!
    Object.assign(state, getDefaultState());
  },
};

export default {
  state,
  actions,
  mutations,
};
