<template>
  <vue-apex-charts
    class="chart"
    :type="type"
    :options="chartOptions"
    :series="config.series"
    width="100%"
    height="100%"
  />
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "Chart",
  components: { VueApexCharts },
  props: {
    type: {
      type: String,
      default: "",
    },
    config: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      options: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        legend: {
          position: "left",
        },
        zoom: {
          enabled: false,
        },
        ...this.config.chartOptions,
      },
    };
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          toolbar: {
            show: false,
          },
        },
        legend: {
          position: "left",
        },
        zoom: {
          enabled: false,
        },
        noData: {
          text: "No relevant data to display",
          align: "center",
          offsetX: 0,
          offsetY: 0,
          style: {
            color: undefined,
            fontSize: "14px",
            fontFamily: undefined,
          },
        },

        ...this.config.chartOptions,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.chart {
  width: 100%;
  height: 100%;
}
</style>
