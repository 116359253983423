var render = function render(){var _vm=this,_c=_vm._self._c;return _c('side-modal',{attrs:{"title":"Media Libraries"},on:{"close":_vm.closeModal}},[_c('div',{staticClass:"side-content-wrapper",attrs:{"slot":"content-left"},slot:"content-left"},[_c('div',{staticClass:"d-flex d-flex-c"},_vm._l((_vm.assetLibraries),function(library,index){return _c('span',{key:index,class:[
          'library-item mb-1',
          { 'is-selected': _vm.selectedLibraryIndex === index },
        ],on:{"click":() => _vm.selectLibraryItem(index)}},[_vm._v(_vm._s(library.name))])}),0)]),_c('div',{staticClass:"side-content-wrapper",attrs:{"slot":"content-right"},slot:"content-right"},[_c('div',{staticClass:"d-flex d-flex-c d-flex-between"},[_c('div',{staticClass:"d-flex d-flex-c d-flex-between"},[_c('view-options',{staticClass:"mb-1",attrs:{"options":_vm.viewOptions},on:{"select-option":_vm.selectViewOption}}),_c('div',{staticClass:"d-flex d-flex-r gallery"},_vm._l((_vm.viewAssets),function(asset,index){return _c('object',{key:index,class:[
              'asset',
              {
                'is-selected': _vm.selectedAssets.includes(asset.id),
              },
            ],on:{"click":() => _vm.selectAsset(asset.id)}},[(asset.media_url)?_c('img',{attrs:{"src":asset.asset_type === 'image'
                  ? asset.media_url
                  : asset.thumbnail_url},on:{"error":_vm.defaultBg}}):_vm._e()])}),0)],1),_c('div',{staticClass:"d-flex d-flex-r d-flex-between"},[_c('content-button',{attrs:{"label":"Upload"},on:{"button-click":function($event){_vm.setActiveModal({
              name: 'UploadModal',
              props: {
                mediaLibraryId: _vm.mediaLibraryId,
              },
              modalSubmit: () => ({}),
            })}}}),_c('content-button',{attrs:{"label":"Done"},on:{"button-click":_vm.done}})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }