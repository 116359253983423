import { render, staticRenderFns } from "./SideModal.vue?vue&type=template&id=1cb71508&scoped=true"
import script from "./SideModal.vue?vue&type=script&lang=js"
export * from "./SideModal.vue?vue&type=script&lang=js"
import style0 from "./SideModal.vue?vue&type=style&index=0&id=1cb71508&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1cb71508",
  null
  
)

export default component.exports