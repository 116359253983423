import { extend, setInteractionMode, localize } from "vee-validate";
import { min, required, email, alpha_spaces } from "vee-validate/dist/rules";

// Install rules
extend("required", {
  ...required,
  message: "This field is required",
});
// extend("required", required)
extend("min", min);
extend("email", email);
extend("alpha_spaces", alpha_spaces);

setInteractionMode("loginForm", ({ errors, value, flags }) => {
  if (flags.validated && flags.changed) {
    return {
      on: ["change", "blur"],
    };
  }
  return { on: ["validated"] };
});

localize({
  en: {
    names: {
      email: "Email address",
      full_name: "Name",
    },
  },
});
