<template>
  <span class="loader"></span>
</template>

<script>
export default {
  name: "Loader",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.loader {
  display: flex;
  justify-self: center;
  align-self: center;
  width: 80px;
  height: 80px;
  color: #7759a4;
  // position: absolute;
  top: 50%;
  left: 50%;
  border: 5px dotted #7759a4;
  border-radius: 50%;
  //display: inline-block;
  box-sizing: border-box;
  animation: rotation 4s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
