<template>
  <button
    :class="['dashboard-button', { 'is-requesting': isRequesting }]"
    :disabled="isRequesting || invalid"
    @click.stop="handleClick"
  >
    <spinner v-if="isRequesting" :width="24" :height="24" />
    <label v-else>{{ label }}</label>
  </button>
</template>

<script>
import Spinner from "../loaders/Spinner.vue";
export default {
  name: "DashboardButton",
  components: { Spinner },
  props: {
    label: { type: String, default: "" },
    isRequesting: { type: Boolean, default: false },
    invalid: { type: Boolean, default: false },
  },
  methods: {
    handleClick() {
      this.$emit("button-click");
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard-button {
  background-color: #221c55;
  padding: 1em 2.5em;
  min-height: 52px;
  border: unset;
  border-radius: 6px;
  color: #fff;

  > label {
    cursor: pointer;
  }

  &:disabled {
    opacity: 0.7;
    cursor: default;
  }
}
</style>
