<template>
  <div class="d-flex d-flex-c">
    <label class="mb-05"
      ><b>{{ label }}</b></label
    >
    <ul class="d-flex d-flex-c select-items">
      <li
        v-for="(item, index) in items"
        :key="index"
        :style="listItemCss"
        :class="[
          'select-item d-flex d-flex-start',
          {
            'is-selected': item.isSelected,
            'is-checkbox': checkbox,
            'is-avatar': enableAvatar,
          },
        ]"
        @click.stop="() => selectItem(item)"
      >
        <label class="item-label">{{ item.value }}</label>
      </li>
      <validation-provider mode="aggressive">
        <div class="d-flex d-flex-c hidden-input">
          <input
            :ref="inputRef"
            :name="label"
            class="list-items-input"
            v-model="validationValue"
          />
        </div>
      </validation-provider>
    </ul>
  </div>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import "@/utils/validation";

export default {
  name: "ListItems",
  components: {
    ValidationProvider,
  },
  props: {
    name: { type: String, default: "" },
    label: { type: String, default: "" },
    items: { type: Array, default: () => [] },
    checkbox: { type: Boolean, default: false },
    enableAvatar: { type: Boolean, default: false },
    avatarUrl: { type: String, default: "" },
  },
  data() {
    return {
      inputRef: `${this.name}-list-items`,
      defaultValue: null,
    };
  },
  computed: {
    validationValue() {
      const isDefault =
        this.defaultValue.length &&
        this.defaultValue.length === this.items.length &&
        this.defaultValue.every((v) => this.items.includes(v));
      return isDefault ? this.defaultValue.toString() : this.items.toString();
    },
    listItemCss() {
      return {
        "--bg-image": `url(${
          this.avatarUrl
            ? this.avatarUrl
            : "/assets/images/icons/dashboard/sidemenu/profile_icon.svg"
        })`,
      };
    },
  },
  created() {
    this.defaultValue = this.items.slice();
  },
  methods: {
    selectItem(item) {
      this.$emit("trigger", {
        id: item.id,
        value: !item.isSelected,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.select-items {
  background-color: #fff;
  width: 100%;
  max-height: 225px;
  overflow-y: auto;

  > li {
    align-items: center;
  }

  .select-item {
    padding: 0.5em 1em;
    margin: 0.15em 0;
    border-radius: 6px;
    cursor: pointer;

    .item-bg {
      width: 50px;
      height: 50px;
      &.social {
        height: 24px;
        width: 24px;
      }
    }

    &:hover {
      background-color: #aab4db;
    }

    &.is-selected {
      background-color: #d5daef;
      &.is-checkbox {
        &::before {
          background-color: #221c55;
        }
      }
    }

    .item-label {
      order: 3;
    }

    &.is-avatar {
      &::after {
        display: block;
        content: "";
        background-image: var(--bg-image);
        width: 32px;
        height: 32px;
        margin-right: 1em;
        border-radius: 100%;
        background-repeat: no-repeat;
        background-position: center;
        order: 2;
      }
    }

    &.is-checkbox {
      &::before {
        display: block;
        content: "";
        width: 20px;
        height: 20px;
        margin-right: 1em;
        background-color: white;
        border: 5px solid white;
        border-radius: 100%;
        outline: 1px solid #ccc;
        order: 1;
      }
    }

    &.is-checkbox {
      &::before {
        width: 15px;
        height: 15px;
        border-width: 2px;
        border-radius: 3px;
      }
    }
  }
  .hidden-input {
    input {
      height: 0;
      width: 0;
      border: none;
      opacity: 0;
    }
  }
}
</style>
