<template>
  <div class="d-flex d-flex-r d-flex-between align-center mb-1 page-wrapper">
    <div class="d-flex d-flex-r">
      <span class="page-picture mr-1">
        <img :src="picture" />
      </span>
      <div class="d-flex d-flex-c page-info">
        <p class="page-name">
          {{ name }}
        </p>
        <p v-if="type" class="page-type">{{ type }}</p>
      </div>
    </div>
    <form-checkbox :name="name" :value="id" :is-selected="isSelected" />
  </div>
</template>
<script>
import FormCheckbox from "@/components/ui/forms/FormCheckbox.vue";
export default {
  components: {
    FormCheckbox,
  },
  props: {
    isSelected: { type: Boolean, default: false },
    picture: {
      type: String,
      default: "assets/images/layout/dashboard/common/content_bg.svg",
    },
    name: { type: String, default: "" },
    type: { type: String, default: "" },
    id: { type: [String, Number] },
  },
};
</script>

<style lang="scss" scoped>
.page-wrapper {
  width: 270px;

  > * {
    margin-right: 1em;
  }

  .page-picture {
    width: 52px;
    height: 52px;

    img {
      border-radius: 12px;
      width: 52px;
      height: 52px;
    }
  }
  .page-info {
    .page-name {
      max-width: 135px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: bold;
    }
    .page-type {
      text-transform: capitalize;
    }
  }
}
</style>
