<template>
  <div class="d-flex loader-wrapper">
    <div class="d-flex align-center loader-background">
      <component v-bind:is="loaderType" v-bind="loaderProps" />
      <label class="ml-05">{{ label }}</label>
    </div>
  </div>
</template>

<script>
export default {
  name: "FullSizeLoader",
  components: {
    Spinner: () => import("@/components/").then((res) => res.Spinner),
  },
  props: {
    label: { type: String, default: "Loading" },
    loaderType: { type: String, default: "Spinner" },
    loaderProps: { type: Object, default: () => ({}) },
  },
};
</script>

<style lang="scss" scoped>
.loader-wrapper {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  .loader-background {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 1em;
    background: rgba(0, 0, 0, 0.5);
    width: 200px;
    height: 50px;
    border-radius: 6px;
    font-size: 0.85rem;
    color: #fff;
  }
}
</style>
