<template>
  <div class="d-flex d-flex-r d-align-end items">
    <div
      v-for="(item, index) in labelsToRender"
      :key="index"
      class="d-flex d-flex-r item"
    >
      <label>{{ item.label }}</label>
      <span>{{ item.value }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ScheduleLabels",
  props: {
    startTime: { type: String, default: "--/--" },
    timeZone: { type: String, default: "--/--" },
    repeatFrequency: { type: String, default: "--/--" },
    repeatDays: { type: String, default: "" },
    endTime: { type: String, default: "--/--" },
  },
  data() {
    return {
      weekDays: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    };
  },
  computed: {
    labelsToRender() {
      return [
        { label: "Time", value: this.startTime },
        { label: "", value: this.timeZone },
        { label: "Repeat", value: this.repeatFrequency },
        { label: "", value: this.repeatDays },
        { label: "End", value: this.endTime },
      ].filter((item) => item.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.items {
  position: relative;
  flex-wrap: wrap;

  .item {
    background-color: #f3f1ff;
    border-radius: 40px;
    padding: 0.5em;
    margin: 0 0.5em 0.5em 0;
    color: #000;
    font-size: 0.85rem;
    height: fit-content;
    label {
      font-weight: 600;
      margin-right: 0.5em;
    }
  }
}
</style>
