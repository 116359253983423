// https://thiscouldbebetter.wordpress.com/2013/02/02/finding-differences-between-two-text-files-in-javascript/

// events

export const highlightDifferences = (textBefore = "", textAfter = "") => {
  // let textBefore = document.getElementById("textareaBefore").value;
  // let textAfter = document.getElementById("textareaAfter").value;

  const differences = new TextDifferencer().findDifferencesBetweenStrings(
    textBefore,
    textAfter,
  );

  const differencesAsString = differences.toString();

  // const textareaDifferences = document.getElementById("textareaDifferences");
  // textareaDifferences.innerHTML = differencesAsString;

  return differencesAsString;
};

// extensions

function ArrayExtensions() {
  // extension class
}
{
  Array.prototype.insertElementAt = function (element, index) {
    this.splice(index, 0, element);
  };

  Array.prototype.insertElementsAt = function (elements, index) {
    for (let i = 0; i < elements.length; i++) {
      this.splice(index + i, 0, elements[i]);
    }
  };

  Array.prototype.removeAt = function (index) {
    this.splice(index, 1);
  };
}

// classes

function TextDifferencer() {
  // do nothing
}
{
  TextDifferencer.prototype.findDifferencesBetweenStrings = function (
    string0,
    string1,
  ) {
    let lengthOfShorterString =
      string0.length <= string1.length ? string0.length : string1.length;

    let numberOfExtremes = 2;
    let passagePairsMatchingAtExtremes = [];

    for (let e = 0; e < numberOfExtremes; e++) {
      let lengthOfMatchingSubstring = 0;

      for (let i = 0; i < lengthOfShorterString; i++) {
        let offsetForString0 = e == 0 ? i : string0.length - i - 1;
        let offsetForString1 = e == 0 ? i : string1.length - i - 1;

        let charFromString0 = string0[offsetForString0];
        let charFromString1 = string1[offsetForString1];

        if (charFromString0 != charFromString1) {
          lengthOfMatchingSubstring = i;
          break;
        }
      }

      let matchingSubstringAtExtreme;

      if (e == 0) {
        matchingSubstringAtExtreme = string0.substr(
          0,
          lengthOfMatchingSubstring,
        );
        string0 = string0.substr(lengthOfMatchingSubstring);
        string1 = string1.substr(lengthOfMatchingSubstring);
      } // if (e == 1)
      else {
        matchingSubstringAtExtreme = string0.substr(
          string0.length - lengthOfMatchingSubstring,
        );
        string0 = string0.substr(0, string0.length - lengthOfMatchingSubstring);
        string1 = string1.substr(0, string1.length - lengthOfMatchingSubstring);
      }

      let passagePairMatchingAtExtreme = new TextPassagePair(
        true, // doPassagesMatch
        [
          new TextPassage(matchingSubstringAtExtreme),
          new TextPassage(matchingSubstringAtExtreme),
        ],
      );

      passagePairsMatchingAtExtremes.push(passagePairMatchingAtExtreme);
    }

    let passagePairsAll = [];

    let passagePairsMatching = this.findPassagePairsMatchingBetweenStrings(
      string0,
      string1,
      [0, 0],
    );

    this.insertPassagePairsDifferentBetweenMatching(
      string0,
      string1,
      passagePairsMatching,
      passagePairsAll,
    );

    for (let e = 0; e < passagePairsMatchingAtExtremes.length; e++) {
      let passagePairMatchingAtExtreme = passagePairsMatchingAtExtremes[e];
      passagePairsAll.insertElementAt(
        passagePairMatchingAtExtreme,
        e == 0 ? 0 : passagePairsAll.length,
      );
    }

    let returnValue = new TextDifferences(passagePairsAll);

    return returnValue;
  };

  TextDifferencer.prototype.findPassagePairsMatchingBetweenStrings = function (
    string0,
    string1,
    positionOffsets,
  ) {
    let passagePairsMatching = [];

    let longestCommonPassagePair = this.findLongestCommonPassagePair(
      string0,
      string1,
    );

    let longestCommonPassageText = longestCommonPassagePair.passages[0].text;
    let lengthOfCommonPassage = longestCommonPassageText.length;

    if (lengthOfCommonPassage == 0) {
      return passagePairsMatching;
    }

    passagePairsMatching.push(longestCommonPassagePair);

    let passages = longestCommonPassagePair.passages;
    let passage0 = passages[0];
    let passage1 = passages[1];

    let passagePairsMatchingBeforeCommon =
      this.findPassagePairsMatchingBetweenStrings(
        string0.substr(0, passage0.position),
        string1.substr(0, passage1.position),
        [positionOffsets[0], positionOffsets[1]],
      );

    let passagePairsMatchingAfterCommon =
      this.findPassagePairsMatchingBetweenStrings(
        string0.substr(passage0.position + lengthOfCommonPassage),
        string1.substr(passage1.position + lengthOfCommonPassage),
        [
          positionOffsets[0] + passage0.position + lengthOfCommonPassage,

          positionOffsets[1] + passage1.position + lengthOfCommonPassage,
        ],
      );

    let passagePairSetsMatchingBeforeAndAfter = [
      passagePairsMatchingBeforeCommon,
      passagePairsMatchingAfterCommon,
    ];

    for (let i = 0; i < passagePairSetsMatchingBeforeAndAfter.length; i++) {
      let passagePairsToInsert = passagePairSetsMatchingBeforeAndAfter[i];
      passagePairsMatching.insertElementsAt(
        passagePairsToInsert,
        i == 0 ? 0 : passagePairsMatching.length,
      );
    }

    for (let i = 0; i < longestCommonPassagePair.passages.length; i++) {
      let passage = longestCommonPassagePair.passages[i];
      passage.position += positionOffsets[i];
    }

    return passagePairsMatching;
  };

  TextDifferencer.prototype.findLongestCommonPassagePair = function (
    string0,
    string1,
  ) {
    let passage0 = new TextPassage("", 0);
    let passage1 = new TextPassage("", 0);

    let returnValue = new TextPassagePair(
      true, // doPassagesMatch
      [passage0, passage1],
    );

    let lengthOfString0 = string0.length;
    let lengthOfString1 = string1.length;

    let substringLengthsForRow = null;
    let substringLengthsForRowPrev;

    let lengthOfLongestCommonSubstringSoFar = 0;
    let longestCommonSubstringsSoFar = "";
    let cellIndex = 0;

    // Build a table whose y-axis is chars from string0,
    // and whose x-axis is chars from string1.
    // Put length of the longest substring in each cell.

    for (let i = 0; i < lengthOfString0; i++) {
      substringLengthsForRowPrev = substringLengthsForRow;
      substringLengthsForRow = [];

      for (let j = 0; j < lengthOfString1; j++) {
        if (string0[i] != string1[j]) {
          substringLengthsForRow[j] = 0;
        } else {
          let cellValue;

          if (i == 0 || j == 0) {
            // first row or column
            cellValue = 1;
          } else {
            // Copy cell to upper left, add 1.
            cellValue = substringLengthsForRowPrev[j - 1] + 1;
          }

          substringLengthsForRow[j] = cellValue;

          if (cellValue > lengthOfLongestCommonSubstringSoFar) {
            lengthOfLongestCommonSubstringSoFar = cellValue;
            let startIndex = i - lengthOfLongestCommonSubstringSoFar + 1;
            let longestCommonSubstringSoFar = string0.substring(
              // not "substr"!
              startIndex,
              i + 1,
            );

            passage0.text = longestCommonSubstringSoFar;
            passage0.position = startIndex;

            passage1.text = longestCommonSubstringSoFar;
            passage1.position = j - lengthOfLongestCommonSubstringSoFar + 1;
          }
        }
      }
    }

    return returnValue;
  };

  TextDifferencer.prototype.insertPassagePairsDifferentBetweenMatching =
    function (string0, string1, passagePairsToInsertBetween, passagePairsAll) {
      passagePairsToInsertBetween.insertElementAt(
        new TextPassagePair(
          true, // doPassagesMatch
          [new TextPassage("", 0), new TextPassage("", 0)],
        ),
        0,
      );

      passagePairsToInsertBetween.push(
        new TextPassagePair(
          true, // doPassagesMatch
          [
            new TextPassage("", string0.length),
            new TextPassage("", string1.length),
          ],
        ),
      );

      let pMax = passagePairsToInsertBetween.length - 1;

      for (let p = 0; p < pMax; p++) {
        let passagePairToInsertAfter = passagePairsToInsertBetween[p];
        let passagePairToInsertBefore = passagePairsToInsertBetween[p + 1];

        this.buildAndInsertPassagePairBetweenExisting(
          string0,
          string1,
          passagePairToInsertBefore,
          passagePairToInsertAfter,
          passagePairsAll,
        );

        passagePairsAll.push(passagePairToInsertBefore);
      }

      let indexOfPassagePairFinal = passagePairsAll.length - 1;

      let passagePairFinal = passagePairsAll[indexOfPassagePairFinal];

      if (
        passagePairFinal.doPassagesMatch == true &&
        passagePairFinal.passages[0].text.length == 0
      ) {
        passagePairsAll.removeAt(indexOfPassagePairFinal, 1);
      }
    };

  TextDifferencer.prototype.buildAndInsertPassagePairBetweenExisting =
    function (
      string0,
      string1,
      passagePairToInsertBefore,
      passagePairToInsertAfter,
      passagePairsAll,
    ) {
      let lengthOfPassageToInsertAfter =
        passagePairToInsertAfter.passages[0].text.length;

      let positionsForPassagePairDifferent = [
        [
          passagePairToInsertAfter.passages[0].position +
            lengthOfPassageToInsertAfter,

          passagePairToInsertAfter.passages[1].position +
            lengthOfPassageToInsertAfter,
        ],
        [
          passagePairToInsertBefore.passages[0].position,
          passagePairToInsertBefore.passages[1].position,
        ],
      ];

      let passageToInsert0 = new TextPassage(
        string0.substring(
          // not "substr"!
          positionsForPassagePairDifferent[0][0],
          positionsForPassagePairDifferent[1][0],
        ),
        positionsForPassagePairDifferent[0][0],
      );

      let passageToInsert1 = new TextPassage(
        string1.substring(
          // not "substr"!
          positionsForPassagePairDifferent[0][1],
          positionsForPassagePairDifferent[1][1],
        ),
        positionsForPassagePairDifferent[0][1],
      );

      let passagePairToInsert = new TextPassagePair(
        false, // doPassagesMatch
        [passageToInsert0, passageToInsert1],
      );

      if (
        passagePairToInsert.passages[0].text.length > 0 ||
        passagePairToInsert.passages[1].text.length > 0
      ) {
        passagePairsAll.push(passagePairToInsert);
      }
    };
}

function TextDifferences(passagePairs) {
  this.passagePairs = passagePairs;
}
{
  // instance methods

  TextDifferences.prototype.toString = function () {
    let returnValue = "";

    for (let p = 0; p < this.passagePairs.length; p++) {
      let passagePair = this.passagePairs[p];
      let passagePairAsString = passagePair.toString();

      returnValue += passagePairAsString;
    }

    return returnValue;
  };
}

function TextPassage(text, position) {
  this.text = text;
  this.position = position;
}

function TextPassagePair(doPassagesMatch, passages) {
  this.doPassagesMatch = doPassagesMatch;
  this.passages = passages;
}
{
  TextPassagePair.prototype.toString = function () {
    let returnValue = "";

    if (this.doPassagesMatch == true) {
      returnValue = this.passages[0].text;
      returnValue = this.escapeStringForHTML(returnValue);
    } else {
      returnValue += "<mark style='background-color:#FF7D7D'>";
      returnValue += this.escapeStringForHTML(this.passages[0].text);
      returnValue += "</mark><mark style='background-color:#B3FFAE'>";
      returnValue += this.escapeStringForHTML(this.passages[1].text);
      returnValue += "</mark>";
    }

    return returnValue;
  };

  TextPassagePair.prototype.escapeStringForHTML = function (stringToEscape) {
    let returnValue = stringToEscape
      .replace("&", "&amp;")
      .replace("<", "&lt;")
      .replace(">", "&gt;")
      .replace("\n", "<br />");

    return returnValue;
  };
}
