<template>
  <div class="d-flex d-flex-c" v-outside-click="handleClose" @click.stop>
    <label v-if="label" class="select-label">{{ label }}</label>
    <div :class="['select-wrapper', { toggled, wide }]" @click="toggle">
      <ul :class="['select-items', { toggled }]">
        <li
          v-for="(item, index) in items"
          :key="index"
          :class="[
            'select-item',
            { 'is-selected': item.value === currentValue.value },
          ]"
          @click="selectItem(item)"
        >
          {{ item.value }}
        </li>
      </ul>
      <label class="selected-item">
        <validation-provider mode="aggressive">
          <div class="d-flex d-flex-c">
            <input
              :ref="inputRef"
              :class="['content-input']"
              :name="name"
              v-model="currentValue.value"
            />
          </div>
        </validation-provider>
        <span>{{ currentValue.value }}</span></label
      >
    </div>
  </div>
</template>
<script>
import { ValidationProvider } from "vee-validate";
import "@/utils/validation";

export default {
  name: "FormSelect",
  components: { ValidationProvider },
  props: {
    name: { type: String, default: "" },
    label: { type: String, default: "" },
    items: { type: Array, default: () => [] },
    wide: { type: Boolean, default: false },
    value: { type: Object, default: () => {} },
  },
  mounted() {
    // The input must be focused/blurred so that
    // the changed validation will trigger on value change
    this.$refs[this.inputRef].focus();
    this.$refs[this.inputRef].blur();
  },
  data() {
    return {
      inputRef: `${this.name}-input`,
      toggled: false,
    };
  },

  computed: {
    currentValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    selectItem(item) {
      this.currentValue = item;
      this.$emit("trigger", item);
    },
    toggle() {
      this.toggled = !this.toggled;
    },
    handleClose() {
      this.toggled = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.select-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e4e8f5;
  height: 45px;
  width: 65px;
  padding: 1em;
  border-radius: 6px;
  cursor: pointer;

  &.wide {
    width: 100%;
    justify-content: flex-start;
    margin-bottom: 2em;
    background-color: #f1f9ff;
    border: 1px solid #bce0fd;
  }

  &.toggled {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    background-image: url("/assets/images/icons/dashboard/schedule/arrow_down_dark.svg");
    background-repeat: no-repeat;
    width: 12px;
    height: 9px;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
  .select-items {
    display: flex;
    flex-direction: column;
    background-color: #e4e8f5;
    position: absolute;
    height: 0;
    width: 100%;
    max-height: 125px;
    left: 0;
    bottom: 45px;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    overflow-y: auto;

    .select-item {
      padding: 0.25em 1em;
      cursor: pointer;
    }

    &.toggled {
      height: auto;
    }
  }
  .selected-item {
    margin-right: 20px;
    input {
      height: 0;
      width: 0;
      border: none;
      opacity: 0;
    }
  }
}

.select-label {
  font-weight: bold;
  font-size: 0.85rem;
}
</style>
