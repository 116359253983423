<template>
  <div class="modal-wrapper">
    <modal title="Authenticate with Facebook" @close="closeModal">
      <div slot="modal-content" class="modal-content-wrapper mt-2">
        <div class="d-flex d-flex-c mb-2" style="width: 450px">
          <p class="mb-1">
            Instagram business accounts are required to be connected to a
            Facebook page. For Socianis to work effectively, it is essential to
            authenticate through Facebook.
          </p>

          <p class="mb-1">
            After clicking on "Authenticate with Facebook" sign in to the
            Facebook profile that has Admin permission for the Facebook page
            connected with your instagram account.
          </p>

          <p>
            Have a look into our guide
            <b>How to connect an Instagram account to Facebook</b> to connect
            your account.
          </p>
        </div>
        <div class="d-flex d-flex-between button-wrapper">
          <blank-button @button-click="closeModal" label="Cancel" @click.stop />
          <content-button
            :disabled="authUrlRequesting"
            label="Authenticate with Facebook"
            @button-click="openPlatformWindow"
            @click.stop
          />
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { Modal } from "@/components";
import { ContentButton, BlankButton } from "@/components/ui";
import { PLATFORM_AUTH, SELECTED_PLATFORM } from "@/utils/constants";
export default {
  name: "InstagramInfoModal",
  components: { Modal, ContentButton, BlankButton },
  computed: {
    ...mapState({
      authUrlRequesting: (state) => state.channel.status.requesting.getAuthUrl,
    }),
  },
  methods: {
    ...mapActions({
      getChannelAuth: "getChannelAuth",
    }),
    closeModal() {
      this.$emit("closeModal");
    },
    openPlatformWindow() {
      const platform = "instagram";
      if (!this.authUrlRequesting) {
        const params = { platform: platform, platform_type: this.selectedType };
        this.getChannelAuth(params).then((res) => {
          window.sessionStorage.setItem(SELECTED_PLATFORM, platform);
          window.sessionStorage.setItem(PLATFORM_AUTH, true);
          window.open(res.data, "_self");
        });
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.modal-wrapper {
  .modal:nth-child(2) {
    z-index: 1060;
  }
}
</style>
