<template>
  <div class="d-flex d-flex-c calendar-content-card">
    <div
      :class="[
        'd-flex d-flex-r mb-1',
        { 'd-flex-between': content.project_name },
        { 'd-flex-end': !content.project_name },
      ]"
    >
      <span v-if="content.project_name" class="project-name">{{
        content.project_name
      }}</span>
      <span class="d-flex d-flex-end close" @click="closeContent">×</span>
    </div>
    <object
      v-if="assetPreview"
      class="content-img-preview mb-1"
      @click="() => selectAsset(index)"
    >
      <img :src="assetPreview" @error="defaultBg" />
    </object>

    <span class="text-preview mb-1">{{ content.text }}</span>
    <label class="mb-05"><b>Scheduled for</b></label>
    <div class="d-flex d-flex-c mb-1 details">
      <span>{{ content.date }}</span>
    </div>
    <label class="mb-05"><b>Post summary</b></label>
    <div class="d-flex d-flex-c mb-1 details">
      <span v-if="content.frequency === 'weekly'"
        ><b>Days: {{ content.repeat_weekdays.toString() }} </b></span
      >
      <span><b>Repeat: </b>{{ content.frequency }}</span>
      <span
        ><b>End date: </b
        >{{ content.end_date ? content.end_date : "N/A" }}</span
      >
    </div>
    <div class="d-flex d-flex-r d-flex-end">
      <button class="edit-button" @click="editContent">Edit</button>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "CalendarContentCard",
  props: {
    content: { type: Object, default: () => ({}) },
  },
  data() {
    return { pathContext: "/schedule/edit" };
  },
  computed: {
    ...mapState({
      assetPreview: function (state) {
        return this.content.assets && this.content.assets.length > 0
          ? (
              state.asset.assets.find(
                (asset) => asset.id === this.content.assets[0],
              ) || {}
            ).media_url
          : "";
      },
    }),
  },
  methods: {
    defaultBg(e) {
      e.target.src = "/assets/images/layout/dashboard/common/content_bg.svg";
    },
    closeContent() {
      this.$emit("close-content");
    },
    editContent() {
      this.$router.push({
        path: this.pathContext,
        query: {
          id: this.content.id,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.calendar-content-card {
  position: absolute;
  width: 250px;
  padding: 1em;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 6px;
  box-shadow: 0px 3px 5px #00000029;
  z-index: 5;
  font-size: 0.9rem;

  .project-name {
    padding: 0.25em 0.5em;
  }
  .close {
    font-size: 1rem;
  }

  .text-preview {
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;

    @supports (-webkit-line-clamp: 2) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  .content-img-preview {
    border-radius: 6px;
    max-height: 150px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      border-radius: 6px;
      object-fit: cover;
    }
  }

  .details,
  .project-name {
    background-color: #e4e8f5;
    width: fit-content;

    border-radius: 6px;
  }

  .details {
    padding: 0.5em;
  }

  .edit-button {
    background-color: #e4e8f5;
    padding: 0.35em;
    border-radius: 6px;
    border: none;
  }
}
</style>
