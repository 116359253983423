<template>
  <div class="d-flex d-flex-r d-flex-between asset-selection-wrapper">
    <div class="d-flex d-flex-c assets">
      <object
        v-for="(selectedAsset, index) in selectedAssets"
        :key="index"
        class="asset mr-1"
      >
        <img
          v-if="selectedAsset && selectedAsset.media_url"
          :src="selectedAsset.media_url"
          @error="defaultBg"
        />
      </object>
    </div>
    <div class="d-flex d-flex-r d-flex-between align-center actions">
      <span
        class="action add"
        @click="
          setActiveSideModal({
            name: 'AssetSelectionModal',
            props: { projectId, assetIds },
            modalSubmit: confirmAssetSelection,
          })
        "
        >+</span
      >
      <span class="action remove" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "AssetSelection",
  props: {
    projectId: { type: String, default: "" },
    assetIds: { type: Array, default: () => [] },
  },
  computed: {
    ...mapState({
      selectedAssets: function (state) {
        return state.asset.assets.filter((asset) =>
          this.assetIds.includes(asset.id),
        );
      },
    }),
  },
  methods: {
    ...mapActions({
      setActiveSideModal: "setActiveSideModal",
    }),
    defaultBg(e) {
      e.target.src = "/assets/images/layout/dashboard/common/content_bg.svg";
    },
    confirmAssetSelection(assets) {
      this.$emit("select-assets", assets);
    },
  },
};
</script>

<style lang="scss" scoped>
.asset-selection-wrapper {
  height: 60px;
  .assets {
    flex-wrap: wrap;
    width: 300px;
    overflow-x: auto;
    overflow-y: hidden;
    align-content: flex-start;
    .asset {
      position: relative;
      cursor: pointer;
      border-radius: 6px;
      width: 85px;
      height: 60px;
      background-image: url("/assets/images/layout/dashboard/common/content_bg.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      background-color: #d5daef;

      img {
        width: 100%;
        height: 100%;
        border-radius: 6px;
        object-fit: cover;
      }
    }
  }
  .actions {
    .action {
      cursor: pointer;
      &.add {
        font-size: 32px;
      }
    }
  }
}
</style>
