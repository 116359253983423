<template>
  <div
    class="d-flex d-flex-r align-center team-item-wrapper"
    @click.stop="() => handleClick(teamId)"
  >
    <profile-picture :name="name" :isTeam="true" />
    <label class="team-name ml-05">{{ name }}</label>
  </div>
</template>

<script>
import ProfilePicture from "./ProfilePicture.vue";

export default {
  name: "TeamItem",
  components: { ProfilePicture },
  props: {
    teamId: { type: String, default: "" },
    name: { type: String, default: "" },
  },

  methods: {
    handleClick(teamId) {
      this.$emit("item-click", teamId);
    },
  },
};
</script>

<style lang="scss" scoped>
.team-item-wrapper {
  cursor: pointer;

  .team-name {
    max-width: 150px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
</style>
