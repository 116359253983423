<template>
  <div class="d-flex d-flex-c profile-menu-wrapper">
    <div class="profile-logo" />
    <div class="d-flex mb-2 nav-name-wrapper">
      <span class="nav-name ml-05">{{ currentRouteName }}</span>
    </div>
    <div class="d-flex d-flex-c mt-1 mb-3">
      <sidebar-menu :menu_items="menu_items" />
    </div>
    <content-button
      class="exit-profile"
      style="width: 200px; padding: 0.5em 2em"
      label="Dashboard"
      @button-click="returnToDashboard"
    />
  </div>
</template>
<script>
import { profileMenuItems } from "@/utils/constants";
import { ContentButton } from "@/components/ui";
import { SidebarMenu } from "@/components/common";

export default {
  name: "ProfileSidebar",
  components: { ContentButton, SidebarMenu },
  data() {
    return {
      menu_items: profileMenuItems,
    };
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    returnToDashboard() {
      this.$router.push("/");
    },
  },
};
</script>

<style lang="scss" scoped>
.profile-menu-wrapper {
  position: sticky;
  top: 0;
  flex: 1;
  max-width: 200px;
  height: 100%;
  margin-right: 5em;

  .profile-logo {
    width: 170px;
    height: 35px;
    position: absolute;
    top: 2.75em;
    background-image: url("/assets/images/layout/logos/socianis_dark.svg");
    background-repeat: no-repeat;
    background-position: center;
  }

  .nav-name-wrapper {
    width: 200px;
    margin-top: 7.5em;
    .nav-name {
      font-size: 1.1rem;
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  .exit-profile {
    position: relative;
    height: 45px;
    &::after {
      content: "";
      position: absolute;
      height: 25px;
      width: 25px;
      top: 10px;
      left: 16px;
      background-image: url("/assets/images/icons/common/exit.png");
      background-size: contain;
    }
  }
}
</style>
