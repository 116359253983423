<template>
  <div class="menu-logo-wrapper">
    <span class="image" />
    <label class="label" />
  </div>
</template>

<script>
export default {
  name: "MenuLogo",
};
</script>

<style lang="scss" scoped>
.menu-logo-wrapper {
  position: relative;

  .image {
    display: block;
    width: 50px;
    height: 50px;
    background-image: url("/assets/images/icons/dashboard/sidemenu/side_bar_logo.svg");
    background-size: 75%;
    background-position: center center;
    background-repeat: no-repeat;
  }
  .label {
    position: absolute;
    top: 50%;
    left: 60px;
    transform: translateY(-50%);
    min-width: 150px;
    height: 35px;
    background-image: url("/assets/images/icons/dashboard/sidemenu/side_bar_logo_text.svg");
    background-repeat: no-repeat;
  }
}
</style>
