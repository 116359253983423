<template>
  <div
    :class="[
      'd-flex d-flex-c mr-1 ml-1 mb-2 plan-wrapper',
      { 'is-active': isActive },
    ]"
  >
    <div class="d-flex d-flex-center target-wrapper">
      {{ $t("ui.best_for") }} {{ target }}
    </div>
    <div class="d-flex d-flex-c plan">
      <div class="d-flex d-flex-between d-flex-r mt-1 mb-1">
        <span class="d-flex">{{ subscriptionLabel }}</span>
      </div>
      <div class="d-flex d-flex-r d-flex-between align-center mb-05">
        <div class="d-flex d-flex-r align-center">
          <span class="d-flex price">{{ formattedPrice }}</span>
          <span v-if="!isFreeTier" style="opacity: 0.75"
            >/{{ $t("ui.monthly_abbr") }}
          </span>
        </div>
        <span v-if="!isFreeTier" style="opacity: 0.75">
          {{ $t("ui.billed") }} {{ billingCycle }}</span
        >
      </div>

      <div class="d-flex d-flex-center mb-1">
        <span class="img-wrapper">
          <img
            :src="`/assets/images/layout/subscription/${subscriptionType}_l.svg`"
        /></span>
      </div>
      <div class="d-flex d-flex-c align-center">
        <div
          :class="[
            'd-flex d-flex-c features-wrapper',
            { 'is-expanded': expanded },
          ]"
        >
          <div
            v-for="(feature, index) in subscriptionFeatures"
            :key="index"
            class="mb-1"
          >
            <div
              class="d-flex d-flex-r d-flex-between feature-row align-center"
            >
              <div class="d-flex">
                {{ $t(`pricing.${feature.name}.label`) }}
              </div>
              <div class="d-flex d-flex-c">
                <div v-if="feature.isList" class="d-flex d-flex-r feature-list">
                  <span
                    v-for="(item, index) in feature.subscription[
                      subscriptionType
                    ]"
                    :key="index"
                    :class="['social ml-05', item]"
                  />
                </div>
                <div v-else-if="feature.isCheck">
                  <span
                    :class="[
                      'check',
                      { 'is-checked': feature.subscription[subscriptionType] },
                    ]"
                  />
                </div>
                <div v-else>
                  <span v-if="feature.subscription[subscriptionType] === -1">{{
                    $t("ui.unlimited")
                  }}</span>
                  <span
                    v-else-if="!feature.subscription[subscriptionType]"
                    class="not-available"
                  ></span>
                  <span v-else>{{
                    $tc(
                      `pricing.${feature.name}.value`,
                      feature.subscription[subscriptionType],
                    )
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          :class="[
            ' d-flex d-flex-r d-flex-center align-center mt-3 mb-1 expand-button',
            ,
            { 'is-expanded': expanded },
          ]"
          @click="handleExpand"
        >
          {{ $t(`ui.${expandLabel}`) }}
        </button>
      </div>
    </div>
    <button
      class="d-flex d-flex-r d-flex-center d-align-center plan-button"
      @click="handlePlanSelect"
    >
      {{ $t(`ui.${buttonLabel}`) }}
    </button>
  </div>
</template>
<script>
import { productTarget } from "@/utils/constants";
export default {
  name: "SubscriptionPlan",
  props: {
    subscriptionType: { type: String, default: "" },
    subscriptionLabel: { type: String, default: "" },
    subscriptionFeatures: { type: Array, default: () => [] },
    price: { type: Number, default: 0 },
    currency: { type: String, default: "eur" },
    billingCycle: { type: String, default: "" },
    expanded: { type: Boolean, default: false },
    trialActivated: { type: Boolean, default: false },
    isActive: { type: Boolean, default: false },
  },
  data() {
    return {
      currencySymbols: {
        eur: "€",
      },
    };
  },
  computed: {
    formattedPrice() {
      if (!this.price) {
        return "Free";
      } else {
        const amount = this.price / 100;
        if (this.billingCycle === "yearly") {
          const discountAmount = amount / 12;
          return `${this.currencySymbols[this.currency]} ${(
            amount / 12
          ).toFixed(2)}`;
        } else {
          return `${this.currencySymbols[this.currency]} ${amount}`;
        }
      }
    },
    isFreeTier() {
      return this.subscriptionLabel === "Grounded";
    },
    expandLabel() {
      return this.expanded ? "less" : "more";
    },
    buttonLabel() {
      if (this.isActive) {
        return "currently_active";
      } else if (!this.trialActivated && this.subscriptionType !== "grounded") {
        return "try_trial";
      } else if (!this.isActive && this.subscriptionType === "grounded") {
        return "downgrade";
      } else {
        return "subscribe";
      }
    },
    target() {
      return productTarget[this.subscriptionType];
    },
  },
  methods: {
    handleExpand() {
      this.$emit("toggle-expand");
    },
    handlePlanSelect() {
      if (this.isActive) {
        return;
      }
      this.$emit("select-plan", this.subscriptionType);
    },
    featureValueFormat(val) {
      return val === -1 ? "Unlimited" : val;
    },
  },
};
</script>
<style lang="scss" scoped>
.plan-wrapper {
  cursor: pointer;
  .target-wrapper {
    padding: 0.5em 1em;
    background: #d5daef;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    font-weight: 600;
  }
  .plan {
    width: 335px;
    padding: 0.5em 1em;
    background-color: #f5f7ff;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;

    .price {
      font-size: 1.5rem;
      font-weight: 600;
    }

    .img-wrapper {
      img {
        max-width: 150px;
      }
    }

    .features-wrapper {
      width: 100%;
      max-height: 200px;
      overflow: hidden;

      &.is-expanded {
        max-height: 100%;
      }
      .feature-row {
        height: 20px;
        font-size: 0.85rem;

        .feature-list {
          .social {
            display: block;
            width: 15px;
            height: 15px;
            color: black;
            background-repeat: no-repeat;
            background-size: contain;
            &.facebook {
              background-image: url("/assets/images/icons/social/facebook_channel.png");
            }
            &.instagram {
              background-image: url("/assets/images/icons/social/instagram_channel.png");
            }
            &.twitter {
              background-image: url("/assets/images/icons/social/twitter_channel.png");
            }
            &.linkedin {
              background-image: url("/assets/images/icons/social/linkedin_channel.png");
            }
          }
        }
        .not-available,
        .check {
          &::after {
            display: flex;
            content: "×";
            font-weight: 600;
            justify-content: center;
            align-items: center;
            width: 15px;
          }

          &.is-checked {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            border-radius: 3px;
            width: 15px;
            height: 15px;
            background-color: #2bd18a;
            &::after {
              content: "";
              margin-bottom: 2px;
              width: 5px;
              height: 10px;
              border: solid white;
              border-width: 0 2px 2px 0;
              -webkit-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              transform: rotate(45deg);
            }
          }
        }
      }
    }
    .expand-button {
      width: 100px;
      padding: 1em 2em;
      border-radius: 24px;
      border: none;
      background-color: #e4e8f5;

      &.is-expanded {
        &::after {
          transform: rotate(225deg);
          -webkit-transform: rotate(225deg);
        }
      }
      &::after {
        content: "";
        display: block;
        border: solid #000;
        border-width: 0 1px 1px 0;
        margin-left: 1em;
        margin-bottom: 0.25em;
        padding: 4px;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        transition: transform 0.15s ease-in-out;
      }
    }
  }
  .plan-button {
    position: relative;
    background-color: #221c55;
    padding: 1.5em 1em;
    color: white;
    border: none;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;

    &::after {
      content: "";
      display: block;
      border: solid #fff;
      border-width: 0 1px 1px 0;
      margin-left: 1em;
      padding: 4px;
      transform: rotate(315deg);
      -webkit-transform: rotate(315deg);
      transition: transform 0.15s ease-in-out;
    }
  }

  &.is-active {
    opacity: 0.75;
    cursor: default;
  }
}
</style>
