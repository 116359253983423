// Wrapper for shared mutations and properties for session storage
import {
  INIT_SESSION_STORE,
  SET_SESSION_ITEM,
  REMOVE_SESSION_ITEM,
} from "../actions/session";

import { RESET_MODULE_STATE } from "../actions/global";

const parseItem = (value) => {
  if (value) {
    if (/^\s*(\{|\[)/.test(value)) {
      try {
        return JSON.parse(value);
      } catch (e) {
        return value;
      }
    }
  }
  return value;
};

const getParsedItem = (key) => {
  return parseItem(sessionStorage.getItem(key));
};

const getDefaultState = () => ({
  selectedPlatform: null,
  platformPopup: null,
  platformAuth: null,
});

const state = getDefaultState();

const actions = {
  initSessionStore: ({ commit, state }) => {
    const keys = Object.keys(state);
    commit(INIT_SESSION_STORE, keys);
  },
  getSessionItem: (context, key = "") => {
    return new Promise((resolve) => {
      resolve(getParsedItem(key));
    });
  },
  setSessionItem: ({ commit }, payload = {}) => {
    commit(SET_SESSION_ITEM, payload);
  },
  removeSessionItem: ({ commit }, key = "") => {
    commit(REMOVE_SESSION_ITEM, key);
  },
};

const mutations = {
  [INIT_SESSION_STORE]: (state, keys) => {
    keys.forEach((key) => {
      state[key] = getParsedItem(key);
    });
  },
  [SET_SESSION_ITEM]: (state, payload) => {
    const { key, value } = payload;
    sessionStorage.setItem(key, parseItem(value));
    state[key] = value;
  },
  [REMOVE_SESSION_ITEM]: (state, key) => {
    sessionStorage.removeItem(key);
    state[key] = null;
  },
  [RESET_MODULE_STATE]: (state) => {
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    // This mutation is called from the logout action!
    Object.assign(state, getDefaultState());
  },
};

export default {
  state,
  actions,
  mutations,
};
