<template>
  <div class="modal-wrapper">
    <modal
      title="Connect a social media channel"
      description="Connect atleast one social media channel"
      @close="closeModal"
    >
      <div slot="modal-content" class="modal-content-wrapper">
        <div class="add-platform-wrapper">
          <add-channel-button
            v-for="(platform, index) in availablePlatforms"
            :key="index"
            :platform="platform"
            :requestingPlatform="requestingPlatform === platform"
            :isRequesting="authUrlRequesting || completeRequest"
            @addChannel="openPlatformWindow"
          />
        </div>
      </div>
    </modal>
    <select-pages-modal
      v-if="selectPagesToggled"
      @closeModal="closeSelectPage"
    />
    <select-linked-in-type
      v-if="selectLinkedInTypeToggled"
      @closeModal="closeSelectLinkedInTypeModal"
    />
    <instagram-info-modal
      v-if="instagramInfoToggeled"
      @closeModal="closeExtraInfoModal"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { Modal } from "@/components";
import { AddChannelButton } from "@/components/dashboard/content/channels";
import SelectPagesModal from "./SelectPagesModal.vue";
import SelectLinkedInType from "./SelectLinkedInType.vue";
import InstagramInfoModal from "./InstagramInfoModal.vue";
import { PLATFORM_AUTH, SELECTED_PLATFORM } from "@/utils/constants";

const availablePlatforms = ["facebook", "instagram", "twitter", "linkedin"];

export default {
  name: "AddChannelModal",
  components: {
    Modal,
    AddChannelButton,
    SelectPagesModal,
    SelectLinkedInType,
    InstagramInfoModal,
  },
  data() {
    return {
      selectLinkedInTypeToggled: false,
      instagramInfoToggeled: false,
      availablePlatforms,
      requestingPlatform: "",
      completeRequest: false,
    };
  },
  computed: {
    ...mapState({
      selectPagesToggled: (state) => state.dashboard.selectPagesToggled,
      authUrlRequesting: (state) =>
        state.channel.status.requesting.getChannelAuth,
    }),
  },
  methods: {
    ...mapActions({
      getChannelAuth: "getChannelAuth",
      togglePagesSelect: "togglePagesSelect",
    }),
    closeModal() {
      this.$emit("closeModal");
    },
    closeSelectPage() {
      this.togglePagesSelect(false);
    },
    closeSelectLinkedInTypeModal() {
      this.selectLinkedInTypeToggled = false;
    },
    closeExtraInfoModal() {
      this.instagramInfoToggeled = false;
    },
    openPlatformWindow(platform) {
      this.completeRequest = true;
      if (platform === "linkedin") {
        this.selectLinkedInTypeToggled = true;
      } else if (platform === "instagram") {
        this.instagramInfoToggeled = true;
      } else {
        if (!this.authUrlRequesting) {
          this.requestingPlatform = platform;
          const params = { platform };
          this.getChannelAuth(params).then((res) => {
            window.sessionStorage.setItem(SELECTED_PLATFORM, platform);
            window.sessionStorage.setItem(PLATFORM_AUTH, true);
            window.open(res.data, "_self");
          });
        }
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.modal-wrapper {
  .modal:nth-child(2) {
    z-index: 1060;
  }
}
.add-platform-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;

  > * {
    margin-right: 1.5em;
    &:nth-child(4) {
      margin-right: 0;
    }
  }
}
</style>
