<template>
  <div
    :class="[
      'd-flex d-flex-c content-card-wrapper',
      { '--is-selected': isSelected },
    ]"
    @click="$emit('card-click')"
  >
    <div class="d-flex d-flex-r d-flex-between">
      <div class="d-flex d-flex-c">
        <div class="d-flex d-flex-r mb-1">
          <span
            class="content-card-image"
            :style="setBg(cardInfo.card_avatar)"
          />
          <div class="d-flex d-flex-r content-card-info">
            <span class="text-preview">{{ cardInfo.text }} </span>
          </div>
        </div>
        <schedule-labels
          :startTime="scheduleStartTime"
          :timeZone="contentTimeZone"
          :repeatFrequency="repeatFrequency"
          :repeatDays="repeatDays"
          :endTime="scheduleTimeEnd"
        />
      </div>
      <div class="d-flex d-flex-r">
        <div class="d-flex d-flex-c d-flex-end"></div>
      </div>
      <div class="d-flex d-flex-c d-flex-between">
        <div class="d-flex d-flex-c">
          <div class="d-flex d-flex-r d-flex-end mb-2">
            <label v-if="cardInfo.project_name" class="mr-1 project-label">{{
              cardInfo.project_name
            }}</label>
            <trash-button @button-click="deleteContent" />
          </div>
          <div class="d-flex d-flex-r card-socials-wrapper">
            <span
              v-for="(platform, index) in cardInfo.platform_types"
              :key="index"
              :class="platform.toLowerCase()"
            />
          </div>
        </div>
        <div class="d-flex d-flex-r card-button-wrapper">
          <button
            v-if="showApproveNow"
            class="content-button"
            :class="[
              'content-button',
              'approve',
              'mr-1',
              { 'is-approved': isApproved },
            ]"
            @click="changeStatus('approved')"
          >
            Approve
          </button>
          <button
            v-else-if="showScheduleNow"
            :class="[
              'content-button',
              'schedule',
              'mr-1',
              { 'is-approved': isApproved },
            ]"
            @click="changeStatus('scheduled')"
          >
            Schedule now
          </button>

          <button class="content-button edit" @click="editContent">edit</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { weekDays } from "@/utils/constants";
import { formatDateWithTz } from "@/utils/timezone";

export default {
  name: "ContentCard",
  components: {
    ScheduleLabels: () =>
      import("@/components/dashboard/content/").then((res) => {
        return res.ScheduleLabels;
      }),
    TrashButton: () =>
      import("@/components/").then((res) => {
        return res.TrashButton;
      }),
  },
  props: {
    cardIndex: { type: Number },
    teamRole: { type: String, default: "" },
    contentTimeZone: { type: String, default: "" },
    cardInfo: { type: Object, default: () => ({}) },
    isSelected: { type: Boolean, default: false },
    isTeamContext: { type: Boolean, default: false },
  },
  computed: {
    isDraft() {
      return this.cardInfo.status !== "scheduled";
    },
    isApproved() {
      return this.cardInfo.status === "approved";
    },
    canApprove() {
      return this.teamRole === "owner" || this.teamRole === "maintainer";
    },
    showScheduleNow() {
      return this.isDraft && (this.isApproved || !this.teamRole);
    },
    showApproveNow() {
      return (
        this.isDraft &&
        this.canApprove &&
        !this.isApproved &&
        !this.showScheduleNow
      );
    },
    scheduleStartTime() {
      if (!this.cardInfo.start_date) {
        return "";
      }
      return formatDateWithTz(
        this.cardInfo.start_date,
        this.contentTimeZone,
      ).format("h:mm A");
    },
    scheduleTimeEnd() {
      if (!this.cardInfo.end_date) {
        return "";
      }
      return formatDateWithTz(
        this.cardInfo.end_date,
        this.contentTimeZone,
      ).format("DD-MM-YYYY, h:mm A");
    },
    repeatFrequency() {
      return this.cardInfo.frequency;
    },
    repeatDays() {
      if (!this.repeat_weekdays) {
        return "";
      }
      return weekDays
        .filter((_, index) => this.cardInfo.repeat_weekdays.includes(index))
        .join(", ");
    },
  },
  methods: {
    setBg(background) {
      return background && background.length > 0
        ? `background-image: url("${background}")`
        : "background-image: url('/assets/images/layout/dashboard/common/content_bg.svg')";
    },
    editContent() {
      this.$emit("edit-content", this.cardInfo);
    },
    changeStatus(status) {
      // console.log(status);
      const payload = {
        id: this.cardInfo.id,
        status,
        oldStatus: this.cardInfo.status,
      };
      this.$emit("card-button-click", payload);
    },
    deleteContent() {
      this.$emit("delete-button-click", this.cardInfo.id);
    },
  },
};
</script>
<style lang="scss" scoped>
.content-card-wrapper {
  position: relative;
  min-width: 540px;
  max-width: 685px;
  min-height: 130px;
  padding: 1em;
  background-color: white;
  border: 1px solid #f9f9f9;
  border-radius: 6px;
  cursor: pointer;

  &:hover,
  &.--is-selected {
    background-color: #eaefff;
    .project-label {
      background-color: white;
    }
  }

  .content-card-image {
    height: 80px;
    min-width: 80px;
    margin-right: 1em;
    border-radius: 6px;
    background-size: cover;
    background-position: center;
  }

  .card-socials-wrapper {
    justify-content: flex-end;
    min-width: 150px;

    > span {
      height: 24px;
      width: 24px;
      margin-right: 0.5em;

      &:last-child {
        margin-right: 0;
      }

      &.facebook {
        background-image: url("/assets/images/icons/social/facebook_channel.png");
      }
      &.twitter {
        background-image: url("/assets/images/icons/social/twitter_channel.png");
      }
      &.instagram {
        background-image: url("/assets/images/icons/social/instagram_channel.png");
      }
      &.linkedin {
        background-image: url("/assets/images/icons/social/linkedin_channel.png");
      }
    }
  }

  .card-button-wrapper {
    justify-content: flex-end;
    min-width: 175px;
  }

  .content-button {
    background-color: #e4e8f5;
    padding: 0.5em;
    height: fit-content;
    border: none;
    border-radius: 6px;
    font-size: 0.85rem;
    outline: none;
    color: black;

    &.is-approved {
      background-color: #2bd18a;
      color: white;
    }

    &.schedule {
      background-color: #221c55;
      color: white;
    }

    &.approve {
      background-color: #2bd18a;
      color: white;
    }
  }

  .content-card-info {
    > span {
      display: flex;
      flex-direction: row;

      > b {
        margin-right: 0.4em;
      }
    }
  }
  .text-preview {
    max-width: 315px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 98%;

    @supports (-webkit-line-clamp: 3) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: initial;
      display: -webkit-box !important;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical !important;
    }
  }
  .project-label {
    padding: 0.25em 0.5em;
    border-radius: 6px;
    background-color: #f3f1ff;
    font-size: 0.75rem;
  }
}
</style>
