<template>
  <div class="d-flex d-flex-c d-self-center h-center">
    <div
      class="d-flex d-align-center"
      style="cursor: pointer; width: fit-content"
      @click="viewSubscriptions"
    >
      &#10094; View Plans
    </div>
    <div
      :class="[
        'd-flex d-flex-r d-self-center d-flex-between payment-details-wrapper',
      ]"
    >
      <payment-methods
        :selectedInterval="selectedInterval"
        :selectedPlan="selectedPlan"
        :context="context"
      />

      <div class="d-flex d-flex-c" style="width: 500px">
        <h2>Order</h2>
        <interval-selector
          :currentInterval="selectedInterval"
          @interval-click="changeInterval"
          class="mb-2"
        />
        <plan-summary
          :plan="selectedProduct.subscriptionLabel"
          :interval="selectedInterval"
          :currency="selectedProduct.currency"
          :price="selectedProduct.price[selectedInterval] / 100"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { IntervalSelector, PlanSummary, PaymentMethods } from ".";
import { SUBSCRIPTION_PLAN_SELECTION } from "@/utils/subscribe.js";

export default {
  name: "PaymentSelection",
  components: { IntervalSelector, PlanSummary, PaymentMethods },
  async created() {
    if (!this.plans.includes(this.$route.params.plan)) {
      this.$router.push({ path: "/profile/subscription" });
    }
    const { interval } = this.$route.query;
    if (interval) {
      this.selectedInterval = interval;
    }
    this.selectedPlan = this.$route.params.plan;
  },
  data() {
    return {
      context: SUBSCRIPTION_PLAN_SELECTION,
      selectedInterval: "monthly",
      selectedPlan: "",
    };
  },
  computed: {
    ...mapState({
      plans: (state) =>
        state.subscription.products.map((product) =>
          product.subscriptionPlan.toLowerCase(),
        ),
      selectedProduct: function (state) {
        return state.subscription.products.find(
          (product) => product.subscriptionPlan === this.selectedPlan,
        );
      },
    }),
  },
  methods: {
    ...mapActions({
      getPlans: "getPlans",
    }),
    changeInterval(interval) {
      this.selectedInterval = interval;
    },
    viewSubscriptions() {
      this.$router.push("/plans");
    },
  },
};
</script>
<style lang="scss" scoped>
.payment-details-wrapper {
  min-height: 700px;
  min-width: 1080px;
}
</style>
