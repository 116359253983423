<template>
  <modal @close="closeModal">
    <div slot="modal-content" class="modal-content-wrapper wrapper">
      <validation-observer ref="selectPageForm" v-slot="{ invalid }">
        <form
          id="selectPageForm"
          name="selectPageForm"
          class="main-wrapper custom-scroll"
          @submit.prevent
        >
          <select-page
            v-for="(page, index) in pages"
            :key="index"
            :name="page.name"
            :picture="page.picture"
            :id="page.id"
          />
        </form>
        <div class="d-flex d-flex-r d-flex-end mt-2">
          <dashboard-button
            :isRequesting="isRequesting"
            label="Connect"
            @button-click="handleSubmit"
          />
        </div>
      </validation-observer>
    </div>
  </modal>
</template>

<script>
import { Modal } from "@/components";
import { DashboardButton } from "@/components/ui/";
import { SelectPage } from "@/components/dashboard/content/channels";
import { mapActions, mapState } from "vuex";
import { ValidationObserver } from "vee-validate";
import { FACEBOOK_PAGES } from "@/utils/constants";

export default {
  name: "SelectPagesModal",
  components: { Modal, SelectPage, ValidationObserver, DashboardButton },
  data: () => ({
    pages: null,
  }),
  async created() {
    this.pages = await this.getJSONStorageItem(FACEBOOK_PAGES);
  },
  beforeDestroy() {
    this.removeStorageItem(FACEBOOK_PAGES);
  },
  computed: {
    ...mapState({
      // isSubmitting: (state) =>
      //   state.dashboard.status && state.dashboard.status.subPlatformRequesting,
      isRequesting: (state) => state.channel.status.requesting.addChannelPage,
    }),
  },
  methods: {
    ...mapActions({
      addChannelPage: "addChannelPage",
      getJSONStorageItem: "getJSONStorageItem",
      removeStorageItem: "removeStorageItem",
      deleteCachedPages: "deleteCachedPages",
    }),
    menuClick() {
      this.$emit("menu-item-click", this.name);
    },
    closeModal() {
      this.deleteCachedPages();
      this.$emit("closeModal");
    },
    handleSubmit() {
      let payload = {
        channel_ids: [],
      };
      const selectPageForm = document.getElementById("selectPageForm");
      const formData = new FormData(selectPageForm);
      formData.forEach((value, key) => {
        payload.channel_ids.push(value);
      });
      this.addChannelPage(payload).then((res) => {
        this.$emit("closeModal");
        // this.closeModal();
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .main-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    max-height: 325px;
    margin-top: 1em 0;
    overflow-x: hidden;
  }

  .socianis-button {
    margin: 0 auto;
  }
}
</style>
