var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.toRender)?_c('div',{class:[
    'd-flex d-flex-r upload-item-wrapper mb-2',
    { 'is-loaded': _vm.loaded && _vm.toRender, error: _vm.status === 'error' },
  ]},[_c('object',{staticClass:"selected-asset img-preview mr-05"},[(_vm.previewImg)?_c('img',{attrs:{"src":_vm.previewImg}}):_vm._e()]),_c('div',{staticClass:"d-flex d-flex-c d-flex-between",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"d-flex d-flex-r d-flex-between mb-05",staticStyle:{"max-width":"288px"}},[_c('label',{staticClass:"item-name"},[_vm._v(_vm._s(_vm.itemName))]),(!_vm.isCompleted)?_c('span',{staticClass:"upload-percentage"},[_vm._v(_vm._s(_vm.uploadProgress)+"%")]):_vm._e()]),_c('div',{class:[
        'load-bar',
        { 'is-completed': _vm.isCompleted, 'has-error': _vm.hasError },
      ]},[_c('div',{staticClass:"progress",style:(`width:${_vm.uploadProgress}%`)})]),_c('span',{class:[
        'status-message',
        { 'is-completed': _vm.isCompleted, 'has-error': _vm.hasError },
      ]},[_vm._v(_vm._s(_vm.statusMessage))])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }