import axios from "axios";
import CONFIG from "../../config";

import store from "../../store";

const httpClient = axios.create({
  baseURL: `${CONFIG.API_HOST}/api`,
  timeout: 60000,
  headers: {
    "Content-type": "application/json",
  },
  withCredentials: true,
});

httpClient.interceptors.request.use((config) => {
  // use config.headers if it has been set
  // config.params = config.params || {};
  config.headers = config.headers || {};
  // add any client instance specific headers to config
  const { id } = store.state.team.currentTeam;
  // config.params.team_id = teamId;
  config.headers["socianis_context_id"] = id;
  return config;
});

httpClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      const { status, data } = error.response;
      const message = data.error ? data.error : data.detail;
      if (status === 401 || status === 403) {
        store.dispatch("logout", null, { root: true });
      }
      if (status === 404 || status === 429) {
        store.dispatch("createNotification", {
          type: "GENERAL_ERROR",
          status: "error",
          text: message,
        });
      }
      return Promise.reject(message);
    } else if (error.request) {
      // The request was made but no response was received
      const { status } = error.request;
      if (status === 0) {
        // store.dispatch("logout", null, { root: true });
        const data = {
          dashboard_status: false,
          message:
            "Failed to connect to server, please check your internet connection.",
        };
      }
      const detail = "Failed to connect to server";
      return Promise.reject(detail);
    } else {
      const detail = error.message
        ? error.message
        : "Something went wrong, please try again.";
      return Promise.reject(detail);
    }
  },
);

export default httpClient;
