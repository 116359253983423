import {
  SAVE_CONTENT_SUCCESS,
  SAVE_CONTENT_ERROR,
  DELETE_CONTENT_SUCCESS,
  DELETE_CONTENT_ERROR,
  SEND_MESSAGE_SUCCESS,
  SEND_MESSAGE_ERROR,
} from "../store/actions/content";

import {
  CREATE_PROJECT_SUCCESS,
  CREATE_PROJECT_ERROR,
  UPDATE_PROJECT_SUCCESS,
  UPDATE_PROJECT_ERROR,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_ERROR,
} from "../store/actions/project";

import {
  DELETE_CHANNEL_SUCCESS,
  DELETE_CHANNEL_ERROR,
  ADD_CHANNEL_SUCCESS,
  ADD_CHANNEL_ERROR,
  ADD_CHANNEL_PAGE_SUCCESS,
  ADD_CHANNEL_PAGE_ERROR,
  GET_CHANNEL_AUTH_URL_ERROR,
} from "../store/actions/channel";

import {
  CREATE_MEDIA_LIBRARY_SUCCESS,
  CREATE_MEDIA_LIBRARY_ERROR,
  UPDATE_MEDIA_LIBRARY_SUCCESS,
  UPDATE_MEDIA_LIBRARY_ERROR,
  DELETE_MEDIA_LIBRARY_SUCCESS,
  DELETE_MEDIA_LIBRARY_ERROR,
} from "../store/actions/library";

import {
  CREATE_TEAM_SUCCESS,
  CREATE_TEAM_ERROR,
  UPDATE_TEAM_SUCCESS,
  UPDATE_TEAM_ERROR,
  DELETE_TEAM_SUCCESS,
  DELETE_TEAM_ERROR,
  LEAVE_TEAM_SUCCESS,
  LEAVE_TEAM_ERROR,
  ADD_TEAM_MEMBER_SUCCESS,
  ADD_TEAM_MEMBER_ERROR,
  UPDATE_TEAM_MEMBER_SUCCESS,
  UPDATE_TEAM_MEMBER_ERROR,
  REMOVE_TEAM_MEMBER_SUCCESS,
  REMOVE_TEAM_MEMBER_ERROR,
  CONFIRM_TEAM_INVITE_SUCCESS,
  CONFIRM_TEAM_INVITE_ERROR,
} from "../store/actions/team";

export const notificationMessages = {
  [SAVE_CONTENT_SUCCESS]: "Content saved",
  [SAVE_CONTENT_ERROR]: "Failed to save content",
  [DELETE_CONTENT_SUCCESS]: "Content deleted",
  [DELETE_CONTENT_ERROR]: "Failed to delete content",
  [SEND_MESSAGE_ERROR]: "Failed to send message",
  [CREATE_PROJECT_SUCCESS]: "Project created",
  [CREATE_PROJECT_ERROR]: "Failed to create project",
  [UPDATE_PROJECT_SUCCESS]: "Project updated",
  [UPDATE_PROJECT_ERROR]: "Failed to update project",
  [DELETE_PROJECT_SUCCESS]: "Project deleted",
  [DELETE_PROJECT_ERROR]: "Failed to delete project",
  [DELETE_CHANNEL_SUCCESS]: "Channel deleted",
  [DELETE_CHANNEL_ERROR]: "Failed to delete channel",
  [ADD_CHANNEL_SUCCESS]: "Channel added",
  [ADD_CHANNEL_ERROR]: "Failed to add channel",
  [ADD_CHANNEL_PAGE_SUCCESS]: "Channel added",
  [ADD_CHANNEL_PAGE_ERROR]: "Failed to add channel",
  [GET_CHANNEL_AUTH_URL_ERROR]: "Failed to fetch authorization token",
  [CREATE_MEDIA_LIBRARY_SUCCESS]: "Media library created",
  [CREATE_MEDIA_LIBRARY_ERROR]: "Failed to create media library",
  [UPDATE_MEDIA_LIBRARY_SUCCESS]: "Media library updated",
  [UPDATE_MEDIA_LIBRARY_ERROR]: "Failed to update media library",
  [DELETE_MEDIA_LIBRARY_SUCCESS]: "Media library deleted",
  [DELETE_MEDIA_LIBRARY_ERROR]: "Failed to delete media library",
  [CREATE_TEAM_SUCCESS]: "Team created",
  [CREATE_TEAM_ERROR]: "Failed to create team",
  [UPDATE_TEAM_SUCCESS]: "Team updated",
  [UPDATE_TEAM_ERROR]: "Failed to update team",
  [DELETE_TEAM_SUCCESS]: "Team deleted",
  [DELETE_TEAM_ERROR]: "Failed to delete team",
  [LEAVE_TEAM_SUCCESS]: "Left team successfully",
  [LEAVE_TEAM_ERROR]: "Failed to leave team",
  [ADD_TEAM_MEMBER_SUCCESS]: "Added member to team",
  [ADD_TEAM_MEMBER_ERROR]: "Failed to add member",
  [UPDATE_TEAM_MEMBER_SUCCESS]: "Updated team member",
  [UPDATE_TEAM_MEMBER_ERROR]: "Failed to update team member",
  [REMOVE_TEAM_MEMBER_SUCCESS]: "Removed team member",
  [REMOVE_TEAM_MEMBER_ERROR]: "Failed to remove team member",
  [CONFIRM_TEAM_INVITE_SUCCESS]: "Team confirmation successful",
  [CONFIRM_TEAM_INVITE_ERROR]: "Failed to confirm team invitation",
};
