export const GET_DASHBOARD_USER_REQUEST = "GET_DASHBOARD_USER_REQUEST";
export const GET_DASHBOARD_USER_ERROR = "GET_DASHBOARD_USER_ERROR";
export const GET_DASHBOARD_USER_SUCCESS = "GET_DASHBOARD_USER_SUCCESS";

export const GET_USER_PROFILE_REQUEST = "GET_USER_PROFILE_REQUEST";
export const GET_USER_PROFILE_ERROR = "GET_USER_PROFILE_ERROR";
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS";

export const UPDATE_USER_PROFILE_REQUEST = "UPDATE_USER_PROFILE_REQUEST";
export const UPDATE_USER_PROFILE_ERROR = "UPDATE_USER_PROFILE_ERROR";
export const UPDATE_USER_PROFILE_SUCCESS = "UPDATE_USER_PROFILE_SUCCESS";

export const UPDATE_USER_PASSWORD_REQUEST = "UPDATE_USER_PASSWORD_REQUEST";
export const UPDATE_USER_PASSWORD_SUCCESS = "UPDATE_USER_PASSWORD_SUCCESS";
export const UPDATE_USER_PASSWORD_ERROR = "UPDATE_USER_PASSWORD_ERROR";

export const SET_ACTIVE_PROFILE_MODAL = "SET_ACTIVE_PROFILE_MODAL";

export const CHANGE_ACTIVE_MENU = "CHANGE_ACTIVE_MENU";

export const SET_ACTIVE_MODAL = "SET_ACTIVE_MODAL";
export const SET_ACTIVE_SIDE_MODAL = "SET_ACTIVE_SIDE_MODAL";
export const TOGGLE_PAGES_SELECT = "TOGGLE_PAGES_SELECT";
