<template>
  <button
    :class="['socianis-button', { 'is-requesting': isRequesting }]"
    :disabled="isRequesting || invalid"
    @click.prevent="handleClick"
    :style="btnStyle"
  >
    <spinner
      v-if="isRequesting"
      :width="20"
      :height="20"
      :wrapperStyle="loaderWrapperStyle"
    />
    <label v-else>{{ label }}</label>
  </button>
</template>

<script>
import Spinner from "../loaders/Spinner.vue";
export default {
  name: "SocianisButton",
  components: { Spinner },
  props: {
    label: { type: String, default: "" },
    isRequesting: { type: Boolean, default: false },
    invalid: { type: Boolean, default: false },
  },
  data() {
    return {
      btnStyle: {
        width: null,
      },
      loaderWrapperStyle: {
        "justify-content": "center",
      },
    };
  },
  mounted() {
    this.btnStyle = { "min-width": `${this.$el.offsetWidth}px` };
  },
  methods: {
    handleClick: function () {
      this.$emit("button-click");
    },
  },
};
</script>

<style lang="scss" scoped>
.socianis-button {
  position: relative;
  padding: 1em 2em;
  border-radius: 25px;
  border: none;
  letter-spacing: 1px;
  background: transparent linear-gradient(90deg, #7759a4 0%, #9962a9 100%) 0% 0%
    no-repeat padding-box;
  color: white;
  cursor: pointer;
  overflow: hidden;

  > label {
    cursor: pointer;
  }

  &:disabled {
    opacity: 0.85;
    &:after {
      animation: unset;
    }
    &:hover:after {
      animation: unset;
    }
  }

  &:after {
    content: "";
    position: absolute;
    background-color: white;
    top: -50%;
    left: -45%;
    transform: rotate(45deg);
    width: 20px;
    height: 200%;
    animation: slide-reverse 0.25s ease;
    opacity: 0.5;
  }

  /* Shine */
  &:hover:after {
    animation: slide 0.25s ease;
  }

  /* animation */

  @keyframes slide {
    from {
      left: -45%;
    }
    to {
      left: 120%;
    }
  }

  @keyframes slide-reverse {
    from {
      left: 120%;
    }
    to {
      left: -45%;
    }
  }
}
</style>
