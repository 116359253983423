<template>
  <div class="d-flex d-flex-c container">
    <label class="tags-label">{{ label }}</label>
    <div
      :class="[
        'd-flex d-flex-r tags-wrapper',
        { error: !!errors, 'is-input': isInput },
      ]"
    >
      <tag
        v-for="(tag, index) in tags"
        :key="index"
        :label="tag.label"
        @remove="$emit('remove-trigger', index)"
      />
      <input
        v-if="isInput"
        name="tag"
        class="tags-overlay"
        placeholder="Enter email adressess"
        @keypress="addTag"
      />
    </div>
    <span v-if="tagWrapperErrorMsg" class="tags-error">{{
      tagWrapperErrorMsg
    }}</span>
    <span v-if="actionLabel" class="action" @click="$emit('trigger')">{{
      actionLabel
    }}</span>
    <validation-provider mode="aggressive">
      <input
        :ref="inputRef"
        :name="label"
        class="tags-input"
        v-model="currentValue"
      />
    </validation-provider>
  </div>
</template>
<script>
import { ValidationProvider, validate } from "vee-validate";
import Tag from "./Tag.vue";

export default {
  name: "TagsWrapper",
  components: { Tag, ValidationProvider },
  props: {
    label: { type: String, default: "" },
    actionLabel: { type: String, default: "" },
    rules: { type: String, default: "" },
    tags: { type: Array, default: () => [] },
    disableSpace: { type: Boolean, default: false },
    value: { type: [String, Number, Array, Object] },
    isInput: { type: Boolean, default: false },
  },
  data() {
    return {
      inputRef: `${this.label}-input`,
      errors: null,
    };
  },
  mounted() {
    // The input must be focused/blurred so that
    // the changed validation will trigger on value change
    this.$refs[this.inputRef].focus();
    this.$refs[this.inputRef].blur();
  },
  computed: {
    currentValue: {
      get() {
        if (Array.isArray(this.value)) {
          return this.value.map((v) => v.id).toString();
        } else {
          return this.value;
        }
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    tagWrapperErrorMsg() {
      return this.errors ? this.errors[0] : null;
    },
  },
  methods: {
    addTag(e) {
      if (e.keyCode === 32) {
        // space
        e.preventDefault();
      }

      if (e.keyCode === 13) {
        // enter
        e.preventDefault();
        validate(e.target.value, this.rules).then((res) => {
          if (res.valid) {
            this.$emit("trigger", e.target.value);
            e.target.value = "";
            if (this.errors) {
              this.errors = null;
            }
          } else {
            this.errors = res.errors;
          }
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  margin-bottom: 1em;
  .tags-label {
    margin-bottom: 0.1em;
    font-size: 0.85rem;
    font-weight: bold;
  }
  .tags-wrapper {
    position: relative;
    flex-wrap: wrap;
    min-height: 75px;
    max-height: 350px;
    overflow-y: auto;
    padding: 0.75em 1em;
    background-color: #f1f9ff;
    border: 1px solid #bce0fd;
    border-radius: 6px;

    &.is-input {
      width: 500px;
    }
    &.error {
      border-color: red;

      .tags-overlay {
        color: red;
      }
    }
    .tags-overlay {
      background: transparent;
      display: flex;
      flex: 1;
      width: 100%;
      height: 36px;
      min-width: 100px;
      padding: 0.75em 1em;
      top: 0;
      left: 0;
      resize: none;
      border: none;
      font-size: 0.75rem;
      white-space: nowrap;
    }
  }
  .tags-error {
    position: relative;
    font-size: 0.75rem;
    color: red;
    right: 0;
  }
  .action {
    font-size: 0.75rem;
    text-align: left;
    padding-top: 0.25em;
    color: #39a4fc;
    text-decoration: underline;
    cursor: pointer;
  }
  .tags-input {
    height: 0;
    width: 0;
    opacity: 0;
  }
}
</style>
