<template>
  <div
    class="d-flex d-flex-c d-self-center pt-1 pb-1 h-center"
    style="position: relative; width: 100%; z-index: 100"
  >
    <div v-if="renderPaymentCompletion" class="d-flex d-flex-c d-flex-center">
      <p class="subscription success">Your trial has been activated!</p>
      <p class="mb-3">
        Congratulations, you can now try out any feature without any limit for
        14 days!
      </p>
      <content-button
        class="exit-profile"
        style="width: 275px; padding: 0.5em 2em"
        label="Continue to Dashboard"
        @button-click="navigateToDashboard"
      />
    </div>
    <div v-else class="d-flex d-flex-c">
      <div class="d-flex d-flex-r d-flex-between ml-1 mr-1 mb-2">
        <div class="d-flex d-flex-c">
          <h3>Your Plan: {{ currentTier }}</h3>
          <h3>Try any paid plan free for 14 days</h3>
        </div>
        <div class="d-flex d-align-self-end">
          <interval-selector @interval-click="changeBillingCycle" />
        </div>
      </div>
      <div class="d-flex d-flex-center flex-wrap subscriptions-wrapper">
        <subscription-plan
          v-for="(product, index) in products"
          :key="index"
          :subscriptionType="product.subscriptionPlan"
          :subscriptionLabel="product.subscriptionLabel"
          :subscriptionFeatures="features"
          :expanded="featuresExpanded"
          :trial-activated="trialActivated"
          :billingCycle="selectedBillingCycle"
          :is-active="
            (currentTier === 'Grounded' &&
              currentTier === product.subscriptionLabel) ||
            (currentTier === product.subscriptionLabel &&
              currentBillingCycle === selectedBillingCycle)
          "
          :price="product.price[selectedBillingCycle]"
          @toggle-expand="toggleExpand"
          @select-plan="selectPlan"
        />
      </div>
      <button
        @click="navigateToDashboard"
        class="d-flex d-flex-center mt-1 ml-1 mr-1 return-dashboard"
      >
        Not Now
      </button>
    </div>
  </div>
</template>
<script>
import dayjs from "@/utils/timezone";
import { mapState, mapActions } from "vuex";

import { IntervalSelector, SubscriptionPlan } from ".";
import { ContentButton } from "@/components/ui";

export default {
  name: "SubscriptionSelection",
  components: { SubscriptionPlan, IntervalSelector, ContentButton },
  beforeDestroy() {
    if (this.renderPaymentCompletion) {
      this.resetPaymentCompletion();
    }
  },
  data() {
    return {
      selectedBillingCycle: "monthly",
      featuresExpanded: false,
      selectedPlan: "",
      paymentCompletionRedirect: false,
    };
  },
  computed: {
    ...mapState({
      products: (state) =>
        state.subscription.products.slice().sort((a, b) => {
          const priceA = a.price.monthly;
          const priceB = b.price.monthly;
          if (priceA < priceB) {
            return -1;
          }
          if (priceA > priceB) {
            return 1;
          }
          return 0;
        }),
      features: (state) => state.subscription.features,
      renderPaymentCompletion: (state) =>
        state.subscription.status.render.paymentCompletion,
      billingInfo: (state) => state.subscription.billing,
    }),
    currentTier() {
      return (
        this.products.find(
          (product) =>
            product.subscriptionPlan === this.billingInfo.product_tier,
        ) || {}
      ).subscriptionLabel;
    },
    currentBillingCycle() {
      return this.billingInfo.billing_cycle;
    },
    trialActivated() {
      return this.billingInfo.trial_activated;
    },
    isTrialing() {
      const { trial_end } = this.billingInfo;
      if (!trial_end) {
        return false;
      }
      return dayjs().isBefore(dayjs(trial_end * 1000));
    },
    requirePaymentDetails() {
      return (
        this.$route.query.action === "payment" ||
        (this.selectedPlan !== "grounded" &&
          !this.isTrialing &&
          this.billingInfo.trial_activated &&
          Object.keys(this.billingInfo.payment_method_info).length === 0)
      );
    },
  },
  methods: {
    ...mapActions({
      resetPaymentCompletion: "resetPaymentCompletion",
      setActiveSubscriptionModal: "setActiveSubscriptionModal",
    }),
    changeBillingCycle(cycle) {
      this.selectedBillingCycle = cycle;
    },
    selectPlan(plan) {
      this.selectedPlan = plan;
      if (this.requirePaymentDetails) {
        this.$router.push({
          path: `/plans/${this.selectedPlan}?interval=${this.selectedBillingCycle}`,
        });
      } else {
        this.setActiveSubscriptionModal({
          name: "ChangePlanModal",
          props: {
            plan: this.selectedPlan,
            interval: this.selectedBillingCycle,
            planDetails: {
              ...this.products.find(
                (product) => product.subscriptionPlan === this.selectedPlan,
              ),
              currentSubscription: this.currentTier,
            },
          },
        });
      }
    },
    toggleExpand() {
      this.featuresExpanded = !this.featuresExpanded;
    },
    navigateToDashboard() {
      this.$router.push("/");
    },
  },
};
</script>
<style lang="scss" scoped>
.subscriptions-wrapper {
  width: 100%;
  justify-content: space-between;
}

.return-dashboard {
  padding: 0.5em;
  border: none;
  background-color: #f5f7ff;
  outline: 1px solid #dce4ec;
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }
}

h3 {
  margin-top: 0;
}

.subscription {
  margin-bottom: 1rem;
  &.success {
    position: relative;
    color: green;
    font-size: 2rem;

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: -75px;
      width: 30px;
      height: 50px;
      border: solid green;
      border-width: 0 12px 12px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}
</style>
